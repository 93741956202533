import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { useHistory } from 'react-router-dom';

// mui
import { Avatar, Box, Button, Typography, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';

// import raisisLogo from './raisis.svg';
import darexLogo from 'resources/svg/app-bar/darex-logo.svg';
import raisisLogo from 'resources/svg/app-bar/raisis-logo.svg';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import DefaultUserPicture from 'resources/img/user/DefaultUserPicture.png';
import { useUser } from 'hooks/useUser';
import { UsersAPI } from 'api_darex';

function Appbar({ logoRaisis, logoDarex, onMenuClick }) {
    const { user } = useUser();
    const { vw } = useWindowDimensions();

    const [profilePic, setProfilePic] = useState('');
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const imgId = user?.ProfilePicture[user.ProfilePicture.length - 1]?.file.fileName;
            if (imgId) {
                const { data: imgUrl } = await UsersAPI.getPicture(imgId);
                setProfilePic(imgUrl);
            }
        })();
    }, [user]);

    return (
        <Box
            sx={{
                backgroundColor: '#FFFFFF',
                display: 'flex',
                justifyContent: vw >= 640 ? 'space-between' : 'flex-end',
                alignItems: 'center',
                width: '100%',
                height: '100%',
            }}
        >
            <img
                onClick={() => history.push('/')}
                style={{
                    backgroundColor: 'rgba(0,0,0,0)',
                    width: '158px',
                    height: '49px',
                    display: vw >= 640 ? 'block' : 'none',
                    marginLeft: vw >= 1080 ? '4rem' : '1rem',
                    cursor: 'pointer',
                }}
                src={logoRaisis}
                alt="logo"
            />

            <Avatar
                variant="square"
                sx={{
                    backgroundColor: 'rgba(0,0,0,0)',
                    display: vw >= 768 ? 'block' : 'none',
                    width: '137px',
                    height: '59px',
                }}
                src={logoDarex}
            />

            {/* actions */}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                {/* notifications */}

                {/* <div
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <Badge
                        onClick={() => {
                            history.push('/profile/?tab=2');
                        }}
                        badgeContent={5}
                        sx={{
                            color: '#929BAA',
                            '& .MuiBadge-badge': {
                                color: '#FFFFFF',
                                backgroundColor: '#5A7FBA',
                                border: '2px solid #FFFFFF',
                                borderRadius: '50%',
                                right: '2px',
                                top: '3px',
                            },
                        }}
                    >
                        <NotificationsIcon
                            sx={{
                                fontSize: '27px',
                            }}
                        />
                    </Badge>
                </div> */}

                {/* user */}

                <div
                    style={{
                        marginRight: vw >= 1080 ? '4rem' : '0',
                        padding: '10px 10px',
                        display: 'flex',
                        flexDirection: 'row',
                        cursor: 'pointer',
                    }}
                    onClick={() => history.push('/profile/?tab=1')}
                >
                    <Avatar
                        sx={{
                            backgroundColor: '#BDBDBD',
                            borderRadius: '50%',
                            mr: '1rem',
                        }}
                        src={profilePic ? profilePic : DefaultUserPicture}
                    />

                    <Typography
                        sx={{
                            color: '#929BAA',
                            textTransform: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {user.firstName + ' ' + user.name}
                    </Typography>
                </div>

                {/* menu */}
                {vw <= 1080 && (
                    <Button onClick={onMenuClick} sx={{ color: '#929BAA' }}>
                        <MenuIcon
                            sx={{
                                fontSize: '2rem',
                            }}
                        />
                    </Button>
                )}
            </Box>
        </Box>
    );
}

Appbar.propTypes = {
    bgcolor: PropTypes.string,
    logoRaisis: PropTypes.node,
    logoDarex: PropTypes.node,
    onMenuClick: PropTypes.func,
};

Appbar.defaultProps = {
    bgcolor: 'primaryCustom',
    logoRaisis: raisisLogo,
    logoDarex: darexLogo,
    onMenuClick: () => {},
};

export default Appbar;
