const ro = {
    'Search offer': 'Caută ofertă',
    'FILTER OFFERS': 'FILTRARE OFERTE',
    'ADD OFFER': 'ADAUGĂ OFERTĂ',
    'Manage offers': 'Administrează ofertele',
    Brand: 'Marcă',
    Actions: 'Acțiuni',
    Opened: 'Deschisă',
    Completed: 'Finalizată',
    Accept: 'Acceptă',
    Refuse: 'Refuză',
    Rebid: 'Reofertează',
    step: 'pas',
    Offer: 'Ofertă',
    'Create offer': 'Creare ofertă',
    'Create a new offer': 'Creare ofertă nouă',
    'Add units': 'Adaugă unități',
    Continue: 'Continuă',
    'Manage offer': 'Administrează ofertă',
    'OFFER ID': 'ID OFERTĂ',
    Unfinished: 'Nefinalizată',
    'Purchase type': 'Tip achiziție',
    'Payment type': 'Tip plată',
    Cash: 'Numerar',
    'Test Drive': 'Testul Drive',
    scheduled: 'programat',
    'Other comments': 'Alte comentarii',
    'Recontract date': 'Dată recontactare',
    'RCI document submission date:': 'Dată trimitere dosar RCI:',
    optional: 'opțional',
    'VIEW UNITS ON OFFER': 'VEZI UNITĂȚILE DE PE OFERTĂ',
    Documents: 'Documente',
    'Identity card': 'Buletin',
    'ADD DOCUMENT': 'ADAUGĂ DOCUMENT',
    Options: 'Opțiuni',
    Motorisation: 'Motorizare',
    Accessories: 'Accesorii',
    'Edit status': 'Modifică status',
    'Viewing order': 'Vizualizare comandă',
    'Acquisition type': 'Tip achiziție',
    Order: 'Comandă',
    'Manage order': 'Gestionați comanda',
    'Edit drive test date': 'Editați data drive testului',
    'Edit recontact date': 'Editați data recontactării',
    'Edit offer': 'Editați oferta',
    'The drive test date has been updated successfully!': 'Data testului de unitate a fost actualizată cu succes!',
    'Something went wrong! The drive test date could not be updated!':
        'Ceva n-a mers bine! Data testului de unitate nu a putut fi actualizată!',
    'The recontact date has been updated successfully!': 'Data recontactării a fost actualizată cu succes!',
    Price: 'Preț',
    'Write the reason why the offer was refused': 'Scrieți motivul pentru care oferta a fost refuzată',
    Reason: 'Motiv',
    'Reason is mandatory!': 'Motivul este obligatoriu!',
    'This reason is not a valid one!': 'Motivul nu este valid!',
    'The reason has to contain at least three characters!': 'Motivul trebuie să conțină cel puțin 3 caractere!',
    'Accepted offer': 'Ofertă acceptată',
    'Refused offer': 'Ofertă refuzată',
    Reoffered: 'Reofertată',
    Reoffer: 'Reofertare',
    'Reoffer from the offer': 'Reofertare din oferta',
    Units: 'Unități',
    'Unselect client': 'Deselectează client',
    'Are you sure you want to unselect this client?': 'Sunteți sigur(ă) că doriți să deselectați acest client?',
    'Add unit': 'Adaugă unitate',
    'Are you sure you want to delete this unit?': 'Sunteți sigur(ă) că doriți să eliminați această unitate?',
    'No units': 'Nu există unități',
    'Visualize units': 'Vizualizează unități',
    'Select unit options': 'Selectează opțiunile unității',
    'Additional information': 'Informații suplimentare',
    'Scheduled at': 'Programat la data',
    'Drive test date': 'Dată drive test',
    'Recontact date': 'Dată recontactare',
    'Additional comments on offer': 'Comentarii suplimentare la ofertă',
    'Filter offers': 'Filtrați ofertele',
    Brands: 'Mărci',
    'Are you sure you want to accept this offer?': 'Sigur doriți să acceptați această ofertă?',
    'Reoffered from the offer:': 'Reoferteaza',
    'Offer reoffered': 'Ofertă reofertată',
    'Write the reason why the offer was reoffered': 'Scrieți motivul reofertării',
    Models: 'Modele',
    Open: 'Deschisă',
    Accepted: 'Acceptată',
    Refused: 'Refuzată',
    on: 'pe',
    'WITHOUT STATUS': 'FĂRĂ STATUS',
    'WITHOUT BRAND': 'FĂRĂ MARCĂ',
    'WITHOUT MODEL': 'FĂRĂ MODEL',
    'The reason for the refusal': 'Motivul refuzului',
    'Offer details': 'Detalii oferte',
    'The reason for reoffering': 'Motivul reofertării',
    'Client type': 'Tip client',
    Individual: 'Individual',
    Legal: 'Juridic',
    'View client': 'Vizualizați clientul',
    'No document has been uploaded': 'Nu a fost încărcat niciun dosar',
    'The test drive is not scheduled': 'Test drive nu este programat',
    'The RCI file date has been updated successfully!': 'Data dosarului RCI a fost actualizată cu succes!',
    'Something went wrong! The RCI file date could not be updated!':
        'Ceva n-a mers bine! Data dosarului RCI nu a putut fi actualizată!',
    'Edit RCI file date': 'Editați data fișierului RCI',
    'see the new offer': 'vezi noua ofertă',
    Others: 'Aletele',
    'Document not submitted': 'Dosar nedepus',
    'Document submitted': 'Dosar depus',
    'AFM document': 'Dosar AFM',
    'Reoffered with the offer': 'Reofertată cu oferta',
    'See the old offer': 'Vezi oferta veche',
    'Offer was marked as accepted!': 'Oferta a fost marcată ca fiind acceptată!',
    'Something went wrong! Offer could not be marked as accepted!':
        'Something went wrong! Offer could not be marked as accepted!',
    'Offer was marked as refused!': 'Oferta a fost marcată ca fiind refuzată!',
    'Something went wrong! Offer could not be marked as refuse!':
        'Ceva n-a mers bine! Oferta nu a putut fi marcată ca refuz!',
    'No date has never been introduced until now': 'Nicio dată nu a fost introdusă până acum',
    'No chassis number has been entered until this moment':
        'Nu a fost introdus niciun număr de șasiu până în acest moment',
    'Out of production': 'Ieşit din producţie',
    Created: 'Creată',
    'Order launched': 'Comanda lansată',
    'Assigned chassis number': 'Număr de șasiu atribuit',
    'In transport': 'În transport',
    'Arrived in the park': 'Ajuns în parc',
    Unverified: 'Neverificat',
    Valid: 'Validat',
    Invalid: 'Nevalidat',
    'Order status': 'Status comandă',
    'Validation status': 'Status validare',
    'Change the status of the order': 'Editați starea comenzii',
    'Modificați starea de validare': 'Editați starea de validare',
    'Choose a valid option type!': 'Alegeți un tip de opțiune valid!',
    'The option is mandatory!': 'Opțiunea este obligatorie!',
    'The order status has been updated successfully!': 'Statusul comenzii a fost actualizată cu succes!',
    'The order status could not be updated successfully!': 'Statusul comenzii nu a putut fi actualizată cu succes!',
    'The validation status has been updated successfully!': 'Starea de validare a fost actualizată cu succes!',
    'The validation status could not be updated successfully!':
        'Starea de validare nu a putut fi actualizată cu succes!',
    'Old offer': 'Oferta veche',
    'View old offer': 'Vezi oferta veche',
    'View new offer': 'Vezi oferta noua',
    'Edit date for advanced payment document': 'Editați data pentru documentul destinat plății în avans',
    'The date of the document for advanced payment has been updated successfully!':
        'Data documentului de plată în avans a fost actualizată cu succes!',
    'The date of the document for advanced payment could not be updated successfully!':
        'Data documentului pentru plata în avans nu a putut fi actualizată cu succes!',
    'Edit the date for payment in advance': 'Editați data plății în avans',
    'The date for payment in advance has been updated successfully!':
        'Data plății în avans a fost actualizată cu succes!',
    'The date for payment in advance could not be updated successfully!':
        'Data plății în avans nu a putut fi actualizată cu succes!',
    'Edit the estimated production date': 'Editați data estimată de producție',
    'The estimated production date has been updated successfully!':
        'Data estimată de producție a fost actualizată cu succes!',
    'The estimated production date could not be updated successfully!':
        'Data estimată de producție nu a putut fi actualizată cu succes!',
    'Edit the ex-factory date': 'Editați data ieșirii din fabrică',
    'The ex-factory date has been updated successfully!': 'Data de ieșire din fabrică a fost actualizată cu succes!',
    'The ex-factory date could not be updated successfully!':
        'Datele ieșirii din fabrică nu au putut fi actualizate cu succes!',
    'Edit the Darex park entry date': 'Editați data de intrare în parcul Darex',
    'The Darex park entry date has been updated successfully!':
        'Data de intrare în parcul Darex a fost actualizată cu succes!',
    'The Darex park entry date could not be updated successfully!':
        'Data de intrare în parcul Darex nu a putut fi actualizată cu succes!',
    'Edit the date set for delivery': 'Editați data setată pentru livrare',
    'The date set for delivery has been updated successfully!':
        'Data stabilită pentru livrare a fost actualizată cu succes!',
    'The date set for delivery could not be updated successfully!':
        'Data stabilită pentru livrare nu a putut fi actualizată cu succes!',
    'The chassis series is mandatory!': 'Seria de șasiu este obligatorie!',
    'Edit chassis number': 'Editați numărul șasiului',
    'The chassis number has been updated successfully!': 'Numărul șasiului a fost actualizat cu succes!',
    'The chassis number could not be updated successfully!': 'Numărul șasiului nu a putut fi actualizat cu succes!',
    'Chassis Number': 'Numarul de sasiu',
    'Edit transfer request date': 'Editați cererea de transfer de date',
    'The transfer request date has been updated successfully!':
        'Data cererii de transfer a fost actualizată cu succes!',
    'The transfer request date could not be updated successfully!':
        'Data solicitării transferului nu a putut fi actualizată cu succes!',
    'Edit cancelling billing date': 'Editați data de anulare a facturării',
    'The cancelling billing date has been updated successfully!':
        'Data de anulare a facturării a fost actualizată cu succes!',
    'The cancelling billing date could not be updated successfully!':
        'Data de anulare a facturării nu a putut fi actualizată cu succes!',
    'Edit rebilling date': 'Editați data de refacturare',
    'The rebilling date has been updated successfully!': 'Data de refacturare a fost actualizată cu succes!',
    'The rebilling date could not be updated successfully!': 'Data de refacturare nu a putut fi actualizată cu succes!',
    'Edit final invoice date': 'Editați data finală a facturării',
    'The final invoice date has been updated successfully!': 'Data finală a facturii a fost actualizată cu succes!',
    'The final invoice date could not be updated successfully!':
        'Data finală a facturii nu a putut fi actualizată cu succes!',
    'Edit the delivery date': 'Editați data livrării',
    'The delivery date has been updated successfully!': 'Data de livrare a fost actualizată cu succes!',
    'The delivery date could not be updated successfully!': 'Data de livrare nu a putut fi actualizată cu succes!',
    Delivered: 'Livrată',
    'Are you sure you want to mark this order as complete?': 'Sigur doriți să marcați această comandă ca finalizată?',
    'The order is completed': 'Comanda este finalizată',
    'The order has been marked as completed!': 'Comanda a fost setată ca fiind terminată!',
    'The order could not be marked as completed!': 'Comanda nu a putut fi setată ca fiind terminată!',
    'Choose a brand': 'Alegeți o marcă',
    'Choose a model': 'Alegeți un model',
    'Choose a color': 'Alegeți o culoare',
    'Choose an engine model': 'Alegeți o motorizare',
    'Choose a price': 'Alegeți un preț',
    'Unit has been successfully deleted': 'Unitatea a fost ștearsă cu succes',
    'Choose a client first!': 'Selectați un client pentru a începe!',
    'Select at least one unit first!': 'Selectați/configurați cel puțin o unitate pentru a continua!',
    'Offer created successfully': 'Ofertă creată cu succes',
    'Change the validation status': 'Schimbă statusul de validare',
    Yes: 'Da',
    No: 'Nu',
    YES: 'DA',
    NO: 'NU',
    'Your client has been activated': 'Clientul a fost activat',
    Other: 'Alta',
    'File submitted': 'Dosar depus',
    'File not submitted': 'Dosar nedepus',
    'AFM validated file': 'Dosar validat AFM',
    'Advance payment': 'Plată avans',
    'Full payment': 'Plată integrală',
    'Payment of the financing file': 'Plată dosar finanțare',
    'Duplicate unit': 'Duplicare unitate',
    'Edit unit': 'Editare unitate',
    'Delete unit': 'Ștergere unitate',
    'Unit has been successfully edited': 'Unitatea a fost editată cu succes!',
    'go to latest': 'mergi la ultimul mesaj',
    'This client is your dedicated client now': 'Acest client este clientul dumneavoastră din acest moment',
    Unit: 'Unitate',
    // Reoffer: 'Reofertează',
    CLOSE: 'ÎNCHIDE',
    'No name has been inserted! Please enter a name for the document file!':
        'Nici un nume nu a fost introdus! Te rog, introdu o denumire a documentului!',
    'No file has been uploaded! Please upload a file!': 'Nici un fișier nu a fost încarcat! Te rog, încarcă un fișier!',
    Hybrid: 'Hibrid',
    'Other funder': 'Alt finanțator',
    'Cancel order': 'Anulați comanda',
    'The order is canceled': 'Comanda este anulată',
    'The counselor on this order has no objectives set in the month of the selected date!':
        'Consilierul de pe această comandă nu are obiective setate în luna datei selectate!',
    'Are you sure you want to cancel this order?': 'Ești sigur că vrei să anulezi această comandă?',
    'The order has been marked as canceled!': 'Comanda a fost anulată!',
    'The order could not be marked as canceled!': 'Comanda nu a putut fi anulată!',
    Cancelled: 'Anulată',
    'Download image': 'Descărcați imaginea',
    'View options': 'Vezi opțiuni',
    'View more details': 'Vezi mai multe detalii',
};

export default { ro };
