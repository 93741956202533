import axios from './axios';

const Objectives = {
    /**
     * Fetches all Objectives's
     *
     * @return {array} Array of Objectives's
     */
    async get(body, perPage, currentPage, pagesToLoad) {
        try {
            const res = await axios.get('/objectives/brandId', {
                params: {
                    ...body,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Objectives's by user ID
     *
     * @return {array} Array of Objectives's
     */
    async getByUserId(userId, startDate) {
        try {
            const res = await axios.get('/objectives/userId', {
                params: {
                    userId,
                    startDate,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Objectives's by user ID and brand ID
     *
     * @return {array} Array of Objectives's
     */
    async getByUserBrandId(data, perPage, currentPage, pagesToLoad) {
        const reqBody = { ...data, perPage, currentPage, pagesToLoad };

        try {
            const res = await axios.get('/objectives/userBrandId', {
                params: reqBody,
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches one Objectives by it's ID
     *
     * @param {string} id ID of the Objectives
     * @return {object} Objectives with the corresponding ID
     */
    async getById(objectivesId) {
        try {
            const res = await axios.get('/objectives/' + objectivesId);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Objectives
     *
     * @param {object} objectives Object with the creation data
     * @return {object} Newly created Objectives
     */
    async create(objectives) {
        let reqBody = { ...objectives };
        try {
            const res = await axios.post('/objectives', reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Deletes Objectives by ID
     *
     * @param {string} objectivesId ID of the Objectives to be deleted
     * @return {string} ID of the deleted Objectives
     */
    async delete(objectivesId) {
        try {
            await axios.delete('/objectives/' + objectivesId);
            return {
                ok: true,
                data: {
                    id: objectivesId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: objectivesId,
                },
            };
        }
    },

    async update(id, objective) {
        let reqBody = { ...objective };
        try {
            const res = await axios.patch('/objectives/' + id, reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: error,
            };
        }
    },

    /**
     * Fetches all objectives witch contain the the specified string
     *
     * @param {string} text used to find objectives
     * @return {object} Array of objectives's
     */
    async searchObjectives(text) {
        try {
            const res = await axios.get(`/objectives/findByString/text?text=${text}`);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all objectives witch contain the the specified string
     *
     * @param {string} userId used to find the userId
     *  @param {string} startDate start date of the period
     *  @param {string} endDate endDate of the period
     * @return {object} Array of objectives's
     */
    async filterObjectives(filterData) {
        try {
            const res = await axios.get('/objectives/filter/objectives', {
                params: {
                    userId: filterData.userId,
                    startDate: filterData.startDate,
                    endDate: filterData.endDate,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all Objectives's by user ID
     * @param {string} userId the id of the user
     *
     * @return {array} Array of Objectives's
     */
    async getAllCounselorsForSefVanzari(data) {
        try {
            const res = await axios.get('/objectives/all/forUser', {
                params: {
                    ...data,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },
};

export default Objectives;
