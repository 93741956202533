import axios from './axios';

const Workpoints = {
    /**
     * Fetches all Workpoints's
     *
     * @return {array} Array of Workpoints's
     */
    async get() {
        try {
            const res = await axios.get('/workPoints');
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches one Workpoints by it's ID
     *
     * @param {string} id ID of the Workpoints
     * @return {object} Workpoints with the corresponding ID
     */
    async getById(workpointsId) {
        try {
            const res = await axios.get('/workPoints/' + workpointsId);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Workpoints
     *
     * @param {object} workpoints Object with the creation data
     * @return {object} Newly created Workpoints
     */
    async create(workpoints) {
        let reqBody = { ...workpoints };
        try {
            const res = await axios.post('/workPoints', reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },

    /**
     * Deletes Workpoints by ID
     *
     * @param {string} workpointsId ID of the Workpoints to be deleted
     * @return {string} ID of the deleted Workpoints
     */
    async delete(workpointsId) {
        try {
            await axios.delete('/workPoints/' + workpointsId);
            return {
                ok: true,
                data: {
                    id: workpointsId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: workpointsId,
                },
            };
        }
    },

    /**
     * Update Workpoints by ID
     *
     * @param {object} workpoints Object with the creation data
     * @param {string} workpointsId ID of the Workpoints to be updated
     * @return {string} ID of the updated Workpoints
     */
    async update(workpointsId, workpoints) {
        let reqBody = { ...workpoints };
        try {
            const res = await axios.patch('/workPoints/' + workpointsId, reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },
};

export default Workpoints;
