import React, { useEffect, useState, useRef } from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { NavigationButton } from 'lib/components/Buttons/buttons';
import { UtilityButton } from 'lib/components/Buttons/buttons';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useHistory } from 'react-router-dom';

import * as yup from 'yup';

import { useTranslation } from 'react-i18next';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { VehiclesAPI } from 'api_darex';

import Step1ModelForm from './Step1ModelForm';
import Step2ColorForm from './Step2ColorForm';
import Step3EngineForm from './Step3EngineForm';

const StepperComponent = ({ brand, updateData }) => {
    const { vw } = useWindowDimensions();
    const { t } = useTranslation();
    const steps = [t('Model'), t('Color'), t('Engine')];
    const history = useHistory();

    const [rowId, setRowId] = useState('');

    const [colors, setColors] = useState([]);
    const [engines, setEngines] = useState([]);

    //if we have data for updates we set the new model with that
    const [newModel, setNewModel] = useState(updateData ? updateData : {});

    const getAllColors = () => {
        VehiclesAPI.getAllColors(brand).then((res) => setColors(res.data));
    };
    const getAllEngines = () => {
        VehiclesAPI.getAllEngines(brand).then((res) => setEngines(res.data));
    };

    useEffect(() => {
        getAllColors(brand);
        getAllEngines(brand);
    }, [brand]);

    //refs for each step
    const formRefModel = useRef();
    const formRefChooseColor = useRef();
    const formRefChooseEngine = useRef();

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        if (formRefModel.current) {
            formRefModel.current.handleSubmit();
        }

        if (formRefChooseColor.current) {
            formRefChooseColor.current.handleSubmit();
        }

        if (formRefChooseEngine.current) {
            formRefChooseEngine.current.handleSubmit();
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setNewModel({});
    };

    //setting the initial state for step 1, with the update data or empty string if creating new model
    const INITIAL_FORM_STATE = {
        modelName: newModel.modelName ? newModel.modelName : '',
        modelType: newModel.modelType ? newModel.modelType : '',
    };
    const FORM_VALIDATION = yup.object().shape({
        // Cheile trebuie sa aiba aceasi denumire ca in INITIAL_FORM_STATE
        modelName: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(3, t('The name has to contain at least three characters'))
            .required(t('The name of the model is mandatory!')),
        modelType: yup
            .string() // Tipul valorii
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(3, t('The name has to contain at least three characters'))
            .required(t('The type of the model is mandatory!')),
    });

    const INITIAL_FORM_STATE_COLORS = {
        colors: newModel?.colors ? newModel.colors : [],
    };

    const FORM_VALIDATION_COLORS = yup.object().shape({
        // Cheile trebuie sa aiba aceasi denumire ca in INITIAL_FORM_STATE
        colors: yup
            .array()
            .of(yup.string().typeError('error'))
            .min(1, t('Choose at least one color'))
            .required(t('Color is mandatory')),
    });

    const INITIAL_FORM_STATE_ENGINES = {
        engines: newModel?.engines ? newModel.engines : [],
    };

    const FORM_VALIDATION_ENGINES = yup.object().shape({
        // Cheile trebuie sa aiba aceasi denumire ca in INITIAL_FORM_STATE
        engines: yup
            .array()
            .of(yup.string().typeError('error'))
            .min(1, t('Choose at least one engine'))
            .required(t('Engine is mandatory')),
    });

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <Step1ModelForm
                        newModel={newModel}
                        setNewModel={setNewModel}
                        brand={brand}
                        setActiveStep={setActiveStep}
                        formRefModel={formRefModel}
                        intialStateModel={INITIAL_FORM_STATE}
                        validationStateModel={FORM_VALIDATION}
                    />
                );
            case 1:
                return (
                    <Step2ColorForm
                        newModel={newModel}
                        setNewModel={setNewModel}
                        brand={brand}
                        rowId={rowId}
                        setRowId={setRowId}
                        setActiveStep={setActiveStep}
                        formRefChooseColor={formRefChooseColor}
                        colors={colors}
                        getAllColors={getAllColors}
                        initialStateColors={INITIAL_FORM_STATE_COLORS}
                        validationStateColors={FORM_VALIDATION_COLORS}
                    />
                );
            case 2:
                return (
                    <Step3EngineForm
                        newModel={newModel}
                        setNewModel={setNewModel}
                        brand={brand}
                        rowId={rowId}
                        setRowId={setRowId}
                        setActiveStep={setActiveStep}
                        formRefChooseEngine={formRefChooseEngine}
                        initialStateEngines={INITIAL_FORM_STATE_ENGINES}
                        validationStateEngines={FORM_VALIDATION_ENGINES}
                        engines={engines}
                        getAllEngines={getAllEngines}
                        updateData={updateData}
                    />
                );

            default:
                return 'Unknown step';
        }
    }

    return (
        <Box
            sx={{
                width: '100%',
                boxSizing: 'border-box',
                borderRadius: '10px',
                padding: '2rem',

                '& .MuiStepper-root': { alignItems: 'center' },
            }}
        >
            <Stepper activeStep={activeStep} orientation={vw <= 670 ? 'vertical' : 'horizontal'}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    return (
                        <Step
                            sx={{
                                '& .MuiStepLabel-root .Mui-completed': {
                                    color: '#5B80BA',
                                    fontWeight: 600,
                                },
                                '& .MuiStepIcon-root.Mui-active': { color: '#5B80BA' },
                                '& .MuiStepIcon-root.Mui-completed': { color: '#5B80BA' },
                            }}
                            key={label}
                            {...stepProps}
                        >
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === steps.length ? (
                <React.Fragment>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '3rem 0 1rem 0',
                            gap: '1.5rem',
                            flexDirection: vw >= 640 ? 'row' : 'column',
                        }}
                    >
                        <UtilityButton
                            variant="outlined"
                            color="blue"
                            onClick={() =>
                                history.push({
                                    pathname: '/vehicles',
                                    state: { savedTab: Number(brand) - 1 },
                                })
                            }
                        >
                            {t('Visualize models')}
                        </UtilityButton>

                        <UtilityButton variant="outlined" color="green" onClick={handleReset}>
                            {t('Create new model')}
                        </UtilityButton>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div>{getStepContent(activeStep)}</div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        {activeStep !== 0 && (
                            <NavigationButton
                                startIcon={<ArrowBackIosIcon fontSize="small" sx={{}} />}
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                {t('Back')}
                            </NavigationButton>
                        )}
                        <Box sx={{ flex: '1 1 auto' }} />

                        <NavigationButton variant="contained" onClick={handleNext}>
                            {activeStep === steps.length - 1 ? t('Finish') : t('Next')}
                        </NavigationButton>
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
};

export default StepperComponent;
