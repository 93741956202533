import axios from './axios';

const Comments = {
    /**
     * Fetches offer comments
     *
     * @return {array} Array of Comments's
     */
    async get(offerId) {
        try {
            const res = await axios.get('/offers/get/comments', {
                params: {
                    offerId,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches offerModel comments
     *
     * @return {array} Array of Comments's
     */
    async getOfferModel(offerModelId) {
        try {
            const res = await axios.get('/offerModel/get/comments', {
                params: {
                    offerModelId,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches one Comments by it's ID
     *
     * @param {string} id ID of the Comments
     * @return {object} Comments with the corresponding ID
     */
    async getById(commentsId) {
        try {
            const res = await axios.get('/comments/' + commentsId);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Comment for offers
     *
     * @param {object} comments Object with the creation data
     * @return {object} Newly created Comments
     */
    async create(comments) {
        try {
            const res = await axios.post(
                `/offers/comments?userId=${comments.userId}&offerId=${comments.offerId}&comment=${comments.comment}`
            );
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Comment for offerModel
     *
     * @param {object} comments Object with the creation data
     * @return {object} Newly created Comments
     */
    async createOfferModel(comments) {
        try {
            const res = await axios.post(
                `/offerModel/comments?userId=${comments.userId}&offerModelId=${comments.offerModelId}&comment=${comments.comment}`
            );
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Deletes Comments by ID
     *
     * @param {string} commentsId ID of the Comments to be deleted
     * @return {string} ID of the deleted Comments
     */
    async delete(commentsId) {
        try {
            await axios.delete('/comments/' + commentsId);
            return {
                ok: true,
                data: {
                    id: commentsId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: commentsId,
                },
            };
        }
    },
};

export default Comments;
