import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';
import { PrimaryButton, RemoveButton, EditButton } from 'lib/components/Buttons/buttons';
import AddIcon from '@mui/icons-material/Add';
import { CustomTable } from 'lib';
import dayjs from 'dayjs';
import AddWorkPoint from './AddWorkPoint';
import ConfirmModal from 'lib/components/Modals/ConfirmModal';
import { WorkPointsAPI } from 'api_darex';
import { toast } from 'react-toastify';
import { PageLayout } from 'lib';
import { useUser } from 'hooks/useUser';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

const WorkPoint = () => {
    const [points, setPoints] = useState([]);

    const { t } = useTranslation();

    const [rowState, setRowState] = useState(false);
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [loading, setLoading] = useState(true);
    const { can } = useUser();
    const history = useHistory();

    const getAllWorkPoints = () => {
        WorkPointsAPI.get().then((res) => {
            setPoints(res.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (!can(18)) {
            history.goBack();
        }
        getAllWorkPoints();
    }, []);

    const labels = [
        {
            id: 'name',
            label: t('Location'),
            minWidth: 10,
        },

        {
            id: 'createdAt',
            label: t('Creation date'),
            minWidth: 10,
        },
        {
            id: 'actions',
            minWidth: 10,
        },
    ];

    const cellModifier = (row, column, value) => {
        if (column.id === 'createdAt') {
            return <div style={{ display: 'flex' }}>{dayjs(value).format('DD MMM YYYY')}</div>;
        }
        if (column.id === 'actions') {
            return (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {can(17) && (
                        <EditButton
                            color="#1860D2"
                            onClick={() => {
                                setRowState(row);
                                setOpen(true);
                            }}
                        />
                    )}
                    {can(16) && (
                        <RemoveButton
                            onClick={() => {
                                setRowState(row);
                                setOpenConfirm(true);
                            }}
                        />
                    )}
                </div>
            );
        }
        return <Typography variant="tableContent">{value}</Typography>;
    };

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Work points')}</title>
            </Helmet>
            <PageLayout
                title={t('Work points')}
                subTitle={t('Management of work points')}
                actionArea={
                    can(17) && (
                        <PrimaryButton
                            startIcon={<AddIcon />}
                            color="primary"
                            onClick={() => {
                                setRowState(null);
                                setOpen(true);
                            }}
                        >
                            {t('Add work point')}
                        </PrimaryButton>
                    )
                }
            >
                <CustomTable
                    title={t('Work points')}
                    loading={loading}
                    labels={labels}
                    tableData={points}
                    cellModifier={cellModifier}
                    withPagination={false}
                />
            </PageLayout>
            <AddWorkPoint open={open} setOpen={setOpen} rowState={rowState} getAllWorkPoints={getAllWorkPoints} />
            <ConfirmModal
                open={openConfirm}
                setOpen={setOpenConfirm}
                onClickButtonYes={() =>
                    WorkPointsAPI.delete(rowState.id).then((res) => {
                        if (res.ok === true) {
                            getAllWorkPoints();
                            setOpenConfirm(false);
                            toast.success(t('The work point was successfully deleted!'));
                        } else {
                            toast.error(t('Something went wrong! This work point could not be deleted!'));
                        }
                    })
                }
                text={t('Are you sure you want to delete this work point?')}
            />
        </>
    );
};

export default WorkPoint;
