const ro = {
    Clients: 'Clienți',
    'Manage your clients': 'Administrează-ți clienții',
    'FILTER CLIENTS': 'FILTRARE CLIENȚI',
    'ADD CLIENT': 'ADAUGĂ CLIENT',
    'Search client': 'Caută client',
    Phone: 'Telefon',
    Adviser: 'Consilier',
    'Client details': 'Detalii client',
    'Client name': 'Nume client',
    Orders: 'Comenzi',
    'Contacted by ': 'Contactat de ',
    ' times': ' ori',
    'Contacted once': 'Contactat o dată',
    'EDIT CLIENT': 'EDITARE CLIENT',
    'DEACTIVATE CLIENT': 'DEZACTIVARE CLIENT',
    'CLIENT TYPE': 'TIP CLIENT',
    COMPANY: 'FIRMĂ',
    TIN: 'CUI',
    'DEDICATED CLIENT': 'CLIENT DEDICAT',
    'LEGAL PERSON': 'PERSOANĂ JURIDICĂ',
    'Listing members': 'Listare reprezentanți',
    "Member's details": 'Detalii reprezentant',
    'GDPR agreement': 'Acord GDPR',
    'ADD GDPR': 'ADAUGĂ GDPR',
    'DELETE GDPR': 'ŞTERGE GDPR',
    'VIEW GDPR': 'VEZI GDPR',
    'DOWNLOAD GDPR': 'DESCARCĂ GDPR',
    'GDPR agreement expires in ': 'Acordul GDPR expiră în ',
    ' years': ' ani',
    ' year': ' an',
    'Firm name': 'Nume firmă',
    New: 'Nou',
    'Add Client': 'Adăugare Client',
    'Individual Person': 'Persoană Fizică',
    Company: 'Persoană Juridică',
    'Company client': 'Companie',
    'County*': 'Județ*',
    'City*': 'Oraș*',
    Street: 'Strada',
    'First name*': 'Prenume*',
    'Last name*': 'Nume*',
    'Phone number*': 'Telefon*',
    'Street number': 'Număr',
    Building: 'Bloc',
    Floor: 'Etaj',
    'Apartment number': 'Apartament',
    'Zip Code': 'Cod Poștal ',
    'Client Source': 'Sursă client',
    Brand: 'Marcă',
    Counselor: 'Consilier',
    'Client Details': 'Detalii client',
    'Save client': 'Salvare client',
    'Empty the form': 'Golire formular',
    'Discard changes': 'Pierde modificări',
    'Company name*': 'Denumire firmă*',
    Add: 'ADĂUGARE',
    Edit: 'EDITARE',
    Cancel: 'RENUNȚARE',
    'Representants list': 'Listare reprezentanți',
    'Add new representant': 'Adăugare reprezentant',
    Address: 'Adresă',
    'First name is mandatory': 'Prenumele este obligatoriu!',
    'First name must contain at least three characters': 'Prenumele trebuie să conțină cel puțin 3 caractere!',
    'Last name must contain at least three characters': 'Numele trebuie să conțină cel putin 3 caractere!',
    'Last name is mandatory': 'Numele este obligatoriu!',
    'Email you introduced is not valid': 'Trebuie să introduceți un mail valid',
    'Email is mandatory': 'Email-ul este obligatoriu',
    'Choose the county': 'Alegeți judetul',
    'Choose the city': 'Alegeți orasul',
    'The name of the street must contain at least three characters':
        'Numele străzii trebuie să conțină cel puțin 3 caractere!',
    'The street number must contain at least one number': 'Numărul străzii trebuie să conțină cel puțin o cifră!',
    'The phone number you introduced is not a valid one': 'Trebuie sa introduceti un numar de telefon valid',
    'Phone number is mandatory': 'Numărul de telefon este obligatoriu',
    'The buildings name must contain at least one character':
        'Numele clădirii trebuie să conțină cel puțin un caracter!',
    'The entrance name must contain at least three characters':
        'Numele scării trebuie sa conțină cel puțin trei caractere!',
    'The number of the appartment must contain at least one character':
        'Numărul apartamentului trebuie să conțină cel puțin o cifră!',
    'The floors number must contain at least one character': 'Numărul etajului trebuie să conțină cel puțin o cifră!',
    'The zip code must contain at least six characters': 'Codul poștal trebuie să conțină cel puțin șase cifre!',
    'Choose the source': 'Alegeți sursa clientului',
    'Choose the brand': 'Alegeți marca',
    'Choose the counselor': 'Alegeți consilierul',
    'This field must contain at least three characters': 'Acest câmp trebuie să conțină cel puțin trei caractere!',
    'CUI is mandatory': 'CUI-ul este obligatoriu',
    'The name of the company is required': 'Numele companiei este obligatoriu',
    Documents: 'Documente',
    Bulletin: 'Buletin',
    'ADD DOCUMENT': 'ADAUGĂ DOCUMENT',
    'Upload document': 'Încarcă document',
    "Document's name": 'Denumire document',
    'Are you sure you want to delete this document?': 'Ești sigur că vrei să ștergi acest document?',
    'The document name is not valid!': 'Numele documentului nu este valid!',
    'The document name must contain at least 3 characters!':
        'Numele documentului trebuie să conțină cel puțin 3 caractere!',
    'The document name is mandatory!': 'Numele documentului este obligatoriu!',
    'The file is mandatory!': 'Fișierul este obligatoriu!',
    'Changing adviser': 'Schimbare consilier',
    'Choose adviser': 'Alege consilier',
    'Filter clients': 'Filtrează clienții',
    'Estimated manufactoring data': 'Date estimare fabricație',
    'Adding date': 'Adăugare dată',
    'Add date': 'Adaugă dată',
    'Modifying template date': 'Modificare dată template',
    'Upload GDPR agreement': 'Încarcă acord GDPR',
    'Date is mandatory!': 'Data este obligatorie!',
    'Change status': 'Schimbare status',
    Validation: 'Validare',
    Completion: 'Finalizare',
    Category: 'Categorie',
    'Body series': 'Serie caroserie',
    'Date of delivery of advance payment documents': 'Data de inmânare documente plata avans',
    'Advance billing date': 'Data de facturare a avansului',
    'Estimated manufacturing data': 'Date estimare fabricație',
    'Ex-factory date': 'Data ieșire din fabricație',
    'Darex park entry date': 'Data intrare parc Darex',
    'Date set for delivery': 'Data stabilită pentru livrare',
    'Transfer request date': 'Data solicitare transfer',
    'Cancellation billing date': 'Data facturare storno',
    'Rebilling date': 'Data refacturare',
    'Final invoice date': 'Data factură finală',
    'Date of delivery made': 'Data livrare efectuată',
    'Call after delivery': 'Apelare după livrare',
    'Feedback la 3 zile după livrare': 'Feedback 3 days after delivery',
    'Representative added successfully': 'Reprezentat adăugat cu succes',
    'Edit representative': 'Editează reprezentant',
    'Delete representative': 'Șterge reprezentant',
    'Are you sure you want to delete this representative?': 'Sunteți sigur că doriți să ștergeți acest reprezentant?',
    'Representative Details': 'Detalii Reprezentant',
    'Representative Role*': 'Rol Reprezentant*',
    'Representative Role': 'Rol Reprezentant',
    'Add client': 'Adăugare client',
    'Edit client': 'Editați clientul',
    'Edit Client': 'Editare Client',
    'No client selected': 'Nu este selectat nici un client',
    'Change counselor': 'Schimbă consilier',
    'Edit Counselor': 'Editează consilier',
    'Choose the status': 'Alegeți statusul',
    Dedicated: 'Dedicat',
    Sleep: 'Adormit',
    SLEEPING: 'ADORMIT',
    ME: 'EU',
    'This client is dedicated to another advisor': 'Acest client este dedicat altui consilier',
    'Name/Phone/Email': 'Nume/Telefon/Email',
    'Counselor changed succesfully': 'Consilier schimbat cu succes',
    'Status changed succesfully': 'Status schimbat cu succes',
    'This field must contain CUI': 'Acest câmp trebuie să conțină CUI-ul',
    'Hobbies, expectations...': 'Hobby-uri, așteptări...',
    'Representative successfully deleted': 'Reprezentant șters cu succes',
    'Representative edited successfully': 'Reprezentant editat cu succes',
    'The brand is mandatory!': 'Marca este obligatorie!',
    'The model is mandatory!': 'Modelul este obligatoriu!',
    'The color is mandatory!': 'Culoarea este obligatorie!',
    'The motorization is mandatory!': 'Motorizarea este obligatorie!',
    'The adviser is mandatory!': 'Consilierul este obligatoriu!',
    'The client has been added successfully!': 'Clientul a fost adăugat cu succes!',
    'The client has been updated successfully!': 'Clientul a fost actualizat cu succes!',
    'Form succesfully emptied': 'Formular golit cu succes!',
    'The name of the company must contain at least three characters':
        'Numele companiei trebuie să conțină cel puțin 3 caractere',
    'This field must containt at least three characters': 'Acest câmp trebuie să conțină cel puțin 3 caractere',
    "You can't create this client": 'Nu puteți crea acest client',
    'Are you sure you want to empty the form?': 'Sunteți sigur că doriți să goliți formularul?',
    'A client with this email address already exist!': 'Există deja un client cu această adresă de email!',
    'A client with this phone number already exist!': 'Există deja un client cu acest număr de telefon!',
    'This client is already your client on your brand/brands!':
        'Acest client este deja clientul dumneavoastră pe una dintre mărcile deținute!',
    'A juridical client must have at least one representative!':
        'Un client juridic trebuie să aibă cel puțin un reprezentant',

    Active: 'Activ',
    'Are you sure you want to delete the GDPR file?': 'Ești sigur că vrei să ștergi fișierul GDPR?',
    'Document was deleted successfully!': 'Documentul a fost șters cu succes!',
    'Document could not be deleted successfully!': 'Documentul nu a putut fi șters!',
    'Document was uploaded successfully!': 'Documentul a fost încărcat cu succes!',
    'Document could not be uploaded successfully!': 'Documentul nu a putut fi încărcat!',
    'GDPR added successfully!': 'GDPR adăugat cu succes!',
    "GDPR couldn't be added successfully!": 'GDPR nu a putut fi adăugat cu succes!',
    'Upload a file first!': 'Încarcă mai întâi un fișier!',
    "Before seeing the customer's details, you must add a document regarding the GDPR agreement!":
        'Înainte de a vedea detaliile clientului, trebuie să adăugați un document privind acordul GDPR!',
    'A client with this CUI already exist!': 'Există deja un client cu acest CUI!',
};

export default { ro };
