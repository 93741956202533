import React from 'react';
import { useRef, useState } from 'react';
import { uploadSingleFile } from 'utils/functions';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CustomDialog } from 'lib';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField } from 'lib';
import { FileUploadWrapper } from 'lib';
import { IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const UploadDocument = ({ open, setOpen, onSubmitFunc, type }) => {
    const formRef = useRef();
    const { t } = useTranslation();
    const [file, setFile] = useState(null);

    const INITIAL_FORM_STATE = {
        documentName: '',
    };

    const handleFileUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    toast.error(t(message));
                    return;
                }
                setFile(blob);
            },
            type
        );
    };

    const FORM_VALIDATION = yup.object().shape({
        documentName: yup
            .string()
            .typeError(t('The document name is not valid!'))
            .min(3, t('The document name must contain at least 3 characters!'))
            .required(t('The document name is mandatory!')),
    });

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={t('Upload document')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={handleSubmit}
            width="600px"
            buttonFinishDisabled={file === null ? true : false}
            onClickButtonClose={() => {
                setFile(null);
                setOpen(false);
            }}
        >
            <Formik
                innerRef={formRef}
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    onSubmitFunc({ values, file });
                    setFile(null);
                    setOpen(false);
                }}
            >
                <Form>
                    <div className="add-document-wrapper">
                        <TextField name="documentName" label={`${t("Document's name")}*`} size="medium" />
                        <div className="document-wrapper">
                            <TextField name="documentFileName" label={file?.name} size="medium" disabled />
                            <FileUploadWrapper onUpload={handleFileUpload}>
                                <IconButton
                                    sx={{
                                        backgroundColor: '#5B80BA',
                                        color: '#fff',
                                        position: 'absolute',
                                        right: '10px',
                                        top: '8px',
                                        boxShadow:
                                            '0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.2)',
                                        '&:hover': { backgroundColor: '#3d5e92', color: '#fff' },
                                    }}
                                >
                                    <input name="image" hidden accept="image/*, application/pdf" type="file" />
                                    <AttachFileIcon />
                                </IconButton>
                            </FileUploadWrapper>
                        </div>
                    </div>
                </Form>
            </Formik>
        </CustomDialog>
    );
};

export default UploadDocument;
