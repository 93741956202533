import React, { useState, useRef } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { UtilityButton } from 'lib/components/Buttons/buttons';
import { CustomDialog } from 'lib';
import useWindowDimensions from 'hooks/useWindowDimensions';

import AddIcon from '@mui/icons-material/Add';

import * as yup from 'yup';
import { TextField } from 'lib';

import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';

import styles from 'styles/inputStyles.module.scss';
import stylesVehicles from './VehicleStyling.module.scss';
import { VehiclesAPI } from 'api_darex';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmModal from 'lib/components/Modals/ConfirmModal';

const Step2ColorForm = ({
    newModel,
    setNewModel,
    brand,
    rowId,
    setRowId,
    setActiveStep,
    formRefChooseColor,
    colors,
    getAllColors = () => {},
    initialStateColors,
    validationStateColors,
}) => {
    const formRefColors = useRef();

    const [addColor, setAddColor] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const { vw } = useWindowDimensions();
    const { t } = useTranslation();

    const handleSubmitColors = () => {
        if (formRefColors.current) {
            formRefColors.current.handleSubmit();
        }
    };

    const INITIAL_FORM_STATE2 = {
        colorName: '',
    };

    const FORM_VALIDATION2 = yup.object().shape({
        // Cheile trebuie sa aiba aceasi denumire ca in INITIAL_FORM_STATE
        colorName: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(3, t('The name has to contain at least three characters'))
            .required(t('Color is mandatory')),
    });
    return (
        <Box sx={{ padding: '0 0rem 0 0.5rem' }}>
            <div
                style={{
                    display: 'flex',
                    marginTop: '32px',
                    marginBottom: '22px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography variant="addingVehicleModel"> {t('Colors')} </Typography>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <UtilityButton onClick={() => setAddColor(true)} startIcon={<AddIcon />} type="button">
                        {t('Add color')}
                    </UtilityButton>
                </div>
            </div>

            <Box>
                <Formik
                    innerRef={formRefChooseColor}
                    initialValues={{
                        ...initialStateColors,
                    }}
                    validationSchema={validationStateColors}
                    onSubmit={(values) => {
                        setNewModel({
                            ...newModel,
                            colors: values.colors,
                        });
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }}
                >
                    {(props) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={'space-evenly'}
                                spacing={2}
                                direction={vw < 670 ? 'column' : 'row'}
                            >
                                {/* {(() => {
                                   
                                })()} */}
                                <Grid item xs={12}>
                                    <Box>
                                        <div
                                            role="group"
                                            aria-labelledby="checkbox-group"
                                            className={styles.checkboxGroupWrapper}
                                        >
                                            <div className={styles.checkboxGroup} style={{ flexDirection: 'column' }}>
                                                {colors?.map((color) => (
                                                    <div key={color.id} className={stylesVehicles.hoverGroupV}>
                                                        <label key={color.id}>
                                                            <Field type="checkbox" name="colors" value={color.id} />
                                                            {color.name}
                                                        </label>
                                                        {props.values.colors.findIndex((c) => c === color.id) < 0 && (
                                                            <div className={stylesVehicles.buttonForDelete}>
                                                                <DeleteIcon
                                                                    sx={{ cursor: 'pointer', position: 'absolute' }}
                                                                    color={'error'}
                                                                    onClick={() => {
                                                                        setRowId(color.id);
                                                                        setOpenConfirm(true);
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                            {props.errors.colors ? (
                                                <div className={styles.checkboxGroupError}>{props.errors.colors}</div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>

                <CustomDialog
                    title={t('Add color')}
                    open={addColor}
                    setOpen={setAddColor}
                    buttonClose={t('Cancel')}
                    buttonFinish={t('Finish')}
                    width="600px"
                    onClickButtonFinish={handleSubmitColors}
                >
                    <Formik
                        innerRef={formRefColors}
                        initialValues={{
                            ...INITIAL_FORM_STATE2,
                        }}
                        validationSchema={FORM_VALIDATION2}
                        onSubmit={(values) => {
                            VehiclesAPI.createColor({ name: values.colorName, brandId: brand }).then((res) => {
                                if (res.ok === true) {
                                    getAllColors();
                                    setAddColor(false);
                                    toast.success(t('Color added succesfully'));
                                } else {
                                    toast.error(t(res.error.response.data.message));
                                }
                            });
                        }}
                    >
                        <Form>
                            <Box>
                                <TextField name="colorName" label={t('Color')} />
                            </Box>
                        </Form>
                    </Formik>
                </CustomDialog>

                <ConfirmModal
                    open={openConfirm}
                    setOpen={setOpenConfirm}
                    text={t('Are you sure you want to delete this color?')}
                    onClickButtonYes={() =>
                        VehiclesAPI.deleteColor(rowId).then((res) => {
                            if (res.ok === true) {
                                toast.success(t('The color was succesfully deleted'));
                                getAllColors();
                                setOpenConfirm(false);
                            } else {
                                toast.error(t('An error ocurred. Please try again'));
                            }
                        })
                    }
                />
            </Box>
        </Box>
    );
};

export default Step2ColorForm;
