import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PageLayout, PaperContainer } from 'lib';

import { Helmet } from 'react-helmet';

import ChooseClient from './ChooseClient';
import ClientInfo from './ClientInfo';
import AddUnits from './AddUnits';

import { NavigationButton, UtilityButton } from 'lib/components/Buttons/buttons';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Stepper from 'lib/components/Stepper/Stepper';
import Finalize from './Finalize';
import { toast } from 'react-toastify';
import { OffersAPI as OfferAPI } from 'api_darex';
import { useUser } from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { useConfirm } from 'hooks/useConfirm';
import styles from './CreateOffer.module.scss';

const CreateOffer = () => {
    const formikRef = useRef();
    const history = useHistory();
    const { user } = useUser();
    const { t } = useTranslation();
    const confirm = useConfirm();

    const [step, setStep] = useState(0);
    const [clientSearch, setClientSearch] = useState('');
    const [client, setClient] = useState({});
    const [units, setUnits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [baseOffer, setBaseOffer] = useState({});
    const [reOfferReason, setReOfferReason] = useState(null);

    const isReoffer = Boolean(baseOffer.id);

    useEffect(() => {
        const offerId = history.location.state?.offerId;
        setReOfferReason(history.location.state?.reOfferReason);

        if (offerId) {
            OfferAPI.getById(offerId).then((res) => {
                if (res.ok) {
                    setBaseOffer(res.data);
                    setClient({
                        ...res.data.ClientBrands.Clients,
                        brand: res.data.ClientBrands.Brand,
                        brandId: res.data.ClientBrands.brandId,
                        user: res.data.user,
                        clientUID: res.data.ClientBrands.id,
                    });
                }
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [history.location]);

    const submitStep1 = () => {
        if (Boolean(client.id)) setStep(1);
        else toast.error(t('Choose a client first!'));
    };

    const submitStep2 = () => {
        if (units.length > 0) setStep(2);
        else toast.error(t('Select at least one unit first!'));
    };

                 const submitStep3 = () => {
        formikRef.current.handleSubmit();
        const s3 = formikRef.current.values;

        const { driveTest, driveTestDate, reContactDate, comment } = s3;

        const body = {
            clientBrandsId: client.clientUID,
            userId: user.id,
            status: 'Open',
            driveTest,
            driveTestDate: driveTest === 'PROGRAMAT' ? driveTestDate : null,
            reContactDate,
            comments: comment,
            RCIfileDate: null,
            offerModel: units.map((unit) => {
                const u = { ...unit };
                u.price = Number(u.price);

                delete u.unitRenderData;
                delete u.id;

                return u;
            }),
        };

        try {
            OfferAPI.create(
                isReoffer ? { ...body, oldOfferId: baseOffer.id, reOfferReason: reOfferReason } : body
            ).then(() => {
                toast.success(t('Offer created successfully'));
                history.push('/offers');
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <PageLayout
            title={isReoffer ? t('Rebid') : t('Create offer')}
            subTitle={
                isReoffer ? (
                    <div>
                        {t('Reoffer from the offer')} #{baseOffer.autoincrement}
                    </div>
                ) : (
                    t('Create a new offer')
                )
            }
        >
            <Helmet>
                <title>
                    {isReoffer ? t('Rebid') : t('Create offer')} {t('step')} {String(step + 1)}
                </title>
            </Helmet>

            <PaperContainer style={{ padding: '50px' }}>
                {!loading && (
                    <React.Fragment>
                        <div
                            style={{
                                marginBottom: '64px',
                            }}
                        >
                            <Stepper steps={[t('Client details'), t('Add units'), t('Finish')]} step={step} />
                        </div>

                        {/* Step 1 */}
                        {step === 0 && (
                            <React.Fragment>
                                {!isReoffer && (
                                    <div style={{ marginBottom: '48px' }}>
                                        <ChooseClient
                                            search={clientSearch}
                                            setSearch={setClientSearch}
                                            setClient={setClient}
                                            isReoffer={isReoffer}
                                            areaOfUse={'OFFER'}
                                        />
                                    </div>
                                )}
                                <ClientInfo client={client} />
                            </React.Fragment>
                        )}

                        {/* Step 2 */}
                        {step === 1 && (
                            <React.Fragment>
                                <AddUnits units={units} setUnits={setUnits} client={client} />
                            </React.Fragment>
                        )}

                        {/* Step 3 */}
                        {step === 2 && (
                            <React.Fragment>
                                <Finalize formikRef={formikRef} units={units} />
                            </React.Fragment>
                        )}

                        {/* Next Step Button */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '32px' }}>
                            {step > 0 ? (
                                <NavigationButton
                                    startIcon={<ArrowBackIosIcon sx={{ width: '12px' }} />}
                                    onClick={() => setStep(step - 1)}
                                >
                                    {t('Back')}
                                </NavigationButton>
                            ) : Boolean(client.id) ? (
                                !isReoffer ? (
                                    <UtilityButton
                                        onClick={() =>
                                            confirm(t('Are you sure you want to unselect this client?'), () => {
                                                setClient({});
                                                setClientSearch('');
                                            })
                                        }
                                        color="red"
                                    >
                                        {t('Unselect client')}
                                    </UtilityButton>
                                ) : (
                                    <div></div>
                                )
                            ) : (
                                <React.Fragment>&nbsp;</React.Fragment>
                            )}

                            <NavigationButton
                                endIcon={<ArrowForwardIosIcon sx={{ width: '12px' }} />}
                                onClick={() => {
                                    if (step === 0) submitStep1();
                                    if (step === 1) submitStep2();
                                    if (step === 2) submitStep3();
                                }}
                            >
                                {t('Continue')}
                            </NavigationButton>
                        </div>
                    </React.Fragment>
                )}
            </PaperContainer>
        </PageLayout>
    );
};

export default CreateOffer;
