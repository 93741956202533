import axios from './axios';

const Tasks = {
    /**
     * Fetches all Tasks's
     *
     * @return {array} Array of Tasks's
     */
    async get() {
        try {
            const res = await axios.get('/tasks');

            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Users witch contain the the specified string
     *
     * @param {string} text used to find Tasks
     * @return {object} Array of Tasks's
     */
    async searchTasks(text) {
        try {
            const res = await axios.get(`/tasks/findByString/text?text=${text}`);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all Users witch contain the the specified string
     *
     * @param {string} text used to find Users
     * @return {object} Array of Users's
     */
    async filterTasks(data) {
        const newData = { ...data, startDate: new Date(data.startDate), endDate: new Date(data.endDate) };

        try {
            const res = await axios.get(`/tasks/filtering/task`, { params: { ...newData } });

            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all Tasks's by user ID
     *
     * @return {array} Array of Tasks's
     */
    async getByUserId(userId, status, perPage = 20, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/tasks/userId', {
                params: {
                    userId,
                    status,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    //TO DO --------------------------
    /**
     * Fetches all Tasks's by user ID
     *
     * @return {array} Array of Tasks's
     */
    async getByUserBrandsId(status, brandId, userId, perPage = 20, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/tasks/UserAndBrandId', {
                params: {
                    status,
                    brandId,
                    userId,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Tasks's by client ID
     *
     * @return {array} Array of Tasks's
     */
    async getByClientId(clientId, status, perPage = 20, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/tasks/clientId', {
                params: {
                    clientId,
                    status,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Tasks's by client ID
     *
     * @return {array} Array of Tasks's
     */
    async getByClientIdBrands(clientBrandsId, status, perPage = 20, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/tasks/clientBrandsId', {
                params: {
                    clientBrandsId,
                    status,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Tasks's by brand ID
     *
     * @return {array} Array of Tasks's
     */
    async getByBrandId(brandId, status, perPage = 20, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/tasks/brandId', {
                params: {
                    brandId,
                    status,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });

            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Tasks's by status
     *
     * @return {array} Array of Tasks's
     */
    async getByStatus(status, perPage = 20, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/tasks/status', {
                params: {
                    status,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches one Tasks by it's ID
     *
     * @param {string} id ID of the Tasks
     * @return {object} Tasks with the corresponding ID
     */
    async getById(tasksId) {
        try {
            const res = await axios.get('/tasks/' + tasksId);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Tasks
     *
     * @param {object} tasks Object with the creation data
     * @return {object} Newly created Tasks
     */
    async create(tasks) {
        let reqBody = { ...tasks };
        try {
            const res = await axios.post('/tasks', reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Deletes Tasks by ID
     *
     * @param {string} tasksId ID of the Tasks to be deleted
     * @return {string} ID of the deleted Tasks
     */
    async delete(tasksId) {
        try {
            await axios.delete('/tasks/' + tasksId);
            return {
                ok: true,
                data: {
                    id: tasksId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: tasksId,
                },
            };
        }
    },

    /**
     * Update Tasks by ID
     *
     * @param {object} tasks Object with the creation data
     * @param {string} taskId ID of the Task to be updated
     * @return {string} ID of the updated Task
     */
    async update(taskId, tasks) {
        const reqBody = { ...tasks };
        try {
            const res = await axios.patch('/tasks/' + taskId, reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },

    /**
     * Update status of the Tasks by ID
     *
     * @param {object} status boolean with the status value
     * @param {string} taskId ID of the Task to be updated
     * @return {string} ID of the updated Task
     */
    async updateStatus(taskId, status) {
        try {
            const res = await axios.patch('/tasks/' + taskId, { status });
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },

    async support(userId, description) {
        try {
            const res = await axios.post(`/tasks/support/user?userId=${userId}&message=${description}`);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },
};

export default Tasks;
