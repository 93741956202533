import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import styles from './Comments.module.scss';

// mui
import { Box, Typography, Divider, TextField, Avatar, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { IconButton } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useUser } from 'hooks/useUser';

import { CommentsAPI } from 'api_darex';
import { toast } from 'react-toastify';

const snapSensibility = 32;

function Comments({ type, id, offerModelId, disabled = false }) {
    const { t } = useTranslation();
    const ref = useRef();
    const { user } = useUser();
    const [comment, setComment] = useState('');
    const [messages, setMessages] = useState([]);
    const [lastMessageFetch, setLastMessageFetch] = useState(0);

    /**
     * Initial Comment Fetch
     */
    useEffect(() => {
        if (type === 'offerModel')
            CommentsAPI.getOfferModel(offerModelId).then((messages) => {
                setMessages(messages.data);
            });
        else
            CommentsAPI.get(id).then((messages) => {
                setMessages(messages.data);
            });
    }, [id]);

    /**
     * Every Comments update's date is marked
     */
    useEffect(() => {
        const date = new Date();
        setLastMessageFetch(date.getTime());
    }, [messages]);

    /**
     * Once 5 seconds refetch all comments
     */
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime();
            if (now - lastMessageFetch >= 1000 * 5) {
                if (type === 'offerModel')
                    CommentsAPI.getOfferModel(offerModelId).then((messages) => {
                        setMessages(messages.data);
                    });
                else
                    CommentsAPI.get(id).then((messages) => {
                        setMessages(messages.data);
                    });
            }
        }, 1000 * 5);

        return () => clearInterval(interval);
    }, [type, lastMessageFetch]);

    const handleSubmitComment = () => {
        const obj = {};

        obj.userId = user.id.toString();
        obj.offerId = id;
        obj.comment = comment;
        obj.offerModelId = offerModelId;

        if (!comment.length) return;
        if (type === 'offerModel') {
            CommentsAPI.createOfferModel(obj).then((res) => {
                if (res.ok === true) {
                    CommentsAPI.getOfferModel(offerModelId).then((messages) => setMessages(messages.data));
                } else {
                    toast.error(t('An error ocurred. Please try again'));
                }
            });
        } else {
            CommentsAPI.create(obj).then((res) => {
                if (res.ok === true) {
                    CommentsAPI.get(id).then((messages) => setMessages(messages.data));
                } else {
                    toast.error(t('An error ocurred. Please try again'));
                }
            });
        }

        setComment('');
    };

    const [snapBottom, setSnapBottom] = useState(true);
    const containerRef = useRef();

    useEffect(() => {
        if (!snapBottom) return;

        const sHeight = containerRef.current.scrollHeight;
        const sTop = containerRef.current.scrollTop;

        if (Math.abs(sTop - sHeight) > 288.5) {
            containerRef.current.scrollTop = sHeight;
        }
    }, [messages, snapBottom]);

    useEffect(() => {
        const handleScroll = (e) => {
            const sTop = e.target.scrollTop + e.target.clientHeight;
            const sHeight = e.target.scrollHeight;

            if (Math.floor(Math.abs(sTop - sHeight)) < snapSensibility) {
                setSnapBottom(true);
                return;
            }

            setSnapBottom(false);
        };

        const c = containerRef.current;
        c.addEventListener('scroll', handleScroll);

        return () => {
            c.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const sortKey = type === 'offerModel' ? 'cratedAt' : 'createdAt';
    const sortedMessages = messages.sort((a, b) => (a[sortKey] > b[sortKey] ? 1 : -1));

    return (
        <Box
            ref={ref}
            sx={{
                backgroundColor: '#FFFFFF',
                marginTop: '1rem',
                borderRadius: '8px',
                position: 'relative',
                boxShadow: '#eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px',
            }}
        >
            <Typography variant="h6" sx={{ paddingTop: '1rem', marginBottom: '1rem', marginLeft: '1rem' }}>
                {t('Comments')}
            </Typography>

            <Divider sx={{ color: '#D9D9D9' }} />

            <div onClick={() => setSnapBottom(true)} className={`${styles.goBottom} ${!snapBottom && styles.visible}`}>
                <>{t('go to latest')}</>
                <ArrowDownwardIcon sx={{ width: '14px' }} />
            </div>

            <Box
                sx={{
                    height: '20rem',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                }}
                ref={containerRef}
            >
                {sortedMessages.map((message) => (
                    <IndividualComment
                        key={message.id}
                        text={message.comments.comment}
                        sendAt={message.createdAt}
                        isCurrentUserMessage={user.id === message.comments.userId}
                        user={message.comments.user.firstName + ' ' + message.comments.user.name}
                        profilePic={message.profilePic}
                        role={message.comments.user.roles.name}
                    />
                ))}
            </Box>

            <Divider sx={{ color: '#D9D9D9' }} />
            {/* Textbox Section */}
            <Box
                sx={{
                    padding: '10px',
                }}
            >
                <TextField
                    disabled={disabled}
                    fullWidth
                    placeholder={t('Write a comment')}
                    variant="outlined"
                    value={comment}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmitComment();
                        }
                    }}
                    onChange={(e) => setComment(e.target.value)}
                    sx={{
                        fieldset: { borderRadius: '4px' },
                        '& .MuiFormHelperText-root.Mui-error': {
                            color: '#FF4B55',
                        },
                        '& .Mui-error': {
                            color: '#FF4B55',
                        },
                        '.css-16jwpsg-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                            {
                                borderColor: '#FF4B55',
                            },
                        '& .MuiOutlinedInput-root': {
                            '&.Mui-focused fieldset': {
                                borderColor: '#5b80ba',
                            },
                        },
                        '& label.Mui-focused': {
                            color: '#5b80ba',
                        },
                        '& label.Mui-error': {
                            color: '#FF4B55',
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                sx={{
                                    display: 'flex',
                                    marginLeft: '1rem',
                                }}
                            >
                                <IconButton
                                    sx={{ marginLeft: '0.2rem', color: '#5B80BA' }}
                                    disabled={comment ? false : true}
                                    onClick={() => handleSubmitComment()}
                                >
                                    <SendIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                ></TextField>
            </Box>
        </Box>
    );
}

function IndividualComment({ text, sendAt, user, isCurrentUserMessage, profilePic, role }) {
    return (
        <div
            style={{
                backgroundColor: isCurrentUserMessage ? 'rgba(248, 245, 255, 1)' : '#f5f9ff',
                borderRadius: '8px',
                margin: '1rem',
                padding: '16px',
                maxWidth: '70%',
                overflowWrap: 'anywhere',
                alignSelf: isCurrentUserMessage ? 'flex-end' : 'flex-start',
                alignContent: 'flex-start',
            }}
        >
            <div
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: isCurrentUserMessage ? 'flex-end' : 'flex-start',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '10px',
                        flexDirection: isCurrentUserMessage ? 'row' : 'row-reverse',
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            fontSize: '12px',
                            color: 'rgba(0, 0, 0, 0.3)',
                            paddingTop: '3px',
                        }}
                    >
                        {' '}
                        {dayjs(sendAt).format('DD MMM YYYY, HH:mm')}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                            flexDirection: isCurrentUserMessage ? 'row' : 'row-reverse',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: isCurrentUserMessage ? 'flex-end' : ' flex-start',
                            }}
                        >
                            <div
                                style={{
                                    color: ' rgba(0, 0, 0, 0.62)',
                                    fontWeight: 600,
                                    fontSize: '16px',
                                }}
                            >
                                {user}
                            </div>
                            <div
                                style={{
                                    color: ' rgba(0, 0, 0, 0.3)',
                                    fontSize: '12px',
                                }}
                            >
                                {role}
                            </div>
                        </div>
                        <div>
                            <Avatar>{profilePic}</Avatar>
                        </div>
                    </div>
                </div>

                <Typography
                    sx={{
                        fontSize: '14px',
                        color: 'rgba(66, 82, 110, 0.86)',
                        paddingTop: '16px',
                        textAlign: 'left',
                    }}
                >
                    {text}
                </Typography>
            </div>
        </div>
    );
}
export default Comments;
