import React from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import { CustomDialog } from 'lib';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField } from 'lib';
import { OffersAPI } from 'api_darex';
import { toast } from 'react-toastify';

const RefuseOffer = ({
    open,
    setOpen,
    rowId,
    triggerRefetch = null,
    setTriggerRefetch = () => {},
    getOffer = () => {},
    handleClose = () => {},
}) => {
    const { t } = useTranslation();
    const formRef = useRef();

    const INITIAL_FORM_STATE = {
        reason: '',
    };

    const FORM_VALIDATION = yup.object().shape({
        reason: yup
            .string()
            .typeError(t('This reason is not a valid one!'))
            .trim()
            .min(3, t('The reason has to contain at least three characters!'))
            .required(t('Reason is mandatory!')),
    });

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={t('Refuse')}
            buttonClose={t('BACK')}
            buttonFinish={t('Finish')}
            onClickButtonFinish={handleSubmit}
            width="500px"
            onClickButtonClose={() => {
                setOpen(false);
            }}
        >
            <Formik
                innerRef={formRef}
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    OffersAPI.refuseOffer(rowId, values.reason).then((res) => {
                        if (res.ok) {
                            getOffer();
                            setTriggerRefetch(!triggerRefetch);
                            handleClose();
                            setOpen(false);
                            toast.success(t('Offer was marked as refused!'));
                        } else {
                            handleClose();
                            setOpen(false);
                            toast.error(t('Something went wrong! Offer could not be marked as refuse!'));
                        }
                    });
                }}
            >
                <Form>
                    <Grid container justifyContent={'space-between'} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="clientDetailsInfo">
                                {t('Write the reason why the offer was refused')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="reason" label={`${t('Reason')}*`} />
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </CustomDialog>
    );
};

export default RefuseOffer;
