import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const dayjs = require('dayjs');
const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

function Problem({ problem }) {
    const { user } = problem;
    const navigate = useHistory();
    var timeSince = dayjs(problem.createdAt).from();

    return (
        <Box
            sx={{
                position: 'relative',
                backgroundColor: '#FFFFFF',
                borderRadius: '1rem',
                padding: '1rem',
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <Avatar sx={{ marginRight: '1rem' }}>
                    <AccountCircleIcon />
                </Avatar>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                        {user ? user.name : 'Nume inexistent'}
                    </Typography>
                    <Typography sx={{ fontSize: '12px', fontWeight: '400' }}>
                        {problem.title} | {timeSince}
                    </Typography>
                </Box>
            </Box>

            <Typography sx={{ marginTop: '1rem' }}>{problem.description}</Typography>

            <Button
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigate(`/trips/trip/${problem.route.id}`)}
                sx={{
                    color: '#5664D2',
                    border: '1px solid #5664D2',
                    borderRadius: '1rem',
                    position: 'absolute',
                    right: '1rem',
                    bottom: '1rem',
                }}
            >
                See trip
            </Button>
        </Box>
    );
}

export default Problem;
