import React, { useEffect, useState } from 'react';
import { Typography, Grid, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomDialog } from 'lib';

import { PrimaryButton } from 'lib/components/Buttons/buttons';
import AddIcon from '@mui/icons-material/Add';

import Search from 'lib/components/Search/Search';
import { PageLayout } from 'lib';
import { CustomTable } from 'lib';
import CustomTableLayout from 'lib/components/Tables/TabsLayout';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import AddObjectivesModal from './AddObjectivesModal';
import { ObjectivesAPI, UsersAPI } from 'api_darex';
import ConfirmModal from 'lib/components/Modals/ConfirmModal';
import { Helmet } from 'react-helmet';

import { EditButton, RemoveButton } from 'lib/components/Buttons/buttons';
import PercentageComponent from './PercentageComponent';

import { toast } from 'react-toastify';
import { useUser } from 'hooks/useUser';
import { calculatePercentage, getProcentColor } from 'utils/functions';
import { useHistory } from 'react-router-dom';

import FilterListIcon from '@mui/icons-material/FilterList';

import dayjs from 'dayjs';

const Objectives = () => {
    const { t } = useTranslation();
    const { user, can } = useUser();
    const [openConfirm, setOpenConfirm] = useState(false);

    const [rowId, setRowId] = useState('');
    const [openFilter, setOpenFilter] = useState(false);
    const [openAddObjectives, setAddOpenObjectives] = useState(false);
    const [daciaObjectives, setDaciaObjectives] = useState([]);
    const [renaultObjectives, setRenaultObjectives] = useState([]);
    const [nissanObjectives, setNissanObjectives] = useState([]);
    const [rowData, setRowData] = useState({});
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const [currentUserObjectives, setCurrentUserObjectives] = useState([]);

    const [tabName, setTabName] = useState('DACIA');

    const [search, setSearch] = useState('');
    const [filterData, setFilterData] = useState({
        startDate: new Date(),
        endDate: new Date(),
        userId: user.roleId === '4' || user.roleId === '5' ? user.id : '',
    });
    const [activeTab, setActiveTab] = useState(0);

    const todaysDate = dayjs().format();

    const [loadingDacia, setLoadingDacia] = useState(true);
    const [loadingRenault, setLoadingRenault] = useState(true);
    const [loadingNissan, setLoadingNissan] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [triggerFilter, setTriggerFilter] = useState(false);
    const [isFilter, setIsFilter] = useState(false);

    const history = useHistory();

    const [filteredAdviser, setFilteredAdviser] = useState([]);

    const [sefDeVanzariObjectives, setSefDeVanzariObjectives] = useState([]);

    useEffect(() => {
        if (!can(12)) {
            history.goBack();
        }
    }, []);

    const handleChange = (e) => {
        setFilterData({
            ...filterData,
            [e.target.name]: e.target.value,
        });
    };

    const labelsRenault = [
        { id: 'counselor', label: t('Counselor'), minWidth: 10 },
        {
            id: 'vpDeliveries',
            label: t('VP Deliveries'),
            minWidth: 10,
        },
        {
            id: 'vuDeliveries',
            label: t('VU Deliveries'),
            minWidth: 10,
        },
        {
            id: 'totalDeliveries',
            label: t('Total Deliveries'),
            minWidth: 10,
        },
        { id: 'pfOrders', label: t('PF Orders'), minWidth: 10 },
        { id: 'pjOrders', label: t('PJ Orders'), minWidth: 10 },
        { id: 'totalOrders', label: t('Total Orders'), minWidth: 10 },

        { id: 'actions', label: t(''), minWidth: 10 },
    ];

    const labelsDacia = [
        { id: 'counselor', label: t('Counselor'), minWidth: 10 },
        {
            id: 'pfDeliveries',
            label: t('PF Deliveries'),
            minWidth: 10,
        },
        {
            id: 'pjDeliveries',
            label: t('PJ Deliveries'),
            minWidth: 10,
        },
        {
            id: 'electricDeliveries',
            label: t('Electric Deliveries'),
            minWidth: 10,
        },
        {
            id: 'electricOrders',
            label: t('Electric Orders'),
            minWidth: 10,
        },
        {
            id: 'thermicOrders',
            label: t('Thermic Orders'),
            minWidth: 10,
        },
        {
            id: 'hybridOrders',
            label: t('Hybrid Orders'),
            minWidth: 10,
        },
        { id: 'actions', label: t(''), minWidth: 90 },
    ];

    const labelsNissan = [
        { id: 'counselor', label: t('Counselor'), minWidth: 10 },
        {
            id: 'totalOrders',
            label: t('Total Deliveries'),
            minWidth: 10,
        },
        { id: 'actions', label: t(''), minWidth: 10 },
    ];

    const cellModifierDacia = (row, column, value) => {
        if (column.id === 'counselor') {
            return (
                <div>
                    <Typography variant="tableContent"> {row.user?.name + ' ' + row.user?.firstName}</Typography>
                </div>
            );
        }
        if (column.id === 'pfDeliveries') {
            let value = calculatePercentage(row.deliveriesPF, row.tDeliveriesPF);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.deliveriesPF === null ? '0' : row.deliveriesPF}/{row.tDeliveriesPF}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'pjDeliveries') {
            let value = calculatePercentage(row.deliveriesPJ, row.tDeliveriesPJ);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.deliveriesPJ === null ? '0' : row.deliveriesPJ}/{row.tDeliveriesPJ}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }

        if (column.id === 'electricDeliveries') {
            let value = calculatePercentage(row.electricalDeliveries, row.tElectricalDeliveries);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.electricalDeliveries === null ? '0' : row.electricalDeliveries}/{row.tElectricalDeliveries}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'electricOrders') {
            let value = calculatePercentage(row.electricalOrders, row.tElectricalOrders);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.electricalOrders === null ? '0' : row.electricalOrders}/{row.tElectricalOrders}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'thermicOrders') {
            let value = calculatePercentage(row.thermicOrders, row.tThermicOrders);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.thermicOrders === null ? '0' : row.thermicOrders}/{row.tThermicOrders}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'hybridOrders') {
            let value = calculatePercentage(row.hybridOrders, row.tHybridOrders);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.hybridOrders === null ? '0' : row.hybridOrders}/{row.tHybridOrders}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'actions') {
            return (
                <div>
                    {can(11) && (
                        <EditButton
                            onClick={() => {
                                setRowData(row);
                                setAddOpenObjectives(true);
                            }}
                        />
                    )}
                    {can(10) && (
                        <RemoveButton
                            onClick={() => {
                                setRowId(row.id);
                                setOpenConfirm(true);
                            }}
                        />
                    )}
                </div>
            );
        }
    };

    const cellModifierRenault = (row, column, value) => {
        if (column.id === 'vpDeliveries') {
            let value = calculatePercentage(row.deliveriesVP, row.tDeliveriesVP);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.deliveriesVP === null ? '0' : row.deliveriesVP}/{row.tDeliveriesVP}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'vuDeliveries') {
            let value = calculatePercentage(row.deliveriesVU, row.tDeliveriesVU);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.deliveriesVU === null ? '0' : row.deliveriesVU} /{row.tDeliveriesVU}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }

        if (column.id === 'totalDeliveries') {
            let value = calculatePercentage(row.totalDeliveries, row.tTotalDeliveries);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.totalDeliveries}/{row.tTotalDeliveries}
                    </Typography>

                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }

        if (column.id === 'totalOrders') {
            let value = calculatePercentage(row.totalOrders, row.tTotalOrders);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.totalOrders}/{row.tTotalOrders}
                    </Typography>

                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'pfOrders') {
            let value = calculatePercentage(row.ordersPF, row.tOrdersPF);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.ordersPF === null ? '0' : row.ordersPF}/{row.tOrdersPF}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'pjOrders') {
            let value = calculatePercentage(row.ordersPJ, row.tOrdersPJ);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.ordersPJ === null ? '0' : row.ordersPJ}/{row.tOrdersPJ}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }

        if (column.id === 'counselor') {
            return (
                <div>
                    <Typography variant="tableContent"> {row.user?.name + ' ' + row.user?.firstName}</Typography>
                </div>
            );
        }
        if (column.id === 'actions') {
            return (
                <div>
                    {can(11) && (
                        <EditButton
                            onClick={() => {
                                setRowData(row);
                                setAddOpenObjectives(true);
                            }}
                        />
                    )}
                    {can(10) && (
                        <RemoveButton
                            onClick={() => {
                                setRowId(row.id);
                                setOpenConfirm(true);
                            }}
                        />
                    )}
                </div>
            );
        }
    };

    const cellModifierNissan = (row, column, value) => {
        if (column.id === 'counselor') {
            return (
                <div>
                    <Typography variant="tableContent"> {row.user?.name + ' ' + row.user?.firstName}</Typography>
                </div>
            );
        }
        if (column.id === 'totalOrders') {
            let value = calculatePercentage(row.totalsDeliveries, row.tTotalsDeliveries);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.totalsDeliveries === null ? '0' : row.totalsDeliveries}/{row.tTotalsDeliveries}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'actions') {
            return (
                <div>
                    {can(11) && (
                        <EditButton
                            onClick={() => {
                                setRowData(row);
                                setAddOpenObjectives(true);
                            }}
                        />
                    )}
                    {can(10) && (
                        <RemoveButton
                            onClick={() => {
                                setRowId(row.id);
                                setOpenConfirm(true);
                            }}
                        />
                    )}
                </div>
            );
        }
    };

    function getDelivaryReport(array, keys) {
        const report = {};

        // Generate totals / dones
        for (const row of array) {
            for (const key of keys) {
                const tKey = `t${key.charAt(0).toUpperCase()}${key.slice(1)}`;

                if (!(key in report)) {
                    report[key] = {
                        done: 0,
                        total: 0,
                        percent: 0,
                    };
                }

                report[key].done += row[key];
                report[key].total += row[tKey];
            }
        }

        // Calculate percentages
        if (Object.keys(report).length > 0)
            for (const key of keys) {
                const res = ((report[key].done * 100) / report[key].total).toFixed(2);
                if (isNaN(res)) report[key].percent = 0;
                else {
                    report[key].percent = res;
                }
            }

        return report;
    }

    //objectives details for Dacia

    const deliveryKeys = [
        'deliveriesPF',
        'deliveriesPJ',
        'electricalDeliveries',
        'electricalOrders',
        'thermicOrders',
        'hybridOrders',
    ];

    const deliveryReport = getDelivaryReport(
        user.roleId === '3' ? sefDeVanzariObjectives : daciaObjectives,
        deliveryKeys
    );

    const deliveryRender = Object.keys(deliveryReport).map((key) => ({
        value: (
            <div key={key}>
                <span style={{ marginRight: '8px' }}>
                    {deliveryReport[key].done} / {deliveryReport[key].total}
                    <PercentageComponent
                        value={deliveryReport[key].percent}
                        color={getProcentColor(deliveryReport[key].percent)}
                    />
                </span>
            </div>
        ),
    }));

    //objectives details for Renault

    const deliveryKeysRenault = [
        'deliveriesVP',
        'deliveriesVU',
        'totalDeliveries',
        'ordersPF',
        'ordersPJ',
        'totalOrders',
    ];

    const deliveryReportRenault = getDelivaryReport(
        user.roleId === '3' ? sefDeVanzariObjectives : renaultObjectives,
        deliveryKeysRenault
    );

    const deliveryRenderRenault = Object.keys(deliveryReportRenault).map((key) => ({
        value: (
            <div key={key}>
                <span style={{ marginRight: '8px' }}>
                    {deliveryReportRenault[key].done} / {deliveryReportRenault[key].total}
                    <PercentageComponent
                        value={deliveryReportRenault[key].percent}
                        color={getProcentColor(deliveryReportRenault[key].percent)}
                    />
                </span>
            </div>
        ),
    }));

    //objectives details for Nissan

    const deliveryKeysNissan = ['totalsDeliveries'];
    const deliveryRaportNissan = getDelivaryReport(
        user.roleId === '3' ? sefDeVanzariObjectives : nissanObjectives,
        deliveryKeysNissan
    );

    const deliveryRenderNissan = Object.keys(deliveryRaportNissan).map((key) => ({
        value: (
            <div key={key}>
                <span style={{ marginRight: '8px' }}>
                    {deliveryRaportNissan[key].done} / {deliveryRaportNissan[key].total}
                    <PercentageComponent
                        value={deliveryRaportNissan[key].percent}
                        color={getProcentColor(deliveryRaportNissan[key].percent)}
                    />
                </span>
            </div>
        ),
    }));

    const existDacia = user.UserBrand.map((item) => item.brandId).includes('1') ? true : false;
    const existRenault = user.UserBrand.map((item) => item.brandId).includes('2') ? true : false;
    const existNissan = user.UserBrand.map((item) => item.brandId).includes('3') ? true : false;

    const getObjectivesByUserId = () => {
        ObjectivesAPI.getByUserId(user.id, todaysDate).then((res) => {
            setCurrentUserObjectives(res.data.content);
            setLoadingDacia(false);
            setLoadingRenault(false);
            setLoadingNissan(false);
        });
    };

    const getObjectivesForSefDeVanzari = () => {
        ObjectivesAPI.getAllCounselorsForSefVanzari({
            userId: user.id,
            startDate: todaysDate,
            brandId: user.UserBrand[0].brandId,
        }).then((res) => {
            setSefDeVanzariObjectives(res.data.content);
        });
    };

    useEffect(() => {
        if (user.roleId === '4' || user.roleId === '5') getObjectivesByUserId();
        if (user.roleId === '3') getObjectivesForSefDeVanzari();
    }, []);

    useEffect(() => {
        if (user.roleId === '1' || user.roleId === '2') {
            UsersAPI.getByBrandsIdNoPagination(['1', '2', '3']).then((res) => {
                const newArray = res.data.content.filter((user) =>
                    user.UserBrand.some((us) => us.brandId === `${activeTab + 1}`)
                );

                setFilteredAdviser(newArray);
            });
        } else if (user.roleId === '3') {
            // const adviserBrands = user.UserBrand.map((brand) => brand.brandId);
            // UsersAPI.getByBrandsIdNoPagination(adviserBrands).then((res) => {
            //     console.log('Test test test test', res.data.content, 'user', user);
            //     setFilteredAdviser(res.data.content);
            // });

            setFilteredAdviser(user.Superior.map((inferior) => inferior.Inferior));
        }
    }, [activeTab]);

    useEffect(() => {
        if (user.UserBrand.map((item) => item.brandId).includes('1')) {
            setActiveTab(0);
            setTabName('DACIA');
        } else if (user.UserBrand.map((item) => item.brandId).includes('2')) {
            setActiveTab(1);
            setTabName('RENAULT');
        } else if (user.UserBrand.map((item) => item.brandId).includes('3')) {
            setActiveTab(2);
            setTabName('NISSAN');
        }
    }, []);

    const handleFilterData = () => {
        setIsSearch(false);
        setIsFilter(true);
        if (activeTab === 0) setLoadingDacia(true);
        if (activeTab === 1) setLoadingRenault(true);
        if (activeTab === 2) setLoadingNissan(true);

        const newFilterData = {
            ...filterData,
            startDate: dayjs(filterData.startDate).startOf('month').format(),
            endDate: dayjs(filterData.endDate).endOf('month').format(),
        };
        // if (newFilterData.userId === '') delete newFilterData.userId;

        setFilterData(newFilterData);

        setTriggerFilter(!triggerFilter);
        setOpenFilter(false);
    };

    const filterFilter = (data) => {
        let filteredData = [];

        if (activeTab === 0) {
            filteredData = data.filter((obj) => obj.brandId === '1');
        }
        if (activeTab === 1) filteredData = data.filter((obj) => obj.brandId === '2');
        if (activeTab === 2) filteredData = data.filter((obj) => obj.brandId === '3');

        return isFilter ? filteredData : [];
    };

    const filteredSearch = (data) => {
        let filteredData = [];
        if (activeTab === 0) {
            filteredData = data.filter((obj) => obj.brandId === '1');
        }
        if (activeTab === 1) filteredData = data.filter((obj) => obj.brandId === '2');
        if (activeTab === 2) filteredData = data.filter((obj) => obj.brandId === '3');

        return isSearch ? filteredData : null;
    };

    const tabs = [
        {
            label: 'DACIA',
            id: 0,
            render: true,
            disabled: !existDacia,
            component:
                user.roleId !== '4' && user.roleID !== '5 ' ? (
                    <CustomTable
                        withBoxShadow={false}
                        labels={labelsDacia}
                        cellModifier={cellModifierDacia}
                        tableData={user.roleId === '3' ? sefDeVanzariObjectives : daciaObjectives}
                        withPagination={true}
                        getterFunction={
                            user.roleId === '3' ? ObjectivesAPI.getAllCounselorsForSefVanzari : ObjectivesAPI.get
                        }
                        setterFunction={user.roleId === '3' ? setSefDeVanzariObjectives : setDaciaObjectives}
                        additionalId={
                            user.roleId === '3'
                                ? { userId: user.id, startDate: todaysDate, brandId: '1' }
                                : { startDate: todaysDate, brandId: '1' }
                        }
                        triggerRefetch={triggerRefetch}
                        hover={false}
                        loading={loadingDacia}
                        setLoading={setLoadingDacia}
                        searchFunction={ObjectivesAPI.searchObjectives}
                        search={search}
                        isSearch={isSearch}
                        triggerSearch={triggerSearch}
                        filterSearch={filteredSearch}
                        triggerFilter={triggerFilter}
                        filterData={filterData}
                        filterFunction={ObjectivesAPI.filterObjectives}
                        isFilter={isFilter}
                        filterFilter={filterFilter}
                        bottomInfo={[
                            {
                                value: (
                                    <div>
                                        <span style={{ fontWeight: 700 }}> {t('Total Orders')} </span>
                                    </div>
                                ),
                            },
                            ...deliveryRender,
                        ]}
                    />
                ) : (
                    <CustomTable
                        labels={labelsDacia}
                        tableData={currentUserObjectives.filter((objective) => objective.brandId === '1')}
                        cellModifier={cellModifierDacia}
                        withPagination={false}
                        hover={false}
                        withBoxShadow={false}
                        withRowColors={false}
                        loading={loadingDacia}
                        setLoading={setLoadingDacia}
                        filterSearch={filteredSearch}
                        triggerFilter={triggerFilter}
                        filterData={filterData}
                        filterFunction={ObjectivesAPI.filterObjectives}
                        isFilter={isFilter}
                        filterFilter={filterFilter}
                        setterFunction={setCurrentUserObjectives}
                    />
                ),
        },
        {
            label: 'RENAULT',
            id: 1,
            render: true,
            disabled: !existRenault,
            component:
                user.roleId !== '4' && user.roleID !== '5 ' ? (
                    <CustomTable
                        withBoxShadow={false}
                        labels={labelsRenault}
                        cellModifier={cellModifierRenault}
                        withPagination={true}
                        tableData={user.roleId === '3' ? sefDeVanzariObjectives : renaultObjectives}
                        getterFunction={
                            user.roleId === '3' ? ObjectivesAPI.getAllCounselorsForSefVanzari : ObjectivesAPI.get
                        }
                        setterFunction={user.roleId === '3' ? setSefDeVanzariObjectives : setRenaultObjectives}
                        additionalId={
                            user.roleId === '3'
                                ? { userId: user.id, startDate: todaysDate, brandId: '2' }
                                : { startDate: todaysDate, brandId: '2' }
                        }
                        triggerRefetch={triggerRefetch}
                        loading={loadingRenault}
                        setLoading={setLoadingRenault}
                        searchFunction={ObjectivesAPI.searchObjectives}
                        search={search}
                        isSearch={isSearch}
                        filterSearch={filteredSearch}
                        triggerSearch={triggerSearch}
                        triggerFilter={triggerFilter}
                        filterData={filterData}
                        filterFunction={ObjectivesAPI.filterObjectives}
                        isFilter={isFilter}
                        filterFilter={filterFilter}
                        bottomInfo={[
                            {
                                value: (
                                    <div>
                                        <span style={{ fontWeight: 700 }}> {t('Total Orders')} </span>
                                    </div>
                                ),
                            },
                            ...deliveryRenderRenault,
                        ]}
                    />
                ) : (
                    <CustomTable
                        labels={labelsRenault}
                        tableData={currentUserObjectives.filter((objective) => objective.brandId === '2')}
                        cellModifier={cellModifierRenault}
                        withPagination={false}
                        hover={false}
                        withBoxShadow={false}
                        withRowColors={false}
                        loading={loadingRenault}
                        setLoading={setLoadingRenault}
                        filterSearch={filteredSearch}
                        triggerFilter={triggerFilter}
                        filterData={filterData}
                        filterFunction={ObjectivesAPI.filterObjectives}
                        isFilter={isFilter}
                        filterFilter={filterFilter}
                        setterFunction={setCurrentUserObjectives}
                    />
                ),
        },
        {
            label: 'NISSAN',
            id: 2,
            render: true,
            disabled: !existNissan,
            component:
                user.roleId !== '4' && user.roleID !== '5 ' ? (
                    <CustomTable
                        withBoxShadow={false}
                        labels={labelsNissan}
                        cellModifier={cellModifierNissan}
                        tableData={user.roleId === '3' ? sefDeVanzariObjectives : nissanObjectives}
                        withPagination={true}
                        getterFunction={
                            user.roleId === '3' ? ObjectivesAPI.getAllCounselorsForSefVanzari : ObjectivesAPI.get
                        }
                        setterFunction={user.roleId === '3' ? setSefDeVanzariObjectives : setNissanObjectives}
                        additionalId={
                            user.roleId === '3'
                                ? { userId: user.id, startDate: todaysDate, brandId: '3' }
                                : { startDate: todaysDate, brandId: '3' }
                        }
                        triggerRefetch={triggerRefetch}
                        loading={loadingNissan}
                        setLoading={setLoadingNissan}
                        searchFunction={ObjectivesAPI.searchObjectives}
                        search={search}
                        isSearch={isSearch}
                        filterSearch={filteredSearch}
                        triggerSearch={triggerSearch}
                        triggerFilter={triggerFilter}
                        filterData={filterData}
                        filterFunction={ObjectivesAPI.filterObjectives}
                        isFilter={isFilter}
                        filterFilter={filterFilter}
                        bottomInfo={[
                            {
                                value: (
                                    <div>
                                        <span style={{ fontWeight: 700 }}> {t('Total Orders')} </span>
                                    </div>
                                ),
                            },
                            ...deliveryRenderNissan,
                        ]}
                    />
                ) : (
                    <CustomTable
                        labels={labelsNissan}
                        tableData={currentUserObjectives.filter((objective) => objective.brandId === '3')}
                        cellModifier={cellModifierNissan}
                        withPagination={false}
                        hover={false}
                        withBoxShadow={false}
                        withRowColors={false}
                        loading={loadingNissan}
                        setLoading={setLoadingNissan}
                        filterSearch={filteredSearch}
                        triggerFilter={triggerFilter}
                        filterData={filterData}
                        filterFunction={ObjectivesAPI.filterObjectives}
                        isFilter={isFilter}
                        filterFilter={filterFilter}
                        setterFunction={setCurrentUserObjectives}
                    />
                ),
        },
    ];

    const handleRemoveFilters = () => {
        setFilterData({
            startDate: new Date(),
            endDate: new Date(),
            userId: user.roleId === '4' || user.roleId === '5' ? user.id : '',
        });
        setIsFilter(false);

        if (activeTab === 0) setDaciaObjectives([]);
        if (activeTab === 1) setRenaultObjectives([]);
        if (activeTab === 2) setNissanObjectives([]);

        if (activeTab === 0) setCurrentUserObjectives([]);
        if (activeTab === 1) setCurrentUserObjectives([]);
        if (activeTab === 2) setCurrentUserObjectives([]);

        if (activeTab === 0) setLoadingDacia(true);
        if (activeTab === 1) setLoadingRenault(true);
        if (activeTab === 2) setLoadingNissan(true);
        setTriggerRefetch(!triggerRefetch);
        setOpenFilter(false);
        getObjectivesByUserId();
    };

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Objectives')}</title>
            </Helmet>
            <PageLayout
                withTooltip={true}
                title={`${t('Objectives')}`}
                subTitle={t('Manage your objectives')}
                actionArea={
                    <>
                        {user.roleId !== '4' && user.roleID !== '5 ' && (
                            <Search
                                setIsFilter={setIsFilter}
                                isSearch={isSearch}
                                setIsSearch={setIsSearch}
                                search={search}
                                setSearch={setSearch}
                                nameToFind={t('Search objective')}
                                triggerRefetch={triggerRefetch}
                                setTriggerRefetch={setTriggerRefetch}
                                triggerSearch={triggerSearch}
                                setTriggerSearch={setTriggerSearch}
                                setterFunction={
                                    activeTab === 0
                                        ? setDaciaObjectives
                                        : activeTab === 1
                                        ? setRenaultObjectives
                                        : activeTab === 2
                                        ? setNissanObjectives
                                        : () => {}
                                }
                                setLoading={
                                    activeTab === 0
                                        ? setLoadingDacia
                                        : activeTab === 1
                                        ? setLoadingRenault
                                        : activeTab === 2
                                        ? setLoadingNissan
                                        : () => {}
                                }
                            />
                        )}

                        <PrimaryButton color="light" onClick={() => setOpenFilter(true)} startIcon={<FilterListIcon />}>
                            {t('Filter objectives')}{' '}
                        </PrimaryButton>
                        {can(11) && (
                            <PrimaryButton
                                onClick={() => {
                                    setRowData(null);
                                    setAddOpenObjectives(true);
                                }}
                                startIcon={<AddIcon />}
                                color="primary"
                            >
                                {t('Add objective')}{' '}
                            </PrimaryButton>
                        )}
                    </>
                }
            >
                <CustomTableLayout
                    tabs={tabs}
                    setTabName={setTabName}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            </PageLayout>

            <CustomDialog
                open={openFilter}
                setOpen={setOpenFilter}
                title={t('Filter objectives')}
                buttonClose={t('Cancel')}
                buttonFinish={t('Finish')}
                width="600px"
                buttonUtility={t('REMOVE FILTERS')}
                onClickButtonUtility={handleRemoveFilters}
                onClickButtonFinish={() => {
                    handleFilterData();
                }}
            >
                <Grid container justifyContent={'space-between'} spacing={4}>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label={t('Start Date')}
                                views={['month', 'year']}
                                value={filterData.startDate}
                                onChange={(value) => {
                                    handleChange({ target: { name: 'startDate', value } });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        sx={{
                                            fieldset: {
                                                borderRadius: '10px',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label={t('End Date')}
                                views={['month', 'year']}
                                value={filterData.endDate}
                                onChange={(value) => {
                                    handleChange({ target: { name: 'endDate', value } });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        sx={{
                                            fieldset: {
                                                borderRadius: '10px',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>

                    {user.roleId !== '4' && user.roleId !== '5' && (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                sx={{
                                    fieldset: {
                                        borderRadius: '10px',
                                    },
                                }}
                                id="outlined-required"
                                label={t('Counselor')}
                                name="userId"
                                value={filterData.userId}
                                onChange={handleChange}
                            >
                                {filteredAdviser.map((user) => (
                                    <MenuItem value={user.id} key={user.id}>
                                        {user.name} {user.firstName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}
                </Grid>
            </CustomDialog>

            <AddObjectivesModal
                open={openAddObjectives}
                setOpen={setAddOpenObjectives}
                tabName={tabName}
                rowData={rowData}
                triggerRefetch={triggerRefetch}
                setTriggerRefetch={setTriggerRefetch}
                filteredAdviser={filteredAdviser}
            />

            <ConfirmModal
                open={openConfirm}
                setOpen={setOpenConfirm}
                onClickButtonYes={() =>
                    ObjectivesAPI.delete(rowId).then((res) => {
                        if (res.ok === true) {
                            setTriggerRefetch(!triggerRefetch);
                            setOpenConfirm(false);
                            toast.success(t('The objective was successfully deleted!'));
                        } else {
                            toast.error(t('Something went wrong!'));
                        }
                    })
                }
                text={t('Are you sure you want to delete this objective?')}
            />
        </>
    );
};

export default Objectives;
