import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Tasks.module.scss';
import { CustomDialog } from 'lib';
import { MenuItem, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useUser } from 'hooks/useUser';

const FilterTasks = ({
    open,
    setOpen,
    filterData = {},
    setFilterData,
    setTriggerFilter,
    triggerFilter,
    triggerRefetch,
    setTriggerRefetch,
    setIsFilter,
    setData,
    setLoading,
    resetSearch = () => {},
    resetFilter,
    clients = [],
    users = [],
    isFilter,
    getClients,
    setClients,
    userId = null,
    clientId = null,
    userState = null,
}) => {
    const { t } = useTranslation();
    const { user } = useUser();

    const types = [
        { id: 'DriveTest', name: t('Schedule drive test') },
        { id: 'GDPR', name: t('GDPR activation') },
        { id: 'reContact', name: t('Costumer recontracting') },
        { id: 'others', name: t('Other task') },
    ];

    const brands = [
        { id: 1, name: 'Dacia' },
        { id: 2, name: 'Renault' },
        { id: 3, name: 'Nissan' },
    ];

    const displayedBrands = () => {
        if (userId) {
            return brands.filter((brand) =>
                userState?.UserBrand.map((uBrand) => Number(uBrand.brandId)).includes(brand.id)
            );
        } else {
            return brands.filter((brand) => user.UserBrand.map((uBrand) => Number(uBrand.brandId)).includes(brand.id));
        }
    };

    const handleChange = (e) => {
        setFilterData({
            ...filterData,
            [e.target.name]: e.target.value,
        });
    };

    const handleRemoveFilters = () => {
        resetFilter();
        setLoading(true);
        setData([]);
        setTriggerRefetch(!triggerRefetch);
        setOpen(false);
    };

    const handleFilter = () => {
        resetSearch();
        setLoading(true);
        setData([]);
        setIsFilter(true);
        setTriggerFilter(!triggerFilter);
        setOpen(false);
    };

    const compStartDate = new Date(filterData.startDate);
    compStartDate.setHours(0, 0, 0, 0);
    const compEndDate = new Date(filterData.endDate);
    compEndDate.setHours(0, 0, 0, 0);
    const currentDate = new Date(Date.now());
    currentDate.setHours(0, 0, 0, 0);

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={t('Filter tasks')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            buttonUtility={t('REMOVE FILTERS')}
            width="580px"
            onClickButtonFinish={handleFilter}
            onClickButtonClose={() => {
                setOpen(false);
            }}
            onClickButtonUtility={handleRemoveFilters}
            buttonUtilityDisabled={!isFilter}
            buttonFinishDisabled={Object.values(filterData).every((x) => x === null || x === '')}
        >
            <div className={styles.addTaskWrapper}>
                {!userId && !clientId && user.roleId !== '4' && (
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <TextField
                            name="userId"
                            label={`${t('Counselor')}`}
                            size="medium"
                            fullWidth
                            select
                            sx={{
                                fieldset: {
                                    borderRadius: '10px',
                                },
                            }}
                            id="outlined-required"
                            value={filterData.userId}
                            onChange={handleChange}
                        >
                            {users.map((user) => (
                                <MenuItem
                                    key={user.id}
                                    value={user.id}
                                    sx
                                    onClick={() => {
                                        getClients(user.id);
                                    }}
                                >
                                    {`${user.name + ' ' + user.firstName}`}
                                </MenuItem>
                            ))}
                        </TextField>
                        <DeleteIcon
                            fontSize="large"
                            sx={{
                                cursor: filterData.userId !== '' && 'pointer',
                                color: filterData.userId !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                                '&:hover': {
                                    color: filterData.userId !== '' && '#a50009',
                                },
                            }}
                            onClick={() => {
                                setFilterData({
                                    ...filterData,
                                    userId: '',
                                    clientBrandsId: '',
                                });
                                setClients([]);
                            }}
                        />
                    </div>
                )}

                {!clientId && (
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <TextField
                            name="clientBrandsId"
                            label={`${t('Client')}`}
                            size="medium"
                            fullWidth
                            select
                            sx={{
                                fieldset: {
                                    borderRadius: '10px',
                                },
                            }}
                            id="outlined-required"
                            value={filterData.clientBrandsId}
                            onChange={handleChange}
                        >
                            {clients.map((client) => (
                                <MenuItem key={client.clientsBrandId} value={client.clientsBrandId} sx>
                                    {`${
                                        client.type === 'Individual'
                                            ? client.lastName + ' ' + client.firstName
                                            : client.type === 'Juridic'
                                            ? client.firmName
                                            : ''
                                    } - ${brands.find((brand) => String(brand.id) === client.brandId)?.['name']}`}
                                </MenuItem>
                            ))}
                        </TextField>
                        <DeleteIcon
                            fontSize="large"
                            sx={{
                                cursor: filterData.clientBrandsId !== '' && 'pointer',
                                color: filterData.clientBrandsId !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                                '&:hover': {
                                    color: filterData.clientBrandsId !== '' && '#a50009',
                                },
                            }}
                            onClick={() =>
                                setFilterData({
                                    ...filterData,
                                    clientBrandsId: '',
                                })
                            }
                        />
                    </div>
                )}

                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <TextField
                        name="type"
                        label={`${t('Type')}`}
                        size="medium"
                        fullWidth
                        select
                        sx={{
                            fieldset: {
                                borderRadius: '10px',
                            },
                        }}
                        id="outlined-required"
                        value={filterData.type}
                        onChange={handleChange}
                    >
                        {types.map((type) => (
                            <MenuItem key={type.id} value={type.id} sx>
                                {type.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <DeleteIcon
                        fontSize="large"
                        sx={{
                            cursor: filterData.type !== '' && 'pointer',
                            color: filterData.type !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                            '&:hover': {
                                color: filterData.type !== '' && '#a50009',
                            },
                        }}
                        onClick={() =>
                            setFilterData({
                                ...filterData,
                                type: '',
                            })
                        }
                    />
                </div>

                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label={t('Start date')}
                            views={['day', 'month', 'year']}
                            value={filterData.startDate}
                            onChange={(value) => {
                                handleChange({ target: { name: 'startDate', value } });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    {...params}
                                    sx={{
                                        fieldset: {
                                            borderRadius: '10px',
                                        },
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <DeleteIcon
                        fontSize="large"
                        sx={{
                            cursor: compStartDate.getTime() !== currentDate.getTime() && 'pointer',
                            color: compStartDate.getTime() !== currentDate.getTime() ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                            '&:hover': {
                                color: compStartDate.getTime() !== currentDate.getTime() && '#a50009',
                            },
                        }}
                        onClick={() =>
                            setFilterData({
                                ...filterData,
                                startDate: new Date(),
                            })
                        }
                    />
                </div>
                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label={t('End date')}
                            views={['day', 'month', 'year']}
                            value={filterData.endDate}
                            onChange={(value) => {
                                handleChange({ target: { name: 'endDate', value } });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    {...params}
                                    sx={{
                                        fieldset: {
                                            borderRadius: '10px',
                                        },
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <DeleteIcon
                        fontSize="large"
                        sx={{
                            cursor: compEndDate.getTime() !== currentDate.getTime() && 'pointer',
                            color: compEndDate.getTime() !== currentDate.getTime() ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                            '&:hover': {
                                color: compEndDate.getTime() !== currentDate.getTime() && '#a50009',
                            },
                        }}
                        onClick={() =>
                            setFilterData({
                                ...filterData,
                                endDate: new Date(),
                            })
                        }
                    />
                </div>

                {!clientId && user.roleId !== '3' && (
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <TextField
                            name="brandId"
                            label={`${t('Brand')}`}
                            size="medium"
                            fullWidth
                            select
                            sx={{
                                fieldset: {
                                    borderRadius: '10px',
                                },
                            }}
                            id="outlined-required"
                            value={filterData.brandId}
                            onChange={handleChange}
                        >
                            {displayedBrands().map((brand) => (
                                <MenuItem key={brand.id} value={brand.id} sx>
                                    {brand.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <DeleteIcon
                            fontSize="large"
                            sx={{
                                cursor: filterData.brandId !== '' && 'pointer',
                                color: filterData.brandId !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                                '&:hover': {
                                    color: filterData.brandId !== '' && '#a50009',
                                },
                            }}
                            onClick={() =>
                                setFilterData({
                                    ...filterData,
                                    brandId: '',
                                })
                            }
                        />
                    </div>
                )}
            </div>
        </CustomDialog>
    );
};

export default FilterTasks;
