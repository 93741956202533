import React from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stepper, Step, StepLabel, Button, Typography, Grid, MenuItem } from '@mui/material';
import { CustomDialog } from 'lib';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { TextField, Select } from 'lib';
import { useHistory } from 'react-router-dom';

const ReOfferOffer = ({ open, setOpen, rowId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const formRef = useRef();

    const INITIAL_FORM_STATE = {
        reason: '',
    };

    const FORM_VALIDATION = yup.object().shape({
        reason: yup
            .string()
            .typeError(t('This reason is not a valid one!'))
            .trim()
            .min(3, t('The reason has to contain at least three characters!'))
            .required(t('Reason is mandatory!')),
    });

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={t('Reoffer')}
            buttonClose={t('BACK')}
            buttonFinish={t('Finish')}
            onClickButtonFinish={handleSubmit}
            width="500px"
            onClickButtonClose={() => {
                setOpen(false);
            }}
        >
            <Formik
                innerRef={formRef}
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    history.push({
                        pathname: '/offers/create',
                        state: {
                            offerId: rowId,
                            reOfferReason: values.reason,
                        },
                    });
                }}
            >
                <Form>
                    <Grid container justifyContent={'space-between'} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="clientDetailsInfo">
                                {t('Write the reason why the offer was reoffered')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="reason" label={`${t('Reason')}*`} />
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </CustomDialog>
    );
};

export default ReOfferOffer;
