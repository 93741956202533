import React, { useRef, useState, useEffect } from 'react';
import { CustomDialog } from 'lib';
import { useTranslation } from 'react-i18next';
import { Avatar, MenuItem, Typography } from '@mui/material';
import { UtilityButton } from 'lib/components/Buttons/buttons';
import { FileUploadWrapper } from 'lib';
import { uploadSingleFile } from 'utils/functions';
import { toast } from 'react-toastify';
import DefaultUserPicture from 'resources/img/user/DefaultUserPicture.png';

import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField, Select, MultiSelect } from 'lib';
import { useUser } from 'hooks/useUser';
import { WorkPointsAPI, UsersAPI, RolesAPI } from 'api_darex';
import { useCallback } from 'react';

const EditUser = ({ userState, open, setOpen, getUserById }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [fileBlob, setFileBlob] = useState(null);
    const [locations, setLocations] = useState([]);
    const [profilePic, setProfilePic] = useState('');
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const { user, getUpdatedUser } = useUser();

    const brands = [
        { name: 'Dacia', id: '1' },
        { name: 'Renault', id: '2' },
        { name: 'Nissan', id: '3' },
    ];

    const getRoles = () => {
        RolesAPI.get().then((res) => {
            setRoles(res.data);
        });
    };

    const getWorkPoints = () => {
        WorkPointsAPI.get().then((res) => {
            setLocations(res.data);
        });
    };

    const getUsers = () => {
        UsersAPI.get().then((res) => {
            setUsers(res.data.content);
        });
    };

    useEffect(() => {
        getRoles();
        getWorkPoints();
        getUsers();
    }, []);

    useEffect(() => {
        (async () => {
            const imgId = userState?.ProfilePicture[userState.ProfilePicture.length - 1]?.file.fileName;
            if (imgId) {
                const { data: imgUrl } = await UsersAPI.getPicture(imgId);
                setProfilePic(imgUrl);
            }
        })();
    }, [userState]);

    const handleFilterUsers = useCallback(
        ({ roleId }) => {
            setFilteredUsers(
                users.filter((item) => {
                    return Number(item.roleId) === roleId - 1;
                })
            );
        },
        [users]
    );

    useEffect(() => {
        handleFilterUsers({ roleId: userState?.roleId });
    }, [users, userState, handleFilterUsers]);

    const handleFileUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    toast.error(t(message));
                    return;
                }
                setFileBlob(blob);
            },
            'image'
        );
    };

    const INITIAL_FORM_STATE = {
        firstName: userState?.firstName,
        name: userState?.name,
        roleId: userState?.roleId,
        workingPointId: userState?.workingPointId ? userState.workingPointId : '',
        UserBrand: userState?.UserBrand.map((item) => {
            const id = item.brandId;
            return id;
        }),
        Inferior: userState?.Inferior
            ? userState?.Inferior.map((item) =>
                  JSON.stringify({
                      id: item.Senior.id,
                      brands: item.Senior.UserBrand,
                  })
              )
            : [],
    };

    const FROM_VALIDATION = yup.object().shape({
        firstName: yup
            .string()
            .typeError(t('The first name is not valid!'))
            .trim()
            .min(3, t('The first name must contain at least 3 characters!'))
            .required(t('The first name is mandatory!')),
        name: yup
            .string()
            .typeError(t('This last name is not valid!'))
            .trim()
            .min(3, t('The last name must contain at least 3 characters!'))
            .required(t('The last name is mandatory!')),
        roleId: yup.string().trim().required(t('The role is mandatory!')),
        workingPointId: yup
            .string()
            .typeError(t('Work point should be a string!'))
            .trim()
            .when('roleId', {
                is: (roleId) => roleId !== '1' && roleId !== '2' && roleId !== '5',
                then: yup.string().required(t('The location is mandatory!')),
            }),
        Inferior: yup
            .array()
            .of(yup.string())
            .when('roleId', {
                is: (roleId) => roleId !== '1' && roleId !== '4' && roleId < '5',
                then: yup
                    .array()
                    .min(1, t('At least one superior is required!'))
                    .max(1, t('Only one superior is required!'))
                    .required(t('Select one superior!')),
            })
            .when('roleId', {
                is: (roleId) => roleId === '4',
                then: yup
                    .array()
                    .min(1, t('At least one superior is required!'))
                    .max(3, t('You can have a maximum of three superiors!'))
                    .required(t('Select one superior!')),
            }),
        UserBrand: yup
            .array()
            .of(yup.string())
            .when('roleId', {
                is: (roleId) => roleId === '4',
                then: yup
                    .array()
                    .min(1, t(`You have to select at least one brand!`))
                    .test({
                        name: 'max',
                        exclusive: false,
                        params: {},
                        message: t(`You have to select the correct number of brands!`),
                        test: (value, context) => value.length === context.parent.Inferior.length && value.length !== 2,
                    })
                    .required(t('Select one or more brands!')),
            })
            .when('roleId', {
                is: (roleId) => roleId === '3',
                then: yup
                    .array()
                    .min(1, t('At least one brand is required!'))
                    .max(1, t('Only one brand is required!'))
                    .required(t('Select one brand!')),
            })
            .when('roleId', {
                is: (roleId) => roleId > '5',
                then: yup
                    .array()
                    .min(1, t('At least one brand is required!'))
                    .max(3, t('Only one brand is required!'))
                    .required(t('Select one brand!')),
            }),
    });

    const onSubmitFunc = (values) => {
        setLoading(true);

        const newFormShape = {
            roleId: values.roleId,
            firstName: values.firstName.trim(),
            name: values.name.trim(),
            workingPointId:
                values.roleId !== '1' && values.roleId !== '2' && values.roleId !== '5' ? values.workingPointId : null,
            UserBrand:
                values.roleId !== '1' && values.roleId !== '2' && values.roleId !== '5'
                    ? values.UserBrand.map((ub) => ({
                          brandId: ub,
                      }))
                    : [{ brandId: '1' }, { brandId: '2' }, { brandId: '3' }],
            Inferior:
                values.roleId !== '1' && values.roleId !== '5'
                    ? values.Inferior.map((item) => {
                          const parsedItem = JSON.parse(item);
                          return { superiorId: parsedItem.id };
                      })
                    : [{ superiorId: user.id }],
        };

        UsersAPI.update(newFormShape, userState.id).then((res) => {
            if (res.ok === true) {
                if (fileBlob) {
                    UsersAPI.updatePicture(userState.id, fileBlob).then((res) => {
                        if (res.ok === true) {
                            getUserById();
                            if (userState.id === user.id) {
                                getUpdatedUser();
                            }
                            setFileBlob(null);
                            setOpen(false);
                            setLoading(false);
                            toast.success(t('Profile picture has been uploaded successfully!'));
                        } else {
                            getUserById();
                            if (userState.id === user.id) {
                                getUpdatedUser();
                            }
                            setLoading(false);
                            toast.error(t('Something went wrong! Profile picture could not be uploaded successfully!'));
                        }
                    });
                } else {
                    getUserById();
                    if (userState.id === user.id) {
                        getUpdatedUser();
                    }
                    setOpen(false);
                    setLoading(false);
                }

                toast.success(t('User has been updated successfully!'));
            } else {
                setLoading(false);
                toast.error(t('Something went wrong! User could not be updated!'));
            }
        });
    };

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const brandsName = {
        1: 'Dacia',
        2: 'Renault',
        3: 'Nissan',
    };

    const handleDisplayBrand = ({ brands }) => {
        return brands.map((item, index) => {
            if (index === brands.length - 1) {
                return brandsName[item.brandId];
            } else {
                return brandsName[item.brandId] + ', ';
            }
        });
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={t('Edit user')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={handleSubmit}
            width="100%"
            maxWidth="700px"
            onClickButtonClose={() => {
                setFileBlob(null);
                setOpen(false);
                setLoading(false);
            }}
            buttonFinishLoading={loading}
            buttonCloseDisabled={loading}
        >
            <Formik
                innerRef={formRef}
                initialValues={{ ...INITIAL_FORM_STATE }}
                validationSchema={FROM_VALIDATION}
                onSubmit={(values) => {
                    onSubmitFunc(values);
                }}
            >
                {(props) => {
                    const parsedAdminId = props.values.Inferior.map((item) => JSON.parse(item));

                    const isDacia =
                        parsedAdminId
                            .map((item) => item.brands.findIndex((item) => item.brandId === '1') < 0)
                            .findIndex((item) => item === false) < 0;
                    const isRenault =
                        parsedAdminId
                            .map((item) => item.brands.findIndex((item) => item.brandId === '2') < 0)
                            .findIndex((item) => item === false) < 0;
                    const isNissan =
                        parsedAdminId
                            .map((item) => item.brands.findIndex((item) => item.brandId === '3') < 0)
                            .findIndex((item) => item === false) < 0;

                    const canBeSelected = ({ user }) => {
                        const brandsResult = parsedAdminId.map((item) =>
                            item.brands.some((brand) =>
                                user.UserBrand.some((uBrand) => uBrand.brandId === brand.brandId)
                            )
                        );
                        const idResult = parsedAdminId.findIndex((item) => item.id === user.id) < 0;
                        return brandsResult.findIndex((item) => item === true) >= 0 && idResult;
                    };

                    if (props.values.UserBrand.indexOf('1') >= 0 && isDacia && props.values.roleId <= '5') {
                        props.setFieldValue(
                            'UserBrand',
                            props.values.UserBrand.filter((item) => item !== '1')
                        );
                    }

                    if (props.values.UserBrand.indexOf('2') >= 0 && isRenault && props.values.roleId <= '5') {
                        props.setFieldValue(
                            'UserBrand',
                            props.values.UserBrand.filter((item) => item !== '2')
                        );
                    }

                    if (props.values.UserBrand.indexOf('3') >= 0 && isNissan && props.values.roleId <= '5') {
                        props.setFieldValue(
                            'UserBrand',
                            props.values.UserBrand.filter((item) => item !== '3')
                        );
                    }

                    return (
                        <Form style={{ height: '100%', marginTop: '10px' }}>
                            <div className="form-wrapper">
                                <div className="form-container">
                                    <div className="form-column">
                                        <div className="column-container">
                                            <TextField name="firstName" label={`${t('First Name')}*`} size="medium" />
                                            {user.id !== userState?.id &&
                                                props.values.roleId !== '1' &&
                                                props.values.roleId !== '2' &&
                                                props.values.roleId !== '5' && (
                                                    <MultiSelect
                                                        name="UserBrand"
                                                        label={`${t('Brands')}*`}
                                                        size="medium"
                                                    >
                                                        {brands.map((brand) => (
                                                            <MenuItem
                                                                key={brand.id}
                                                                value={brand.id}
                                                                sx
                                                                disabled={
                                                                    (props.values.roleId <= 5 &&
                                                                        brand.id === '1' &&
                                                                        isDacia) ||
                                                                    (props.values.roleId <= 5 &&
                                                                        brand.id === '2' &&
                                                                        isRenault) ||
                                                                    (props.values.roleId <= 5 &&
                                                                        brand.id === '3' &&
                                                                        isNissan)
                                                                }
                                                            >
                                                                {brand.name}
                                                            </MenuItem>
                                                        ))}
                                                    </MultiSelect>
                                                )}
                                            {user.id !== userState?.id &&
                                                props.values.roleId !== '1' &&
                                                props.values.roleId !== '2' &&
                                                props.values.roleId !== '5' && (
                                                    <Select
                                                        name="workingPointId"
                                                        label={`${t('Assigned work point')}*`}
                                                        size="medium"
                                                    >
                                                        {locations.map((location) => (
                                                            <MenuItem key={location.id} value={location.id} sx>
                                                                {location.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                        </div>
                                    </div>
                                    <div className="form-column">
                                        <div className="column-container">
                                            <TextField name="name" label={`${t('Last Name')}*`} size="medium" />
                                            {user.roleId === '1' &&
                                                userState?.id !== user.id &&
                                                userState?.roleId !== '1' && (
                                                    <Select name="roleId" label={`${t('Role')}*`} size="medium">
                                                        {roles.map((role) => (
                                                            <MenuItem
                                                                key={role.id}
                                                                value={role.id}
                                                                sx
                                                                onClick={() => {
                                                                    if (role.id !== '1' && role.id < '5') {
                                                                        handleFilterUsers({ roleId: role.id });
                                                                    }
                                                                    props.setFieldValue('Inferior', []);
                                                                    props.setFieldValue('UserBrand', []);
                                                                }}
                                                            >
                                                                {role.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                )}
                                            {props.values.roleId !== '' &&
                                                props.values.roleId !== '1' &&
                                                props.values.roleId < '5' && (
                                                    <MultiSelect
                                                        name="Inferior"
                                                        label={`${t('Superior')}*`}
                                                        size="medium"
                                                    >
                                                        {filteredUsers?.map((user) => (
                                                            <MenuItem
                                                                key={user.id}
                                                                value={JSON.stringify({
                                                                    id: user.id,
                                                                    brands: user.UserBrand,
                                                                })}
                                                                disabled={canBeSelected({ user })}
                                                            >
                                                                {user.name} {user.firstName}{' '}
                                                                <span
                                                                    style={{
                                                                        width: '5px',
                                                                        height: '5px',
                                                                        borderRadius: '15px',
                                                                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                                                        marginLeft: '8px',
                                                                        marginRight: '8px',
                                                                        display: 'inline-block',
                                                                        verticalAlign: 'middle',
                                                                    }}
                                                                />
                                                                {handleDisplayBrand({ brands: user.UserBrand })}
                                                            </MenuItem>
                                                        ))}
                                                    </MultiSelect>
                                                )}
                                        </div>
                                    </div>
                                </div>
                                <div className="image-wrapper">
                                    <Typography
                                        sx={{ fontSize: '14px', fontWeight: '600', color: 'black', opacity: '62%' }}
                                    >
                                        {t('Add profile picture')}
                                    </Typography>
                                    <div className="image-wrapperContainer">
                                        <Avatar
                                            src={
                                                profilePic && !fileBlob
                                                    ? profilePic
                                                    : fileBlob
                                                    ? URL.createObjectURL(fileBlob)
                                                    : DefaultUserPicture
                                            }
                                            sx={{ width: '100px', height: '100px' }}
                                        />
                                        <FileUploadWrapper onUpload={handleFileUpload}>
                                            <UtilityButton variant="contained" type="button" height="41px">
                                                {fileBlob || profilePic ? t('CHANGE PHOTO') : t('UPLOAD')}
                                            </UtilityButton>
                                        </FileUploadWrapper>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </CustomDialog>
    );
};

export default EditUser;
