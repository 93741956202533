import React, { useEffect, useState } from 'react';
import { Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs, Avatar } from '@mui/material';
import PropTypes from 'prop-types';
import { PermissionsWrapper } from 'lib';
import { LanguageDropDown } from 'lib/components/Dropdowns/dropdowns';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { UtilityButton } from 'lib/components/Buttons/buttons';
import ChangeName from './ChangeName';
import ChangePassword from './ChangePassword';
import ChangeProfilePicture from './ChangeProfilePicture';
import DownloadReport from './DownloadReport';
import { Helmet } from 'react-helmet';
import { useUser } from 'hooks/useUser';
import { UsersAPI, WorkPointsAPI } from 'api_darex';
import DefaultUserPicture from 'resources/img/user/DefaultUserPicture.png';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Profile = () => {
    const { t, i18n } = useTranslation();
    const [openChangeName, setOpenChangeName] = useState(false);
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [openChangeImage, setOpenChangeImage] = useState(false);
    const [selectedLang, setSelectedLang] = useState(i18n.language);
    const [openDownloadReport, setOpenDownloadReport] = useState(false);
    const [profilePic, setProfilePic] = useState('');
    const [workpointName, setWorkpointName] = useState('');
    const { vw } = useWindowDimensions();
    const { user, getUpdatedUser } = useUser();

    const params = new URL(document.location).searchParams;
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (params.get('tab')) {
            setValue(Number(params.get('tab')));
        }
    }, []);

    useEffect(() => {
        (async () => {
            const imgId = user.ProfilePicture?.[user.ProfilePicture.length - 1]?.file.fileName;
            if (imgId) {
                const { data: imgUrl } = await UsersAPI.getPicture(imgId);
                setProfilePic(imgUrl);
            }
        })();
    }, [user]);

    useEffect(() => {
        if (user.roleId === '3' || user.roleId === '4') {
            WorkPointsAPI.getById(user.workingPointId).then((res) => {
                setWorkpointName(res.data.name);
            });
        }
    }, []);

    const dropDownItems = [
        {
            id: 1,
            label: 'Română',
            onClick: () => {
                handleLangChange('ro');
            },
        },
        {
            id: 2,
            label: 'English',
            onClick: () => {
                handleLangChange('en');
            },
        },
    ];

    const [dropDownValue, setDropDownValue] = useState(i18n.language === 'ro' ? 1 : 2);

    const handleLangChange = (prop) => {
        const lang = prop;
        localStorage.setItem('preferredLang', lang);
        setDropDownValue(lang === 'ro' ? 1 : 2);
        setSelectedLang(lang);
        i18n.changeLanguage(lang);
    };

    useEffect(() => {
        setDropDownValue(i18n.language === 'ro' ? 1 : 2);
        setSelectedLang(i18n.language);
    }, [i18n.language]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Profile')}</title>
            </Helmet>
            <>
                <div className="profile-header">
                    <Typography variant="firstPageTitle" sx={{ padding: vw <= 640 ? '0 1rem' : 0 }}>
                        {t('Hello')}, {user.firstName}! {t('Welcome to the platform')}!
                    </Typography>
                    <Box>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            sx={{
                                '& .MuiButtonBase-root': {
                                    padding: vw > 640 ? '12px 16px' : '12px 8px',
                                },
                                '.css-1aquho2-MuiTabs-indicator': { backgroundColor: 'rgba(0, 0, 0, 0.62)' },
                            }}
                        >
                            <Tab
                                label={t('General settings')}
                                {...a11yProps(0)}
                                sx={{
                                    fontFamily: ['Montserrat', 'sans-serif'].join(','),
                                    fontStyle: 'normal',
                                    lineHeight: '17px',
                                    fontWeight: 600,
                                    fontSize: vw > 640 ? '14px' : '12px',
                                    textTransform: vw <= 640 && 'none',
                                    letterSpacing: '0.1px',
                                    color: 'rgba(0, 0, 0, 0.62)',
                                    '&.Mui-selected': {
                                        color: 'rgba(0, 0, 0, 0.62)',
                                    },
                                }}
                            />
                            <Tab
                                label={t('Profile settings')}
                                {...a11yProps(1)}
                                sx={{
                                    fontFamily: ['Montserrat', 'sans-serif'].join(','),
                                    fontStyle: 'normal',
                                    lineHeight: '17px',
                                    fontWeight: 600,
                                    fontSize: vw > 640 ? '14px' : '12px',
                                    textTransform: vw <= 640 && 'none',
                                    letterSpacing: '0.1px',
                                    color: 'rgba(0, 0, 0, 0.62)',
                                    '&.Mui-selected': {
                                        color: 'rgba(0, 0, 0, 0.62)',
                                    },
                                }}
                            />
                            {user.roleId === '1' && (
                                <Tab
                                    label={t('REPORT')}
                                    {...a11yProps(2)}
                                    sx={{
                                        fontFamily: ['Montserrat', 'sans-serif'].join(','),
                                        fontStyle: 'normal',
                                        lineHeight: '17px',
                                        fontWeight: 600,
                                        fontSize: vw > 640 ? '14px' : '12px',
                                        textTransform: vw <= 640 && 'none',
                                        letterSpacing: '0.1px',
                                        color: 'rgba(0, 0, 0, 0.62)',
                                        '&.Mui-selected': {
                                            color: 'rgba(0, 0, 0, 0.62)',
                                        },
                                    }}
                                />
                            )}
                        </Tabs>
                    </Box>
                </div>
                <div className="setting-wrapper">
                    <TabPanel value={value} index={0}>
                        <div className="language-wrapper">
                            <Typography variant="profileBigText">{t('Set the language of the platform')}</Typography>
                            <div className="language-container">
                                <div className="language-left">
                                    <Typography variant="profileBigText">{t('Change the language')}</Typography>
                                    <Typography variant="profileMediumText">
                                        {t('Updates the language in which the application is displayed')}
                                    </Typography>
                                </div>
                                <div className="language-right">
                                    <LanguageDropDown
                                        dropDownValue={dropDownValue}
                                        setDropDownValue={setDropDownValue}
                                        dropDownItems={dropDownItems}
                                    />
                                </div>
                            </div>
                        </div>
                        {user.roleId === '1' && (
                            <div className="permissions-wrapper">
                                <div className="permissions-container">
                                    <Typography variant="profileBigText">{t('Setting permissions')}</Typography>
                                    <Typography variant="profileSmallText">
                                        {t('Access allowed only for Admin')}
                                    </Typography>
                                </div>
                                <PermissionsWrapper />
                            </div>
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div className="profile-wrapper">
                            <div className="profile-left">
                                <div className="left-container">
                                    <Avatar
                                        alt="user"
                                        sx={{
                                            width: '150px',
                                            height: '150px',
                                            svg: { width: '150px', height: '150px' },
                                        }}
                                        src={profilePic ? profilePic : DefaultUserPicture}
                                    />
                                    <UtilityButton
                                        variant="contained"
                                        width="140px"
                                        height="41px"
                                        onClick={() => {
                                            setOpenChangeImage(true);
                                        }}
                                    >
                                        {t('EDIT')}
                                    </UtilityButton>
                                </div>
                            </div>
                            <div className="profile-right">
                                <div className="right-container">
                                    <div className="row-container">
                                        <div className="row-info">
                                            <Typography variant="profileSmallText">{t('Complete name')}</Typography>
                                            <Typography variant="profileDataText">
                                                {user.firstName + ' ' + user.name}
                                            </Typography>
                                        </div>
                                        <Typography variant="profileModifyText" onClick={() => setOpenChangeName(true)}>
                                            {t('Modify')}
                                        </Typography>
                                    </div>
                                    <Divider sx={{ margin: '1rem 0' }} />
                                    <div className="row-container">
                                        <div className="row-info">
                                            <Typography variant="profileSmallText">{t('Role')}</Typography>
                                            <Typography variant="profileDataText">{user.roles.name}</Typography>
                                        </div>
                                        {user.roleId === '4' && (
                                            <div className="row-info">
                                                <Typography variant="profileSmallText">{t('Location')}</Typography>
                                                <Typography variant="profileDataText">
                                                    {workpointName ? workpointName : t('No workpoint')}
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                    <Divider sx={{ margin: '1rem 0' }} />
                                    <div className="row-container">
                                        <div className="row-info">
                                            <Typography variant="profileSmallText">E-mail</Typography>
                                            <Typography variant="profileDataText">{user.email}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="actions-wrapper">
                            <Typography variant="profileBigText">{t('Login data settings')}</Typography>
                            <div className="password-wrapper">
                                <div className="action-info">
                                    <Typography variant="profileBigText">{t('Change the password')}</Typography>
                                    <Typography variant="profileMediumText">
                                        {t('Update your password as often as possible for better account security')}
                                    </Typography>
                                </div>
                                <Typography variant="profileModifyText" onClick={() => setOpenChangePassword(true)}>
                                    {t('Modify')}
                                </Typography>
                            </div>
                        </div>
                        <ChangeName
                            open={openChangeName}
                            setOpen={setOpenChangeName}
                            userData={user}
                            getUserById={getUpdatedUser}
                        />
                        <ChangePassword
                            open={openChangePassword}
                            setOpen={setOpenChangePassword}
                            userData={user}
                            getUserById={getUpdatedUser}
                        />
                        <ChangeProfilePicture
                            open={openChangeImage}
                            setOpen={setOpenChangeImage}
                            userData={user}
                            getUserById={getUpdatedUser}
                        />
                    </TabPanel>
                    {user.roleId === '1' && (
                        <TabPanel value={value} index={2}>
                            <div className="language-wrapper">
                                <div className="language-container">
                                    <div className="language-left">
                                        <Typography variant="profileBigText">{t('Download report')}</Typography>
                                        <Typography variant="profileMediumText">
                                            {t('Download a report of the database between two dates')}
                                        </Typography>
                                    </div>
                                    <div className="language-right">
                                        <Typography
                                            variant="profileModifyText"
                                            onClick={() => setOpenDownloadReport(true)}
                                        >
                                            {t('Download report')}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <DownloadReport open={openDownloadReport} setOpen={setOpenDownloadReport} />
                        </TabPanel>
                    )}
                </div>
            </>
        </>
    );
};

export default Profile;
