import { useState, useEffect, useContext, useCallback, createContext } from 'react';
import { RolesAPI } from 'api_darex';
import axios from '../api_darex/axios';

const initialState = {
    user: {},
    permissions: [],
    accessToken: undefined,
    setAccessToken: () => {},
};

const UserContext = createContext(initialState);

export const UserProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
    const [user, setUser] = useState({});
    const [permissions, setPermissions] = useState([]);
    const [loadingUser, setLoadingUser] = useState(true);

    const me = async () => {
        try {
            const response = await axios.get('/users/me');
            return response.data;
        } catch (error) {
            return {};
        }
    };

    const getUpdatedUser = async () => {
        const refreshUser = await me();
        setUser(refreshUser);
    };

    const can = useCallback(
        (permissionId) => {
            const permissionIndex = permissions.rolePermissions.findIndex(
                (p) => Number(p.permissionId) === permissionId
            );
            return permissionIndex >= 0;
        },
        [permissions]
    );

    const handleAccessTokenChange = async () => {
        if (!user.id && accessToken) {
            localStorage.setItem('accessToken', accessToken);
            axios.defaults.headers.common.Authorization = 'Bearer ' + accessToken;

            setLoadingUser(true);
            const user = await me();

            /**
             * [FIX]
             * We have to explicitly check if user has a role (only for older users)
             * There may be some users that do not have a roleId
             */
            if (user.roleId) {
                const { data } = await RolesAPI.getById(user.roleId);
                setPermissions(data);
            }

            setUser(user);
        } else if (!accessToken) {
            /**
             * Log out
             * Reset important values
             */
            localStorage.removeItem('accessToken');
            setUser({});
            setPermissions([]);
        }

        setLoadingUser(false);
    };

    useEffect(() => {
        handleAccessTokenChange();
    }, [accessToken]);

    return (
        <UserContext.Provider value={{ user, loadingUser, accessToken, setAccessToken, can, getUpdatedUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
