import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { CustomTable, Tooltip } from 'lib';
import { Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClientOrderDetails from './ClientOrderDetails';
import { OrdersAPI } from 'api_darex';
import { useUser } from 'hooks/useUser';

const ClientOrders = () => {
    const { t } = useTranslation();
    const navigate = useHistory();
    const { id } = useParams();
    const { can } = useUser();
    const [orderId, setOrderId] = useState(null);
    const [displayOrder, setDisplayOrder] = useState(false);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [triggerRefetch, setTriggerRefetch] = useState(false);

    const brandsName = {
        1: 'Dacia',
        2: 'Renault',
        3: 'Nissan',
    };

    const statusType = {
        Created: t('Created'),
        OrderLaunch: t('Order launched'),
        AlocatedChassisNumber: t('Assigned chassis number'),
        OutOfProduction: t('Out of production'),
        ArrivedInThePark: t('Arrived in the park'),
        Delivered: t('Delivered'),
        Completed: t('Completed'),
        Cancelled: t('Cancelled'),
    };

    const labels = [
        {
            id: 'id',
            label: 'ID',
            minWidth: 10,
            align: 'center',
        },
        {
            id: 'brand',
            label: t('Brand'),
            minWidth: 100,
        },
        {
            id: 'model',
            label: 'Model',
            minWidth: 10,
        },
        {
            id: 'status',
            label: 'Status',
            minWidth: 10,
        },
        {
            id: 'offer',
            label: t('Offer'),
            minWidth: 10,
        },
        {
            id: 'date',
            label: t('Creation date'),
            minWidth: 150,
            align: 'right',
        },
        {
            id: 'view',
            minWidth: 4,
            align: 'center',
        },
    ];

    const cellModifier = (row, column, value) => {
        if (column.id === 'id') {
            return <Typography variant="tableContent">{row?.autoincrement}</Typography>;
        }
        if (column.id === 'brand') {
            return <Typography variant="tableContent">{brandsName[row.brandId]}</Typography>;
        }
        if (column.id === 'model') {
            return <Typography variant="tableContent">{row.model?.name} </Typography>;
        }
        if (column.id === 'status') {
            return <Typography variant="tableContent">{statusType[value]}</Typography>;
        }
        if (column.id === 'offer') {
            return (
                <Typography variant="tableContent">
                    {t('Offer')} #{row.offer?.autoincrement}
                </Typography>
            );
        }
        if (column.id === 'date') {
            return <Typography variant="tableContent">{dayjs(row.createdAt).format('DD MMM YYYY')}</Typography>;
        }
        if (column.id === 'view') {
            return (
                can(6) && (
                    <Tooltip position="left" textTip={<text>{t('View more details')}</text>} followCursor>
                        <div
                            className="details-arrow-style"
                            onClick={() => {
                                setOrderId(row.id);
                                setDisplayOrder(true);
                            }}
                        >
                            <ChevronRightIcon />
                        </div>
                    </Tooltip>
                )
            );
        }
        return <Typography variant="tableContent">{value}</Typography>;
    };

    return !displayOrder ? (
        <CustomTable
            labels={labels}
            loading={loading}
            setLoading={setLoading}
            tableData={orders}
            cellModifier={cellModifier}
            getterFunction={OrdersAPI.getByClientIdBrands}
            additionalId={id}
            setterFunction={setOrders}
            withPagination={true}
            triggerRefetch={triggerRefetch}
            withBoxShadow={false}
            withRowColors={false}
        />
    ) : (
        <ClientOrderDetails idOrder={orderId} setDisplayOrder={setDisplayOrder} />
    );
};

export default ClientOrders;
