import styles from './Offer.module.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import CustomTableLayout from 'lib/components/Tables/TabsLayout';
import { ActionButton } from 'lib/components/Buttons/buttons';
import DetailsComponent from './DetailsComponent';
import OrderComponent from '../Orders/OrderComponent';
import RefuseOffer from './modals/RefuseOffer';
import { OffersAPI } from 'api_darex';
import { LoadingSpinner, PageLayout } from 'lib';
import { useUser } from 'hooks/useUser';
import ConfirmModal from 'lib/components/Modals/ConfirmModal';
import ReOfferOffer from './modals/ReOfferOffer';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { Tooltip } from 'lib';

const Offer = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const { can, user } = useUser();
    const [offer, setOffer] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openReOffer, setOpenReOffer] = useState(false);
    const [openRefuseModal, setOpenRefuseModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const getOffer = useCallback(() => {
        OffersAPI.getById(id).then((res) => {
            setOffer(res.data);
            setLoading(false);
        });
    }, [id]);

    const getDocuments = useCallback(() => {
        OffersAPI.getDocuments(id).then((res) => {
            setDocuments(res.data);
        });
    }, [id]);

    useEffect(() => {
        getOffer();
        getDocuments();
    }, [getOffer, getDocuments]);

    const tabs = [
        {
            label: t('Offer details'),
            id: 0,
            render: true,
            hasUtility: true,
            component: (
                <DetailsComponent
                    offer={offer}
                    documents={documents}
                    getOffer={getOffer}
                    getDocuments={getDocuments}
                    loading={loading}
                />
            ),
        },
        {
            label: t('Orders'),
            id: 1,
            hasUtility: false,
            render: offer?.status === 'Accepted' ? true : false,
            component: <OrderComponent id={id} />,
        },
    ];

    const utility =
        offer?.status === 'Open'
            ? [
                  {
                      id: 0,
                      component: (
                          <Tooltip position="left" textTip={t('View old offer')} followCursor>
                              <Typography
                                  variant="objectivesName"
                                  color="#009C10"
                                  sx={{
                                      '&:hover': {
                                          cursor: 'pointer',
                                          color: '#004e08',
                                      },

                                      span: {
                                          textDecoration: 'underline',
                                      },
                                  }}
                                  onClick={() => {
                                      history.push(`/offers/${offer?.oldOfferId}`);
                                  }}
                              >
                                  {`${t('Old offer')}: `}
                                  <span
                                      styles={{
                                          color: '#009C10',

                                          '&:hover': {
                                              cursor: 'pointer',
                                              color: '#004e08',
                                          },
                                      }}
                                  >{`#${offer?.oldOffer?.autoincrement}`}</span>
                              </Typography>
                          </Tooltip>
                      ),
                      render: can(6) && offer?.oldOfferId !== null,
                  },
                  {
                      id: 1,
                      component: (
                          <div>
                              <ActionButton
                                  color="green"
                                  onClick={() => {
                                      setOpenConfirm(true);
                                  }}
                              >
                                  {t('Accept')}
                              </ActionButton>
                          </div>
                      ),
                      render: can(5) && user.roleId === '4',
                  },
                  {
                      id: 2,
                      component: (
                          <ActionButton
                              color="red"
                              onClick={() => {
                                  setOpenRefuseModal(true);
                              }}
                          >
                              {t('Refuse')}
                          </ActionButton>
                      ),
                      render: can(5) && user.roleId === '4',
                  },
                  {
                      id: 3,
                      component: (
                          <ActionButton
                              color="blue"
                              onClick={() => {
                                  setOpenReOffer(true);
                              }}
                          >
                              {t('Rebid')}
                          </ActionButton>
                      ),
                      render: can(5) && user.roleId === '4',
                  },
              ]
            : offer?.status === 'Accepted'
            ? [
                  {
                      id: 0,
                      component: (
                          <Tooltip position="left" textTip={t('View old offer')} followCursor>
                              <Typography
                                  variant="objectivesName"
                                  color="#009C10"
                                  sx={{
                                      '&:hover': {
                                          cursor: 'pointer',
                                          color: '#004e08',
                                      },

                                      span: {
                                          textDecoration: 'underline',
                                      },
                                  }}
                                  onClick={() => {
                                      history.push(`/offers/${offer?.oldOfferId}`);
                                  }}
                              >
                                  {`${t('Old offer')}: `}
                                  <span
                                      styles={{
                                          color: '#009C10',

                                          '&:hover': {
                                              cursor: 'pointer',
                                              color: '#004e08',
                                          },
                                      }}
                                  >{`#${offer?.oldOffer?.autoincrement}`}</span>
                              </Typography>
                          </Tooltip>
                      ),
                      render: can(6) && offer?.oldOfferId !== null,
                  },
                  {
                      id: 1,
                      component: (
                          <Typography variant="objectivesName" color="#009C10">
                              {t('Accepted offer')}
                          </Typography>
                      ),
                      render: true,
                  },
              ]
            : offer?.status === 'Refused'
            ? [
                  {
                      id: 0,
                      component: (
                          <Tooltip position="left" textTip={t('View old offer')} followCursor>
                              <Typography
                                  variant="objectivesName"
                                  color="#009C10"
                                  sx={{
                                      '&:hover': {
                                          cursor: 'pointer',
                                          color: '#004e08',
                                      },

                                      span: {
                                          textDecoration: 'underline',
                                      },
                                  }}
                                  onClick={() => {
                                      history.push(`/offers/${offer?.oldOfferId}`);
                                  }}
                              >
                                  {`${t('Old offer')}: `}
                                  <span
                                      styles={{
                                          color: '#009C10',

                                          '&:hover': {
                                              cursor: 'pointer',
                                              color: '#004e08',
                                          },
                                      }}
                                  >{`#${offer?.oldOffer?.autoincrement}`}</span>
                              </Typography>
                          </Tooltip>
                      ),
                      render: can(6) && offer?.oldOfferId !== null,
                  },
                  {
                      id: 1,
                      component: (
                          <Typography variant="objectivesName" color="#FF4B55">
                              {t('Refused offer')}
                          </Typography>
                      ),
                      render: true,
                  },
              ]
            : offer?.status === 'ReOffered'
            ? [
                  {
                      id: 0,
                      component: (
                          <Tooltip position="left" textTip={t('View old offer')} followCursor>
                              <Typography
                                  variant="objectivesName"
                                  color="#009C10"
                                  sx={{
                                      '&:hover': {
                                          cursor: 'pointer',
                                          color: '#004e08',
                                      },

                                      span: {
                                          textDecoration: 'underline',
                                      },
                                  }}
                                  onClick={() => {
                                      history.push(`/offers/${offer?.oldOfferId}`);
                                  }}
                              >
                                  {`${t('Old offer')}: `}
                                  <span
                                      styles={{
                                          color: '#009C10',

                                          '&:hover': {
                                              cursor: 'pointer',
                                              color: '#004e08',
                                          },
                                      }}
                                  >{`#${offer?.oldOffer?.autoincrement}`}</span>
                              </Typography>
                          </Tooltip>
                      ),
                      render: can(6) && offer?.oldOfferId !== null,
                  },
                  {
                      id: 1,
                      component: (
                          <Tooltip position="left" textTip={t('View new offer')} followCursor>
                              <Typography
                                  variant="objectivesName"
                                  color="#5B80BA"
                                  sx={{
                                      '&:hover': {
                                          cursor: 'pointer',
                                          color: '#293f61',
                                      },

                                      span: {
                                          textDecoration: 'underline',
                                      },
                                  }}
                                  onClick={() => {
                                      history.push(`/offers/${offer.historyOffer[0].id}`);
                                  }}
                              >
                                  {`${t('Reoffered with the offer')}: `}
                                  <span
                                      styles={{
                                          color: '#5B80BA',

                                          '&:hover': {
                                              cursor: 'pointer',
                                              color: '#293f61',
                                          },
                                      }}
                                  >{`#${offer?.historyOffer[0]?.autoincrement}`}</span>
                              </Typography>
                          </Tooltip>
                      ),
                      render: true,
                  },
              ]
            : [];

    const handleAccept = () => {
        OffersAPI.acceptOffer(offer.id).then((res) => {
            if (res.ok) {
                getOffer();
                setOpenConfirm(false);
                toast.success(t('Offer was marked as accepted!'));
            } else {
                setOpenConfirm(false);
                toast.error(t('Something went wrong! Offer could not be marked as accepted!'));
            }
        });
    };

    return loading ? (
        <LoadingSpinner loading={loading} margin="0 auto" />
    ) : (
        <>
            <PageLayout title={`${t('Offer')} #${offer.autoincrement}`} subTitle={t('Manage offer')} backArrow={true}>
                <div className={styles.tabsWrapper}>
                    <CustomTableLayout
                        tabs={tabs}
                        utility={utility}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                </div>
            </PageLayout>
            <RefuseOffer open={openRefuseModal} setOpen={setOpenRefuseModal} rowId={offer?.id} getOffer={getOffer} />
            <ReOfferOffer open={openReOffer} setOpen={setOpenReOffer} rowId={offer?.id} />
            <ConfirmModal
                open={openConfirm}
                setOpen={setOpenConfirm}
                text={t('Are you sure you want to accept this offer?')}
                onClickButtonYes={() => handleAccept()}
            />
        </>
    );
};

export default Offer;
