const ro = {
    Version: 'Versiune',
    'This is the initial version of the platform.': 'Aceasta este versiunea inițială a platformei.',
    'New features': 'Noi caracteristici',
    Features: 'Caracteristici',
    'Bugs fixed': 'Bug-uri rezolvate',
    'The administrator has access to the entire platform with full rights, including: users, offers, tasks, objectives, clients, bidding, nomenclatures. He can create, edit, lock and reset a user account.':
        'Administratorul are acces la întreaga platformă cu drepturi depline, acestea incluzând: utilizatori, oferte, sarcini, obiective, clienți, ofertare, nomenclatoare. El poate crea, edita, bloca și reseta un cont de utilizator.',
    'The sales manager(director) can see sales managers, advisors, offers, tasks, targets, customers. He can edit an offer (accept, reject, re-offer), add tasks, targets.':
        'Directorul de vânzări poate vedea șefii de vânzări, consilierii, ofertele, sarcinile, obictivele, clienții. El poate edita o ofertă (accepta, refuza, reoferta), adăuga sarcini, obiective.',
    'The sales manager(chief) can see advisors, offers, tasks, targets, customers. He can edit an offer (accept, reject, re-offer), add tasks, targets.':
        'Șeful de vânzări poate vedea consilierii, ofertele, sarcinile, obictivele, clienții. El poate edita o ofertă (accepta, refuza, reoferta), adăuga sarcini, obiective.',
    'The advisor can view the bids, tasks, targets, clients and bidding sections. He can add a quote, tasks and clients.':
        'Consilierul poate vedea secțiunile de oferte, sarcini, obiective, clienți și de ofertare. El poate adăuga o ofertă, sarcini și clienți.',
    'The sales assistant is in charge of the quotations and orders sections.':
        'Asistentul de vânzări se ocupă de secțiunile de oferte și comenzi.',
    'There are 4 possible customer cases: (1) new customer, (2) existing active customer on the same car brand, (3) existing customer but on another brand, (4) existing "sleeping" customer.':
        'Există 4 cazuri posibile de clienți: (1) client nou, (2) client existent activ pe aceeași marcă auto, (3) client existent, dar pe alta marcă, (4) client existent "adormit".',
    'The user can create a customer from scratch or perform a search for that customer and then continue the creation process with existing data.':
        'Utilizatorul poate crea de la zero un client sau poate realiza o căutare a clientului respectiv și apoi să continue procesului de creare cu datele deja existente.',
    'A customer can be added as an individual or a legal entity.':
        'Un client poate fi adăugat ca persoană fizică sau persoană juridică.',
    'The administrator is the only one who has access to the nomenclatures and can add, edit and delete vehicles (make, model, colour, engine), work points and customer sources.':
        'Administratorul este singurul care are acces la nomenclatoare și poate adăuga, edita și șterge vehicule (marcă, model, culoare, motorizare), puncte de lucru și surse client.',
    'Tasks can be divided into 4 categories: scheduled drive tests, GDPR agreement, customer recontact, other types of tasks.':
        'Sarcinile pot fi împărțite în 4 categorii: drive test-uri programate, acord GDPR, recontactare clienți, alte tipuri de sarcini.',
    'Targets can be added per advisor, per advisor brand (Dacia, Renault, Nissan), in turn.':
        'Obiectivele pot fi adăugate pe consilier, pe fiecare marcă a consilierului (Dacia, Renault, Nissan), pe rând.',
    'The offer can be created by choosing a customer or adding a new one, adding vehicles to the offer, final invoice details. The offer can be accepted, rejected and re-offered.':
        'Oferta poate fi creată prin alegerea unui client sau adăugarea unuia nou, adăugarea de vehicule pe ofertă, detalii finale factură. Oferta se poate accepta, refuza și reoferta.',
    "Daily report for the advisor containing the report recipients (Advisor), the date the report is sent (every morning at 8:00 a.m.), the period for which the report is generated (previous day and some of the next day's objectives) and the content of the report (the advisor's activity and objectives).":
        'Raportul zilnic pentru consilier ce conține destinatari raport (Consilier), data trimiterii raportului (în fiecare dimineață de la 8:00), perioada pe care se generează raportul (ziua anterioară și unele obiective din următoarea zi) și conținutul raportului (activitatea consilierului și obiectivele sale).',
    'Daily report for report recipients, Head of Sales and Sales Manager, the date the report is sent (every morning at 8:10 a.m.), the period for which the report is generated (previous day) and the content of the report (activity of each individual advisor).':
        'Raportul zilnic pentru destinatari raport, Șeful de vânzări și Directorul de vânzări, data trimiterii raport (în fiecare dimineață de la 8:10), perioada pe care se generează raportul (ziua anterioară) și conținutul raportului (activitatea fiecărui consilier în parte).',
    'Weekly report for report recipients, Head of Sales and Director of Sales, the date the report is sent (every Monday at 8:00 a.m.), the period for which the report is generated (previous week) and the content of the report (activity of each individual advisor).':
        'Raportul săptămânal pentru destinatari raport, Șeful de vânzări și Directorul de vânzări, data trimiterii raport (în fiecare zi de luni de la 8:00), perioada pe care se generează raportul (săptămâna anterioară) și conținutul raportului (activitatea fiecărui consilier în parte).',
    'Cumulative weekly report for report recipients, Head of Sales, Sales Manager and Administrator, report submission date (every Monday at 8:00 a.m.), period over which the report is generated (previous week) and report content (cumulative sales teams, per brand).':
        'Raportul săptămânal cumulat pentru destinatari raport, Șeful de vânzări, Directorul de vânzări și Administrator, data trimiterii raport (în fiecare zi de luni de la 8:00), perioada pe care se generează raportul (săptămâna anterioară) și conținutul raportului (cumul echipe de vânzare, pe fiecare marcă).',
    'Monthly report for report recipients, Head of Sales, Sales Manager and Administrator, date of report submission (on the first day of the month at 8:00 a.m.), period over which the report is generated (previous month) and report content (activity of each individual advisor).':
        'Raportul lunar pentru destinatari raport, Șeful de vânzări, Directorul de vânzări și Administrator, data trimiterii raport (în prima zi a lunii de la 8:00), perioada pe care se generează raportul (luna anterioară) și conținutul raportului (activitatea fiecărui consilier în parte).',
    'Cumulative monthly report for report recipients, Sales Manager, Sales Director and Administrator, date of report submission (first day of the month at 8:00 a.m.), period for which the report is generated (previous month) and report content (cumulative sales teams, per brand).':
        'Raportul lunar cumulat pentru destinatari raport, Șeful de vânzări, Directorul de vânzări și Administrator, data trimiterii raport (în prima zi a lunii de la 8:00), perioada pe care se generează raportul (luna anterioară) și conținutul raportului (cumul echipe de vânzare, pe fiecare marcă).',
    'It will be possible to export the database automatically on a monthly, quarterly and annual basis with customer and transaction data.':
        'Se va putea face export automat a bazei de date lunar, trimestrial și anual, cu datele de client și tranzacție.',
    'Automation is achieved by automatically sending reports by e-mail, exporting the database, notifications related to some events longer in duration and at the same time, the system will automatically generate some tasks depending on the activities undertaken by the user within the platform.':
        'Automatizarea se realizează prin trimiterea automată a rapoartelor prin e-mail, exportarea bazei de date, notificări legate de unele evenimente mai lungi în durată și totodată, sistemul va genera automat unele sarcini în funcție de activitățile întreprinse de utilizator în cadrul platformei.',
};
export default { ro };
