import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDialog } from 'lib';

import { MenuItem, TextField } from '@mui/material';
import styles from './FilterUsers.module.scss';
import { RolesAPI, WorkPointsAPI } from 'api_darex';
import { useUser } from 'hooks/useUser';
import DeleteIcon from '@mui/icons-material/Delete';

const FilterUsers = ({
    open,
    setOpen,
    filterData,
    setFilterData,
    setTriggerFilter,
    triggerFilter,
    triggerRefetch,
    setTriggerRefetch,
    setIsFilter,
    setData,
    setLoading,
    resetSearch,
    resetFilter,
    isFilter,
}) => {
    const [locations, setLocations] = useState([]);
    const [roles, setRoles] = useState([]);
    const { user } = useUser();
    const { t } = useTranslation();

    const brands = [
        { id: 1, name: 'Dacia' },
        { id: 2, name: 'Renault' },
        { id: 3, name: 'Nissan' },
    ];

    const displayedBrands = brands.filter((brand) =>
        user.UserBrand.map((uBrand) => Number(uBrand.brandId)).includes(brand.id)
    );

    const getRoles = () => {
        RolesAPI.get().then((res) => {
            if (user.roleId !== '4') {
                setRoles(res.data);
            } else {
                setRoles(res.data.filter((item) => item.id === '4'));
            }
        });
    };

    const getWorkPoints = () => {
        WorkPointsAPI.get().then((res) => {
            setLocations(res.data);
        });
    };

    useEffect(() => {
        getRoles();
        getWorkPoints();
    }, []);

    const handleChange = (e) => {
        setFilterData({
            ...filterData,
            [e.target.name]: e.target.value,
        });
    };

    const handleRemoveFilters = () => {
        resetFilter();
        setLoading(true);
        setData([]);
        setTriggerRefetch(!triggerRefetch);
        setOpen(false);
    };

    const handleFilter = () => {
        resetSearch();
        setLoading(true);
        setData([]);
        setIsFilter(true);
        setTriggerFilter(!triggerFilter);
        setOpen(false);
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={t('Filter users')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            buttonUtility={t('REMOVE FILTERS')}
            onClickButtonFinish={handleFilter}
            width="580px"
            onClickButtonClose={() => {
                setOpen(false);
            }}
            onClickButtonUtility={handleRemoveFilters}
            buttonUtilityDisabled={!isFilter}
            buttonFinishDisabled={Object.values(filterData).every((x) => x === null || x === '')}
        >
            <div className={styles.container}>
                {user.roleId !== '3' && (
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <TextField
                            name="brandId"
                            label={`${t('Brand')}`}
                            size="medium"
                            fullWidth
                            select
                            sx={{
                                fieldset: {
                                    borderRadius: '10px',
                                },
                            }}
                            id="outlined-required"
                            value={filterData.brandId}
                            onChange={handleChange}
                        >
                            {displayedBrands.map((brand) => (
                                <MenuItem key={brand.id} value={brand.id} sx>
                                    {brand.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <DeleteIcon
                            fontSize="large"
                            sx={{
                                cursor: filterData.brandId !== '' && 'pointer',
                                color: filterData.brandId !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                                '&:hover': {
                                    color: filterData.brandId !== '' && '#a50009',
                                },
                            }}
                            onClick={() =>
                                setFilterData({
                                    ...filterData,
                                    brandId: '',
                                })
                            }
                        />
                    </div>
                )}
                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <TextField
                        name="workingPoint"
                        label={`${t('Work point')}`}
                        size="medium"
                        fullWidth
                        select
                        sx={{
                            fieldset: {
                                borderRadius: '10px',
                            },
                        }}
                        id="outlined-required"
                        value={filterData.workingPoint}
                        onChange={handleChange}
                    >
                        {locations.map((location) => (
                            <MenuItem key={location.id} value={location.id} sx>
                                {location.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <DeleteIcon
                        fontSize="large"
                        sx={{
                            cursor: filterData.workingPoint !== '' && 'pointer',
                            color: filterData.workingPoint !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                            '&:hover': {
                                color: filterData.workingPoint !== '' && '#a50009',
                            },
                        }}
                        onClick={() =>
                            setFilterData({
                                ...filterData,
                                workingPoint: '',
                            })
                        }
                    />
                </div>
                {user.roleId !== '4' && user.roleId !== '3' && (
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <TextField
                            name="roleId"
                            label={`${t('Role')}`}
                            size="medium"
                            fullWidth
                            select
                            sx={{
                                fieldset: {
                                    borderRadius: '10px',
                                },
                            }}
                            id="outlined-required"
                            onChange={handleChange}
                            value={filterData.roleId}
                        >
                            {roles.map((roleItem) => (
                                <MenuItem key={roleItem.id} value={roleItem.id} sx>
                                    {roleItem.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <DeleteIcon
                            fontSize="large"
                            sx={{
                                cursor: filterData.roleId !== '' && 'pointer',
                                color: filterData.roleId !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                                '&:hover': {
                                    color: filterData.roleId !== '' && '#a50009',
                                },
                            }}
                            onClick={() =>
                                setFilterData({
                                    ...filterData,
                                    roleId: '',
                                })
                            }
                        />
                    </div>
                )}
            </div>
        </CustomDialog>
    );
};

export default FilterUsers;
