import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Select, TextField } from 'lib';
import styles from './AddClient.module.scss';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Grid, Typography, MenuItem, Divider } from '@mui/material';
import VisualizeRepresentative from './VisualizeRepresentative';
import AddRepresentativeForm from './AddRepresentativeForm';
import { ClientsAPI } from 'api_darex';
import { toast } from 'react-toastify';
import { useConfirm } from 'hooks/useConfirm';
import { useUser } from 'hooks/useUser';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const JuridicClientForm = ({
    formikRef,
    client,
    clientSources,
    users,
    states,
    cities,
    submitCallback,
    createFromOffer,
    clientDataForCreation,
    setModalOpen,
}) => {
    const { t } = useTranslation();
    const { vw } = useWindowDimensions();
    const confirm = useConfirm();
    const { user } = useUser();

    const history = useHistory();

    const [representativesList, setRepresentativesList] = useState('listareReprezentanti');
    const [representatives, setRepresentatives] = useState([]);
    const [editRepr, setEditRepr] = useState({});

    const brands = user.UserBrand.map((b) => b.brand);

    const initialValues = {
        id: null,
        ...client,
        userId:
            client.type === '' || Object.keys(clientDataForCreation).length > 0 || client.fromClientsCreation
                ? user.id
                : client.user.id,
    };

    useEffect(() => {
        setRepresentatives(client?.CUI ? client.Contacts : []);
    }, [client]);

    const validationSchema = yup.object().shape({
        firmName: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(3, t('The name of the company must contain at least three characters'))
            .required(t('The name of the company is required')),

        CUI: yup
            .string()
            .typeError(t('This field must contain CUI'))
            .trim()
            .min(3, t('This field must containt at least three characters'))
            .required(t('CUI is mandatory')),

        stateId: yup.string().required(t('Choose the county')),

        cityId: yup.string().required(t('Choose the city')),

        street: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(1, t('The name of the street must contain at least three characters')),

        nrStreet: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(1, t('The street number must contain at least one number')),

        apartmentBuilding: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(1, t('The buildings name must contain at least one character')),

        staircase: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(1, t('The entrance name must contain at least three characters')),

        floor: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(1, t('The floors number must contain at least one character')),

        apartmentNr: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(1, t('The number of the appartment must contain at least one character')),

        postalCode: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(6, t('The zip code must contain at least six characters')),

        clientSourceId: yup.string().required(t('Choose the source')),

        brandId: yup.string().required(t('Choose the brand')),

        userId: yup.string().required(t('Choose the counselor')),

        details: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(3, t('This field must contain at least three characters')),
    });

    const handleSubmit = () => {
        const { values } = formikRef.current;
        const obj = { ...values };
        obj.Contacts = representatives.map((r) => {
            delete r.id;
            return r;
        });
        obj.type = 'Juridic';
        obj.brandId = obj.brandId.toString();
        obj.nrStreet = Number(obj.nrStreet);
        obj.apartmentNr = Number(obj.apartmentNr);
        obj.CUI = obj.CUI;
        delete obj.Tasks;
        delete obj.brand;
        delete obj.city;
        delete obj.clientSource;
        delete obj.state;
        delete obj.status;
        delete obj.user;
        delete obj.company;
        delete obj.isDeleted;
        delete obj.id;

        if (obj.Contacts.length === 0) {
            toast.error(t('A juridical client must have at least one representative!'));
            return;
        }

        if (client.id && !createFromOffer) {
            ClientsAPI.update(client.id, obj).then((res) => {
                if (res.ok === true) {
                    toast.success(t('The client has been updated successfully!'));
                    history.push('/clients');
                } else {
                    toast.error(t('An error ocurred. Please try again'));
                }
            });
        } else {
            if (Object.keys(clientDataForCreation).length > 0 || client.fromClientsCreation) {
                ClientsAPI.createClientOnAnotherBrand(
                    createFromOffer ? client.id : client.idForClient,
                    obj.brandId,
                    user.id
                ).then((res) => {
                    if (res.ok === true) {
                        toast.success(t('The client has been added successfully!'));
                        !createFromOffer && history.push('/clients');
                        createFromOffer && setModalOpen(false);
                        submitCallback(res.data);
                    } else {
                        if (
                            res.error.status === 404 &&
                            res.error.data.message === 'The Client on Brand already exist!'
                        ) {
                            toast.error(t('This client is already your client on your brand/brands!'));
                        } else if (res.error.status === 500) {
                            toast.error(t('An error ocurred. Please try again'));
                        }
                    }
                });
            } else {
                ClientsAPI.create(obj).then((res) => {
                    if (res.ok === true) {
                        submitCallback(res.data);
                        !createFromOffer && history.push('/clients');
                        createFromOffer && setModalOpen(false);
                        toast.success(t('The client has been added successfully!'));
                    } else {
                        if (res.error.status === 400 && res.error.data.message === 'This email already exist') {
                            toast.error(t('A client with this email address already exist!'));
                        } else if (
                            res.error.status === 404 &&
                            res.error.data.message === 'The client with this CUI already exist'
                        ) {
                            toast.error(t('A client with this CUI already exist!'));
                        } else if (res.error.status === 500) {
                            toast.error(t('An error ocurred. Please try again'));
                        }
                    }
                });
            }
        }
    };

    return (
        <div className={styles.addNewClient}>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => (
                    <Form>
                        <Grid
                            container
                            justifyContent={'flex-start'}
                            spacing={2}
                            direction={vw < 670 ? 'column' : 'row'}
                            sx={{ marginBottom: '16px' }}
                        >
                            <Grid item xs={4}>
                                <TextField name="firmName" label={t('Company name*')} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField name="CUI" label={t('CUI*')} />
                            </Grid>
                        </Grid>

                        <Typography variant={'clientData'}> {t('Address')} </Typography>

                        <Grid
                            sx={{ marginTop: '8px', marginBottom: '16px' }}
                            container
                            spacing={2}
                            justifyContent={'space-between'}
                            direction={vw < 670 ? 'column' : 'row'}
                        >
                            <Grid item xs={4}>
                                <Select sx name="stateId" label={t('County*')}>
                                    {states.map((county) => (
                                        <MenuItem key={county.id} value={county.id}>
                                            {county.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item xs={4}>
                                <Select
                                    name="cityId"
                                    label={t('City*')}
                                    disabled={props.values.county !== '' ? false : true}
                                >
                                    {cities
                                        .filter((city) => city.state_id === props.values.stateId)
                                        .map((city) => (
                                            <MenuItem key={city.id} value={city.id}>
                                                {city.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField name="street" label={t('Street')} />
                            </Grid>
                        </Grid>

                        <Grid
                            sx={{ marginBottom: '32px', marginTop: '8px' }}
                            container
                            justifyContent={'space-between'}
                            spacing={2}
                            direction={vw < 670 ? 'column' : 'row'}
                        >
                            <Grid item xs={2}>
                                <TextField name="nrStreet" label={t('Street number')} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField name="apartmentBuilding" label={t('Building')} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField name="staircase" label={t('Scară')} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField name="floor" label={t('Floor')} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField name="apartmentNr" label={t('Apartment number')} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField name="postalCode" label={t('Zip Code')} />
                            </Grid>
                        </Grid>

                        <Grid
                            sx={{ marginBottom: '32px' }}
                            container
                            justifyContent={'space-between'}
                            spacing={2}
                            direction={vw < 670 ? 'column' : 'row'}
                        >
                            <Grid item xs={4}>
                                <div style={{ marginBottom: '16px' }}>
                                    <Typography variant={'clientData'}> {t('Client Source')} </Typography>
                                </div>

                                <Select name="clientSourceId">
                                    {clientSources.map((source) => (
                                        <MenuItem key={source.id} value={source.id}>
                                            {source.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ marginBottom: '16px' }}>
                                    <Typography variant={'clientData'}> {t('Counselor')} </Typography>
                                </div>

                                <Select
                                    name="userId"
                                    disabled={
                                        user.roles.name === 'Consilier' || user.roles.name === 'Asistent de vanzari'
                                    }
                                >
                                    {users.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user.name + ' ' + user.firstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ marginBottom: '16px' }}>
                                    <Typography variant={'clientData'}> {t('Brand')} </Typography>{' '}
                                </div>
                                <Select name="brandId">
                                    {brands.map((brand) => (
                                        <MenuItem key={brand.id} value={brand.id} sx>
                                            {brand.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>

            {/* View / Add Repsesentative tabs */}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '32px',
                }}
            >
                <div
                    style={{
                        marginRight: '32px',
                        cursor: 'pointer',
                        color: representativesList === 'listareReprezentanti' ? '#7695C5' : 'rgba(0, 0, 0, 0.3)',
                    }}
                    onClick={() => setRepresentativesList('listareReprezentanti')}
                >
                    {t('Representants list')}
                </div>
                <div
                    style={{
                        cursor: 'pointer',
                        color: representativesList !== 'listareReprezentanti' ? '#7695C5' : 'rgba(0, 0, 0, 0.3)',
                    }}
                    onClick={() => setRepresentativesList('adaugareReprezentanti')}
                >
                    {t('Add new representant')}
                </div>
            </div>

            <Divider
                style={{
                    marginTop: '16px',
                    marginBottom: '16px',
                    backgroundColor: 'rgba(91, 128, 186, 1)',
                }}
            />

            {/* List representatives */}
            {representativesList === 'listareReprezentanti' && (
                <React.Fragment>
                    {representatives.map((info, index) => (
                        <div key={info.id}>
                            <VisualizeRepresentative
                                representativeInfo={info}
                                setRepresentativesList={setRepresentativesList}
                                // index={index}
                                // representatives={representatives}
                                // setRepresentatives={setRepresentatives}

                                onDelete={() =>
                                    confirm(t('Are you sure you want to delete this representative?'), () => {
                                        toast.success(t('Representative successfully deleted'));
                                        setRepresentatives(representatives.filter((_, i) => i !== index));
                                    })
                                }
                                onClickEdit={() => {
                                    setRepresentativesList('adaugareReprezentanti');
                                    setEditRepr(info);
                                }}
                            />
                            <Divider />
                        </div>
                    ))}
                </React.Fragment>
            )}

            {/* Add representative */}
            {representativesList === 'adaugareReprezentanti' && (
                <AddRepresentativeForm
                    infoRepr={editRepr}
                    setInfoRepr={setEditRepr}
                    representatives={representatives}
                    setRepresentatives={setRepresentatives}
                    setRepresentativesList={setRepresentativesList}
                />
            )}
        </div>
    );
};

JuridicClientForm.propTypes = {
    client: PropTypes.object,
    clientSources: PropTypes.array,
    brands: PropTypes.array,
    users: PropTypes.array,

    // Required
    formikRef: PropTypes.object.isRequired,
};

JuridicClientForm.defaultProps = {
    client: {},
    clientSources: [],
    brands: [],
    users: [],
};

export default JuridicClientForm;
