import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import PropTypes from 'prop-types';

const StepperComponent = ({ steps, step }) => {
    return steps.length > 0 ? (
        <Stepper activeStep={step}>
            {steps.map((label, index) => {
                return (
                    <Step
                        key={label}
                        sx={{
                            '& .MuiStepIcon-root.Mui-active': { color: '#5B80BA' },
                            '& .MuiStepIcon-root.Mui-completed': { color: '#5B80BA' },
                        }}
                    >
                        <StepLabel>{label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    ) : (
        <React.Fragment />
    );
};

StepperComponent.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.string),
    step: PropTypes.number,
};

StepperComponent.defaultProps = {
    steps: [],
    step: 0,
};

export default StepperComponent;
