import React, { useEffect, useState, useRef } from 'react';
import styles from './AddClient.module.scss';

import { useTranslation } from 'react-i18next';
import Search from 'lib/components/Search/Search';
import { Radio, RadioGroup, FormControlLabel, FormControl, Typography, Divider } from '@mui/material';
import { UtilityButton } from 'lib/components/Buttons/buttons';

import useWindowDimensions from 'hooks/useWindowDimensions';
import IndividualClientForm from './IndividualClientForm';

import { useParams } from 'react-router-dom';
import { useConfirm } from 'hooks/useConfirm';
import JuridicClientForm from './JuridicClientForm';

import { ClientSourceAPI, UsersAPI, ClientsAPI, CitiesAPI } from 'api_darex';
import PropTypes from 'prop-types';
import { useUser } from 'hooks/useUser';
import { toast } from 'react-toastify';

import ChooseClient from 'pages/CreateOffer/ChooseClient';
import { useHistory } from 'react-router-dom';

const AddClientMain = ({
    clientDataForCreation = {},
    submitCallback = () => {},
    createFromOffer = false,
    areaOfUse,
    setModalOpen,
}) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { vw } = useWindowDimensions();
    const { user } = useUser();
    const navigate = useHistory();
    const confirm = useConfirm();

    const individualClientFormikRef = useRef();
    const juridicClientFormikRef = useRef();

    const [clientSources, setClientSources] = useState([]);
    const [users, setUsers] = useState([]);
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);

    const defaultClientData = {
        type: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        stateId: '',
        cityId: '',
        street: '',
        nrStreet: '',
        apartmentBuilding: '',
        staircase: '',
        floor: '',
        apartmentNr: '',
        postalCode: '',
        clientSourceId: '',
        brandId: '',
        userId: user.id || '',
        details: '',
        CUI: '',
        firmName: '',
    };

    /**
     * Must be null until know if it is create / edit mode
     */
    const [clientData, setClientData] = useState(null);
    const isEditing = Boolean(id);
    const isClientSelected = clientData?.type?.length !== 0;

    /**
     * Here we find it out!!!
     */
    useEffect(() => {
        if (id)
            ClientsAPI.getById(id).then((client) => {
                setClientData(client.data);
                setRadioState(client.data.type);
            });
        else setClientData(defaultClientData);
    }, [id]);

    const [clientSearch, setClientSearch] = useState('');
    const [radioState, setRadioState] = useState('Individual');

    useEffect(() => {
        ClientSourceAPI.get().then((res) => setClientSources(res.data));
        UsersAPI.get().then((res) => setUsers(res.data.content));
        CitiesAPI.getAllCities().then((res) => setCities(res.data));
        CitiesAPI.getAllStates().then((res) => setStates(res.data));
    }, []);

    const superSetClient = (client) => {
        if (Object.keys(client).length > 0) {
            const formClient = {};

            for (const key of Object.keys(defaultClientData)) {
                formClient[key] = client[key] ?? defaultClientData[key];
            }

            formClient.userId = user.id;
            formClient.brandId = '';

            setClientData(() => {
                const aux = { ...client };
                aux.fromClientsCreation = true;
                aux.idForClient = client.id;
                aux.brandId = '';
                aux.userId = user.id;
                aux.nrStreet = clientDataForCreation.nrStreet === 0 ? '' : clientDataForCreation.nrStreet;
                aux.apartmentNr = clientDataForCreation.apartmentNr === 0 ? '' : clientDataForCreation.apartmentNr;
                delete aux.id;

                return aux;
            });

            if (client.type === radioState) {
                if (client.type === 'Individual')
                    individualClientFormikRef.current.resetForm({
                        values: {
                            ...formClient,
                        },
                    });

                if (client.type === 'Juridic')
                    juridicClientFormikRef.current.resetForm({
                        values: {
                            ...formClient,
                        },
                    });
            }

            setRadioState(client.type);
        }
    };

    useEffect(() => {
        if (Object.keys(clientDataForCreation).length > 0) {
            setRadioState(clientDataForCreation.type);

            const newClientInfoFromOfferCreation = {
                ...clientDataForCreation,
                brandId: '',
                nrStreet: clientDataForCreation.nrStreet === 0 ? '' : clientDataForCreation.nrStreet,
                apartmentNr: clientDataForCreation.apartmentNr === 0 ? '' : clientDataForCreation.apartmentNr,
            };

            setClientData(newClientInfoFromOfferCreation);
        }
    }, [clientDataForCreation]);

    const handleSubmitClient = () => {
        if (radioState === 'Individual') individualClientFormikRef.current.handleSubmit();
        if (radioState === 'Juridic') juridicClientFormikRef.current.handleSubmit();
    };

    const handleResetForm = () => {
        setClientSearch('');
        setClientData(defaultClientData);

        if (radioState === 'Individual')
            individualClientFormikRef.current.resetForm({
                values: {
                    ...defaultClientData,
                },
            });

        if (radioState === 'Juridic') {
            juridicClientFormikRef.current.resetForm({
                values: {
                    ...defaultClientData,
                },
            });
        }
    };

    return (
        <div className={styles.mainFormClient}>
            {/* Title */}
            <Typography
                style={{
                    color: 'rgba(0, 0, 0, 0.62)',
                    fontSize: '24px',
                    fontWeight: 700,
                    fontFamily: ['Montserrat', 'sans-serif'].join(','),
                    paddingLeft: '30px',
                    paddingTop: !createFromOffer ? '24px' : 0,
                    marginBottom: isEditing || isClientSelected ? '30px' : '0px',
                }}
            >
                {Boolean(id) ? t('Edit Client') : t('Add Client')}
            </Typography>

            {/* Client Type Radio */}
            {!isEditing && !isClientSelected && (
                <div className={styles.radioButtonStyling}>
                    <FormControl style={{ display: 'flex', flexDirection: 'row' }}>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={radioState}
                            onChange={(e) => setRadioState(e.target.value)}
                            sx={{ display: 'flex', flexDirection: 'row' }}
                        >
                            <FormControlLabel
                                value="Individual"
                                control={<Radio sx={{ '&.Mui-checked': { color: '#A8A8A8' } }} />}
                                label={<Typography variant="permissions">{t('Individual Person')}</Typography>}
                            />
                            <FormControlLabel
                                value="Juridic"
                                control={<Radio sx={{ '&.Mui-checked': { color: '#A8A8A8' } }} />}
                                label={<Typography variant="permissions"> {t('Company')} </Typography>}
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
            )}

            {(clientData?.type === '' || (Object.keys(clientDataForCreation).length === 0 && !isEditing)) && (
                <div style={{ padding: '20px 30px 50px 30px', display: 'flex', gap: '25px', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <ChooseClient
                            search={clientSearch}
                            setSearch={setClientSearch}
                            setClient={superSetClient}
                            isReoffer={false}
                            areaOfUse={'CLIENTS'}
                        />
                    </div>
                    {isClientSelected && (
                        <div>
                            <UtilityButton
                                onClick={() =>
                                    confirm(t('Are you sure you want to unselect this client?'), () => {
                                        handleResetForm();
                                    })
                                }
                                color="red"
                            >
                                {t('Unselect client')}
                            </UtilityButton>
                        </div>
                    )}
                </div>
            )}

            {/* INDIVIDUAL */}
            {radioState === 'Individual' && (
                <div className={styles.persoanaFizicaMain}>
                    {/* <div className={styles.searchClient}>
                        <div>
                            <Search
                                nameToFind="client"
                                style={{
                                    boxShadow: '0px 1px 2px rgba(36,36,36,0.25)',
                                    borderRadius: '15px',
                                    height: '56px',
                                }}
                            />
                        </div>
                    </div> */}

                    {/* <Divider sx={{ marginTop: '32px', marginBottom: '32px' }} /> */}

                    {Boolean(clientData) && (
                        <IndividualClientForm
                            formikRef={individualClientFormikRef}
                            client={clientData}
                            clientDataForCreation={clientDataForCreation}
                            submitCallback={submitCallback}
                            createFromOffer={createFromOffer}
                            setModalOpen={setModalOpen}
                        />
                    )}
                </div>
            )}

            {/* JURIDIC */}
            {radioState === 'Juridic' && (
                <div className={styles.persoanaJuridicaMain}>
                    {Boolean(clientData) && (
                        <JuridicClientForm
                            formikRef={juridicClientFormikRef}
                            client={clientData}
                            clientSources={clientSources}
                            users={users}
                            cities={cities}
                            states={states}
                            clientDataForCreation={clientDataForCreation}
                            submitCallback={submitCallback}
                            createFromOffer={createFromOffer}
                            setModalOpen={setModalOpen}
                        />
                    )}
                </div>
            )}

            {/* Bottom Buttons */}
            <div
                style={{
                    padding: '32px 24px',
                    display: 'flex',
                    flexDirection: vw < 670 ? 'column' : 'row',
                    width: '100%',
                    gap: '1rem',
                }}
            >
                <UtilityButton type="submit" variant="contained" onClick={handleSubmitClient}>
                    {t('Save client')}
                </UtilityButton>
                <UtilityButton
                    type="button"
                    onClick={() => {
                        confirm(t('Are you sure you want to empty the form?'), () => {
                            handleResetForm();
                        });
                    }}
                >
                    {t('Empty the form')}{' '}
                </UtilityButton>
                {!createFromOffer && (
                    <UtilityButton type="button" color="red" onClick={() => navigate.push('/clients')}>
                        {t('Discard changes')}
                    </UtilityButton>
                )}
            </div>
        </div>
    );
};

AddClientMain.propTypes = {
    clientData: PropTypes.object,
    setClientData: PropTypes.func,
};

AddClientMain.defaultProps = {
    clientData: {},
    setClientData: () => {},
};

export default AddClientMain;
