import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';

import AddIcon from '@mui/icons-material/Add';
import plus from './assets/plus.svg';
import { Box, Typography, Divider, Grid } from '@mui/material';
import { UtilityButton, EditClientButton } from 'lib/components/Buttons/buttons';

import { toast } from 'react-toastify';
import ConfirmModal from 'lib/components/Modals/ConfirmModal';
import UploadGDPR from './modals/UploadGDPR';
import EditAdviser from './modals/EditAdviser';
import { useUser } from 'hooks/useUser';
import EditClientCounselor from './modals/EditClientCounselor';
import EditClientStatus from './modals/EditClientStatus';
import useWindowDimensions from 'hooks/useWindowDimensions';
import VisualizeRepresentative from './VisualizeRepresentative';
import styles from '../CreateOffer/CreateOffer.module.scss';
import UploadDocument from './modals/UploadDocument';
import DocumentRow from 'lib/components/DocumentRowNew/DocumentRow';
import { ClientsAPI } from 'api_darex';
import { useEffect } from 'react';

const S = () => <span style={{ marginInline: 16, opacity: 0.5 }}>&#9679;</span>;

const DetailsClient = ({ client, getClientById = () => {} }) => {
    const { t } = useTranslation();

    const navigate = useHistory();
    const { vw } = useWindowDimensions();
    const [documents, setDocuments] = useState([]);

    const [openEdit, setOpenEdit] = useState(false);
    const [openDeactivate, setOpenDeactivate] = useState(false);
    const [openAddGDPR, setOpenAddGDPR] = useState(false);
    const [openDeleteGDPR, setOpenDeleteGDPR] = useState(false);
    const [openDocument, setOpenDocument] = useState(false);
    const [openEditCounselor, setEditCounselor] = useState(false);
    const [openEditStatus, setEditStatus] = useState(false);
    const { can, user } = useUser();

    const getDocuments = () => {
        ClientsAPI.getDocuments(client.clientsId).then((res) => {
            setDocuments(res.res.data);
        });
    };

    useEffect(() => {
        getDocuments();
    }, []);

    const onSubmitFunction = ({ values, file }) => {
        ClientsAPI.addDocument(client.clientsId, 'doc', values.documentName, file).then((res) => {
            if (res.ok) {
                toast.success(t('Document was uploaded successfully!'));
                getDocuments();
            } else {
                toast.error(t('Document could not be uploaded successfully!'));
            }
        });
    };

    const onDeleteFunction = ({ doc }) => {
        ClientsAPI.deleteDocument(doc.id).then((res) => {
            if (res.ok) {
                toast.success(t('Document was deleted successfully!'));
                getDocuments();
            } else {
                toast.error(t('Document could not be deleted successfully!'));
            }
        });
    };

    const handleDownloadDocument = (path) => {
        ClientsAPI.getDocumentByPath(path).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });
    };

    return (
        <>
            <div className="client-mid-wrapper">
                {client.hasGDPR && (
                    <>
                        <Box sx={{ p: 2 }} />
                        <div className="client-details-actions">
                            <Typography variant="clientDetails" style={{ alignItems: 'center' }}>
                                {t('Client details')}
                            </Typography>
                            <div>
                                {can(14) && user.roleId !== '1' && user.roleId !== '2' && user.roleId !== '3' && (
                                    <div className="buttons-action-edit" style={{ marginRight: '20px' }}>
                                        <UtilityButton
                                            className="edit-client"
                                            variant="outlined"
                                            color="blue"
                                            // width="120"
                                            // height="18"
                                            onClick={() => navigate.push(`/clients/update/${client.clientsId}`)}
                                        >
                                            {t('EDIT CLIENT')}
                                        </UtilityButton>
                                    </div>
                                )}

                                {/* {can(14) && (user.roleId === '1' || user.roleId === '2' || user.roleId === '3') && (
                            <div>
                                <UtilityButton
                                    className="deactivate-client"
                                    variant="outlined"
                                    color="red"
                                    // width="160"
                                    // height="18"
                                    onClick={() => setOpenDeactivate(true)}
                                >
                                    {t('DEACTIVATE CLIENT')}
                                </UtilityButton>
                            </div>
                        )} */}
                            </div>
                        </div>
                        <div style={{ padding: '4% 5% 0% 7%' }}>
                            <Grid
                                container
                                justifyContent={
                                    'space-between                                                                                                          '
                                }
                                spacing={2}
                                direction={vw < 600 ? 'column' : 'row'}
                            >
                                {client.firmName && (
                                    <Grid item xs={5}>
                                        <>
                                            <div style={{ marginBottom: '16px' }}>
                                                <Typography variant="userHeader">{t('Company client')} : </Typography>
                                                <Typography variant="clientDetailsInfo">{client?.firmName}</Typography>
                                            </div>
                                            <div>
                                                <Typography variant="userHeader">{t('CUI')} : </Typography>
                                                <Typography variant="clientDetailsInfo">{client?.CUI}</Typography>
                                            </div>
                                        </>
                                    </Grid>
                                )}
                                <Grid item xs={5}>
                                    <div
                                        style={{
                                            marginBottom: '16px',
                                        }}
                                    >
                                        <Typography variant="userHeader">{t('STATUS')} : </Typography>
                                        <Typography variant="clientDetailsInfo">
                                            {client?.status === 'New' ? t('Active') : t(client?.status)}
                                        </Typography>
                                    </div>
                                    <div style={{ marginBottom: '16px' }}>
                                        <Typography variant="userHeader"> {t('CLIENT TYPE')} : </Typography>
                                        <Typography variant="clientDetailsInfo">
                                            {client?.type === 'Individual' ? t('Individual Person') : t('LEGAL PERSON')}
                                        </Typography>
                                    </div>
                                    <div style={{ marginBottom: '16px' }}>
                                        <Typography variant="userHeader">{t('Counselor')} : </Typography>
                                        <Typography variant="clientDetailsInfo">
                                            {client?.user?.firstName + ' ' + client?.user?.name}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    <div style={{ marginBottom: '16px' }}>
                                        {can(14) && (
                                            <EditClientButton
                                                variant="outlined"
                                                color="blue"
                                                height="20px"
                                                onClick={() => setEditStatus(true)}
                                            >
                                                {vw < 600 ? t('Edit status') : t('EDIT')}
                                            </EditClientButton>
                                        )}
                                    </div>
                                    {vw < 600 ? <></> : <div style={{ marginBottom: '16px', color: '#ffffff' }}>x</div>}

                                    <div>
                                        {can(14) && user.roleId !== '4' && (
                                            <EditClientButton
                                                className="bottom-edit-button"
                                                variant="outlined"
                                                color="blue"
                                                height="20px"
                                                onClick={() => setEditCounselor(true)}
                                            >
                                                {vw < 600 ? t('Edit Counselor') : t('EDIT')}
                                            </EditClientButton>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ padding: '4% 5% 4% 7%' }}>
                            {client.type === 'Individual' ? (
                                <div className={styles.container_2}>
                                    <ul>
                                        <li>
                                            <div>
                                                {/* Title */}
                                                <Typography variant="clientData">
                                                    {t('Name')} / {t('Phone')} / E-mail
                                                </Typography>

                                                {/* Data */}
                                                <Typography variant="clientListMember">
                                                    {[
                                                        `${client.firstName} ${client.lastName}`,
                                                        <S />,
                                                        client.phone,
                                                        <S />,
                                                        client.email,
                                                    ]}
                                                </Typography>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                {/* Title */}
                                                <Typography variant="clientData">{t('Address')}</Typography>

                                                {/* Data */}
                                                <Typography variant="clientListMember">
                                                    {[
                                                        client?.state?.name,
                                                        <S />,
                                                        client?.city?.name,
                                                        <S />,
                                                        `Str. ${client?.street || ' - '}`,
                                                        <S />,
                                                        `Bl. ${client?.apartmentBuilding || ' - '},  Sc. ${
                                                            client?.staircase || ' - '
                                                        }, Et. ${client?.floor || ' - '},  Ap. ${
                                                            client?.apartmentNr || ' - '
                                                        }`,
                                                        <S />,
                                                        `CP. ${client.postalCode || ' - '}`,
                                                    ]}
                                                </Typography>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <Typography variant="clientData">{t('Client source')}</Typography>
                                                <Typography variant="clientListMember">
                                                    {client.clientSource.name}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="clientData">{t('Brand')}</Typography>
                                                <Typography variant="clientListMember">{client.brand.name}</Typography>
                                            </div>
                                            <div>
                                                <Typography variant="clientData">
                                                    {t('Representative Details')}
                                                </Typography>
                                                <Typography variant="clientListMember">
                                                    {client.details || ' - '}
                                                </Typography>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            ) : (
                                <div className={styles.container_2}>
                                    <ul>
                                        <li>
                                            <div>
                                                <Typography variant="clientData">{t('Address')}</Typography>
                                                <Typography variant="clientListMember">
                                                    {[
                                                        client?.state?.name,
                                                        <S />,
                                                        client?.city?.name,
                                                        <S />,
                                                        `Str. ${client?.street || ' - '}`,
                                                        <S />,
                                                        `Bl. ${client?.apartmentBuilding || ' - '},  Sc. ${
                                                            client?.staircase || ' - '
                                                        }, Et. ${client?.floor || ' - '},  Ap. ${
                                                            client?.apartmentNr || ' - '
                                                        }`,
                                                        <S />,

                                                        `CP. ${client.postalCode || ' - '}`,
                                                    ]}
                                                </Typography>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <Typography variant="clientData">{t('Client source')}</Typography>
                                                <Typography variant="clientListMember">
                                                    {client.clientSource.name}
                                                </Typography>
                                            </div>
                                            <div>
                                                <Typography variant="clientData">{t('Brand')}</Typography>
                                                <Typography variant="clientListMember">{client.brand.name}</Typography>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        {client?.firmName && (
                            <>
                                <Divider light style={{ width: '85%', margin: '0 auto' }} />
                                <Box sx={{ p: 2 }} />
                                <div className="client-list-members">
                                    <Typography variant="h6">{t('Listing members')}</Typography>
                                    <div className="list-of-names">
                                        {client.Contacts.map((contact) => (
                                            <div className="listItem">
                                                <VisualizeRepresentative
                                                    key={contact.id}
                                                    representativeInfo={contact}
                                                />
                                                {/* <ListMember key={contact.id} info={contact} /> */}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                        <Divider color="#63728C" sx={{ width: '85%', height: '2px' }} style={{ margin: '0 auto' }} />
                    </>
                )}

                {!client.hasGDPR && (
                    <div className="gdpr-agreement">
                        <Typography variant="h6Small">
                            {t(
                                "Before seeing the customer's details, you must add a document regarding the GDPR agreement!"
                            )}
                        </Typography>
                    </div>
                )}

                <div className="gdpr-agreement">
                    <Typography variant="h6">{t('GDPR agreement')}</Typography>
                    <div>
                        {can(14) && !client.hasGDPR && (
                            <UtilityButton
                                variant="outlined"
                                color="blue"
                                // width="112"
                                // height="18"
                                startIcon={<img src={plus} alt="Add GDPR" />}
                                onClick={() => setOpenAddGDPR(true)}
                            >
                                {t('ADD GDPR')}
                            </UtilityButton>
                        )}
                        {can(14) && client.hasGDPR && (
                            <UtilityButton
                                variant="outlined"
                                color="blue"
                                width="112"
                                height="18"
                                onClick={() =>
                                    handleDownloadDocument(documents.filter((doc) => doc.tag === 'GDPR')[0].file.path)
                                }
                            >
                                {t('DOWNLOAD GDPR')}
                            </UtilityButton>
                        )}
                        {/* {can(13) && client.hasGDPR && (
                            <UtilityButton
                                variant="outlined"
                                color="red"
                                width="112"
                                height="18"
                                startIcon={<DeleteIcon />}
                                onClick={() => setOpenDeleteGDPR(true)}
                            >
                                {t('DELETE GDPR')}
                            </UtilityButton>
                        )} */}
                    </div>
                </div>
                {client.hasGDPR && (
                    <>
                        <Divider light style={{ width: '85%', margin: '0 auto' }} />
                        <div className="client-documents-section">
                            <Typography variant="h6" className="documentTitle">
                                {t('Documents')}
                            </Typography>
                            <div className="client-documents">
                                <div className="documentsWrapper">
                                    {documents
                                        .filter((doc) => doc.tag !== 'GDPR')
                                        .map((doc, index) => {
                                            return (
                                                <DocumentRow
                                                    key={index}
                                                    doc={doc}
                                                    permission={14}
                                                    onDeleteFunc={onDeleteFunction}
                                                    onDownloadFunction={handleDownloadDocument}
                                                />
                                            );
                                        })}
                                </div>
                                <div className="client-add-document">
                                    {can(14) && (
                                        <UtilityButton
                                            variant="outlined"
                                            color="blue"
                                            width="155"
                                            height="18"
                                            startIcon={<AddIcon />}
                                            onClick={() => setOpenDocument(true)}
                                        >
                                            {t('ADD DOCUMENT')}
                                        </UtilityButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            {/* <ConfirmModal
                text={t('Are you sure you want to delete the GDPR file?')}
                buttonYes={t('YES')}
                buttonNo={t('NO')}
                onClickButtonYes={() => {
                    const doc = documents.filter((doc) => doc.tag === 'GDPR')[0];
                    onDeleteFunction({ doc });
                    getClientById();
                    getDocuments();
                    setOpenDeleteGDPR(false);
                }}
                open={openDeleteGDPR}
                setOpen={setOpenDeleteGDPR}
            /> */}
            {/* <UploadDocument open={openDocument} setOpen={setOpenDocument} /> */}
            <UploadGDPR
                open={openAddGDPR}
                setOpen={setOpenAddGDPR}
                client={client}
                getDocuments={getDocuments}
                getClient={getClientById}
            />
            <EditAdviser open={openEdit} setOpen={setOpenEdit} />
            <EditClientCounselor
                open={openEditCounselor}
                setOpen={setEditCounselor}
                id={client.id}
                counselorId={client?.user.id}
                brandId={client.brandId}
                addCallback={() => getClientById()}
            />
            <EditClientStatus
                open={openEditStatus}
                setOpen={setEditStatus}
                addCallback={() => getClientById()}
                client={client}
            />
            <UploadDocument open={openDocument} setOpen={setOpenDocument} onSubmitFunc={onSubmitFunction} type="file" />
        </>
    );
};

export default DetailsClient;
