import styles from './ModalOffer.module.scss';
import React from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDialog, LoadingSpinner } from 'lib';
import { Typography } from '@mui/material';
import { UtilityButton } from 'lib/components/Buttons/buttons';
import { Divider } from '@mui/material';
import { FileUploadWrapper } from 'lib';
import { uploadSingleFile } from 'utils/functions';
import { toast } from 'react-toastify';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DocumentRowDelete from 'lib/components/DocumentRowNew/DocumentRow';
import { TextField } from '@mui/material';
import { useEffect } from 'react';
import { useUser } from 'hooks/useUser';
import { OrdersAPI } from 'api_darex';

const CustomAccordion = styled(Accordion)(({ theme }) => {
    return {
        boxShadow: 'none',
        border: `none`,
        borderRadius: '0 !important',
        '&:first-of-type': {
            borderTop: 'none',
        },
        '&:last-child': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        '.MuiAccordionDetails-root': {
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
            gap: '20px',
            padding: '0 0 20px 18px',
        },
        '.MuiAccordionSummary-root': {
            paddingTop: '10px',
            paddingBottom: '10px',
        },
        '&.MuiAccordion-root:before': {
            opacity: '1 !important',
        },
    };
});

const ViewOffer = ({ open, setOpen, offerData }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [fileBlob, setFileBlob] = useState(null);
    const [name, setName] = useState(null);
    const [orders, setOrders] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [loading, setLoading] = useState(true);
    const handleChange = (panel) => (event, isExpanded) => {
        setLoading(true);
        setExpanded(isExpanded ? panel : false);
    };
    const [documents, setDocuments] = useState([]);
    const { can } = useUser();

    const textFiledStyles = {
        fieldset: { borderRadius: '10px' },
        '& .MuiFormHelperText-root.Mui-error': {
            color: '#FF4B55',
        },
        '& .Mui-error': {
            color: '#FF4B55',
        },
        '.css-16jwpsg-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FF4B55',
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#5b80ba',
            },
        },
        '& label.Mui-focused': {
            color: '#5b80ba',
        },
        '& label.Mui-error': {
            color: '#FF4B55',
        },
    };

    useEffect(() => {
        if (offerData) {
            setOrders(
                offerData.OfferModel.map((item) => {
                    return { ...item, isOpen: false };
                })
            );
        }
    }, [offerData]);

    const engineType = {
        thermic: t('Thermic'),
        electrical: t('Electric'),
        hibrid: t('Hybrid'),
    };

    const getDocuments = (id) => {
        OrdersAPI.getDocuments(id).then((res) => {
            if (res.ok) {
                setLoading(false);
                setDocuments(res.data);
            }
        });
    };

    useEffect(() => {
        if (expanded !== false && orders.length) {
            setName(null);
            setFileBlob(null);
            getDocuments(orders[expanded].id);
        }
    }, [expanded, orders]);

    const handleOpen = (index) => {
        setOrders(
            orders.map((item, i) => {
                if (i === index) {
                    item.isOpen = true;
                }
                return item;
            })
        );
    };

    const handleClose = (index) => {
        setOrders(
            orders.map((item, i) => {
                if (i === index) {
                    item.isOpen = false;
                }
                return item;
            })
        );
        setName(null);
        setFileBlob(null);
    };

    const displayEngine = (offer) => {
        const item = offer.ModelEngine;
        return `${item?.engine.name}  (${engineType[item?.engine.type]})`;
    };

    const onSubmitFunction = ({ id, index }) => {
        handleClose(index);
        setLoading(true);

        if (!name) {
            toast.error(t('No name has been inserted! Please enter a name for the document file!'));
            return;
        }
        if (!fileBlob) {
            toast.error(t('No file has been uploaded! Please upload a file!'));
            return;
        }

        OrdersAPI.addDocument(id, name, 'file', fileBlob).then((res) => {
            if (res.ok) {
                toast.success(t('Document was uploaded successfully!'));
                getDocuments(orders[expanded].id);
                setName(null);
                setFileBlob(null);
            } else {
                handleOpen(index);
                toast.error(t('Document could not be uploaded successfully!'));
            }
        });
    };

    const onDeleteFunction = ({ id }) => {
        setLoading(true);

        OrdersAPI.removeDocument(id).then((res) => {
            if (res.ok) {
                toast.success(t('Document was deleted successfully!'));
                getDocuments(orders[expanded].id);
                setName(null);
                setFileBlob(null);
            } else {
                toast.error(t('Document could not be deleted successfully!'));
            }
        });
    };

    const handleDownloadDocument = (path) => {
        OrdersAPI.getDocumentByPath(path).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', path);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });
    };

    const handleFileUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    toast.error(t(message));
                    return;
                }
                setFileBlob(blob);
            },
            'file'
        );
    };

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <>
            {orders && (
                <CustomDialog
                    open={open}
                    setOpen={setOpen}
                    title={t('Viewing order')}
                    buttonClose={t('BACK')}
                    onClickButtonFinish={handleSubmit}
                    width="600px"
                    styles={{
                        '& .MuiDialogContent-root': { padding: '0 0 15px 0' },
                        '& .MuiDialogActions-root': {
                            paddingTop: '0.5rem !important',
                        },
                    }}
                    onClickButtonClose={() => {
                        setFileBlob(null);
                        setName(null);
                        setOpen(false);
                    }}
                >
                    <Divider style={{ width: '100%' }} />
                    <div>
                        {orders.map((order, index) => (
                            <CustomAccordion
                                style={{ width: '100%' }}
                                elevation={0}
                                disableGutters
                                square
                                expanded={expanded === index}
                                onChange={handleChange(index)}
                                key={index}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant="userHeader">{`${t('Unit #')}${
                                        order.autoincrement
                                    }`}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className={styles.modalRowOfferDetails}>
                                        <Typography variant="userHeader" style={{ flex: 1 }}>
                                            {t('Brand')}:
                                        </Typography>
                                        <Typography variant="clientDetailsInfo" style={{ flex: 2 }}>
                                            {order.brandId === '1'
                                                ? 'Dacia'
                                                : order.brandId === '2'
                                                ? t('Renault')
                                                : order.brandId === '3'
                                                ? t('Nissan')
                                                : ''}
                                        </Typography>
                                    </div>
                                    <div className={styles.modalRowOfferDetails}>
                                        <Typography variant="userHeader" style={{ flex: 1 }}>
                                            Model:
                                        </Typography>
                                        <Typography variant="clientDetailsInfo" style={{ flex: 2 }}>
                                            {order.model.name}
                                        </Typography>
                                    </div>
                                    <div className={styles.modalRowOfferDetails}>
                                        <Typography variant="userHeader" style={{ flex: 1 }}>
                                            {t('Color')}:
                                        </Typography>
                                        <Typography variant="clientDetailsInfo" style={{ flex: 2 }}>
                                            {order.ModelColor?.color?.name}
                                        </Typography>
                                    </div>
                                    <div className={styles.modalRowOfferDetails}>
                                        <Typography variant="userHeader" style={{ flex: 1 }}>
                                            {t('Motorisation')}:
                                        </Typography>

                                        <Typography variant="clientDetailsInfo" style={{ flex: 2 }}>
                                            {displayEngine(order)}
                                        </Typography>
                                    </div>
                                    <div className={styles.modalRowOfferDetails}>
                                        <Typography variant="userHeader" style={{ flex: 1 }}>
                                            {t('Acquisition type')}:
                                        </Typography>
                                        <Typography
                                            variant="clientDetailsInfo"
                                            style={{ flex: 2, display: 'flex', alignItems: 'center' }}
                                        >
                                            <div>
                                                {order.paymentType === 'CASH'
                                                    ? t('Cash')
                                                    : order.paymentType === 'RCI'
                                                    ? 'RCI'
                                                    : order.paymentType === 'OTHER'
                                                    ? t('Other')
                                                    : ''}
                                            </div>
                                            <div
                                                style={{
                                                    width: '5px',
                                                    height: '5px',
                                                    borderRadius: '50%',
                                                    backgroundColor: 'rgba(167, 167, 167, 1)',
                                                    margin: '0 20px',
                                                }}
                                            ></div>
                                            <div>
                                                {order.purchaseType === 'NON_REMAT'
                                                    ? 'Non remat'
                                                    : order.purchaseType === 'DOSAR_NEDEPUS'
                                                    ? t('Document not submitted')
                                                    : order.purchaseType === 'DOSAR_DEPUS'
                                                    ? t('Document submitted')
                                                    : order.purchaseType === 'DOSAR_AFM'
                                                    ? t('AFM document')
                                                    : order.purchaseType === 'REMAT'
                                                    ? 'REMAT'
                                                    : ''}
                                            </div>
                                        </Typography>
                                    </div>
                                    <div className={styles.modalRowOfferDetails}>
                                        <Typography variant="userHeader" style={{ flex: 1 }}>
                                            {t('Accessories')}:
                                        </Typography>
                                        <Typography variant="clientDetailsInfo" style={{ flex: 2 }}>
                                            {order.accessories}
                                        </Typography>
                                    </div>
                                    <div className={styles.modalRowOfferDetails}>
                                        <Typography variant="userHeader" style={{ flex: 1 }}>
                                            {t('Options')}:
                                        </Typography>
                                        <Typography variant="clientDetailsInfo" style={{ flex: 2 }}>
                                            {order.options}
                                        </Typography>
                                    </div>
                                    <div className={styles.modalRowOfferDetails}>
                                        <Typography variant="userHeader" style={{ flex: 1 }}>
                                            {t('Comments')}:
                                        </Typography>
                                        <Typography variant="clientDetailsInfo" style={{ flex: 2 }}>
                                            {order.details}
                                        </Typography>
                                    </div>
                                </AccordionDetails>
                                {can(5) && (
                                    <>
                                        <Divider style={{ width: '100%' }} />
                                        <div className={styles.modalDocumentsWrapper}>
                                            <Typography variant="clientDetailsInfo">{t('Documents')}</Typography>
                                            {loading ? (
                                                <LoadingSpinner loading={loading} margin="0 auto" />
                                            ) : (
                                                documents
                                                    ?.filter((doc) => doc.tag === 'file')
                                                    .map((doc, index) => {
                                                        return (
                                                            <DocumentRowDelete
                                                                key={index}
                                                                doc={doc}
                                                                permission={4}
                                                                onDeleteFunc={() => {
                                                                    onDeleteFunction({ id: doc?.id });
                                                                }}
                                                                onDownloadFunction={handleDownloadDocument}
                                                            />
                                                        );
                                                    })
                                            )}
                                            {order.isOpen && (
                                                <div className={styles.modalAddDocumentWrapper}>
                                                    <TextField
                                                        label={`${t("Document's name")}*`}
                                                        size="medium"
                                                        variant="outlined"
                                                        fullWidth={true}
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        sx={{
                                                            ...textFiledStyles,
                                                        }}
                                                    />
                                                    <div className={styles.modalDocumentWrapper}>
                                                        <TextField
                                                            label={fileBlob?.name}
                                                            size="medium"
                                                            fullWidth={true}
                                                            disabled
                                                            variant="outlined"
                                                            sx={{
                                                                ...textFiledStyles,
                                                            }}
                                                        />
                                                        <FileUploadWrapper onUpload={handleFileUpload}>
                                                            <IconButton
                                                                sx={{
                                                                    backgroundColor: '#5B80BA',
                                                                    color: '#fff',
                                                                    position: 'absolute',
                                                                    right: '10px',
                                                                    top: '8px',
                                                                    boxShadow:
                                                                        '0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.2)',
                                                                    '&:hover': {
                                                                        backgroundColor: '#3d5e92',
                                                                        color: '#fff',
                                                                    },
                                                                }}
                                                            >
                                                                <input
                                                                    name="documentFile"
                                                                    hidden
                                                                    accept="application/pdf"
                                                                    type="file"
                                                                />
                                                                <AttachFileIcon />
                                                            </IconButton>
                                                        </FileUploadWrapper>
                                                    </div>
                                                </div>
                                            )}

                                            <div className={styles.modalAddWrapper}>
                                                {order.isOpen ? (
                                                    <div className={styles.buttons}>
                                                        <UtilityButton onClick={() => handleClose(index)}>
                                                            {t('CLOSE')}
                                                        </UtilityButton>
                                                        <UtilityButton
                                                            onClick={() => onSubmitFunction({ id: order.id, index })}
                                                        >
                                                            {t('SAVE')}
                                                        </UtilityButton>
                                                    </div>
                                                ) : (
                                                    <UtilityButton
                                                        startIcon={<AddIcon />}
                                                        onClick={() => handleOpen(index)}
                                                    >
                                                        {t('ADD DOCUMENT')}
                                                    </UtilityButton>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </CustomAccordion>
                        ))}
                    </div>
                </CustomDialog>
            )}
        </>
    );
};

export default ViewOffer;
