import React, { useRef, useCallback, memo } from 'react';
import styles from './TasksComponent.module.scss';
import TaskComponent from 'lib/components/Task/TaskComponent';
import { Divider, Typography } from '@mui/material';
import dayjs from 'dayjs';
import LoadingSpinner from '../Spinners/LoadingSpinner';

/**
 * Tasks component
 * @param {array} clients - represents the clients that will be rendered in add or edit modal
 * @param {function} getAllTasks - function used to refresh the tasks when one of them is added, deleted, edited or marked as done
 * @param {array} tasks - tasks to be render in the component
 * @returns a component
 */
const DoneTasksComponent = ({
    tasks,
    getDoneTasks,
    getToDoTasks,
    clients,
    setPageNumberDone,
    loading,
    hasMore,
    activeTab,
    isSearch,
    isFilter,
}) => {
    const observer = useRef();

    const lastTaskElementRef = useCallback(
        (node) => {
            if (loading || isSearch || isFilter) return;

            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    setPageNumberDone((prev) => prev + 1);
                }
            });

            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const checkForTimeLine = ({ item, index }) => {
        const itemDate = new Date(item.deadline);
        itemDate.setHours(0, 0, 0, 0);
        const lastItemDate = new Date(tasks[index - 1] ? tasks[index - 1].deadline : null);
        lastItemDate.setHours(0, 0, 0, 0);

        if (index === 0 || itemDate.getTime() !== lastItemDate.getTime()) {
            return true;
        } else return false;
    };

    return (
        <div className={styles.toDoTasksWrapper}>
            <div className={styles.toDoTasksContainer}>
                {tasks
                    ?.sort((a, b) => {
                        const aDate = new Date(a.deadline);
                        aDate.setHours(0, 0, 0, 0);
                        const bDate = new Date(b.deadline);
                        bDate.setHours(0, 0, 0, 0);

                        if (aDate.getTime() < bDate.getTime()) return -1;
                        else if (aDate.getTime() > bDate.getTime()) return 1;
                        else return 0;
                    })
                    .map((item, index) => (
                        <React.Fragment key={item.id}>
                            {checkForTimeLine({ item, index }) && (
                                <div className={`${styles.timeWrapper} ${index === 0 && styles.topVariant}`}>
                                    <div className={`${styles.bigDot} ${index === 0 && styles.bigDotTopVariant}`} />
                                    <Divider sx={{ '&.MuiDivider-root': { width: 'calc(100% - 16px)' } }}>
                                        <Typography variant="taskStatusText" color="#8E99A7">
                                            {dayjs(item.deadline).format('DD MMM YYYY')}
                                        </Typography>
                                    </Divider>
                                </div>
                            )}
                            {tasks.length === index + 1 ? (
                                <TaskComponent
                                    innerRef={lastTaskElementRef}
                                    item={item}
                                    getToDoTasks={getToDoTasks}
                                    getDoneTasks={getDoneTasks}
                                    clients={clients}
                                    activeTab={activeTab}
                                />
                            ) : (
                                <TaskComponent
                                    item={item}
                                    getToDoTasks={getToDoTasks}
                                    getDoneTasks={getDoneTasks}
                                    clients={clients}
                                    activeTab={activeTab}
                                />
                            )}
                        </React.Fragment>
                    ))}

                {loading && (
                    <div className={styles.loadingWrapper}>
                        <LoadingSpinner loading={loading} margin="1rem auto" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(DoneTasksComponent);
