import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomTableLayout from 'lib/components/Tables/TabsLayout';
import DetailsClient from './DetailsClient';
import ClientOffers from './ClientOffers';
import ClientTasks from './ClientTasks';
import ClientOrders from './ClientOrders';
import { LoadingSpinner, PageLayout } from 'lib';
import { ClientsAPI } from 'api_darex';
import { Helmet } from 'react-helmet';

const Client = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [client, setClient] = useState(null);
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(0);

    const getClientById = () => {
        ClientsAPI.getByIdBrands(id).then((res) => {
            const client = res.data;

            setClient({
                ...client.Clients,
                brandId: client.brandId,
                status: client.status,
                userId: client.userId,
                brand: client.Brand,
                user: client.User,
                id: client.id,
                clientsId: client.clientsId,
            });
            setLoading(false);
        });
    };

    useEffect(() => {
        getClientById();
    }, []);

    const tabs = [
        {
            label: t('Client details'),
            id: 0,
            render: true,
            hasUtility: true,
            component: loading ? (
                <LoadingSpinner margin="0 auto" loading={loading} />
            ) : (
                <DetailsClient client={client} getClientById={getClientById} />
            ),
        },
        { label: t('Offers'), id: 1, render: client?.hasGDPR, hasUtility: true, component: <ClientOffers /> },
        {
            label: t('Tasks'),
            id: 2,
            render: client?.hasGDPR,
            hasUtility: true,
            component: <ClientTasks client={client} />,
        },
        { label: t('Orders'), id: 3, render: client?.hasGDPR, hasUtility: true, component: <ClientOrders /> },
    ];

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Client')}</title>
            </Helmet>
            <PageLayout
                title={`${t('Client')} #${client?.clientsId}: ${
                    client?.type === 'Individual'
                        ? client?.lastName + ' ' + client?.firstName
                        : client?.type === 'Juridic'
                        ? client?.firmName
                        : ''
                }`}
                subTitle={t('Manage your clients')}
                backArrow
            >
                <CustomTableLayout tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            </PageLayout>
        </>
    );
};

export default Client;
