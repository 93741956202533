import React, { useState } from 'react';
import styles from './permissionsComponent.module.scss';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import { ReactComponent as SmallArrow } from 'resources/svg/administrationPage/smallArrow.svg';

import { ReactComponent as RoundPlusIcon } from 'resources/svg/administrationPage/round-plus.svg';

import { ReactComponent as EyeIcon } from 'resources/svg/administrationPage/eye-icon.svg';
import { ReactComponent as DeleteIcon } from 'resources/svg/administrationPage/delete-icon.svg';

import { ReactComponent as UsersIcon } from 'resources/svg/menu-navbar/UsersIcon.svg';
import { ReactComponent as OffersIcon } from 'resources/svg/menu-navbar/OffersIcon.svg';
import { ReactComponent as TasksIcon } from 'resources/svg/menu-navbar/TasksIcon.svg';
import { ReactComponent as ObjectiveIcon } from 'resources/svg/menu-navbar/ObjectiveIcon.svg';
import { ReactComponent as ClientsLogo } from 'resources/svg/menu-navbar/ClientsLogo.svg';
import { ReactComponent as VehiclesNomenclaturesLogo } from 'resources/svg/menu-navbar/vehiclesNomenclatures.svg';

import { useTranslation } from 'react-i18next';

const SinglePermissionComponent = (props) => {
    const {
        permissionGroup,
        permissions,
        selectedPermission,
        handleSelectedPermissions,
        finalPermissions,
        canBeEdited,
    } = props;

    const { t } = useTranslation();

    // State for the open and close of the permissions group
    const [open, setOpen] = useState(false);

    const permissionsMapper = {
        VIEW: {
            icon: <EyeIcon />,
            name: t('View'),
        },
        EDIT: {
            icon: <RoundPlusIcon />,
            name: t('Create & Edit'),
        },
        DELETE: {
            icon: <DeleteIcon />,
            name: t('Delete'),
        },
    };

    const permissionGroupsMapper = {
        Users: {
            icon: <UsersIcon style={{ width: '23px', height: '23px' }} />,
        },
        Offers: {
            icon: <OffersIcon style={{ width: '23px', height: '23px' }} />,
        },
        Tasks: {
            icon: <TasksIcon style={{ width: '23px', height: '23px' }} />,
        },
        Objectives: {
            icon: <ObjectiveIcon style={{ width: '23px', height: '23px' }} />,
        },
        Clients: {
            icon: <ClientsLogo style={{ width: '23px', height: '23px' }} />,
        },
        Nomenclatures: {
            icon: <VehiclesNomenclaturesLogo style={{ width: '23px', height: '23px' }} />,
        },
    };

    const handleOpenAndCloseOfPermissionsGroup = () => {
        setOpen(!open);
    };

    const detectState = (permission, permissionIndex) => {
        let isAbove = false;
        let isSelected = false;

        if (selectedPermission) {
            isSelected = selectedPermission === permission.id;

            if (!isSelected) {
                const selectedIndex = permissions.findIndex((p) => p.id === selectedPermission);
                if (permissionIndex < selectedIndex) {
                    isAbove = true;
                }
            }
        }

        return [isSelected, isAbove];
    };

    const countOfSelectedPermissionsOnEachGroup = () => {
        let selected = 0;

        permissions.forEach((p) => {
            if (finalPermissions.includes(p.id)) {
                ++selected;
            }
        });

        return selected;
    };

    return (
        <div className={styles.singlePermissionWrapper}>
            <div className={styles.permissionRow} onClick={handleOpenAndCloseOfPermissionsGroup}>
                <div className={styles.permissionNameContainer}>
                    <div
                        style={{
                            marginRight: '20px',
                        }}
                    >
                        <SmallArrow
                            style={{
                                width: '7px',
                                height: '7px',
                                transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
                                transitionDuration: '0.3s',
                            }}
                        />
                    </div>

                    <div
                        style={{
                            marginRight: '10px',
                        }}
                    >
                        {permissionGroupsMapper[permissionGroup.name].icon}
                    </div>
                    <div>
                        <Typography variant="permissions">{t(permissionGroup.name)}</Typography>
                    </div>
                </div>
                <div
                    style={{
                        marginRight: '16px',
                    }}
                >
                    <Typography variant="permissions">{countOfSelectedPermissionsOnEachGroup()}/3</Typography>
                </div>
            </div>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={styles.permissionControlsWrapper}>
                    {permissions.map((permission, permissionIndex) => (
                        <div key={permission.id} className={styles.roleComponentWrapper}>
                            <div className={styles.roleComponentNameContainer}>
                                <div>{permissionsMapper[permission.name].icon}</div>
                                <div>
                                    <Typography variant="permissions">
                                        {permissionsMapper[permission.name].name}
                                    </Typography>
                                </div>
                            </div>

                            <div>
                                {(() => {
                                    const [isSelected, isAbove] = detectState(permission, permissionIndex);

                                    return (
                                        <Checkbox
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#5A7FBA',
                                                },
                                                '& .MuiSvgIcon-root': { fontSize: 19 },
                                                padding: 0,
                                                opacity: isAbove ? 0.25 : 1,
                                            }}
                                            /**
                                             * Permission is checked if:
                                             * 1. Is the one selected in this group
                                             * 2. is Above (visually) from the selected one
                                             */
                                            checked={isSelected || isAbove}
                                            onChange={(e) => {
                                                handleSelectedPermissions(e, permission);
                                            }}
                                            disabled={canBeEdited ? false : true}
                                        />
                                    );
                                })()}
                            </div>
                        </div>
                    ))}
                </div>
            </Collapse>
        </div>
    );
};

export default SinglePermissionComponent;
