import axios from './axios';

const Clients = {
    /**
     * Fetches all Clients's
     *
     * @return {array} Array of Clients's
     */
    async get(perPage = 99999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/clients', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Users witch contain the the specified string
     *
     * @param {string} text used to find Users
     * @return {object} Array of Users's
     */
    async filterClients(data) {
        try {
            const res = await axios.get(`/clients/filter/clients`, { params: { ...data } });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all Clients witch contain the the specified string
     *
     * @param {string} text used to find Clients
     * @return {object} Array of Clients's
     */
    async searchClients(text) {
        try {
            const res = await axios.get(`/clients/findByString/text?text=${text}`);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all clients on every brand
     * @param {*} perPage
     * @param {*} currentPage
     * @param {*} pagesToLoad
     * @returns
     */
    async getClientsByBrands(perPage = 99999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/clients/all/clientsBrand', {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Clients's by userId
     *
     * @return {array} Array of Clients's
     */
    async getByUserIdBrands(userId, perPage = 9999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/clients/clientsBrand/' + userId, {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all clients on specific brand
     * @param {*} perPage
     * @param {*} currentPage
     * @param {*} pagesToLoad
     * @returns
     */
    async getClientsByOneBrandBrands(brandId, perPage = 99999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/clients/clients/Brand/' + brandId, {
                params: {
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });

            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all clients on specific brand
     * @param {*} perPage
     * @param {*} currentPage
     * @param {*} pagesToLoad
     * @returns
     */
    async getClientsByOneBrand(brandId, perPage = 99999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/clients/brandId', {
                params: {
                    brandId,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Clients's by userId
     *
     * @return {array} Array of Clients's
     */
    async getByUserId(userId, perPage = 9999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/clients/userId', {
                params: {
                    userId,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches one Clients by it's ID
     *
     * @param {string} id ID of the Clients
     * @return {object} Clients with the corresponding ID
     */
    async getById(clientsId) {
        try {
            const res = await axios.get('/clients/' + clientsId);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches one Clients by it's ID
     *
     * @param {string} id ID of the Clients
     * @return {object} Clients with the corresponding ID
     */
    async getByIdBrands(clientsId) {
        try {
            const res = await axios.get('/clients/clientBrand/' + clientsId);

            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Clients
     *
     * @param {object} clients Object with the creation data
     * @return {object} Newly created Clients
     */
    async create(clients) {
        // let reqBody = { ...clients };
        try {
            const res = await axios.post('/clients', clients);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error: error.response,
                data: {},
            };
        }
    },

    /**
     * Creates the same client but with different brand
     *
     * @param {object} clients Object with the creation data
     * @return {object} Newly created Clients
     */
    async createClientOnAnotherBrand(clientsId, brandId, userId) {
        try {
            const res = await axios.post('/clients/create/clientsBrand', {
                brandId,
                clientsId,
                userId,
            });
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error: error.response,
                data: {},
            };
        }
    },

    /**
     * Deletes Clients by ID
     *
     * @param {string} clientsId ID of the Clients to be deleted
     * @return {string} ID of the deleted Clients
     */
    async delete(clientsId) {
        try {
            await axios.delete('/clients/' + clientsId);
            return {
                ok: true,
                data: {
                    id: clientsId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: clientsId,
                },
            };
        }
    },

    async update(id, client) {
        try {
            const res = await axios.put('/clients/' + id, client);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },

    /**
     * Updates the counselor for a client by ID
     *
     * @param {string} clientsId ID of the Clients to be deleted
     * @return {string} userId Id of the new user
     */

    async updateCounselor(clientId, counselorId) {
        try {
            const res = await axios.patch(`/clients/user?id=${clientId}&userId=${counselorId}`);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },

    /**
     * Updates the status for a client by ID
     *
     * @param {string} clientsId ID of the Clients to be deleted
     * @return {string} userId Id of the new user
     */

    async updateStatus(id, status) {
        try {
            const res = await axios.patch(`/clients/status?id=${id}&status=${status}`);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },

    async addDocument(clientId, tag, fileName, file) {
        try {
            await axios.post(
                `/clients/createDocs/${clientId}?tag=${tag}&fileName=${fileName}`,
                { file },
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            );
            return {
                ok: true,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: error,
            };
        }
    },

    async getDocuments(clientId) {
        try {
            const res = await axios.get(`/clients/getDocs/${clientId}`);
            return {
                ok: true,
                res,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },

    async deleteDocument(clientDocId) {
        try {
            const res = await axios.delete('/clients/deleteDocs/' + clientDocId);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    async getDocumentByPath(path) {
        try {
            const res = await axios.get('clients/getDocById/' + path, { responseType: 'arraybuffer' });
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },
};

export default Clients;
