import React, { useEffect, useState } from 'react';
import PermissionsComponent from './PermissionsComponent';
import { RolesAPI } from 'api_darex';
import { LoadingSpinner } from 'lib';

const PermissionsWrapper = () => {
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);

    const getAllRoles = () => {
        RolesAPI.get().then((res) => {
            if (res.ok) {
                setRoles(res.data);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        getAllRoles();
    }, []);

    return (
        <>
            {loading ? (
                <LoadingSpinner loading={loading} margin={'3rem auto'} />
            ) : (
                <div
                    style={{
                        width: '100%',
                        margin: '0 auto',
                        boxShadow: '#eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px',
                        borderRadius: '15px',
                        background: '#FFFFFF',
                    }}
                >
                    {/* This is the rol creation component */}
                    <PermissionsComponent type="CREATE" getAllRoles={getAllRoles} />

                    {roles.map((role) => (
                        <div key={role.id}>
                            {/* This is the rol display, edit and delete component */}
                            <PermissionsComponent type="DISPLAY" role={role} getAllRoles={getAllRoles} />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default PermissionsWrapper;
