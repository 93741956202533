import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDialog } from 'lib';
import { MenuItem, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useUser } from 'hooks/useUser';
import { UsersAPI } from 'api_darex';

const FilterClients = ({
    open,
    setOpen,
    filterData,
    setFilterData,
    setTriggerFilter,
    triggerFilter,
    triggerRefetch,
    setTriggerRefetch,
    setIsFilter,
    setData,
    setLoading,
    resetSearch,
    resetFilter,
    isFilter,
    noUser = false,
    userState = null,
}) => {
    const { t } = useTranslation();
    const { user } = useUser();
    const [users, setUsers] = useState([]);

    const brands = [
        { name: 'Dacia', id: 1 },
        { name: 'Nissan', id: 3 },
        { name: 'Renault', id: 2 },
    ];

    const displayedBrands = () => {
        if (userState) {
            return brands.filter((brand) =>
                userState?.UserBrand.map((uBrand) => Number(uBrand.brandId)).includes(brand.id)
            );
        } else {
            return brands.filter((brand) => user.UserBrand.map((uBrand) => Number(uBrand.brandId)).includes(brand.id));
        }
    };

    const getAllUsers = useCallback(() => {
        if (user.roleId === '1' || user.roleId === '2') {
            UsersAPI.getByBrandsIdNoPagination([1, 2, 3]).then((res) => {
                setUsers(res.data.content);
            });
        } else if (user.roleId === '3') {
            UsersAPI.getByBrandsIdNoPagination(user.UserBrand[0].brandId).then((res) => {
                setUsers(res.data.content);
            });
        }
    }, [user]);

    useEffect(() => {
        getAllUsers();
    }, [getAllUsers]);

    const handleChange = (e) => {
        setFilterData({
            ...filterData,
            [e.target.name]: e.target.value,
        });
    };

    const handleRemoveFilters = () => {
        resetFilter();
        setLoading(true);
        setData([]);
        setTriggerRefetch(!triggerRefetch);
        setOpen(false);
    };

    const handleFilter = () => {
        resetSearch();
        setLoading(true);
        setData([]);
        setIsFilter(true);
        setTriggerFilter(!triggerFilter);
        setOpen(false);
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={t('Filter clients')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            buttonUtility={t('REMOVE FILTERS')}
            onClickButtonFinish={handleFilter}
            width="535px"
            onClickButtonClose={() => {
                setOpen(false);
            }}
            onClickButtonUtility={handleRemoveFilters}
            buttonUtilityDisabled={!isFilter}
            buttonFinishDisabled={Object.values(filterData).every((x) => x === null || x === '')}
        >
            <div className="add-clients-wrapper">
                {user.roleId !== '4' && !noUser && (
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <TextField
                            name="userId"
                            label={`${t('Counselor')}`}
                            size="medium"
                            fullWidth
                            select
                            sx={{
                                fieldset: {
                                    borderRadius: '10px',
                                },
                            }}
                            id="outlined-required"
                            value={filterData.userId}
                            onChange={handleChange}
                        >
                            {users.map((user) => (
                                <MenuItem key={user.id} value={user.id} sx>
                                    {`${user.name + ' ' + user.firstName}`}
                                </MenuItem>
                            ))}
                        </TextField>
                        <DeleteIcon
                            fontSize="large"
                            sx={{
                                cursor: filterData.userId !== '' && 'pointer',
                                color: filterData.userId !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                                '&:hover': {
                                    color: filterData.userId !== '' && '#a50009',
                                },
                            }}
                            onClick={() => {
                                setFilterData({
                                    ...filterData,
                                    userId: '',
                                });
                            }}
                        />
                    </div>
                )}
                {(user.roleId !== '3' || noUser) && (
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <TextField
                            name="brandId"
                            label={`${t('Brand')}`}
                            size="medium"
                            fullWidth
                            select
                            sx={{
                                fieldset: {
                                    borderRadius: '10px',
                                },
                            }}
                            id="outlined-required"
                            value={filterData.brandId}
                            onChange={handleChange}
                        >
                            {displayedBrands().map((brand) => (
                                <MenuItem key={brand.id} value={brand.id} sx>
                                    {brand.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <DeleteIcon
                            fontSize="large"
                            sx={{
                                cursor: filterData.brandId !== '' && 'pointer',
                                color: filterData.brandId !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                                '&:hover': {
                                    color: filterData.brandId !== '' && '#a50009',
                                },
                            }}
                            onClick={() =>
                                setFilterData({
                                    ...filterData,
                                    brandId: '',
                                })
                            }
                        />
                    </div>
                )}
            </div>
        </CustomDialog>
    );
};

export default FilterClients;
