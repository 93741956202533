import React, { useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField } from 'lib';
import { CustomDialog } from 'lib';
import { ClientSourceAPI } from 'api_darex';
import { toast } from 'react-toastify';

const AddClientSource = ({ open, setOpen, rowState, getAllClientSources }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [hasModified, setHasModified] = useState(false);
    const [loading, setLoading] = useState(false);

    const INITIAL_FORM_STATE = {
        name: rowState?.name ? rowState.name : '',
    };

    const FORM_VALIDATION = yup.object().shape({
        name: yup.string().trim().required(t('The client source is mandatory!')),
    });

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={rowState ? t('Edit client source') : t('Add client source')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            buttonFinishDisabled={rowState ? !hasModified : false}
            onClickButtonFinish={handleSubmit}
            onClickButtonClose={() => {
                setOpen(false);
                setHasModified(false);
            }}
            width="580px"
            buttonFinishLoading={loading}
            buttonCloseDisabled={loading}
        >
            <Formik
                innerRef={formRef}
                enableReinitialize={true}
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    setLoading(true);
                    rowState
                        ? ClientSourceAPI.update(rowState.id, values).then((res) => {
                              if (res.ok === true) {
                                  getAllClientSources();
                                  setOpen(false);
                                  setLoading(false);
                                  toast.success(t('This client source has been updated successfully!'));
                              } else if (res.data.response.data.message === 'This client source already exist') {
                                  setLoading(false);
                                  toast.error(t('There is already a client source with this name!'));
                              } else {
                                  setLoading(false);
                                  toast.error(t('Something went wrong!'));
                              }
                          })
                        : ClientSourceAPI.create(values).then((res) => {
                              if (res.ok === true) {
                                  getAllClientSources();
                                  setOpen(false);
                                  setLoading(false);
                                  toast.success(t('Client source was successfully added!'));
                              } else {
                                  if (res.data.response.data.message === 'This client source already exist') {
                                      setLoading(false);
                                      toast.error(t('There is already a client source with this name!'));
                                  } else {
                                      setLoading(false);
                                      toast.error(t('Something went wrong!'));
                                  }
                              }
                          });
                }}
            >
                <Form
                    onChange={(e) => {
                        const { name, value } = e.target;

                        if (rowState && INITIAL_FORM_STATE[name] === value) setHasModified(false);
                        else if (rowState && INITIAL_FORM_STATE[name] !== value) setHasModified(true);
                    }}
                >
                    <div className="add-user-wrapper" style={{ paddingTop: '10px' }}>
                        <TextField name="name" label={`${t('Source')}`} size="medium" />
                    </div>
                </Form>
            </Formik>
        </CustomDialog>
    );
};

export default AddClientSource;
