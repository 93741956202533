import React from 'react';
import { useRef, useState } from 'react';
import { uploadSingleFile } from 'utils/functions';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CustomDialog } from 'lib';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField } from 'lib';
import { FileUploadWrapper } from 'lib';
import { IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { ClientsAPI } from 'api_darex';

const UploadGDPR = ({ open, setOpen, client, getDocuments, getClient }) => {
    const formRef = useRef();
    const { t } = useTranslation();
    const [fileBlob, setFileBlob] = useState(null);

    const INITIAL_FORM_STATE = {
        fileGdpr: null,
    };

    const handleFileUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    toast.error(t(message));
                    return;
                }
                setFileBlob(blob);
                formRef.current.setFieldValue('fileGdpr', blob);
            },
            'file'
        );
    };

    const FORM_VALIDATION = yup.object().shape({
        fileGdpr: yup.mixed().required(t('The file is mandatory!')),
    });

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={t('Upload GDPR agreement')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={handleSubmit}
            width="600px"
            onClickButtonClose={() => {
                setFileBlob(null);
                setOpen(false);
            }}
        >
            <Formik
                innerRef={formRef}
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={() => {
                    if (fileBlob) {
                        ClientsAPI.addDocument(client.clientsId, 'GDPR', 'GDPR', fileBlob).then((res) => {
                            if (res.ok) {
                                getClient();
                                getDocuments();
                                setOpen(false);
                                setFileBlob(null);
                                toast.success(t('GDPR added successfully!'));
                            } else {
                                toast.error(t(`GDPR couldn't be added successfully!`));
                            }
                        });
                    } else {
                        toast.error(t(`Upload a file first!`));
                    }
                }}
            >
                {({ errors }) => (
                    <Form>
                        <div className="add-document-wrapper">
                            <div className="document-wrapper">
                                <TextField name="fileName" label={fileBlob?.name} size="medium" disabled />
                                <FileUploadWrapper onUpload={handleFileUpload}>
                                    <IconButton
                                        sx={{
                                            backgroundColor: '#5B80BA',
                                            color: '#fff',
                                            position: 'absolute',
                                            right: '10px',
                                            top: '8px',
                                            boxShadow:
                                                '0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.2)',
                                            '&:hover': { backgroundColor: '#3d5e92', color: '#fff' },
                                        }}
                                    >
                                        <input name="fileGdpr" hidden accept=".pdf" type="file" />
                                        <AttachFileIcon />
                                    </IconButton>
                                </FileUploadWrapper>
                                {errors.file && <div className="checkboxGroupError">{errors.file}</div>}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </CustomDialog>
    );
};

export default UploadGDPR;
