import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { Formik } from 'formik';
import { MenuItem } from '@mui/material';

import { OrdersAPI } from 'api_darex';
import { CustomDialog, Select } from 'lib';

const DropDownModal = ({ open, setOpen, order, useCase, getOrder }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [loading, setLoading] = useState(false);

    let INITIAL_FORM_STATE;
    let onSubmitFunc;
    let title;
    let label;
    let items;

    switch (useCase) {
        case 'statusOrder':
            INITIAL_FORM_STATE = {
                value: order.status,
            };
            label = t('Order status');
            title = t('Change the status of the order');
            items = [
                {
                    id: 'Created',
                    label: t('Created'),
                    isDisabled: true,
                },
                {
                    id: 'OrderLaunch',
                    label: t('Order launched'),
                    isDisabled: true,
                },
                {
                    id: 'AlocatedChassisNumber',
                    label: t('Assigned chassis number'),
                    isDisabled: true,
                },
                {
                    id: 'OutOfProduction',
                    label: t('Out of production'),
                    isDisabled: true,
                },
                {
                    id: 'ArrivedInThePark',
                    label: t('Arrived in the park'),
                    isDisabled:
                        !Boolean(order.dateBillingAdvancePayment) ||
                        !Boolean(order.dateExitProduction) ||
                        !Boolean(order.chassisNumber) ||
                        order.status === 'Completed',
                },
                {
                    id: 'Delivered',
                    label: t('Delivered'),
                    isDisabled:
                        !Boolean(order.dateBillingAdvancePayment) ||
                        !Boolean(order.dateExitProduction) ||
                        !Boolean(order.chassisNumber) ||
                        order.status === 'Completed',
                },
                {
                    id: 'Completed',
                    label: t('Completed'),
                    isDisabled: true,
                },
                {
                    id: 'Cancelled',
                    label: t('Cancelled'),
                    isDisabled: true,
                },
            ];
            onSubmitFunc = (values) => {
                setLoading(true);

                OrdersAPI.update(order.id, { status: values.value }).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(t('The order status has been updated successfully!'));
                    } else {
                        setLoading(false);
                        toast.error(t('The order status could not be updated successfully!'));
                    }
                });
            };
            break;
        case 'statusValidate':
            INITIAL_FORM_STATE = {
                value: order.validateStatus,
            };
            label = t('Validation status');
            title = t('Change the validation status');
            items = [
                {
                    id: 'Unverified',
                    label: t('Unverified'),
                    isDisabled: order.status === 'Completed',
                },
                {
                    id: 'Valid',
                    label: t('Valid'),
                    isDisabled: order.status === 'Completed',
                },
                {
                    id: 'Invalid',
                    label: t('Invalid'),
                    isDisabled: order.status === 'Completed',
                },
            ];
            onSubmitFunc = (values) => {
                setLoading(true);

                OrdersAPI.update(order.id, { validateStatus: values.value }).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(t('The validation status has been updated successfully!'));
                    } else {
                        setLoading(false);
                        toast.error(t('The validation status could not be updated successfully!'));
                    }
                });
            };
            break;
        default:
    }

    const FROM_VALIDATION = yup.object().shape({
        value: yup.string().typeError(t('Choose a valid option type!')).required(t('The option is mandatory!')),
    });

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={title}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={handleSubmit}
            width="100%"
            maxWidth="450px"
            onClickButtonClose={() => {
                setOpen(false);
            }}
            buttonFinishLoading={loading}
            buttonCloseDisabled={loading}
        >
            <Formik
                innerRef={formRef}
                initialValues={{ ...INITIAL_FORM_STATE }}
                validationSchema={FROM_VALIDATION}
                onSubmit={(values) => onSubmitFunc(values)}
            >
                <Select name="value" label={label} size="medium">
                    {items?.map((item) => (
                        <MenuItem key={item.id} value={item.id} disabled={item.isDisabled} sx>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </Formik>
        </CustomDialog>
    );
};

export default DropDownModal;
