import React, { useState, useEffect, useRef } from 'react';

import { Formik, Form } from 'formik';
import { RolesAPI } from 'api_darex';

import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';

import { TextField } from 'lib';
import { NavigationButton } from 'lib/components/Buttons/buttons.jsx';
import SinglePermissionComponent from './SinglePermissionComponent';
import ConfirmModal from '../Modals/ConfirmModal';

import { ReactComponent as PlusIcon } from 'resources/svg/administrationPage/plus-icon.svg';
import { ReactComponent as ArrowIcon } from 'resources/svg/menu-navbar/Arrow.svg';
import { ReactComponent as DeleteIconRed } from 'resources/svg/buttonsIcons/deleteIconRed.svg';

import styles from './permissionsComponent.module.scss';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { commonValuesInTwoObjects } from 'utils/functions';

const PermissionsComponent = (props) => {
    const { type, role = null, getAllRoles } = props;

    const { t } = useTranslation();

    // State for the opening and closing the collapse
    const [open, setOpen] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState(false);

    const [permissionGroups, setPermissionGroups] = useState([]);
    const [permissions, setPermissions] = useState([]);

    const [selectedPermissions, setSelectedPermissions] = useState({});
    const [finalPermissions, setFinalPermissions] = useState([]);

    // This state is a copy of role permissinios, to be use when user is changing a role permissions, but not save that changes
    const [rolePermissionsCopy, setRolePermissionsCopy] = useState({
        finalPermissions: [],
        selectedPermissions: {},
    });

    const handleOpenAndCloseOfTheCollapse = () => {
        setOpen(!open);
    };

    /**
     * Ref for the Formik. We use this to be able to access the submit function
     * from formik, outside of the formik FORM
     */
    const formRef = useRef();

    // This function triger the onSubmit function in Formik
    const handleSubmitFormik = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const INITIAL_FORM_STATE = {
        role: role ? role.name : '',
    };

    const FORM_VALIDATION = yup.object().shape({
        role: yup
            .string()
            .typeError(t('You must to declare a valid name for the role!'))
            .trim()
            .min(3, t('The name of the role must be at least 3 characters long!'))
            .required(t('The name of the role is required!')),
    });

    useEffect(() => {
        RolesAPI.getAllPermissions().then((res) => {
            if (res.ok) {
                const orderedPermissions = [];
                setPermissionGroups(res.data.GroupPermissions);

                // For each group we group them by 3 and we sort them by name
                for (const group of res.data.GroupPermissions) {
                    const p = res.data.permissions.filter((perm) => perm.permissionGroupId === group.id);
                    const rowOf3 = [
                        p.filter((perm) => perm.name === 'VIEW')[0],
                        p.filter((perm) => perm.name === 'EDIT')[0],
                        p.filter((perm) => perm.name === 'DELETE')[0],
                    ];
                    orderedPermissions.push(...rowOf3);
                }
                setPermissions(orderedPermissions);

                if (!role) return;

                let RP = [...role.rolePermissions];
                const newSelectedPermissions = {};

                RP.forEach(({ permission }) => {
                    if (!(permission.permissionGroupId in newSelectedPermissions)) {
                        newSelectedPermissions[permission.permissionGroupId] = permission.id;
                    } else {
                        const selectedPermissionIndex = orderedPermissions.findIndex(
                            (p) => p.id === newSelectedPermissions[permission.permissionGroupId]
                        );

                        const potentialPermissionIndex = orderedPermissions.findIndex((p) => p.id === permission.id);

                        if (potentialPermissionIndex > selectedPermissionIndex) {
                            newSelectedPermissions[permission.permissionGroupId] = permission.id;
                        }
                    }
                });

                /**
                 * We set the finalPermissions base on the permissions of every role. This set is for the checkMarks,
                 * to display al the permissions selected in every group
                 */
                setFinalPermissions(RP.map((perm) => perm.permissionId));

                setSelectedPermissions(newSelectedPermissions);

                setRolePermissionsCopy({
                    finalPermissions: RP.map((perm) => perm.permissionId),
                    selectedPermissions: newSelectedPermissions,
                });
            }
        });
    }, [role]);

    /**
     * This function is called on every check of the permission, and decide what permissions must be checked
     * or what permissions must be unchecked and set the finalPermissions with the id of every selected permission.
     * finalPermissions is set here for displaying the number of every permissions selected and to be use in the role create request
     * @param {*} e - syntactic event
     * @param {*} permission - permission as a object
     */
    const handleSelectedPermissions = (e, permission) => {
        const newSelectedPermissions = { ...selectedPermissions };
        const groupId = permission.permissionGroupId;

        if (groupId in selectedPermissions) {
            if (selectedPermissions[groupId] === permission.id) {
                // 1. Clicked on the same permission, so we unselect it
                delete newSelectedPermissions[groupId];
            } else {
                // 2. Clicked on another permission from the same group, so we change the active permission
                newSelectedPermissions[groupId] = permission.id;
            }
        } else {
            // 3. Group has not any permission selected so we activat the one we clicked on
            newSelectedPermissions[groupId] = permission.id;
        }

        setSelectedPermissions(newSelectedPermissions);

        const finalPermissions = [];

        for (const groupId in newSelectedPermissions) {
            const groupIndex = permissionGroups.findIndex((pg) => pg.id === groupId);
            let startIndex = 3 * groupIndex;
            let selectedIndex = permissions.findIndex((p) => p.id === newSelectedPermissions[groupId]);

            /**
             * We add the selected permission in every group
             * and all the permissions before the selected permission from their group
             */
            for (let i = startIndex; i <= selectedIndex; i++) {
                finalPermissions.push(permissions[i].id);
            }
        }

        setFinalPermissions(finalPermissions.sort());
    };

    /**
     * This function is called when the user click on discard button
     */
    const discardHandle = () => {
        setOpen(false);

        // We delete all the selected permissions and finalPermissions, only in the component of the type 'CREATE'
        if (type === 'CREATE') {
            setSelectedPermissions({});
            setFinalPermissions([]);
        } else if (role) {
            setSelectedPermissions(
                commonValuesInTwoObjects(selectedPermissions, rolePermissionsCopy.selectedPermissions)
            );
            setFinalPermissions(rolePermissionsCopy.finalPermissions);
        }
    };

    const handleDelete = () => {
        RolesAPI.delete(role.id).then((res) => {
            if (res.ok) getAllRoles();
            toast.success(t('The role was deleted successfully!'));
        });
    };

    const handleCreateOrEditRole = (values) => {
        if (finalPermissions.length < 1) {
            toast.warning(t('The role must have at least one permission in at least one permissions group!'));
            return;
        }

        const reqBody = {
            name: values.role,
            rolePermissions: finalPermissions.map((permission) => ({
                permissionId: permission,
            })),
        };

        if (type === 'CREATE') {
            // reqBody.rolePermissions = finalPermissions.map((permission) => ({
            //     permissionId: permission,
            // }));

            RolesAPI.create(reqBody).then((res) => {
                if (res.ok) {
                    getAllRoles();
                    discardHandle();
                    toast.success(t('The role was created successfully!'));
                } else {
                    toast.error(t(res.error.response.data.message));
                }
            });
        } else if (type === 'DISPLAY') {
            RolesAPI.edit(role.id, reqBody).then((res) => {
                if (res.ok) {
                    getAllRoles();
                    discardHandle();
                    toast.success(t('The role was edited successfully!'));
                }
            });
        }
    };

    const isStandardPermission = () => {
        let isStandard = true;

        if (role && role.id <= 5) {
            isStandard = false;
        }

        return isStandard;
    };

    return (
        <List component="nav" aria-labelledby="nested-list-subheader" className={styles.borderWrap}>
            {/*
              Header of the component
             */}
            <div className={styles.permissions} onClick={handleOpenAndCloseOfTheCollapse}>
                {/* Name of the role / add role */}
                <div className={styles.permissionsIconWrapper}>
                    {type === 'CREATE' && <PlusIcon />}
                    <div className={styles.headerName}>{type === 'DISPLAY' ? role.name : t('Add role')}</div>
                </div>
                <div className={styles.headerTitle}>
                    {type === 'DISPLAY' ? `${finalPermissions.length} ${t('permissions')}` : t('Define a new role')}
                </div>
                <div className={styles.arrowIconWrapper}>
                    {' '}
                    <ArrowIcon
                        style={{
                            transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                            transitionDuration: '0.4s',
                            width: '13px',
                            height: '13px',
                        }}
                    />
                </div>
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={styles.collapseWrapper}>
                    <div className={styles.inputWrapper}>
                        <Formik
                            innerRef={formRef}
                            enableReinitialize={true}
                            initialValues={{
                                ...INITIAL_FORM_STATE,
                            }}
                            validationSchema={FORM_VALIDATION}
                            onSubmit={(values) => {
                                handleCreateOrEditRole(values);
                            }}
                        >
                            <Form>
                                <TextField
                                    name="role"
                                    label={t('Role name')}
                                    size="medium"
                                    disabled={!isStandardPermission()}
                                />
                            </Form>
                        </Formik>
                    </div>

                    {/* Permission Groups */}
                    <div className={styles.permissionsWrapper}>
                        {permissions.length > 0 &&
                            permissionGroups.map((permissionGroup) => (
                                <div key={permissionGroup.id}>
                                    <SinglePermissionComponent
                                        permissionGroup={permissionGroup}
                                        permissions={permissions.filter(
                                            (p) => p.permissionGroupId === permissionGroup.id
                                        )}
                                        selectedPermission={selectedPermissions[permissionGroup.id]}
                                        handleSelectedPermissions={handleSelectedPermissions}
                                        finalPermissions={finalPermissions}
                                        canBeEdited={isStandardPermission()}
                                    />
                                </div>
                            ))}
                    </div>

                    {isStandardPermission() && (
                        <div className={styles.controlsWrapper}>
                            <div>
                                {type === 'DISPLAY' && (
                                    <NavigationButton
                                        startIcon={<DeleteIconRed />}
                                        color="red"
                                        onClick={() => setDeleteModalState(true)}
                                    >
                                        {t('Delete')}
                                    </NavigationButton>
                                )}
                            </div>

                            <div>
                                <NavigationButton color="grey" onClick={discardHandle}>
                                    {t('Discard')}
                                </NavigationButton>
                                <NavigationButton color="blue" onClick={handleSubmitFormik}>
                                    {t('Save')}
                                </NavigationButton>
                            </div>
                        </div>
                    )}
                </div>
            </Collapse>
            <ConfirmModal
                text={t('Are you sure you want to delete this role?')}
                open={deleteModalState}
                onClickButtonYes={() => handleDelete()}
                setOpen={setDeleteModalState}
            />
        </List>
    );
};

export default PermissionsComponent;
