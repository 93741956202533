import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import styles from './CreateOffer.module.scss';
import { DatePicker, TextField } from 'lib';
import { Formik, Form, Field } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { UtilityButton } from 'lib/components/Buttons/buttons';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'lib/components/Buttons/buttons';
import SeeUnitsModal from 'nomenclators/Vehicles/SeeUnitsModal/SeeUnitsModal';
import radioStyles from 'styles/radioGroup.module.scss';
import PropTypes from 'prop-types';

const Finalize = ({ formikRef, units }) => {
    const [seeUnits, setSeeUnits] = useState(false);
    const { t } = useTranslation();

    const initialValues = {
        driveTest: 'DA',
        reContactDate: new Date(),
        driveTestDate: new Date(),
        comment: '',
    };

    const docs = [
        {
            id: 1,
            name: 'Buletin',
            imgName: 'buletin.jpg',
        },
        {
            id: 2,
            name: 'Pasaport',
            imgName: 'passport.jpg',
        },
    ];

    return (
        <React.Fragment>
            <div className={styles.container_4}>
                <Formik innerRef={formikRef} initialValues={initialValues}>
                    {(props) => (
                        <Form>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', mb: 5 }}>
                                <Typography variant="h6">Test Drive</Typography>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <div role="group" className={radioStyles.radioGroupWrapper}>
                                        <label>
                                            <Field type="radio" name="driveTest" value="DA" />
                                            <div className={radioStyles.labelName}>{t('Yes')}</div>
                                        </label>
                                        <label>
                                            <Field type="radio" name="driveTest" value="NU" />
                                            <div className={radioStyles.labelName}>{t('No')}</div>
                                        </label>

                                        <label>
                                            <Field type="radio" name="driveTest" value="PROGRAMAT" />
                                            <div className={radioStyles.labelName}>{t('Scheduled at')}</div>
                                        </label>
                                    </div>

                                    <div
                                        style={
                                            props.values.driveTest !== 'PROGRAMAT'
                                                ? {
                                                      opacity: 0.25,
                                                      pointerEvents: 'none',
                                                  }
                                                : {}
                                        }
                                    >
                                        <DatePicker name="driveTestDate" label={t('Drive test date')} />
                                    </div>
                                </Box>
                            </Box>

                            {/* Date */}
                            <Box sx={{ mb: 5 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    {t('Recontact date')}
                                </Typography>
                                <DatePicker name="reContactDate" label="" />
                            </Box>

                            {/* Comments */}
                            <Box sx={{ mb: 5 }}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    {t('Additional comments on offer')}
                                </Typography>

                                <TextField name="comment" multiline rows={5} />
                            </Box>
                        </Form>
                    )}
                </Formik>

                {/* Documents */}
                <Box sx={{ mb: 5 }}>
                    {/* <Typography variant="h6" sx={{ mb: 2 }}>
                        {t('Documents')}
                    </Typography> */}

                    {/* <ul>
                        {docs.map((doc) => (
                            <DocumentRowDelete doc={doc} />
                        ))}
                    </ul> */}

                    <div className={styles.buttonContainer}>
                        <PrimaryButton onClick={() => setSeeUnits(true)}>{t('Visualize units')}</PrimaryButton>
                        {/* <UtilityButton startIcon={<AddIcon />}>{t('ADD DOCUMENT')}</UtilityButton> */}
                    </div>
                </Box>
            </div>

            <SeeUnitsModal open={seeUnits} setOpen={setSeeUnits} units={units} />
        </React.Fragment>
    );
};

Finalize.propTypes = {
    formikRef: PropTypes.object.isRequired,
    units: PropTypes.array.isRequired,
};

export default Finalize;
