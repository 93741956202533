const versionsInfo = [
    {
        typeVersioning: 'Major',
        version: '1.1.0',
        description: 'This is the first stable version of the platform.',
        features: [],
        bugsFixed: [],
    },
    {
        typeVersioning: 'Major',
        version: '1.0.0 Beta',
        description: 'This is the initial version of the platform.',
        features: [
            'The administrator has access to the entire platform with full rights, including: users, offers, tasks, objectives, clients, bidding, nomenclatures. He can create, edit, lock and reset a user account.',
            'The sales manager(director) can see sales managers, advisors, offers, tasks, targets, customers. He can edit an offer (accept, reject, re-offer), add tasks, targets.',
            'The sales manager(chief) can see advisors, offers, tasks, targets, customers. He can edit an offer (accept, reject, re-offer), add tasks, targets.',
            'The advisor can view the bids, tasks, targets, clients and bidding sections. He can add a quote, tasks and clients.',
            'The sales assistant is in charge of the quotations and orders sections.',
            'There are 4 possible customer cases: (1) new customer, (2) existing active customer on the same car brand, (3) existing customer but on another brand, (4) existing "sleeping" customer.',
            'The user can create a customer from scratch or perform a search for that customer and then continue the creation process with existing data.',
            'A customer can be added as an individual or a legal entity.',
            'The administrator is the only one who has access to the nomenclatures and can add, edit and delete vehicles (make, model, colour, engine), work points and customer sources.',
            'Tasks can be divided into 4 categories: scheduled drive tests, GDPR agreement, customer recontact, other types of tasks.',
            'Targets can be added per advisor, per advisor brand (Dacia, Renault, Nissan), in turn.',
            'The offer can be created by choosing a customer or adding a new one, adding vehicles to the offer, final invoice details. The offer can be accepted, rejected and re-offered.',
            "Daily report for the advisor containing the report recipients (Advisor), the date the report is sent (every morning at 8:00 a.m.), the period for which the report is generated (previous day and some of the next day's objectives) and the content of the report (the advisor's activity and objectives).",
            'Daily report for report recipients, Head of Sales and Sales Manager, the date the report is sent (every morning at 8:10 a.m.), the period for which the report is generated (previous day) and the content of the report (activity of each individual advisor).',
            'Weekly report for report recipients, Head of Sales and Director of Sales, the date the report is sent (every Monday at 8:00 a.m.), the period for which the report is generated (previous week) and the content of the report (activity of each individual advisor).',
            'Cumulative weekly report for report recipients, Head of Sales, Sales Manager and Administrator, report submission date (every Monday at 8:00 a.m.), period over which the report is generated (previous week) and report content (cumulative sales teams, per brand).',
            'Monthly report for report recipients, Head of Sales, Sales Manager and Administrator, date of report submission (on the first day of the month at 8:00 a.m.), period over which the report is generated (previous month) and report content (activity of each individual advisor).',
            'Cumulative monthly report for report recipients, Sales Manager, Sales Director and Administrator, date of report submission (first day of the month at 8:00 a.m.), period for which the report is generated (previous month) and report content (cumulative sales teams, per brand).',
            'It will be possible to export the database automatically on a monthly, quarterly and annual basis with customer and transaction data.',
            'Automation is achieved by automatically sending reports by e-mail, exporting the database, notifications related to some events longer in duration and at the same time, the system will automatically generate some tasks depending on the activities undertaken by the user within the platform.',
        ],
        bugsFixed: [],
    },
];

export default versionsInfo;
