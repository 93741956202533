import React, { useEffect, useState, useCallback } from 'react';
import styles from './ClientZones.module.scss';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';
import { ModifySmallButton } from 'lib/components/Buttons/buttons';
import { Comments, LoadingSpinner } from 'lib';
import { UtilityButton } from 'lib/components/Buttons/buttons';
import AddIcon from '@mui/icons-material/Add';
import UploadDocument from 'pages/Clients/modals/UploadDocument';
import { useUser } from 'hooks/useUser';
import { Chip } from '@mui/material';
import { OrdersAPI } from 'api_darex';
import DropDownModal from 'pages/Orders/DropDownModal';
import DatesModal from 'pages/Orders/DatesModal';
import ChassisNumberModal from 'pages/Orders/ChassisNumberModal';
import ConfirmModal from 'lib/components/Modals/ConfirmModal';
import { toast } from 'react-toastify';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DocumentRow from 'lib/components/DocumentRowNew/DocumentRow';

const ClientOrderDetails = ({ idOrder, setDisplayOrder }) => {
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const [openAddDocument, setOpenAddDocument] = useState(false);
    const [openAddImage, setOpenAddImage] = useState(false);
    const [useCase, setUseCase] = useState(null);
    const [openDropModal, setOpenDropModal] = useState(false);
    const [openDate, setOpenDate] = useState(false);
    const [openSeries, setOpenSeries] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [openDelete, setOpenDelete] = useState(false);
    const { t } = useTranslation();
    const { can, user } = useUser();

    const getOrder = useCallback(() => {
        OrdersAPI.getById(idOrder).then((res) => {
            setOrder(res.data);
            setLoading(false);
        });
    }, [idOrder]);

    const getDocuments = useCallback(() => {
        OrdersAPI.getDocuments(idOrder).then((res) => {
            setDocuments(res.data);
        });
    }, [idOrder]);

    useEffect(() => {
        getOrder();
        getDocuments();
    }, [getDocuments, getOrder]);

    const brandsName = {
        1: 'Dacia',
        2: 'Renault',
        3: 'Nissan',
    };

    const vehicleType = {
        personal: t('Personal Vehicle'),
        utility: t('Utility Vehicle'),
    };

    const engineType = {
        thermic: t('Thermic'),
        electrical: t('Electric'),
        hibrid: t('Electric'),
    };

    const statusType = {
        Created: t('Created'),
        OrderLaunch: t('Order launched'),
        AlocatedChassisNumber: t('Assigned chassis number'),
        OutOfProduction: t('Out of production'),
        ArrivedInThePark: t('Arrived in the park'),
        Delivered: t('Delivered'),
        Completed: t('Completed'),
        Cancelled: t('Cancelled'),
    };

    const validateType = {
        Created: t('Created'),
        Unverified: t('Unverified'),
        Valid: t('Valid'),
        Invalid: t('Invalid'),
    };

    const hasImage = () => {
        return documents.findIndex((doc) => doc.tag === 'image');
    };

    const onSubmitFunctionDoc = ({ values, file }) => {
        OrdersAPI.addDocument(order.id, values.documentName, 'file', file).then((res) => {
            if (res.ok) {
                toast.success(t('Document was uploaded successfully!'));
                getDocuments();
            } else {
                toast.error(t('Document could not be uploaded successfully!'));
            }
        });
    };

    const onSubmitFunctionImage = ({ values, file }) => {
        if (hasImage() >= 0) {
            OrdersAPI.updateDocument(documents[hasImage()].id, 'image', values.documentName, file).then((res) => {
                if (res.ok) {
                    toast.success(t('Document was updated successfully!'));
                    getDocuments();
                } else {
                    toast.error(t('Document could not be updated successfully!'));
                }
            });
        } else {
            OrdersAPI.addDocument(order.id, values.documentName, 'image', file).then((res) => {
                if (res.ok) {
                    toast.success(t('Document was uploaded successfully!'));
                    getDocuments();
                } else {
                    toast.error(t('Document could not be uploaded successfully!'));
                }
            });
        }
    };

    const onDeleteFunction = ({ doc }) => {
        OrdersAPI.removeDocument(doc.id).then((res) => {
            if (res.ok) {
                toast.success(t('Document was deleted successfully!'));
                getDocuments();
            } else {
                toast.error(t('Document could not be deleted successfully!'));
            }
        });
    };

    const handleDownloadDocument = (path) => {
        OrdersAPI.getDocumentByPath(path).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', path);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });
    };

    const dataOneFirstColumn = [
        {
            id: 0,
            label: 'ID',
            value: order?.autoincrement,
        },
        {
            id: 1,
            label: t('Brand'),
            value: brandsName[order?.brandId],
        },
        {
            id: 2,
            label: 'Model',
            value: order?.model?.name,
        },
        {
            id: 3,
            label: t('Color'),
            value: order?.ModelColor?.color?.name,
        },
        {
            id: 4,
            label: t('Status'),
            value: statusType[order?.status],
        },
    ];

    const dataOneSecondColumn = [
        {
            id: 0,
            label: t('Engine'),
            value: order?.ModelEngine?.engine?.name,
        },
        {
            id: 1,
            label: t('Category'),
            value: engineType[order?.ModelEngine?.engine?.type],
        },
        {
            id: 2,
            label: 'Rabla',
            value: order?.oldCar === 'DA' ? t('YES') : order?.oldCar === 'NU' ? t('NO') : '',
        },
        {
            id: 3,
            label: t('Tip'),
            value: vehicleType[order?.model?.type],
        },
        {
            id: 4,
            label: t('Creation date'),
            value: dayjs(order?.createdAt).format('DD MMM YYYY'),
        },
    ];

    const dataThree = [
        {
            id: 0,
            label: t('Date of delivery of advance payment documents'),
            disabled: order?.isDeleted || user.roleId === '5' || order?.status === 'Completed',
            value: order?.dateAdvancePaymentDoc ? dayjs(order.dateAdvancePaymentDoc).format('DD MMM YYYY') : '-',
            hasUploadImage: false,
            onClick: () => {
                setUseCase('advancePaymentDoc');
                setOpenDate(true);
            },
        },
        {
            id: 1,
            label: t('Advance billing date'),
            value: order?.dateBillingAdvancePayment
                ? dayjs(order.dateBillingAdvancePayment).format('DD MMM YYYY')
                : '-',
            hasUploadImage: false,
            disabled: order?.isDeleted || user.roleId === '5' || order?.status === 'Completed',
            onClick: () => {
                setUseCase('billingAdvancePayment');
                setOpenDate(true);
            },
        },
        {
            id: 2,
            label: t('Estimated manufacturing data'),
            disabled: order?.isDeleted || user.roleId === '5' || order?.status === 'Completed',
            value: order?.dateEstimationProduction ? dayjs(order.dateEstimationProduction).format('DD MMM YYYY') : '-',
            hasUploadImage: false,
            onClick: () => {
                setUseCase('estimationProduction');
                setOpenDate(true);
            },
        },
        {
            id: 3,
            label: t('Ex-factory date'),
            disabled: order?.isDeleted || user.roleId === '5' || order?.status === 'Completed',
            value: order?.dateExitProduction ? dayjs(order.dateExitProduction).format('DD MMM YYYY') : '-',
            hasUploadImage: true,
            onClick: () => {
                setUseCase('exitProduction');
                setOpenDate(true);
            },
        },
        {
            id: 4,
            label: t('Darex park entry date'),
            disabled: order?.isDeleted || user.roleId === '5' || order?.status === 'Completed',
            value: order?.dateOfArrivalPark ? dayjs(order.dateOfArrivalPark).format('DD MMM YYYY') : '-',
            hasUploadImage: false,
            onClick: () => {
                setUseCase('arrivalPark');
                setOpenDate(true);
            },
        },
        {
            id: 5,
            label: t('Date set for delivery'),
            disabled: order?.isDeleted || user.roleId === '5' || order?.status === 'Completed',
            value: order?.dateFixedDelivery ? dayjs(order.dateFixedDelivery).format('DD MMM YYYY') : '-',
            hasUploadImage: false,
            onClick: () => {
                setUseCase('fixedDelivery');
                setOpenDate(true);
            },
        },
    ];

    const dataFour = [
        {
            id: 0,
            label: t('Transfer request date'),
            disabled: order?.isDeleted || user.roleId === '5' || order?.status === 'Completed',
            value: order?.dateTransferRequest ? dayjs(order.dateTransferRequest).format('DD MMM YYYY') : '-',
            onClick: () => {
                setUseCase('transferRequest');
                setOpenDate(true);
            },
        },
        {
            id: 1,
            label: t('Cancellation billing date'),
            disabled: order?.isDeleted || user.roleId === '5' || order?.status === 'Completed',
            value: order?.dateStornoBill ? dayjs(order.dateStornoBill).format('DD MMM YYYY') : '-',
            onClick: () => {
                setUseCase('stornoBill');
                setOpenDate(true);
            },
        },
        {
            id: 2,
            label: t('Rebilling date'),
            disabled: order?.isDeleted || user.roleId === '5' || order?.status === 'Completed',
            value: order?.dateRebilling ? dayjs(order.dateRebilling).format('DD MMM YYYY') : '-',
            onClick: () => {
                setUseCase('rebilling');
                setOpenDate(true);
            },
        },
        {
            id: 3,
            label: t('Final invoice date'),
            disabled: order?.isDeleted || user.roleId === '5' || order?.status === 'Completed',
            value: order?.dateFinalBill ? dayjs(order.dateFinalBill).format('DD MMM YYYY') : '-',
            onClick: () => {
                setUseCase('finalBill');
                setOpenDate(true);
            },
        },
        {
            id: 4,
            label: t('Date of delivery made'),
            disabled: order?.isDeleted || user.roleId === '5' || order?.status === 'Completed',
            value: order?.deliveryDate ? dayjs(order.deliveryDate).format('DD MMM YYYY') : '-',
            onClick: () => {
                setUseCase('delivery');
                setOpenDate(true);
            },
        },
    ];

    const handleCompleteOrder = () => {
        OrdersAPI.update(order.id, { status: 'Completed' }).then((res) => {
            if (res.ok) {
                getOrder();
                setOpenConfirm(false);
                toast.success(t('The order has been marked as completed!'));
            } else {
                toast.error(t('The order could not be marked as completed!'));
            }
        });
    };

    const handleDeleteOrder = () => {
        OrdersAPI.delete(order.id).then((res) => {
            if (res.ok) {
                getOrder();
                setOpenDelete(false);
                toast.success(t('The order has been marked as completed!'));
            } else {
                toast.error(t('The order could not be marked as completed!'));
            }
        });
    };

    return loading ? (
        <LoadingSpinner loading={loading} margin="0 auto" />
    ) : (
        <>
            <div className={styles.clientOrderWrapper}>
                <div className={styles.headerWrapper}>
                    <div className={styles.navigationWrapper}>
                        <Typography
                            onClick={() => setDisplayOrder(false)}
                            variant="pageBack"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                '&:hover': {
                                    cursor: 'pointer',
                                    color: '#5b80ba',
                                },
                            }}
                        >
                            <ArrowBackIosNewIcon
                                style={{
                                    marginRight: '5px',
                                    fontSize: '1rem',
                                }}
                            />
                            {t('Back')}
                        </Typography>
                        <Typography variant="clientOrderText">{`${t('Order')} #${
                            order.offer.autoincrement
                        }`}</Typography>
                    </div>
                    <div className={styles.buttonsWrapper}>
                        <div className={styles.headerLeft}>
                            <div className={styles.headerRow}>
                                <Typography variant="orderStatus">{t('Order status')}</Typography>
                            </div>

                            <Chip
                                sx={{
                                    backgroundColor:
                                        order.isDeleted === true
                                            ? '#FF4B55'
                                            : order.status === 'Created' || order.status === 'Opened'
                                            ? '#5B80BA'
                                            : order.status === 'Completed' || order.status === 'Delivered'
                                            ? '#009C10'
                                            : '#EFAC00',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor:
                                            order.isDeleted === true
                                                ? '#a50009'
                                                : order.status === 'Created' || order.status === 'Opened'
                                                ? '#293f61'
                                                : order.status === 'Completed' || order.status === 'Delivered'
                                                ? '#004e08'
                                                : '#775600',
                                    },
                                    '&:disabled': {
                                        opacity: '0.38',
                                    },
                                }}
                                label={`${statusType[order.status]}`}
                                onClick={() => {
                                    setUseCase('statusOrder');
                                    setOpenDropModal(true);
                                }}
                                disabled={!can(5) || order.status === 'Completed' || order?.isDeleted}
                            />
                        </div>
                        <div className={styles.headerRight}>
                            <div className={styles.headerRow}>
                                <Typography variant="orderStatus">{t('Validation status')}</Typography>
                            </div>
                            <Chip
                                sx={{
                                    backgroundColor:
                                        order.validateStatus === 'Valid'
                                            ? '#009C10'
                                            : order.validateStatus === 'Invalid'
                                            ? '#FF4B55'
                                            : '#5B80BA',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor:
                                            order.validateStatus === 'Valid'
                                                ? '#009C10'
                                                : order.validateStatus === 'Invalid'
                                                ? '#a50009'
                                                : '#293f61',
                                    },
                                    '&:disabled': {
                                        opacity: '0.38',
                                    },
                                }}
                                label={`${validateType[order.validateStatus]}`}
                                onClick={() => {
                                    setUseCase('statusValidate');
                                    setOpenDropModal(true);
                                }}
                                disabled={
                                    !can(5) || order.status === 'Completed' || order?.isDeleted || user.roleId !== '5'
                                }
                            />
                        </div>
                        {order.status !== 'Completed' && can(5) ? (
                            <Chip
                                disabled={
                                    order.validateStatus !== 'Valid' ||
                                    order.status !== 'Delivered' ||
                                    order.deliveryDate === null ||
                                    order.dateBillingAdvancePayment === null ||
                                    order.chassisNumber === null ||
                                    order.dateExitProduction === null ||
                                    order.dateOfArrivalPark === null ||
                                    order.deliveryDate === null ||
                                    order.dateFinalBill === null ||
                                    order.isDeleted
                                }
                                sx={{
                                    backgroundColor: '#009C10',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#004e08',
                                    },
                                }}
                                label={`${t('Completion')}`}
                                onClick={() => {
                                    setOpenConfirm(true);
                                }}
                            />
                        ) : (
                            <Typography variant="objectivesName" sx={{ color: '#009C10' }}>
                                {t('The order is completed')}
                            </Typography>
                        )}
                        {order.isDeleted === false && can(5) ? (
                            <Chip
                                disabled={order.status === 'Completed' || (user.roleId !== '5' && user.roleId !== '1')}
                                sx={{
                                    backgroundColor: '#FF4B55',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#a50009',
                                    },
                                }}
                                label={`${t('Cancel order')}`}
                                onClick={() => {
                                    setOpenDelete(true);
                                }}
                            />
                        ) : (
                            <Typography variant="objectivesName" sx={{ color: '#FF4B55' }}>
                                {t('The order is canceled')}
                            </Typography>
                        )}
                    </div>
                </div>
                <Divider />
                <div className={styles.dataOne}>
                    <div className={styles.column}>
                        {dataOneFirstColumn.map((item) => {
                            return (
                                <div className={styles.row} key={item.id}>
                                    <div className={styles.cellLeft}>
                                        <Typography variant="orderLabelText">{item.label}:</Typography>
                                    </div>
                                    <div className={styles.cellRight}>
                                        <Typography variant="orderDataText">{item.value}</Typography>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.column}>
                        {dataOneSecondColumn.map((item) => {
                            return (
                                <div className={styles.row} key={item.id}>
                                    <div className={styles.cellLeft}>
                                        <Typography variant="orderLabelText">{item.label}:</Typography>
                                    </div>
                                    <div className={styles.cellRight}>
                                        <Typography variant="orderDataText">{item.value}</Typography>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Divider />
                <div className={styles.dataTwo}>
                    <div className={styles.series}>
                        <Typography variant="orderLabelText">{t('Body series')}:</Typography>
                        <Typography variant="orderDataText">
                            {order.chassisNumber ? order.chassisNumber : '-'}
                        </Typography>
                    </div>
                    {can(5) && (
                        <ModifySmallButton
                            disabled={order.isDeleted || user.roleId === '5' || order?.status === 'Completed'}
                            onClick={() => setOpenSeries(true)}
                        >
                            {t('Modify')}
                        </ModifySmallButton>
                    )}
                </div>
                <Divider />
                <div className={styles.dataThree}>
                    {dataThree.map((item) => {
                        return (
                            <div className={styles.row} key={item.id}>
                                <div className={styles.left}>
                                    <Typography variant="orderLabelText">{item.label}:</Typography>
                                    <Typography variant="orderDataText">{item.value}</Typography>
                                </div>
                                <div className={styles.right}>
                                    <div className={styles.buttons}>
                                        {item.hasUploadImage && hasImage() >= 0 && (
                                            <ModifySmallButton
                                                onClick={() => handleDownloadDocument(documents[hasImage()].file.path)}
                                            >
                                                {t('Download image')}
                                            </ModifySmallButton>
                                        )}
                                        {item.hasUploadImage && can(5) && (
                                            <ModifySmallButton
                                                onClick={() => setOpenAddImage(true)}
                                                disabled={item.disabled}
                                            >
                                                {hasImage() >= 0 ? t('Change image') : t('Upload image')}
                                            </ModifySmallButton>
                                        )}
                                        {can(5) && (
                                            <ModifySmallButton onClick={item.onClick} disabled={item.disabled}>
                                                {t('Modify')}
                                            </ModifySmallButton>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <Divider />
                <div className={styles.dataFour}>
                    {dataFour.map((item) => {
                        return (
                            <div className={styles.row} key={item.id}>
                                <div className={styles.left}>
                                    <Typography variant="orderLabelText">{item.label}:</Typography>
                                    <Typography variant="orderDataText">{item.value}</Typography>
                                </div>
                                <div className={styles.right}>
                                    {can(5) && (
                                        <ModifySmallButton disabled={item.disabled} onClick={item.onClick}>
                                            {t('Modify')}
                                        </ModifySmallButton>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <Divider />
                <div className={styles.documentsWrapper}>
                    <div className={styles.title}>
                        <Typography variant="h6">{t('Documents')}</Typography>
                    </div>
                    <div className={styles.documents}>
                        {documents
                            ?.filter((doc) => doc.tag === 'file')
                            .map((doc, index) => {
                                return (
                                    <DocumentRow
                                        key={index}
                                        doc={doc}
                                        permission={4}
                                        onDeleteFunc={onDeleteFunction}
                                        onDownloadFunction={handleDownloadDocument}
                                        canDelete={!order?.isDeleted}
                                    />
                                );
                            })}
                    </div>
                    {can(5) && (
                        <div className={styles.button}>
                            <UtilityButton
                                startIcon={<AddIcon />}
                                onClick={() => setOpenAddDocument(true)}
                                disabled={order?.isDeleted}
                            >
                                {t('ADD DOCUMENT')}
                            </UtilityButton>
                        </div>
                    )}
                </div>
                <div className={styles.comments}>
                    <Comments type="offerModel" offerModelId={order.id} disabled={order?.isDeleted} />
                </div>
            </div>
            <UploadDocument
                open={openAddDocument}
                setOpen={setOpenAddDocument}
                onSubmitFunc={onSubmitFunctionDoc}
                type="file"
            />
            <UploadDocument
                open={openAddImage}
                setOpen={setOpenAddImage}
                onSubmitFunc={onSubmitFunctionImage}
                type="image"
            />
            <DropDownModal
                order={order}
                getOrder={getOrder}
                useCase={useCase}
                open={openDropModal}
                setOpen={setOpenDropModal}
            />
            <DatesModal order={order} getOrder={getOrder} useCase={useCase} open={openDate} setOpen={setOpenDate} />
            <ChassisNumberModal order={order} getOrder={getOrder} open={openSeries} setOpen={setOpenSeries} />
            <ConfirmModal
                onClickButtonYes={handleCompleteOrder}
                open={openConfirm}
                setOpen={setOpenConfirm}
                text={t('Are you sure you want to mark this order as complete?')}
            />
            <ConfirmModal
                onClickButtonYes={handleDeleteOrder}
                open={openDelete}
                setOpen={setOpenDelete}
                text={t('Are you sure you want to cancel this order?')}
            />
        </>
    );
};

export default ClientOrderDetails;
