import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Formik, Form } from 'formik';
import { emailRegex, phoneRegex } from 'utils/regex';
import * as yup from 'yup';
import { TextField } from 'lib';
import { Grid, Typography } from '@mui/material';
import { UtilityButton } from 'lib/components/Buttons/buttons';
import { toast } from 'react-toastify';

const AddRepresentativeForm = ({
    infoRepr = {},
    setInfoRepr = () => {},
    setRepresentativesList = () => {},
    representatives,
    setRepresentatives = () => {},
}) => {
    const { t } = useTranslation();
    const { vw } = useWindowDimensions();
    const formRefClients2 = useRef();

    const initialValues = {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        ...infoRepr,
    };

    const validationSchema = yup.object().shape({
        firstName: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(3, t('First name must contain at least three characters'))
            .required(t('First name is mandatory')),
        lastName: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(3, t('Last name must contain at least three characters'))
            .required(t('Last name is mandatory')),

        email: yup
            .string()
            .trim()
            .matches(emailRegex, t('Email you introduced is not valid'))
            .required(t('Email is mandatory')),

        phone: yup
            .string()
            .trim()
            .matches(phoneRegex, t('The phone number you introduced is not a valid one'))
            .required(t('Phone number is mandatory')),
    });

    const handleSubmitRepresentative = () => {
        const obj = { ...formRefClients2.current.values };

        if (obj.id) {
            // edit
            const objIndex = representatives.findIndex((r) => r.id === obj.id);
            const shallowInfo = [...representatives];
            shallowInfo[objIndex] = obj;
            setRepresentatives(shallowInfo);
            toast.success(t('Representative edited successfully'));
        } else {
            // create
            const newId = Date.now();
            obj.id = newId;
            setRepresentatives([...representatives, obj]);
            toast.success(t('Representative added successfully'));
        }

        setInfoRepr({});
        setRepresentativesList('listareReprezentanti');
    };

    return (
        <div>
            <Formik
                innerRef={formRefClients2}
                initialValues={{
                    ...initialValues,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmitRepresentative}
            >
                <Form>
                    <Grid
                        container
                        spacing={2}
                        justifyContent={'space-between'}
                        direction={vw < 670 ? 'column' : 'row'}
                        sx={{ marginBottom: '16px' }}
                    >
                        <Grid item xs={4}>
                            <TextField name="firstName" label={t('First name*')} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField name="lastName" label={t('Last name*')} />{' '}
                        </Grid>
                        <Grid item xs={4}>
                            <TextField name="phone" label={t('Phone number*')} />{' '}
                        </Grid>
                        <Grid item xs={4}>
                            <TextField name="email" label={t('Email*')} />{' '}
                        </Grid>
                        <Grid item xs={8}>
                            <TextField name="representativeRole" label={t('Representative Role*')} />{' '}
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="clientData"> {t('Representative Details')} </Typography>
                            <div style={{ marginTop: '16px' }}>
                                <TextField name="details" placeholder={t('Hobbies, expectations...')} />
                            </div>
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: '32px', display: 'flex', gap: '1rem' }}>
                        <UtilityButton variant="contained" type="submit">
                            {Object.keys(infoRepr).length > 0 ? t('Edit') : t('Add')}
                        </UtilityButton>
                        {Object.keys(infoRepr).length > 0 && (
                            <UtilityButton
                                variant="contained"
                                type="submit"
                                onClick={() => {
                                    setRepresentativesList('listareReprezentanti');
                                    setInfoRepr({});
                                }}
                            >
                                {t('Cancel')}
                            </UtilityButton>
                        )}
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

export default AddRepresentativeForm;
