import { Typography } from '@mui/material';
import React from 'react';
import styles from './CreateOffer.module.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

const S = () => <span style={{ marginInline: 16, opacity: 0.5 }}>&#9679;</span>;

const ClientInfo = ({ client }) => {
    const { t } = useTranslation();

    return Boolean(client.id) ? (
        <React.Fragment>
            {client.type === 'Individual' ? (
                <div className={styles.container_2}>
                    <ul>
                        <li>
                            <div>
                                {/* Title */}
                                <Typography variant="clientData">
                                    {t('Name')} / {t('Phone')} / E-mail
                                </Typography>

                                {/* Data */}
                                <Typography variant="clientListMember">
                                    {[
                                        `${client.firstName} ${client.lastName}`,
                                        <S />,
                                        client.phone,
                                        <S />,
                                        client.email,
                                    ]}
                                </Typography>
                            </div>
                        </li>
                        <li>
                            <div>
                                {/* Title */}
                                <Typography variant="clientData">{t('Address')}</Typography>

                                {/* Data */}
                                <Typography variant="clientListMember">
                                    {[
                                        client?.state?.name,
                                        <S />,
                                        client?.city?.name,
                                        <S />,
                                        `Str. ${client?.street || ' - '}`,
                                        <S />,
                                        `Bl. ${client?.apartmentBuilding || ' - '},  Sc. ${
                                            client?.staircase || ' - '
                                        }, Et. ${client?.floor || ' - '},  Ap. ${client?.apartmentNr || ' - '}`,
                                        <S />,
                                        `CP. ${client.postalCode || ' - '}`,
                                    ]}
                                </Typography>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Typography variant="clientData">{t('Client source')}</Typography>
                                <Typography variant="clientListMember">{client.clientSource.name}</Typography>
                            </div>
                            <div>
                                <Typography variant="clientData">{t('Brand')}</Typography>
                                <Typography variant="clientListMember">{client.brand.name}</Typography>
                            </div>
                            <div>
                                <Typography variant="clientData">{t('Counselor')}</Typography>
                                <Typography variant="clientListMember">{client.user.firstName}</Typography>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Typography variant="clientData">{t('Representative Details')}</Typography>
                                <Typography variant="clientListMember">{client.details || ' - '}</Typography>
                            </div>
                        </li>
                    </ul>
                </div>
            ) : (
                <div className={styles.container_2}>
                    <ul>
                        <li>
                            <div>
                                <Typography variant="clientData">{t('Firm name')}</Typography>
                                <Typography variant="clientListMember">{client.firmName}</Typography>
                            </div>
                            <div>
                                <Typography variant="clientData">{t('CUI')}</Typography>
                                <Typography variant="clientListMember">{client.CUI}</Typography>
                            </div>
                        </li>

                        <li>
                            <div>
                                <Typography variant="clientData">{t('Address')}</Typography>
                                <Typography variant="clientListMember">
                                    {[
                                        client?.state?.name,
                                        <S />,
                                        client?.city?.name,
                                        <S />,
                                        `Str. ${client?.street || ' - '}`,
                                        <S />,
                                        `Bl. ${client?.apartmentBuilding || ' - '},  Sc. ${
                                            client?.staircase || ' - '
                                        }, Et. ${client?.floor || ' - '},  Ap. ${client?.apartmentNr || ' - '}`,
                                        <S />,

                                        `CP. ${client.postalCode || ' - '}`,
                                    ]}
                                </Typography>
                            </div>
                        </li>
                        <li>
                            <div>
                                <Typography variant="clientData">{t('Client source')}</Typography>
                                <Typography variant="clientListMember">{client.clientSource.name}</Typography>
                            </div>
                            <div>
                                <Typography variant="clientData">{t('Brand')}</Typography>
                                <Typography variant="clientListMember">{client.brand.name}</Typography>
                            </div>
                            <div>
                                <Typography variant="clientData">{t('Counselor')}</Typography>
                                <Typography variant="clientListMember">{client.user.firstName}</Typography>
                            </div>
                        </li>
                    </ul>
                </div>
            )}
        </React.Fragment>
    ) : (
        <Box
            sx={{
                background: '#f4f4f4',
                borderRadius: '10px',
                padding: '64px 16px',
                textAlign: 'center',
            }}
        >
            <Typography>{t('No client selected')}</Typography>
        </Box>
    );
};

ClientInfo.propTypes = {
    client: PropTypes.object.isRequired,
};

export default ClientInfo;
