import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDialog } from 'lib';
import AddClientMain from '../AddClientMain';

const EditAdviser = ({ open, setOpen }) => {
    const { t } = useTranslation();

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            width="600px"
            onClickButtonClose={() => {
                setOpen(false);
            }}
        >
            <AddClientMain />
        </CustomDialog>
    );
};

export default EditAdviser;
