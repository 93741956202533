import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDialog } from 'lib';

import { MenuItem, TextField } from '@mui/material';
import styles from './Offers.module.scss';
import { ClientsAPI, VehiclesAPI, UsersAPI } from 'api_darex';
import DeleteIcon from '@mui/icons-material/Delete';
import { useUser } from 'hooks/useUser';

const FilterOffers = ({
    open,
    setOpen,
    filterData,
    setFilterData,
    setTriggerFilter,
    triggerFilter,
    triggerRefetch,
    setTriggerRefetch,
    setIsFilter,
    setData,
    setLoading,
    resetSearch,
    resetFilter,
    isFilter,
    userState = null,
}) => {
    const { t } = useTranslation();
    const [clients, setClients] = useState([]);
    const [models, setModels] = useState([]);
    const [counselors, setCounselors] = useState([]);
    const { user } = useUser();

    const brands = [
        { id: 1, name: 'Dacia' },
        { id: 2, name: 'Renault' },
        { id: 3, name: 'Nissan' },
    ];

    const displayedBrands = () => {
        if (userState) {
            return brands.filter((brand) =>
                userState?.UserBrand.map((uBrand) => Number(uBrand.brandId)).includes(brand.id)
            );
        } else {
            return brands.filter((brand) => user.UserBrand.map((uBrand) => Number(uBrand.brandId)).includes(brand.id));
        }
    };

    const status = [
        { label: t('Open'), value: 'Open' },
        { label: t('Accepted'), value: 'Accepted' },
        { label: t('Refused'), value: 'Refused' },
        { label: t('Reoffered'), value: 'ReOffered' },
    ];

    const getCounselors = () => {
        UsersAPI.getByBrandsIdNoPagination(user.UserBrand.map((b) => b.brandId)).then((res) => {
            setCounselors(res.data.content);
        });
    };

    const getAllClients = () => {
        ClientsAPI.getClientsByBrands(99999, 0, 1).then((res) => {
            if (userState) {
                setClients(
                    res.data.content
                        .filter(
                            (item) =>
                                userState.id === item?.User.id &&
                                userState.UserBrand.some((uBrand) => uBrand.brandId === item.brandId)
                        )
                        .map((client) => ({
                            ...client.Clients,
                            brandId: client.brandId,
                            userId: client.userId,
                            clientsBrandId: client.id,
                            brand: client.Brand,
                            user: client.User,
                            clientUID: client.id,
                        }))
                );
            } else if (user.roleId === '3') {
                setClients(
                    res.data.content
                        .filter((item) => item.brandId === user.UserBrand[0].brandId)
                        .map((client) => ({
                            ...client.Clients,
                            brandId: client.brandId,
                            userId: client.userId,
                            clientsBrandId: client.id,
                            brand: client.Brand,
                            user: client.User,
                            clientUID: client.id,
                        }))
                );
            } else if (user.roleId === '4') {
                setClients(
                    res.data.content
                        .filter((item) => item.userId === user.id)
                        .map((client) => ({
                            ...client.Clients,
                            brandId: client.brandId,
                            userId: client.userId,
                            clientsBrandId: client.id,
                            brand: client.Brand,
                            user: client.User,
                            clientUID: client.id,
                        }))
                );
            } else {
                setClients(
                    res.data.content.map((client) => ({
                        ...client.Clients,
                        brandId: client.brandId,
                        userId: client.userId,
                        clientsBrandId: client.id,
                        brand: client.Brand,
                        user: client.User,
                        clientUID: client.id,
                    }))
                );
            }
        });
    };

    const getAllModels = (brandId) => {
        VehiclesAPI.getAllVehicles(brandId, 99999, 0, 1).then((res) => {
            if (res.ok) setModels(res.data.content);
            else setModels([]);
        });
    };

    useEffect(() => {
        getAllClients();
        if (user.roleId !== '4') {
            getCounselors();
        }
        if (user.roleId === '3') {
            getAllModels(filterData.brandId);
        }
    }, []);

    const handleChange = (e) => {
        setFilterData({
            ...filterData,
            [e.target.name]: e.target.value,
        });
    };

    const handleRemoveFilters = () => {
        resetFilter();
        setLoading(true);
        setData([]);
        setTriggerRefetch(!triggerRefetch);
        setOpen(false);
    };

    const handleFilter = () => {
        resetSearch();
        setLoading(true);
        setData([]);
        setIsFilter(true);
        setTriggerFilter(!triggerFilter);
        setOpen(false);
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={t('Filter offers')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            buttonUtility={t('REMOVE FILTERS')}
            onClickButtonFinish={handleFilter}
            width="580px"
            onClickButtonClose={() => {
                setOpen(false);
            }}
            onClickButtonUtility={handleRemoveFilters}
            buttonUtilityDisabled={!isFilter}
            buttonFinishDisabled={Object.values(filterData).every((x) => x === null || x === '')}
        >
            <div className={styles.container}>
                {user.roleId !== '4' && (
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <TextField
                            name="userId"
                            label={`${t('Counselor')}`}
                            size="medium"
                            fullWidth
                            select
                            sx={{
                                fieldset: {
                                    borderRadius: '10px',
                                },
                            }}
                            id="outlined-required"
                            value={filterData.userId}
                            onChange={handleChange}
                        >
                            {counselors.map((counselor) => (
                                <MenuItem key={counselor.id} value={counselor.id} sx>
                                    {`${counselor.name + ' ' + counselor.firstName}`}
                                </MenuItem>
                            ))}
                        </TextField>
                        <DeleteIcon
                            fontSize="large"
                            sx={{
                                cursor: filterData.userId !== '' && 'pointer',
                                color: filterData.userId !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                                '&:hover': {
                                    color: filterData.userId !== '' && '#a50009',
                                },
                            }}
                            onClick={() =>
                                setFilterData({
                                    ...filterData,
                                    userId: '',
                                })
                            }
                        />
                    </div>
                )}

                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <TextField
                        name="clientBrandsId"
                        label={`${t('Client')}`}
                        size="medium"
                        fullWidth
                        select
                        sx={{
                            fieldset: {
                                borderRadius: '10px',
                            },
                        }}
                        id="outlined-required"
                        value={filterData.clientBrandsId}
                        onChange={handleChange}
                    >
                        {clients.map((client) => (
                            <MenuItem key={client.clientsBrandId} value={client.clientsBrandId} sx>
                                {`${
                                    client.type === 'Individual'
                                        ? client.lastName + ' ' + client.firstName
                                        : client.type === 'Juridic'
                                        ? client.firmName
                                        : ''
                                } - ${brands.find((brand) => String(brand.id) === client.brandId)?.['name']}`}
                            </MenuItem>
                        ))}
                    </TextField>
                    <DeleteIcon
                        fontSize="large"
                        sx={{
                            cursor: filterData.clientBrandsId !== '' && 'pointer',
                            color: filterData.clientBrandsId !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                            '&:hover': {
                                color: filterData.clientBrandsId !== '' && '#a50009',
                            },
                        }}
                        onClick={() =>
                            setFilterData({
                                ...filterData,
                                clientBrandsId: '',
                            })
                        }
                    />
                </div>

                {user.roleId !== '3' && (
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <TextField
                            name="brandId"
                            label={`${t('Brand')}`}
                            size="medium"
                            fullWidth
                            select
                            sx={{
                                fieldset: {
                                    borderRadius: '10px',
                                },
                            }}
                            id="outlined-required"
                            value={filterData.brandId}
                            onChange={handleChange}
                        >
                            {displayedBrands().map((brand) => (
                                <MenuItem key={brand.id} value={brand.id} sx onClick={() => getAllModels(brand.id)}>
                                    {brand.name}
                                </MenuItem>
                            ))}
                        </TextField>
                        <DeleteIcon
                            fontSize="large"
                            sx={{
                                cursor: filterData.brandId !== '' && 'pointer',
                                color: filterData.brandId !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                                '&:hover': {
                                    color: filterData.brandId !== '' && '#a50009',
                                },
                            }}
                            onClick={() =>
                                setFilterData({
                                    ...filterData,
                                    modelId: '',
                                    brandId: '',
                                })
                            }
                        />
                    </div>
                )}

                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <TextField
                        name="modelId"
                        label={`${t('Model')}`}
                        size="medium"
                        fullWidth
                        select
                        sx={{
                            fieldset: {
                                borderRadius: '10px',
                            },
                        }}
                        id="outlined-required"
                        value={filterData.modelId}
                        onChange={handleChange}
                    >
                        {models.map((model) => (
                            <MenuItem key={model.id} value={model.id} sx>
                                {model.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <DeleteIcon
                        fontSize="large"
                        sx={{
                            cursor: filterData.modelId !== '' && 'pointer',
                            color: filterData.modelId !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                            '&:hover': {
                                color: filterData.modelId !== '' && '#a50009',
                            },
                        }}
                        onClick={() =>
                            setFilterData({
                                ...filterData,
                                modelId: '',
                            })
                        }
                    />
                </div>

                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <TextField
                        name="status"
                        label={`${t('Status')}`}
                        size="medium"
                        fullWidth
                        select
                        sx={{
                            fieldset: {
                                borderRadius: '10px',
                            },
                        }}
                        id="outlined-required"
                        value={filterData.status}
                        onChange={handleChange}
                    >
                        {status.map((status) => (
                            <MenuItem key={status.value} value={status.value} sx>
                                {status.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <DeleteIcon
                        fontSize="large"
                        sx={{
                            cursor: filterData.status !== '' && 'pointer',
                            color: filterData.status !== '' ? '#FF4B55' : 'rgba(0, 0, 0, 0.3)',
                            '&:hover': {
                                color: filterData.status !== '' && '#a50009',
                            },
                        }}
                        onClick={() =>
                            setFilterData({
                                ...filterData,
                                status: '',
                            })
                        }
                    />
                </div>
            </div>
        </CustomDialog>
    );
};

export default FilterOffers;
