import axios from './axios';

const Offers = {
    /**
     * Add document on offer
     *
     * @param {name} name that represents the document the document
     * @param {file} file with the document data
     * @param {string} offerId offer document to be updated
     * @return {object} Newly created file
     */
    async addDocument(offerId, fileName, file) {
        try {
            const res = await axios.post(
                `/offers/createDocs/${offerId}?fileName=${fileName}`,
                { file },
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            );
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Fetches all Users witch contain the the specified string
     *
     * @param {string} text used to find Users
     * @return {object} Array of Users's
     */
    async filterOffers(data) {
        try {
            const res = await axios.get(`/offers/filtering/offers`, { params: { ...data } });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Add document on offer
     *
     * @param {name} name that represents the document the document
     * @param {file} file with the document data
     * @param {string} offerId offer document to be updated
     * @return {object} Newly created file
     */
    async getDocuments(offerId) {
        try {
            const res = await axios.get('/offers/getDocs/' + offerId);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Add document on offer
     *
     * @param {name} name that represents the document the document
     * @param {file} file with the document data
     * @param {string} offerId offer document to be updated
     * @return {object} Newly created file
     */
    async getDocumentByPath(path) {
        try {
            const res = await axios.get('/offers/getDocById/' + path, { responseType: 'arraybuffer' });
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Add document on offer
     *
     * @param {name} name that represents the document the document
     * @param {file} file with the document data
     * @param {string} offerId offer document to be updated
     * @return {object} Newly created file
     */
    async removeDocument(fileId) {
        try {
            const res = await axios.delete('/offers/deleteDocs/' + fileId);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Fetches all Offers's
     *
     * @return {array} Array of Offers's
     */
    async get(perPage, currentPage, pagesToLoad) {
        try {
            const res = await axios.get('/offers', { params: { perPage, currentPage, pagesToLoad } });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Offers witch contain the the specified string
     *
     * @param {string} text used to find Offers
     * @return {object} Array of Offers's
     */
    async searchOffers(text) {
        try {
            const res = await axios.get(`/offers/findByString/text?text=${text}`);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all Offers's by user ID
     *
     * @return {array} Array of Offers's
     */
    async getByUserId(userId, perPage, currentPage, pagesToLoad) {
        try {
            const res = await axios.get('/offers/userId', {
                params: {
                    userId,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Offers's by brand ID
     *
     * @return {array} Array of Offers's
     */
    async getByBrandId(brandId, perPage, currentPage, pagesToLoad) {
        try {
            const res = await axios.get('/offers/brandId', {
                params: {
                    brandId,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Offers's by client ID
     *
     * @return {array} Array of Offers's
     */
    async getByClientId(clientId, perPage, currentPage, pagesToLoad) {
        try {
            const res = await axios.get('/offers/clientId', {
                params: {
                    clientId,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Offers's by client ID
     *
     * @return {array} Array of Offers's
     */
    async getByClientIdBrands(clientBrandsId, perPage, currentPage, pagesToLoad) {
        try {
            const res = await axios.get('/offers/clientBrandsId', {
                params: {
                    clientBrandsId,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches one Offers by it's ID
     *
     * @param {string} id ID of the Offers
     * @return {object} Offers with the corresponding ID
     */
    async getById(offersId) {
        try {
            const res = await axios.get('/offers/' + offersId);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Offer
     *
     * @param {object} offer Object with the creation data
     * @return {object} Newly created Offer
     */
    async create(offer) {
        let reqBody = { ...offer };
        try {
            const res = await axios.post('/offers', reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Deletes Offers by ID
     *
     * @param {string} offersId ID of the Offers to be deleted
     * @return {string} ID of the deleted Offers
     */
    async delete(offersId) {
        try {
            await axios.delete('/offers/' + offersId);
            return {
                ok: true,
                data: {
                    id: offersId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: offersId,
                },
            };
        }
    },

    /**
     * Update driveTestDate of the Offer by ID
     *
     * @param {date} driveTestDate
     * @param {string} offersId ID of the Offer to be updated
     * @return {string} ID of the updated Offer
     */
    async updateDriveTest(offersId, driveTestDate) {
        try {
            const res = await axios.patch('/offers/' + offersId, { driveTestDate });
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },

    /**
     * Update reContactDate of the Offer by ID
     *
     * @param {date} reContactDate
     * @param {string} offersId ID of the Offer to be updated
     * @return {string} ID of the updated Offer
     */
    async updateReContact(offersId, reContactDate) {
        try {
            const res = await axios.patch('/offers/' + offersId, { reContactDate });
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },

    /**
     * Refuse offer of the Offer by ID
     *
     * @param {date} reContactDate
     * @param {string} offersId ID of the Offer to be updated
     * @return {string} ID of the updated Offer
     */
    async refuseOffer(offersId, refuseReason) {
        try {
            const res = await axios.patch(`/offers/refused/${offersId}?refuseReason=${refuseReason}`);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },

    /**
     * Accept offer of the Offer by ID
     *
     * @param {date} reContactDate
     * @param {string} offersId ID of the Offer to be updated
     * @return {string} ID of the updated Offer
     */
    async acceptOffer(offersId) {
        try {
            const res = await axios.patch('/offers/accepted/' + offersId);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },

    /**
     * Update RCIfileDate of the Offer by ID
     *
     * @param {date} reContactDate
     * @param {string} offersId ID of the Offer to be updated
     * @return {string} ID of the updated Offer
     */
    async updateRCIfileDate(offersId, RCIfileDate) {
        try {
            const res = await axios.patch('/offers/' + offersId, { RCIfileDate });
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },
};

export default Offers;
