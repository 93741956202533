import { createTheme } from '@mui/material/styles';

const customTheme = createTheme({
    overrides: {
        MuiSelect: {
            select: {
                padding: '100px',
            },
        },
        MuiInputLabel: {
            outline: {
                fieldset: {
                    borderRadius: 'none',
                },
            },
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'cardButton' },
                    style: {
                        textTransform: 'none',
                        border: 'none',
                        backgroundColor: '#F7F8F9',
                        width: '100%',
                        display: 'flex',
                        textAlign: 'left',
                        fontSize: '13px',
                        fontWeight: 900,
                        lineHeight: '22.1px',
                        padding: '0.5rem 0',
                    },
                },
            ],
        },
    },

    palette: {
        lightCustom: {
            main: '#FFFFFF',
        },
        primaryCustom: {
            main: '#5664D2',
            light: '#7783DB',
            dark: '#3C4693',
            border: 'alpha(#5664D2, 0.5)',
            background: 'rgba(86, 100, 210, 0.08)',
        },
        secondaryCustom: {
            main: '#E91E63',
            border: 'rgba(233, 30, 99, 0.5)',
        },
        textCustom: {
            primary: '#253858',
            secondary: 'rgba(66, 82, 110, 0.86)',
        },
        actionCustom: {
            disabled: 'rgba(0, 0, 0, 0.26)',
        },
        otherCustom: {
            activeRating: '#FFB400',
            divider: 'rgba(0, 0, 0, 0.12)',
            border: 'rgba(0, 0, 0, 0.23)',
        },
        infoCustom: {
            main: '#2196F3',
            dark: '#0B79D0',
            light: '#64B6F7',
            textDark: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #2196F3',
            lightBg: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
        },
        successCustom: {
            main: '#4CAF50',
            dark: '#3B873E',
            light: '#7BC67E',
        },
        errorCustom: {
            main: '#F44336',
            dark: '#E31B0C',
            light: '#F88078',
        },
        warningCustom: {
            dark: '#C77700',
            light: '#FFB547',
        },
        blueGreyCustom: {
            700: '#66788A',
            400: '#C7CED4',
            200: '#EDF0F2',
        },
        shadowCustom: {
            24: '#ffffff',
        },
        tabsColor: {
            name: '#5B80BA',
        },
    },
    typography: {
        // color: "#253858",
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        firstPageTitle: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            lineHeight: '34px',
            fontWeight: 700,
            fontSize: '28px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        tableTitle: {
            fontFamily: '"Montserrat", sans-serif',
            lineHeight: 1.6,
            fontWeight: 900,
            fontSize: '18px',
            letterSpacing: '0.15px',
            color: '#253858',
        },
        objectivesName: {
            fontFamily: '"Montserrat", sans-serif',
            lineHeight: '24px',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        objectivesList: {
            fontFamily: '"Montserrat", sans-serif',
            lineHeight: '24px',
            fontWeight: 400,
            fontSize: '16px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.62)',
        },

        h6: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            color: 'rgba(0, 0, 0, 0.62)',
            fontWeight: 700,
            fontSize: '20px',
        },
        h6Small: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            color: '#FF4B55',
            fontWeight: 700,
            fontSize: '18px',
        },
        detailsText: {
            fontFamily: '"Montserrat", sans-serif',
            color: '#253858',
        },
        sidebarLabel: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            lineHeight: '16px',
            fontWeight: 600,
            fontSize: '13px',
            letterSpacing: '0.15px',
            color: '#8E99A7',
        },
        sidebarItem: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            lineHeight: '24px',
            fontWeight: 900,
            fontSize: '14px',
            letterSpacing: '0.15px',
        },
        objectivesSummary: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            lineHeight: '24px',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.15px',
            color: '#5B80BA',
        },
        cardHeader: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            lineHeight: '24.8px',
            fontWeight: 600,
            fontSize: '20px',
            letterSpacing: '0.4px',
            color: 'rgb(95,95,96)',
            marginTop: '32px',
            marginBottom: '16px',
        },
        smallCardHeader: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            lineHeight: '17.02px',
            fontWeight: 400,
            fontSize: '14px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        smallCardTitle: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            lineHeight: '32.02px',
            fontWeight: 900,
            fontSize: '24px',
            letterSpacing: '0.15px',
        },
        tableContent: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            lineHeight: '17px',
            fontWeight: 400,
            fontSize: '15px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.95)',
        },
        loginForm: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            lineHeight: '157%',
            fontWeight: 500,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: '#7E7E7E',
        },
        permissions: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '15px',
            color: 'rgba(0, 0, 0, 0.62)',
            display: 'flex',
            textAlign: 'center',
        },
        pageTitle: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '33px',
            letterSpacing: '0.15px',
            color: 'rgba(0, 0, 0, 1)',
        },
        pageInfo: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 1)',
        },
        pageBack: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        userHeader: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        userPrimaryData: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        userSecondaryData: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.1px',
            color: '#5B80BA',
        },
        logoutPopover: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
            display: 'flex',
            textAlign: 'center',
        },
        clientDetails: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '24px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        clientContactedTimesBox: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: '#fff',
            backgroundColor: '#EFAC00',
            borderRadius: '100px',
            padding: '6px 15px',
        },
        clientDetailsInfo: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        clientListMember: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            letterSpacing: '0.1px',
            color: '#7E7E7E',
        },
        clientListMemberDetails: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '12px',
            letterSpacing: '0.1px',
            color: '#7695C5',
        },
        profileBigText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        profileSmallText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '12px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        profileMediumText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        profileDataText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        profileModifyText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
            cursor: 'pointer',
            '&:hover': { color: '#5B80BA' },
        },
        profileModifyTextSecond: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: '#FFFFFF',
            cursor: 'pointer',
            '&:hover': { color: 'lightgrey' },
        },
        profileAlertMessage: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            letterSpacing: '0.1px',
            textTransform: 'uppercase',
            color: '#F23B2F',
        },
        addingVehicleModel: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '20px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        vehicleNomenclatorText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(91, 128, 186, 1)',
        },
        vehicleNomenclatorFaded: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.3)',
        },
        clientData: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 900,
            fontSize: '12px',
            letterSpacing: '0.1px',
            color: '#7695C5',
        },
        nomenclatorName: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '20px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        offerDataLabel: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        offerDataText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        offerDateText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        offerOptionalText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 300,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        offerBigText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '20px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        taskNameText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '20px',
            letterSpacing: '0.1px',
        },
        taskDateText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        taskPersonText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: '#8E99A7',
        },
        taskStatusText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            letterSpacing: '0.1px',
        },
        objectivesLabels: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '15px',
            letterSpacing: '0.1px',
            color: '#000000',
        },
        clientOrderText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            letterSpacing: '0.1px',
            color: '#5B80BA',
        },
        orderLabelText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '15px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        orderDataText: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '15px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
        orderStatus: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '13px',
            letterSpacing: '0.1px',
            color: 'rgba(0, 0, 0, 0.62)',
        },
    },
});

export default customTheme;
