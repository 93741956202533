import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';

/**
 * Search component
 * @param {*} search - state for the search input
 * @param {*} setSearch - set the state function
 * @param {*} nameToFind - placeholder value
 * @param {object} style - object to override styles
 */
const Search = ({
    isSearch,
    setIsSearch = () => {},
    search,
    setSearch = () => {},
    nameToFind,
    style,
    triggerSearch,
    setTriggerSearch = () => {},
    triggerRefetch,
    setTriggerRefetch = () => {},
    setLoading = () => {},
    setterFunction = () => {},
    resetFilter = () => {},
}) => {
    const defaultStyle = {
        width: '100%',
        borderRadius: '999px',
        boxShadow: '#eaeef4 0px 4px 16px, #eaeef1 0px 8px 32px',
        backgroundColor: '#FFFFFF',
        display: 'flex',
        justifyContent: 'center',
        padding: '9px 20px',
    };

    const handleRemoveSearch = () => {
        setIsSearch(false);
        setLoading(true);
        setterFunction([]);
        setTriggerRefetch(!triggerRefetch);
    };

    useEffect(() => {
        if (search === '' && isSearch) {
            handleRemoveSearch();
        }
    }, [search]);

    const handleSearch = () => {
        if (search !== '') {
            resetFilter();
            setLoading(true);
            setterFunction([]);
            setIsSearch(true);
            setTriggerSearch(!triggerSearch);
        }
    };

    const keyDownHandler = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };

    return (
        <TextField
            id="search-input"
            placeholder={nameToFind}
            sx={{ ...defaultStyle, ...style }}
            variant="standard"
            value={search}
            onChange={(e) => {
                setSearch(e.target.value);
            }}
            autoComplete="off"
            onKeyDown={keyDownHandler}
            InputProps={{
                endAdornment: (
                    <InputAdornment sx={{ marginRight: '10px' }} position="start">
                        {!isSearch ? (
                            <SearchIcon
                                sx={{
                                    color: search !== '' && '#17ad00',
                                    transform: search !== '' && 'scale(1.1,1.1)',
                                    transition: search !== '' && '.5s',
                                    cursor: search !== '' && 'pointer',
                                }}
                                onClick={search !== '' ? () => handleSearch() : undefined}
                            />
                        ) : (
                            <ClearIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setSearch('');
                                    handleRemoveSearch();
                                }}
                            />
                        )}
                    </InputAdornment>
                ),
                disableUnderline: true,
            }}
        />
    );
};

Search.propTypes = {
    search: PropTypes.string,
    setSearch: PropTypes.func,
    nameToFind: PropTypes.string,
    style: PropTypes.object,
};

Search.defaultProps = {
    search: '',
    setSearch: () => {},
    nameToFind: 'Default placeholder',
    style: {},
};

export default Search;
