import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import OrderDetailsContent from './OrderDetailsContent';
import { PageLayout } from 'lib';
import { Helmet } from 'react-helmet';
import { OrdersAPI } from 'api_darex';

const OrderDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [documents, setDocuments] = useState([]);

    const getOrder = useCallback(() => {
        OrdersAPI.getById(id).then((res) => {
            setOrder(res.data);
            setLoading(false);
        });
    }, [id]);

    const getDocuments = useCallback(() => {
        OrdersAPI.getDocuments(id).then((res) => {
            setDocuments(res.data);
        });
    }, [id]);

    useEffect(() => {
        getOrder();
        getDocuments();
    }, [getOrder, getDocuments]);

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Order')}</title>
            </Helmet>

            <PageLayout
                title={`${t('Order')} #${order?.autoincrement ? order.autoincrement : ''}`}
                subTitle={t('Manage order')}
                backArrow
            >
                <OrderDetailsContent
                    order={order}
                    documents={documents}
                    getDocuments={getDocuments}
                    loading={loading}
                    getOrder={getOrder}
                />
            </PageLayout>
        </>
    );
};

export default OrderDetails;
