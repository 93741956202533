import React, { useState } from 'react';
import styles from './SuggestionList.module.scss';
import PropTypes from 'prop-types';
import { ClickAwayListener } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'lib';
import InfoIcon from '@mui/icons-material/Info';
import ClientsAPI from 'api_darex/Clients';
import { toast } from 'react-toastify';
import { useConfirm } from 'hooks/useConfirm';

/**
 * This component is like a popup with suggestions for search fields specifically
 * @param {*} trigger - Element that triggers the list to appear
 * @param {*} list - List of items to display
 * @param {*} onSelectItem - Event handler clicking an item
 * @param {*} renderItem - (item) => `${item.key1} ${item.key2}`
 * @returns
 */
const SuggestionList = ({
    trigger,
    list,
    onSelectItem,
    renderItem,
    isLegitClient,
    myUserInfo,
    setClientDataForCreation,
    setToggleAddNewClientModal,
    getCLients,
    areaOfUse,
}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const confirm = useConfirm();

    const brands = ['Dacia', 'Renault', 'Nissan'];

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={styles.container}>
                <div onClick={() => setOpen(true)}>{trigger}</div>

                <div className={`${styles.list} ${open && styles.opened}`}>
                    <ul>
                        {list.map((item, index) => {
                            const clientVerification = isLegitClient(item);

                            return (
                                <li
                                    key={item.id + index + Math.random()}
                                    style={{
                                        cursor: clientVerification.isLegitClient ? 'pointer' : 'not-allowed',
                                    }}
                                    onClick={() => {
                                        if (areaOfUse === 'OFFER') {
                                            if (clientVerification.isLegitClient) {
                                                setOpen(false);

                                                if (!clientVerification.creation) {
                                                    if (clientVerification.awake) {
                                                        ClientsAPI.updateStatus(item.clientUID, 'New').then(() => {
                                                            toast.success(t('Your client has been activated'));
                                                            getCLients();
                                                            onSelectItem(item);
                                                        });
                                                    } else {
                                                        onSelectItem(item);
                                                    }
                                                } else if (clientVerification.creation) {
                                                    if (clientVerification.changeAdviser) {
                                                        ClientsAPI.updateCounselor(item.clientUID, myUserInfo.id).then(
                                                            () => {
                                                                ClientsAPI.updateStatus(item.clientUID, 'New').then(
                                                                    () => {
                                                                        toast.success(
                                                                            t(
                                                                                'This client is your dedicated client now'
                                                                            )
                                                                        );
                                                                        getCLients();
                                                                        onSelectItem(item);
                                                                    }
                                                                );
                                                            }
                                                        );
                                                    } else {
                                                        setClientDataForCreation(item);
                                                        setToggleAddNewClientModal(true);
                                                    }
                                                }
                                            }
                                        } else {
                                            if (clientVerification) {
                                                setOpen(false);

                                                if (clientVerification.creation) {
                                                    onSelectItem(item);
                                                } else {
                                                    if (clientVerification.awake) {
                                                        confirm(t('Would you like to awake your client?'), () => {
                                                            ClientsAPI.updateStatus(item.clientUID, 'New').then(() => {
                                                                toast.success(t('Your client has been activated'));
                                                                getCLients();
                                                            });
                                                        });
                                                    } else if (clientVerification.changeAdviser) {
                                                        confirm(
                                                            t(
                                                                'Would you like to make this client yours and awake him?'
                                                            ),
                                                            () => {
                                                                ClientsAPI.updateCounselor(
                                                                    item.clientUID,
                                                                    myUserInfo.id
                                                                ).then(() => {
                                                                    ClientsAPI.updateStatus(item.clientUID, 'New').then(
                                                                        () => {
                                                                            toast.success(
                                                                                t(
                                                                                    'This client is your dedicated client now'
                                                                                )
                                                                            );
                                                                            getCLients();
                                                                        }
                                                                    );
                                                                });
                                                            }
                                                        );
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                >
                                    <div className={styles.listItemWrapper}>
                                        {/* Status dot */}
                                        {clientVerification.isLegitClient ? (
                                            <div className={styles.circleStatus_green}></div>
                                        ) : (
                                            <Tooltip
                                                position="top"
                                                textTip={
                                                    areaOfUse === 'OFFER'
                                                        ? t('This client is dedicated to another advisor')
                                                        : t("You can't create this client")
                                                }
                                                style={{
                                                    border: '1px solid',
                                                    borderColor: '#ff4b55',
                                                    color: '#ff4b55',
                                                    fontSize: '13px',
                                                }}
                                                arrow
                                            >
                                                <div className={styles.circleStatus_red}>
                                                    <InfoIcon
                                                        sx={{
                                                            fontSize: '18px',
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        )}

                                        {/* List item */}
                                        <div className={styles.listItem}>
                                            {/* Company name and CUI */}
                                            {item.type === 'Juridic' ? (
                                                <div className={clientVerification.isLegitClient && styles.clientLegit}>
                                                    {item.firmName} <span className={styles.companyLabels}>CUI:</span>{' '}
                                                    <span className={styles.companyLabels}>{item.CUI}</span>
                                                </div>
                                            ) : (
                                                //  Client name
                                                <div className={clientVerification.isLegitClient && styles.clientLegit}>
                                                    {item.firstName} {item.lastName}{' '}
                                                </div>
                                            )}

                                            {/* Clint adviser */}
                                            <div className={styles.listItem_consilier}>
                                                {t('Adviser')}:{' '}
                                                <span
                                                    className={
                                                        clientVerification.isLegitClient && styles.consilierSpan_legit
                                                    }
                                                >
                                                    {item.userId === myUserInfo.id ? (
                                                        <span>{t('ME')}</span>
                                                    ) : (
                                                        <div>
                                                            {item.user.name} {item.user.firstName}
                                                        </div>
                                                    )}
                                                </span>
                                            </div>

                                            {/* Client brand */}
                                            <div className={styles.listItem_brand}>
                                                {t('Brand')}:{' '}
                                                <span
                                                    className={
                                                        clientVerification.isLegitClient && styles.brandSpan_legit
                                                    }
                                                >
                                                    {brands[Number(item.brandId) - 1]}
                                                </span>
                                            </div>

                                            {/* Sleeping client badge */}
                                            {item.status === 'Sleep' && (
                                                <div
                                                    className={
                                                        clientVerification.isLegitClient && styles.sleepingClient
                                                    }
                                                >
                                                    {t('SLEEPING')}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </ClickAwayListener>
    );
};

SuggestionList.propTypes = {
    trigger: PropTypes.element,
    list: PropTypes.array,
    onSelectItem: PropTypes.func,

    // Required
    renderItem: PropTypes.func.isRequired,
    isLegitClient: PropTypes.func.isRequired,
    setClientDataForCreation: PropTypes.func,
    setToggleAddNewClientModal: PropTypes.func,
    getCLients: PropTypes.func.isRequired,
};

SuggestionList.defaultProps = {
    trigger: <React.Fragment />,
    list: [],
    onSelectItem: (item) => {},
    isLegitClient: () => null,
    setClientDataForCreation: () => null,
    setToggleAddNewClientModal: () => null,
    getCLients: () => null,
};

export default SuggestionList;
