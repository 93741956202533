import axios from 'api_darex/axios';

const Vehicles = {
    /**
     * Fetches all the Colors for a specific Model
     * @param {modelId} id ID of the Model
     * @return {array} Array of Model Colors
     */
    async getModelColors(modelId) {
        try {
            const res = await axios.get('/modelColor', {
                params: {
                    modelId,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches all Vehicles witch contain the the specified string
     *
     * @param {string} text used to find Vehicles
     * @return {object} Array of Vehicles's
     */
    async searchVehicle(text) {
        try {
            const res = await axios.get(`/models/findByString/text?text=${text}`);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all the Engines for a specific Model
     * @param {modelId} id ID of the Model
     * @return {array} Array of Model Engines
     */
    async getModelEngines(modelId) {
        try {
            const res = await axios.get('/modelEngine', {
                params: {
                    modelId,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    async getAllVehicles(brandId, perPage = 9999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/models', {
                params: {
                    brandId,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    async createModel(model) {
        let reqBody = { ...model };
        try {
            const res = await axios.post('/models', reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
                error,
            };
        }
    },

    async getAllColors(brandId) {
        try {
            const res = await axios.get('/colors', {
                params: { brandId },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    async getAllEngines(brandId) {
        try {
            const res = await axios.get('/engines', {
                params: {
                    brandId,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    async createColor(color) {
        let reqBody = { ...color };
        try {
            const res = await axios.post('/colors', reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
                error,
            };
        }
    },

    async createEngine(engine) {
        let reqBody = { ...engine };
        try {
            const res = await axios.post('/engines', reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
                error,
            };
        }
    },

    /**
     * Fetches all Vehicles's
     *
     * @return {array} Array of Vehicles's
     */
    async get() {
        try {
            const res = await axios.get('/vehicles');
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches one Vehicles by it's ID
     *
     * @param {string} id ID of the Vehicles
     * @return {object} Vehicles with the corresponding ID
     */
    async getById(vehiclesId) {
        try {
            const res = await axios.get('/vehicles/' + vehiclesId);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Vehicles
     *
     * @param {object} vehicles Object with the creation data
     * @return {object} Newly created Vehicles
     */
    async create(vehicles) {
        let reqBody = { ...vehicles };
        try {
            const res = await axios.post('/vehicles', reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    async deleteColor(id) {
        try {
            await axios.delete('/colors/' + id);
            return {
                ok: true,
                data: {
                    id,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id,
                },
            };
        }
    },

    async deleteEngine(id) {
        try {
            await axios.delete('/engines/' + id);
            return {
                ok: true,
                data: {
                    id,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id,
                },
            };
        }
    },

    /**
     * Deletes Model by ID
     *
     * @param {string} modelId ID of the Vehicles to be deleted
     * @return {string} ID of the deleted Vehicles
     */
    async deleteModel(id) {
        try {
            await axios.delete('/models/' + id);
            return {
                ok: true,
                data: {
                    id,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id,
                },
            };
        }
    },

    /**
     * Deletes Vehicles by ID
     *
     * @param {string} id ID of the model to be edited
     * @return {count} count of the edited model
     */
    async editModel(id, model) {
        let reqBody = { ...model };
        try {
            const res = await axios.put(`/models/${id}`, reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Deletes Vehicles by ID
     *
     * @param {string} modelId ID of the Vehicles to be deleted
     * @return {string} ID of the deleted Vehicles
     */
    async delete(id) {
        try {
            await axios.delete('/models/' + id);
            return {
                ok: true,
                data: {
                    id: id,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: id,
                },
            };
        }
    },
};

export default Vehicles;
