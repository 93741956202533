import axios from './axios';

const Roles = {
    /**
     * Fetches all Roles's
     *
     * @return {array} Array of Roles's
     */
    async get() {
        try {
            const res = await axios.get('/roles');
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches one Roles by it's ID
     *
     * @param {string} id ID of the Roles
     * @return {object} Roles with the corresponding ID
     */
    async getById(rolesId) {
        try {
            const res = await axios.get('/roles/' + rolesId);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Roles
     *
     * @param {object} roles Object with the creation data
     * @return {object} Newly created Roles
     */
    async create(roles) {
        let reqBody = { ...roles };
        try {
            const res = await axios.post('/roles', reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Edit one Roles
     *
     * @param {object} roles Object with the edit data of the role
     * @return {object} Edited role
     */
    async edit(roleId, roles) {
        let reqBody = { ...roles };
        try {
            const res = await axios.patch(`/roles/${roleId}`, reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Deletes Roles by ID
     *
     * @param {string} rolesId ID of the Roles to be deleted
     * @return {string} ID of the deleted Roles
     */
    async delete(rolesId) {
        try {
            await axios.delete('/roles/' + rolesId);
            return {
                ok: true,
                data: {
                    id: rolesId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: rolesId,
                },
            };
        }
    },

    /**
     * Get all permissions for roles
     * @returns array of permissions
     */
    async getAllPermissions() {
        try {
            const res = await axios.get('/roles/permissions/all');
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },
};

export default Roles;
