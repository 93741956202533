import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField } from 'lib';
import { CustomDialog } from 'lib';
import { WorkPointsAPI } from 'api_darex';
import { toast } from 'react-toastify';

const AddWorkPoint = ({ open, setOpen, rowState, getAllWorkPoints }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [hasModified, setHasModified] = useState(false);
    const [loading, setLoading] = useState(false);

    const INITIAL_FORM_STATE = {
        name: rowState?.name ? rowState.name : '',
    };

    const FORM_VALIDATION = yup.object().shape({
        name: yup.string().trim().required(t('The locality is mandatory!')),
    });

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={rowState ? t('Edit work point') : t('Add work point')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            buttonFinishDisabled={rowState ? !hasModified : false}
            onClickButtonFinish={handleSubmit}
            onClickButtonClose={() => {
                setOpen(false);
                setHasModified(false);
            }}
            width="580px"
            buttonFinishLoading={loading}
            buttonCloseDisabled={loading}
        >
            <Formik
                innerRef={formRef}
                // enableReinitialize={true}
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    setLoading(true);
                    rowState
                        ? WorkPointsAPI.update(rowState.id, values).then((res) => {
                              if (res.ok === true) {
                                  getAllWorkPoints();
                                  setOpen(false);
                                  setHasModified(false);
                                  setLoading(false);
                                  toast.success(t('The work point has been updated successfully!'));
                              } else if (
                                  res.data.response.data.message === 'The work point with this name already exist'
                              ) {
                                  setLoading(false);
                                  toast.error(t('There is already a work point with this name!'));
                              } else {
                                  setLoading(false);
                                  toast.error(t('Something went wrong!'));
                              }
                          })
                        : WorkPointsAPI.create(values).then((res) => {
                              if (res.ok === true) {
                                  getAllWorkPoints();
                                  setOpen(false);
                                  setHasModified(false);
                                  setLoading(false);
                                  toast.success(t('Work point has been added successfully!'));
                              } else {
                                  if (
                                      res.data.response.data.message === 'The work point with this name already exist'
                                  ) {
                                      setLoading(false);
                                      toast.error(t('There is already a work point with this name!'));
                                  } else {
                                      setLoading(false);
                                      toast.error(t('Something went wrong!'));
                                  }
                              }
                          });
                }}
            >
                <Form
                    onChange={(e) => {
                        const { name, value } = e.target;

                        if (rowState && INITIAL_FORM_STATE[name] === value) setHasModified(false);
                        else if (rowState && INITIAL_FORM_STATE[name] !== value) setHasModified(true);
                    }}
                >
                    <div className="add-user-wrapper" style={{ paddingTop: '10px' }}>
                        <TextField name="name" label={`${t('Location')}`} size="medium" />
                    </div>
                </Form>
            </Formik>
        </CustomDialog>
    );
};

export default AddWorkPoint;
