import React from 'react';

const PercentageComponent = ({ color, value }) => {
    return (
        <>
            <span style={{ marginRight: '8px', marginLeft: '8px' }}>*</span>
            <span style={{ color: color, fontWeight: 600 }}>
                {isNaN(value) ? '0.00' : !isFinite(value) ? ' - ' : value}%
            </span>
        </>
    );
};

export default PercentageComponent;
