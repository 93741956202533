import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { PrimaryButton } from 'lib/components/Buttons/buttons';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';

import Search from 'lib/components/Search/Search';
import FilterClients from './modals/FilterClients';
import { PageLayout, Tooltip } from 'lib';
import { Helmet } from 'react-helmet';

import { CustomTable } from 'lib';
import { ClientsAPI } from 'api_darex';
import { useUser } from 'hooks/useUser';

const Clients = () => {
    const { t } = useTranslation();
    const navigate = useHistory();
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [openFilterClients, setOpenFilterClients] = useState(false);
    const [clients, setClients] = useState([]);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const [triggerFilter, setTriggerFilter] = useState(false);
    const { can } = useUser();
    const { user } = useUser();
    const [filterData, setFilterData] = useState({
        brandId: user.roleId === '3' ? user.UserBrand[0].brandId : '',
        userId: user.roleId === '4' ? user.id : '',
    });
    const [isFilter, setIsFilter] = useState(false);

    useEffect(() => {
        if (!can(15)) {
            navigate.goBack();
        }
    }, []);

    const resetSearch = () => {
        setIsSearch(false);
        setSearch('');
    };

    const resetFilter = () => {
        setIsFilter(false);
        setFilterData({
            brandId: user.roleId === '3' ? user.UserBrand[0].brandId : '',
            userId: user.roleId === '4' ? user.id : '',
        });
    };

    const labels = [
        {
            id: 'client',
            label: 'Client',
            minWidth: 10,
        },
        {
            id: 'phone',
            label: t('Phone'),
            minWidth: 10,
        },
        {
            id: 'user',
            label: t('Adviser'),
            minWidth: 10,
        },
        {
            id: 'brand',
            label: t('Brand'),
            minWidth: 10,
        },
        {
            id: 'createdAt',
            label: t('Creation date'),
            minWidth: 10,
        },
        {
            id: 'actions',
            minWidth: 10,
        },
    ];

    const cellModifier = (row, column, value) => {
        if (column.id === 'client') {
            if (row.type === 'Individual')
                return (
                    <Typography variant="tableContent">
                        {row.firstName} {row.lastName}
                    </Typography>
                );
            else return <Typography variant="tableContent">{row.firmName}</Typography>;
        }

        if (column.id === 'brand') {
            return <Typography variant="tableContent">{value?.name}</Typography>;
        }
        if (column.id === 'createdAt') {
            return <div style={{ display: 'flex' }}>{dayjs(value).format('DD MMM YYYY')}</div>;
        }
        if (column.id === 'actions') {
            return (
                can(15) && (
                    <Tooltip position="left" textTip={<text>{t('View more details')}</text>} followCursor>
                        <div className="actions-class" onClick={() => navigate.push(`/clients/${row.clientsBrandId}`)}>
                            <ChevronRightIcon />
                        </div>
                    </Tooltip>
                )
            );
        }
        if (column.id === 'user') {
            return (
                <Typography variant="tableContent">
                    {row.user?.name} {row.user?.firstName}
                </Typography>
            );
        }

        if (column.id === 'phone') {
            if (row.type === 'Juridic')
                if (row.Contacts[0]?.phone === null) return <div></div>;
                else return <Typography variant="tableContent">{row?.Contacts[0]?.phone}</Typography>;
            else return <Typography variant="tableContent">{row?.phone}</Typography>;
        }
        return <Typography variant="tableContent">{value}</Typography>;
    };

    const getDynamicData = () => {
        if (user.roleId === '1' || user.roleId === '2' || user.roleId === '5' || user.roleId > '5') {
            return {
                func: ClientsAPI.getClientsByBrands,
                additionalId: null,
                setState: (data) =>
                    data.map((client) => ({
                        ...client.Clients,
                        brandId: client.brandId,
                        userId: client.userId,
                        clientsBrandId: client.id,
                        brand: client.Brand,
                        user: client.User,
                        clientUID: client.id,
                        createdAt: client.createdAt,
                    })),
                filterSearch: null,
                filterFilter: null,
            };
        } else if (user.roleId === '3') {
            return {
                func: ClientsAPI.getClientsByOneBrandBrands,
                additionalId: user.UserBrand[0].brandId,
                setState: (data) =>
                    data.map((client) => ({
                        ...client.Clients,
                        brandId: client.brandId,
                        userId: client.userId,
                        clientsBrandId: client.id,
                        brand: client.Brand,
                        user: client.User,
                        clientUID: client.id,
                        createdAt: client.createdAt,
                    })),
                filterSearch: (data) => data.filter((item) => item.brandId === user.UserBrand[0].brandId),
                filterFilter: (data) => data.filter((item) => item.brandId === user.UserBrand[0].brandId),
            };
        } else if (user.roleId === '4') {
            return {
                func: ClientsAPI.getByUserIdBrands,
                additionalId: user.id,
                setState: (data) =>
                    data.map((client) => ({
                        ...client.Clients,
                        brandId: client.brandId,
                        userId: client.userId,
                        clientsBrandId: client.id,
                        brand: client.Brand,
                        user: client.User,
                        clientUID: client.id,
                        createdAt: client.createdAt,
                    })),
                filterSearch: (data) => data.filter((item) => item.userId === user.id),
                filterFilter: (data) => data.filter((item) => item.userId === user.id),
            };
        }
    };

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Clients')}</title>
            </Helmet>
            <PageLayout
                title={t('Clients')}
                subTitle={t('Manage your clients')}
                actionArea={
                    <>
                        <Search
                            search={search}
                            setSearch={setSearch}
                            isSearch={isSearch}
                            setIsSearch={setIsSearch}
                            triggerRefetch={triggerRefetch}
                            setTriggerRefetch={setTriggerRefetch}
                            triggerSearch={triggerSearch}
                            setTriggerSearch={setTriggerSearch}
                            setterFunction={setClients}
                            setLoading={setLoading}
                            nameToFind={t('Search client')}
                            setIsFilter={setIsFilter}
                            resetFilter={resetFilter}
                        />
                        <PrimaryButton
                            startIcon={<FilterListIcon />}
                            color="light"
                            onClick={() => setOpenFilterClients(true)}
                        >
                            {t('FILTER CLIENTS')}
                        </PrimaryButton>
                        {can(14) && (user.roleId === '4' || user.roleId > '5') && (
                            <PrimaryButton
                                startIcon={<AddIcon />}
                                color="primary"
                                onClick={() => navigate.push('/clients/create')}
                            >
                                {t('ADD CLIENT')}
                            </PrimaryButton>
                        )}
                    </>
                }
            >
                <CustomTable
                    title={t('Clients')}
                    labels={labels}
                    loading={loading}
                    setLoading={setLoading}
                    tableData={clients}
                    cellModifier={cellModifier}
                    getterFunction={getDynamicData().func}
                    additionalId={getDynamicData().additionalId}
                    setState={getDynamicData().setState}
                    setterFunction={setClients}
                    withPagination={true}
                    triggerRefetch={triggerRefetch}
                    triggerSearch={triggerSearch}
                    triggerFilter={triggerFilter}
                    isSearch={isSearch}
                    search={search}
                    searchFunction={ClientsAPI.searchClients}
                    filterSearch={getDynamicData().filterSearch}
                    customKey="clientUID"
                    setTriggerFilter={setTriggerFilter}
                    isFilter={isFilter}
                    filterFilter={getDynamicData().filterFilter}
                    filterFunction={ClientsAPI.filterClients}
                    filterData={filterData}
                />
            </PageLayout>

            <FilterClients
                open={openFilterClients}
                setOpen={setOpenFilterClients}
                filterData={filterData}
                setFilterData={setFilterData}
                triggerFilter={triggerFilter}
                setTriggerFilter={setTriggerFilter}
                triggerRefetch={triggerRefetch}
                setTriggerRefetch={setTriggerRefetch}
                setIsFilter={setIsFilter}
                setLoading={setLoading}
                setData={setClients}
                resetSearch={resetSearch}
                resetFilter={resetFilter}
                isFilter={isFilter}
            />
        </>
    );
};

export default Clients;
