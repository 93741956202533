import React, { useEffect } from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDialog, MultiSelect } from 'lib';
import { MenuItem, Typography } from '@mui/material';
import { UtilityButton } from 'lib/components/Buttons/buttons';
import { Avatar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FileUploadWrapper } from 'lib';
import { uploadSingleFile } from 'utils/functions';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import DefaultUserPicture from 'resources/img/user/DefaultUserPicture.png';

import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { emailRegex } from 'utils/regex';
import { TextField, Select } from 'lib';
import { RolesAPI, UsersAPI, WorkPointsAPI } from 'api_darex';
import { useUser } from 'hooks/useUser';

const AddUser = ({ open, setOpen, triggerRefetch, setTriggerRefetch }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    let history = useHistory();
    const [fileBlob, setFileBlob] = useState(null);
    const [locations, setLocations] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const { user } = useUser();

    const brandsName = {
        1: 'Dacia',
        2: 'Renault',
        3: 'Nissan',
    };

    const getRoles = () => {
        RolesAPI.get().then((res) => {
            setRoles(res.data);
        });
    };

    const getWorkPoints = () => {
        WorkPointsAPI.get().then((res) => {
            setLocations(res.data);
        });
    };

    const getUsers = () => {
        UsersAPI.get().then((res) => {
            setUsers(res.data.content);
        });
    };

    useEffect(() => {
        getRoles();
        getWorkPoints();
        getUsers();
    }, []);

    const handleFilterUsers = ({ roleId }) => {
        setFilteredUsers(
            users.filter((item) => {
                return Number(item.roleId) === roleId - 1;
            })
        );
    };

    const INITIAL_FORM_STATE = {
        name: '',
        firstName: '',
        email: '',
        roleId: '',
        UserBrand: [],
        workingPointId: '',
        Inferior: [],
    };

    const handleFileUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    toast.error(t(message));
                    return;
                }
                setFileBlob(blob);
            },
            'image'
        );
    };

    const FORM_VALIDATION = yup.object().shape({
        firstName: yup
            .string()
            .typeError(t('The first name is not valid!'))
            .trim()
            .min(3, t('The first name must contain at least 3 characters!'))
            .required(t('The first name is mandatory!')),
        name: yup
            .string()
            .typeError(t('This last name is not valid!'))
            .trim()
            .min(3, t('The last name must contain at least 3 characters!'))
            .required(t('The last name is mandatory!')),
        roleId: yup.string().trim().required(t('The role is mandatory!')),
        email: yup
            .string()
            .trim()
            .matches(emailRegex, t('You must enter a valid email!'))
            .required(t('Email is required!')),
        workingPointId: yup
            .string()
            .typeError(t('Work point should be a string!'))
            .trim()
            .when('roleId', {
                is: (roleId) => roleId !== '1' && roleId !== '2' && roleId !== '5',
                then: yup.string().required(t('The location is mandatory!')),
            }),
        Inferior: yup
            .array()
            .of(yup.string())
            .when('roleId', {
                is: (roleId) => roleId !== '1' && roleId !== '4' && roleId < '5',
                then: yup
                    .array()
                    .min(1, t('At least one superior is required!'))
                    .max(1, t('Only one superior is required!'))
                    .required(t('Select one superior!')),
            })
            .when('roleId', {
                is: (roleId) => roleId === '4',
                then: yup
                    .array()
                    .min(1, t('At least one superior is required!'))
                    .max(3, t('You can have a maximum of three superiors!'))
                    .required(t('Select one superior!')),
            }),
        UserBrand: yup
            .array()
            .of(yup.string())
            .when('roleId', {
                is: (roleId) => roleId === '4',
                then: yup
                    .array()
                    .min(1, t(`You have to select at least one brand!`))
                    .test({
                        name: 'max',
                        exclusive: false,
                        params: {},
                        message: t(`You have to select the correct number of brands!`),
                        test: (value, context) => value.length === context.parent.Inferior.length && value.length !== 2,
                    })
                    .required(t('Select one or more brands!')),
            })
            .when('roleId', {
                is: (roleId) => roleId === '3',
                then: yup
                    .array()
                    .min(1, t('At least one brand is required!'))
                    .max(1, t('Only one brand is required!'))
                    .required(t('Select one brand!')),
            })
            .when('roleId', {
                is: (roleId) => roleId > '5',
                then: yup
                    .array()
                    .min(1, t('At least one brand is required!'))
                    .max(3, t('Only one brand is required!'))
                    .required(t('Select one brand!')),
            }),
    });

    const onSubmitFunc = (values) => {
        setLoading(true);

        const newFormShape = {
            roleId: values.roleId,
            email: values.email,
            firstName: values.firstName.trim(),
            name: values.name.trim(),
            workingPointId:
                values.roleId !== '1' && values.roleId !== '2' && values.roleId !== '5' ? values.workingPointId : null,
            UserBrand:
                values.roleId !== '1' && values.roleId !== '2' && values.roleId !== '5'
                    ? values.UserBrand.map((ub) => ({
                          brandId: ub,
                      }))
                    : [{ brandId: '1' }, { brandId: '2' }, { brandId: '3' }],
            Inferior:
                values.roleId !== '1' && values.roleId !== '5'
                    ? values.Inferior.map((item) => {
                          const parsedItem = JSON.parse(item);
                          return { superiorId: parsedItem.id };
                      })
                    : [{ superiorId: user.id }],
        };

        UsersAPI.create(newFormShape).then((res) => {
            if (res.ok === true) {
                if (fileBlob) {
                    UsersAPI.addPicture(res.data.id, fileBlob).then((res) => {
                        if (res.ok === true) {
                            toast.success(t('Profile picture has been uploaded successfully!'));
                        } else {
                            toast.error(t('Something went wrong! Profile picture could not be uploaded successfully!'));
                        }
                    });
                }

                setTriggerRefetch(!triggerRefetch);
                getUsers();
                setOpen(false);
                setLoading(false);
                setFileBlob(null);
                toast.success(t('User has been created successfully!'));
            } else {
                if (res.error.response.data.errors.message === 'The email is already use!') {
                    setLoading(false);
                    toast.error(t('The email is already use! User could not been created successfully!'));
                } else {
                    setLoading(false);
                    toast.error(t('Something went wrong! User could not been created!'));
                }
            }
        });
    };

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const handleDisplayBrand = ({ brands }) => {
        return brands.map((item, index) => {
            if (index === brands.length - 1) {
                return brandsName[item.brandId];
            } else {
                return brandsName[item.brandId] + ', ';
            }
        });
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={t('Add user')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={handleSubmit}
            width="580px"
            onClickButtonClose={() => {
                setFileBlob(null);
                setOpen(false);
                setLoading(false);
            }}
            buttonFinishLoading={loading}
            buttonCloseDisabled={loading}
        >
            <Formik
                innerRef={formRef}
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    onSubmitFunc(values);
                }}
            >
                {(props) => {
                    const parsedAdminId = props.values.Inferior.map((item) => JSON.parse(item));

                    const isDacia =
                        parsedAdminId
                            .map((item) => item.brands.findIndex((item) => item.brandId === '1') < 0)
                            .findIndex((item) => item === false) < 0;
                    const isRenault =
                        parsedAdminId
                            .map((item) => item.brands.findIndex((item) => item.brandId === '2') < 0)
                            .findIndex((item) => item === false) < 0;
                    const isNissan =
                        parsedAdminId
                            .map((item) => item.brands.findIndex((item) => item.brandId === '3') < 0)
                            .findIndex((item) => item === false) < 0;

                    const canBeSelected = ({ user }) => {
                        const brandsResult = parsedAdminId.map((item) =>
                            item.brands.some((brand) =>
                                user.UserBrand.some((uBrand) => uBrand.brandId === brand.brandId)
                            )
                        );
                        const idResult = parsedAdminId.findIndex((item) => item.id === user.id) < 0;

                        return brandsResult.findIndex((item) => item === true) >= 0 && idResult;
                    };

                    if (props.values.UserBrand.indexOf('1') >= 0 && isDacia && props.values.roleId <= '5') {
                        props.setFieldValue(
                            'UserBrand',
                            props.values.UserBrand.filter((item) => item !== '1')
                        );
                    }

                    if (props.values.UserBrand.indexOf('2') >= 0 && isRenault && props.values.roleId <= '5') {
                        props.setFieldValue(
                            'UserBrand',
                            props.values.UserBrand.filter((item) => item !== '2')
                        );
                    }

                    if (props.values.UserBrand.indexOf('3') >= 0 && isNissan && props.values.roleId <= '5') {
                        props.setFieldValue(
                            'UserBrand',
                            props.values.UserBrand.filter((item) => item !== '3')
                        );
                    }

                    return (
                        <Form>
                            <div className="add-user-wrapper">
                                <TextField name="name" label={`${t('Last Name')}*`} size="medium" />
                                <TextField name="firstName" label={`${t('First Name')}*`} size="medium" />
                                <TextField name="email" label="Email*" size="medium" />
                                <Select name="roleId" label={`${t('Role')}*`} size="medium">
                                    {roles.map((role) => (
                                        <MenuItem
                                            key={role.id}
                                            value={role.id}
                                            onClick={() => {
                                                if (role.id !== '1' && role.id < '5') {
                                                    handleFilterUsers({ roleId: role.id });
                                                }
                                                props.setFieldValue('Inferior', []);
                                            }}
                                            sx
                                        >
                                            {role.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {props.values.roleId !== '' &&
                                    props.values.roleId !== '1' &&
                                    props.values.roleId < '5' && (
                                        <MultiSelect name="Inferior" label={`${t('Superior')}*`} size="medium">
                                            {filteredUsers?.map((user) => (
                                                <MenuItem
                                                    key={user.id}
                                                    value={JSON.stringify({
                                                        id: user.id,
                                                        brands: user.UserBrand,
                                                    })}
                                                    disabled={canBeSelected({ user })}
                                                >
                                                    {user.name} {user.firstName}{' '}
                                                    <span
                                                        style={{
                                                            width: '5px',
                                                            height: '5px',
                                                            borderRadius: '15px',
                                                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                                            marginLeft: '8px',
                                                            marginRight: '8px',
                                                            display: 'inline-block',
                                                            verticalAlign: 'middle',
                                                        }}
                                                    />
                                                    {handleDisplayBrand({ brands: user.UserBrand })}
                                                </MenuItem>
                                            ))}
                                        </MultiSelect>
                                    )}
                                {props.values.roleId !== '1' &&
                                    props.values.roleId !== '2' &&
                                    props.values.roleId !== '5' &&
                                    (locations.length ? (
                                        <Select
                                            name="workingPointId"
                                            label={`${t('Assigned work point')}*`}
                                            size="medium"
                                        >
                                            {locations.map((location) => (
                                                <MenuItem key={location.id} value={location.id} sx>
                                                    {location.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    ) : (
                                        <div className="notWorkpoint">
                                            <div style={{ textAlign: 'center' }}>
                                                <Typography sx={{ color: 'red', fontSize: '14px' }}>{`${t(
                                                    'Not assigned work point yet. Please, go to nomenclature and choose one'
                                                )}*`}</Typography>
                                            </div>
                                            <div>
                                                <UtilityButton
                                                    variant="contained"
                                                    type="button"
                                                    startIcon={<AddIcon />}
                                                    style={{
                                                        minHeight: '30px',
                                                        whiteSpace: 'wrap',
                                                    }}
                                                    onClick={() => history.push('/work-points')}
                                                >
                                                    {t('ADD WORKPOINT')}
                                                </UtilityButton>
                                            </div>
                                        </div>
                                    ))}
                                <div className="image-wrapper">
                                    <Typography
                                        sx={{ fontSize: '14px', fontWeight: '600', color: 'black', opacity: '62%' }}
                                    >
                                        {t('Add profile picture')}
                                    </Typography>
                                    <div className="imageContainer">
                                        <Avatar
                                            src={fileBlob ? URL.createObjectURL(fileBlob) : DefaultUserPicture}
                                            sx={{ width: '80px', height: '80px' }}
                                        />
                                        <FileUploadWrapper onUpload={handleFileUpload}>
                                            <UtilityButton variant="contained" type="button" height="41px">
                                                {fileBlob ? t('CHANGE PHOTO') : t('UPLOAD')}
                                                <input name="image" hidden accept="image/*" type="file" />
                                            </UtilityButton>
                                        </FileUploadWrapper>
                                    </div>
                                </div>
                                {props.values.roleId !== '1' &&
                                    props.values.roleId !== '2' &&
                                    props.values.roleId !== '5' && (
                                        <div className="brands-wrapper">
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    color: 'black',
                                                    opacity: '62%',
                                                }}
                                            >
                                                {t('Choose the brands')}
                                            </Typography>
                                            <div
                                                role="group"
                                                aria-labelledby="checkbox-group"
                                                className="checkboxGroupWrapper"
                                            >
                                                <div className="checkboxGroup">
                                                    <label>
                                                        <Field
                                                            type="checkbox"
                                                            name="UserBrand"
                                                            id="firstCheckbox"
                                                            value="1"
                                                            checked={
                                                                props.values.roleId <= 5
                                                                    ? props.values.UserBrand.indexOf('1') >= 0 &&
                                                                      !isDacia
                                                                    : undefined
                                                            }
                                                            disabled={isDacia && props.values.roleId <= '5'}
                                                        />
                                                        DACIA
                                                    </label>
                                                    <label>
                                                        <Field
                                                            type="checkbox"
                                                            name="UserBrand"
                                                            id="secondCheckbox"
                                                            value="2"
                                                            checked={
                                                                props.values.roleId <= 5
                                                                    ? props.values.UserBrand.indexOf('2') >= 0 &&
                                                                      !isRenault
                                                                    : undefined
                                                            }
                                                            disabled={isRenault && props.values.roleId <= '5'}
                                                        />
                                                        RENAULT
                                                    </label>
                                                    <label>
                                                        <Field
                                                            type="checkbox"
                                                            name="UserBrand"
                                                            id="thirdCheckbox"
                                                            value="3"
                                                            checked={
                                                                props.values.roleId <= 5
                                                                    ? props.values.UserBrand.indexOf('3') >= 0 &&
                                                                      !isNissan
                                                                    : undefined
                                                            }
                                                            disabled={isNissan && props.values.roleId <= '5'}
                                                        />
                                                        NISSAN
                                                    </label>
                                                </div>
                                                {props.errors.UserBrand && props.touched.UserBrand && (
                                                    <div className="checkboxGroupError">{props.errors.UserBrand}</div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </CustomDialog>
    );
};

export default AddUser;
