import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDialog } from 'lib';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Select } from 'lib';
import { UsersAPI } from 'api_darex';
import { MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import { ClientsAPI } from 'api_darex';

const EditClientCounselor = ({ open, setOpen, id, counselorId, addCallback, brandId }) => {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const formikRef = useRef();

    const initialValues = {
        userId: '',
    };

    const validationSchema = yup.object().shape({
        userId: yup.string().required(t('Choose the counselor')),
    });

    useEffect(() => {
        UsersAPI.getByBrandsIdNoPagination(brandId).then((res) =>
            setUsers(res.data.content.filter((item) => item.id !== counselorId))
        );
    }, []);

    const handleSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    };

    const handleSubmitCounselor = (id, userId) => {
        id = id.toString();
        userId = userId.toString();
        ClientsAPI.updateCounselor(id, userId).then((res) => {
            if (res.ok === true) {
                addCallback();
                setOpen(false);
                toast.success(t('Counselor changed succesfully'));
            } else {
                toast.error(t('An error ocurred. Please try again'));
            }
        });
    };

    return (
        <CustomDialog
            setOpen={setOpen}
            title={t('Change counselor')}
            buttonClose={t('BACK')}
            open={open}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={handleSubmit}
            width="100%"
        >
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmitCounselor(id, values.userId)}
            >
                <Form>
                    <Select name="userId">
                        {users.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                                {user.name} {user.firstName}
                            </MenuItem>
                        ))}
                    </Select>
                </Form>
            </Formik>
        </CustomDialog>
    );
};

export default EditClientCounselor;
