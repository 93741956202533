import axios from './axios';

const Orders = {
    /**
     * Add document on offer
     *
     * @param {name} name that represents the document the document
     * @param {file} file with the document data
     * @param {string} offerId offer document to be updated
     * @return {object} Newly created file
     */
    async addDocument(orderId, fileName, tag, file) {
        try {
            const res = await axios.post(
                `/offerModel/createDocs/${orderId}?tag=${tag}&fileName=${fileName}`,
                { file },
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            );
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Add document on offer
     *
     * @param {name} name that represents the document the document
     * @param {file} file with the document data
     * @param {string} offerId offer document to be updated
     * @return {object} Newly created file
     */
    async getDocuments(orderId) {
        try {
            const res = await axios.get('/offerModel/getDocs/' + orderId);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Add document on offer
     *
     * @param {name} name that represents the document the document
     * @param {file} file with the document data
     * @param {string} offerId offer document to be updated
     * @return {object} Newly created file
     */
    async getDocumentByPath(path) {
        try {
            const res = await axios.get('/offerModel/getDocById/' + path, { responseType: 'arraybuffer' });
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Add document on offer
     *
     * @param {name} name that represents the document the document
     * @param {file} file with the document data
     * @param {string} offerId offer document to be updated
     * @return {object} Newly created file
     */
    async removeDocument(fileId) {
        try {
            const res = await axios.delete('/offerModel/deleteDocs/' + fileId);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Add document on offer
     *
     * @param {name} name that represents the document the document
     * @param {file} file with the document data
     * @param {string} offerId offer document to be updated
     * @return {object} Newly created file
     */
    async updateDocument(fileId, tag, fileName, file) {
        try {
            const res = await axios.patch(
                `/offerModel/updateDocs/${fileId}?tag=${tag}&fileName=${fileName}`,
                { file },
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            );
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Fetches all Orders's
     *
     * @return {array} Array of Orders's
     */
    async get(perPage = 99999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/offerModel', { params: { perPage, currentPage, pagesToLoad } });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches one Orders by it's ID
     *
     * @param {string} id ID of the Orders
     * @return {object} Orders with the corresponding ID
     */
    async getById(ordersId) {
        try {
            const res = await axios.get('/offerModel/' + ordersId);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all Orders by offer ID
     *
     * @param {string} id ID of the Offer
     * @return {object} Orders with the corresponding offer ID
     */
    async getByOfferId(offerId, perPage = 99999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/offerModel/offerId', {
                params: { offerId, perPage, currentPage, pagesToLoad },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all Orders by client ID
     *
     * @param {string} id ID of the Client
     * @return {object} Orders with the corresponding client ID
     */
    async getByClientId(clientId, perPage = 99999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/offerModel/getAll/clientId', {
                params: { id: clientId, perPage, currentPage, pagesToLoad },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all Orders by client ID
     *
     * @param {string} id ID of the Client
     * @return {object} Orders with the corresponding client ID
     */
    async getByClientIdBrands(clientBrandsId, perPage = 99999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/offerModel/getAll/clientBrandsId', {
                params: { clientBrandsId, perPage, currentPage, pagesToLoad },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Orders
     *
     * @param {object} orders Object with the creation data
     * @return {object} Newly created Orders
     */
    async create(orders) {
        let reqBody = { ...orders };
        try {
            const res = await axios.post('/orders', reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Deletes Orders by ID
     *
     * @param {string} ordersId ID of the Orders to be deleted
     * @return {string} ID of the deleted Orders
     */
    async delete(ordersId) {
        try {
            await axios.delete('/offerModel/delete/' + ordersId);
            return {
                ok: true,
                data: {
                    id: ordersId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: ordersId,
                },
            };
        }
    },

    async update(orderId, body) {
        try {
            await axios.put(`/offerModel/${orderId}`, body);
            return {
                ok: true,
                data: orderId,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
                error,
            };
        }
    },

    async updateLaunch(id, dateBillingAdvancePayment) {
        try {
            await axios.patch(`/offerModel/id/launch?id=${id}&dateBillingAdvancePayment=${dateBillingAdvancePayment}`);
            return {
                ok: true,
                data: {},
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
                error,
            };
        }
    },

    async updateVIN(id, chassisNumber) {
        try {
            await axios.patch(`/offerModel/id/updateVIN?id=${id}&chassisNumber=${chassisNumber}`);
            return {
                ok: true,
                data: {},
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
                error,
            };
        }
    },

    async updateOutOfProduction(id, dateExitProduction) {
        try {
            await axios.patch(`/offerModel/id/out?id=${id}&dateExitProduction=${dateExitProduction}`);
            return {
                ok: true,
                data: {},
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
                error,
            };
        }
    },
};

export default Orders;
