import React, { useState, useEffect } from 'react';
import styles from './Dashboard.module.scss';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Typography, Box } from '@mui/material';
import { UtilityButton } from 'lib/components/Buttons/buttons.jsx';

import { ReactComponent as OfertareIcon } from 'resources/svg/administrationPage/receipt-add.svg';
import { ReactComponent as OferteIcon } from 'resources/svg/administrationPage/receipt-item.svg';
import { ReactComponent as SarciniIcon } from 'resources/svg/administrationPage/task-square.svg';
import { ReactComponent as ObiectiveIcon } from 'resources/svg/administrationPage/chart.svg';
import { ReactComponent as ClientiIcon } from 'resources/svg/administrationPage/profile-2user.svg';
import { ReactComponent as VehicleIcon } from 'resources/svg/administrationPage/3d-cube-scan.svg';
import { ReactComponent as WorkPointsIcon } from 'resources/svg/menu-navbar/WorkPointsIcon.svg';
import { ReactComponent as SourceIcon } from 'resources/svg/administrationPage/client-source.svg';

import GroupIcon from '@mui/icons-material/Group';
import { GeneralAdministrationButton } from 'lib/components/Buttons/buttons.jsx';
import TableLayout from 'lib/components/Tables/TabsLayout';
import { CustomTable } from 'lib';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Helmet } from 'react-helmet';
import { useUser } from 'hooks/useUser';
import { ObjectivesAPI } from 'api_darex';
import PercentageComponent from 'pages/Objectives/PercentageComponent';
import { Tooltip } from 'lib';
import InfoIcon from '@mui/icons-material/Info';

import dayjs from 'dayjs';
import { calculatePercentage, getProcentColor } from 'utils/functions';

function Dashboard() {
    const { t } = useTranslation();
    let history = useHistory();
    const { vw } = useWindowDimensions();
    const { user, can } = useUser();
    const [loadingDacia, setLoadingDacia] = useState(true);
    const [loadingRenault, setLoadingRenault] = useState(true);
    const [loadingNissan, setLoadingNissan] = useState(true);
    const [daciaObjectives, setDaciaObjectives] = useState([]);
    const [renaultObjectives, setRenaultObjectives] = useState([]);
    const [nissanObjectives, setNissanObjectives] = useState([]);
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const existDacia = user.UserBrand.map((item) => item.brandId).includes('1') ? true : false;
    const existRenault = user.UserBrand.map((item) => item.brandId).includes('2') ? true : false;
    const existNissan = user.UserBrand.map((item) => item.brandId).includes('3') ? true : false;
    const [activeTab, setActiveTab] = useState(0);
    const [currentUserObjectives, setCurrentUserObjectives] = useState([]);
    const todaysDate = dayjs().format();

    const getObjectivesByUserId = () => {
        ObjectivesAPI.getByUserId(user.id, todaysDate).then((res) => setCurrentUserObjectives(res.data.content));
    };

    const getDaciaObjectives = () => {
        ObjectivesAPI.get({ startDate: todaysDate, brandId: '1' }, '9999', '0', '1').then((res) => {
            setDaciaObjectives(res.data.content);
            setLoadingDacia(false);
        });
    };

    const getRenaultObjectives = () => {
        ObjectivesAPI.get({ startDate: todaysDate, brandId: '2' }, '9999', '0', '1').then((res) => {
            setRenaultObjectives(res.data.content);
            setLoadingRenault(false);
        });
    };

    const getNissanObjectives = () => {
        ObjectivesAPI.get({ startDate: todaysDate, brandId: '3' }, '9999', '0', '1').then((res) => {
            setNissanObjectives(res.data.content);
            setLoadingNissan(false);
        });
    };
    useEffect(() => {
        if (user.roleId === '4' || user.roleID === '5') getObjectivesByUserId();
        getDaciaObjectives();
        getRenaultObjectives();
        getNissanObjectives();
    }, []);

    const emptyArray = [{ id: 1, name: 'dummy', val: '1' }];

    //labels data for pages
    const labelsDacia = [
        { id: 'counselor', label: t('Counselor'), minWidth: 10 },
        {
            id: 'pfDeliveries',
            label: t('PF Deliveries'),
            minWidth: 10,
        },
        {
            id: 'pjDeliveries',
            label: t('PJ Deliveries'),
            minWidth: 10,
        },
        {
            id: 'electricDeliveries',
            label: t('Electric Deliveries'),
            minWidth: 10,
        },
        {
            id: 'electricOrders',
            label: t('Electric Orders'),
            minWidth: 10,
        },
        {
            id: 'thermicOrders',
            label: t('Thermic Orders'),
            minWidth: 10,
        },
        {
            id: 'hybridOrders',
            label: t('Hybrid Orders'),
            minWidth: 10,
        },
    ];

    const labelsRenault = [
        { id: 'counselor', label: t('Counselor'), minWidth: 10 },
        {
            id: 'vpDeliveries',
            label: t('VP Deliveries'),
            minWidth: 10,
        },
        {
            id: 'vuDeliveries',
            label: t('VU Deliveries'),
            minWidth: 10,
        },
        {
            id: 'totalDeliveries',
            label: t('Total Deliveries'),
            minWidth: 10,
        },
        { id: 'pfOrders', label: t('PF Orders'), minWidth: 10 },
        { id: 'pjOrders', label: t('PJ Orders'), minWidth: 10 },
        { id: 'totalOrders', label: t('Total Orders'), minWidth: 10 },
    ];

    const labelsNissan = [
        { id: 'counselor', label: t('Counselor'), minWidth: 10 },
        {
            id: 'totalOrders',
            label: t('Total Deliveries'),
            minWidth: 10,
        },
    ];

    const cellModifierDacia = (row, column, value) => {
        if (column.id === 'counselor') {
            return (
                <div>
                    <Typography variant="tableContent"> {row.user?.name + ' ' + row.user?.firstName}</Typography>
                </div>
            );
        }
        if (column.id === 'pfDeliveries') {
            let value = calculatePercentage(row.deliveriesPF, row.tDeliveriesPF);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.deliveriesPF === null ? '0' : row.deliveriesPF}/{row.tDeliveriesPF}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'pjDeliveries') {
            let value = calculatePercentage(row.deliveriesPJ, row.tDeliveriesPJ);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.deliveriesPJ === null ? '0' : row.deliveriesPJ}/{row.tDeliveriesPJ}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }

        if (column.id === 'electricDeliveries') {
            let value = calculatePercentage(row.electricalDeliveries, row.tElectricalDeliveries);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.electricalDeliveries === null ? '0' : row.electricalDeliveries}/{row.tElectricalDeliveries}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'electricOrders') {
            let value = calculatePercentage(row.electricalOrders, row.tElectricalOrders);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.electricalOrders === null ? '0' : row.electricalOrders}/{row.tElectricalOrders}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'thermicOrders') {
            let value = calculatePercentage(row.thermicOrders, row.tThermicOrders);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.thermicOrders === null ? '0' : row.thermicOrders}/{row.tThermicOrders}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'hybridOrders') {
            let value = calculatePercentage(row.hybridOrders, row.tHybridOrders);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.hybridOrders === null ? '0' : row.hybridOrders}/{row.tHybridOrders}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
    };

    const cellModifierRenault = (row, column, value) => {
        if (column.id === 'vpDeliveries') {
            let value = calculatePercentage(row.deliveriesVP, row.tDeliveriesVP);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.deliveriesVP === null ? '0' : row.deliveriesVP}/{row.tDeliveriesVP}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'vuDeliveries') {
            let value = calculatePercentage(row.deliveriesVU, row.tDeliveriesVU);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.deliveriesVU === null ? '0' : row.deliveriesVU} /{row.tDeliveriesVU}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }

        if (column.id === 'totalDeliveries') {
            let value = calculatePercentage(row.totalDeliveries, row.tTotalDeliveries);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.totalDeliveries}/{row.tTotalDeliveries}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }

        if (column.id === 'totalOrders') {
            let value = calculatePercentage(row.totalOrders, row.tTotalOrders);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.totalOrders}/{row.tTotalOrders}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'pfOrders') {
            let value = calculatePercentage(row.ordersPF, row.tOrdersPF);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.ordersPF === null ? '0' : row.ordersPF}/{row.tOrdersPF}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
        if (column.id === 'pjOrders') {
            let value = calculatePercentage(row.ordersPJ, row.tOrdersPJ);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.ordersPJ === null ? '0' : row.ordersPJ}/{row.tOrdersPJ}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }

        if (column.id === 'counselor') {
            return (
                <div>
                    <Typography variant="tableContent"> {row.user.name + ' ' + row.user.firstName}</Typography>
                </div>
            );
        }
    };

    const cellModifierNissan = (row, column, value) => {
        if (column.id === 'counselor') {
            return (
                <div>
                    <Typography variant="tableContent"> {row.user.name + ' ' + row.user.firstName}</Typography>
                </div>
            );
        }
        if (column.id === 'totalOrders') {
            let value = calculatePercentage(row.totalsDeliveries, row.tTotalsDeliveries);
            let color = getProcentColor(value);
            return (
                <div>
                    <Typography variant="tableContent">
                        {row.totalsDeliveries === null ? '0' : row.totalsDeliveries}/{row.tTotalsDeliveries}
                    </Typography>
                    <PercentageComponent value={value} color={color} />
                </div>
            );
        }
    };
    const generalAdministrationData = [
        {
            id: 1,
            text: t('Users'),
            icon: <GroupIcon />,
            path: '/users',
            render: can(3),
        },
        {
            id: 2,
            text: t('Bidding'),
            icon: <OfertareIcon />,
            path: '/offers/create',
            render: can(5) && user.roleId === '4',
        },
        {
            id: 3,
            text: t('Offers'),
            icon: <OferteIcon />,
            path: '/offers',
            render: can(6),
        },
        {
            id: 4,
            text: t('Tasks'),
            icon: <SarciniIcon />,
            path: '/tasks',
            render: can(9),
        },
        {
            id: 5,
            text: t('Objectives'),
            icon: <ObiectiveIcon />,
            path: '/objectives',
            render: can(12),
        },
        {
            id: 6,
            text: t('Clients'),
            icon: <ClientiIcon />,
            path: '/clients',
            render: can(15),
        },
    ];

    const unitFeaturesData = [
        {
            id: 1,
            text: t('Vehicles'),
            icon: <VehicleIcon />,
            path: '/vehicles',
            render: can(18),
        },
        {
            id: 2,
            text: t('Work points'),
            icon: <WorkPointsIcon />,
            path: '/work-points',
            render: can(18),
        },
        {
            id: 3,
            text: t('Client source'),
            icon: <SourceIcon />,
            path: '/client-source',
            render: can(18),
        },
    ];

    function getDelivaryReport(array, keys) {
        const report = {};

        // Generate totals / dones
        for (const row of array) {
            for (const key of keys) {
                const tKey = `t${key.charAt(0).toUpperCase()}${key.slice(1)}`;

                if (!(key in report)) {
                    report[key] = {
                        done: 0,
                        total: 0,
                        percent: 0,
                    };
                }

                report[key].done += row[key];
                report[key].total += row[tKey];
            }
        }

        // Calculate percentages
        if (Object.keys(report).length > 0)
            for (const key of keys) {
                const res = ((report[key].done * 100) / report[key].total).toFixed(2);
                if (isNaN(res)) report[key].percent = 0;
                else {
                    report[key].percent = res;
                }
            }

        return report;
    }

    //objectives details for Dacia

    const deliveryKeys = [
        'deliveriesPF',
        'deliveriesPJ',
        'electricalDeliveries',
        'electricalOrders',
        'thermicOrders',
        'hybridOrders',
    ];

    const deliveryReport = getDelivaryReport(daciaObjectives, deliveryKeys);

    const deliveryRender = Object.keys(deliveryReport).map((key) => ({
        value: (
            <div key={key}>
                <span style={{ marginRight: '8px' }}>
                    {deliveryReport[key].done} / {deliveryReport[key].total}
                    <PercentageComponent
                        value={deliveryReport[key].percent}
                        color={getProcentColor(deliveryReport[key].percent)}
                    />
                </span>
            </div>
        ),
    }));

    //objectives details for Renault

    const deliveryKeysRenault = [
        'deliveriesVP',
        'deliveriesVU',
        'totalDeliveries',
        'ordersPF',
        'ordersPJ',
        'totalOrders',
    ];

    const deliveryReportRenault = getDelivaryReport(renaultObjectives, deliveryKeysRenault);

    const deliveryRenderRenault = Object.keys(deliveryReportRenault).map((key) => ({
        value: (
            <div key={key}>
                <span style={{ marginRight: '8px' }}>
                    {deliveryReportRenault[key].done} / {deliveryReportRenault[key].total}
                    <PercentageComponent
                        value={deliveryReportRenault[key].percent}
                        color={getProcentColor(deliveryReportRenault[key].percent)}
                    />
                </span>
            </div>
        ),
    }));

    //objectives details for Nissan

    const deliveryKeysNissan = ['totalsDeliveries'];
    const deliveryRaportNissan = getDelivaryReport(nissanObjectives, deliveryKeysNissan);

    const deliveryRenderNissan = Object.keys(deliveryRaportNissan).map((key) => ({
        value: (
            <div key={key}>
                <span style={{ marginRight: '8px' }}>
                    {deliveryRaportNissan[key].done} / {deliveryRaportNissan[key].total}
                    <PercentageComponent
                        value={deliveryRaportNissan[key].percent}
                        color={getProcentColor(deliveryRaportNissan[key].percent)}
                    />
                </span>
            </div>
        ),
    }));
    const tabs = [
        {
            label: 'DACIA',
            id: 0,
            render: existDacia,
            hasUtility: true,
            component:
                user.roleId !== '4' && user.roleID !== '5 ' ? (
                    <CustomTable
                        labels={labelsDacia}
                        tableData={emptyArray}
                        // cellModifier={cellModifierDacia}
                        withPagination={false}
                        hover={false}
                        withBoxShadow={false}
                        withRowColors={false}
                        loading={loadingDacia}
                        bottomInfo={[
                            {
                                value: (
                                    <div>
                                        <span style={{ fontWeight: 700 }}> {t('Total Orders')} </span>
                                    </div>
                                ),
                            },
                            ...deliveryRender,
                        ]}
                    />
                ) : (
                    <CustomTable
                        labels={labelsDacia}
                        tableData={currentUserObjectives.filter((objective) => objective.brandId === '1')}
                        cellModifier={cellModifierDacia}
                        withPagination={false}
                        hover={false}
                        withBoxShadow={false}
                        withRowColors={false}
                        loading={loadingDacia}
                    />
                ),
        },
        {
            label: 'RENAULT',
            id: 1,
            render: existRenault,
            hasUtility: true,
            component:
                user.roleId !== '4' && user.roleID !== '5 ' ? (
                    <CustomTable
                        labels={labelsRenault}
                        tableData={emptyArray}
                        // cellModifier={cellModifierRenault}
                        withPagination={false}
                        hover={false}
                        withBoxShadow={false}
                        withRowColors={false}
                        loading={loadingRenault}
                        bottomInfo={[
                            {
                                value: (
                                    <div>
                                        <span style={{ fontWeight: 700 }}> {t('Total Orders')} </span>
                                    </div>
                                ),
                            },
                            ...deliveryRenderRenault,
                        ]}
                    />
                ) : (
                    <CustomTable
                        labels={labelsRenault}
                        tableData={currentUserObjectives.filter((objective) => objective.brandId === '2')}
                        cellModifier={cellModifierRenault}
                        withPagination={false}
                        hover={false}
                        withBoxShadow={false}
                        withRowColors={false}
                        loading={loadingRenault}
                    />
                ),
        },
        {
            label: 'NISSAN',
            id: 2,
            render: existNissan,
            hasUtility: true,
            component:
                user.roleId !== '4' && user.roleID !== '5 ' ? (
                    <CustomTable
                        labels={labelsNissan}
                        tableData={emptyArray}
                        // cellModifier={cellModifierNissan}
                        withPagination={false}
                        hover={false}
                        withBoxShadow={false}
                        withRowColors={false}
                        loading={loadingNissan}
                        bottomInfo={[
                            {
                                value: (
                                    <div>
                                        <span style={{ fontWeight: 700 }}> {t('Total Orders')} </span>
                                    </div>
                                ),
                            },
                            ...deliveryRenderNissan,
                        ]}
                    />
                ) : (
                    <CustomTable
                        labels={labelsNissan}
                        tableData={currentUserObjectives.filter((objective) => objective.brandId === '3')}
                        cellModifier={cellModifierNissan}
                        withPagination={false}
                        hover={false}
                        withBoxShadow={false}
                        withRowColors={false}
                        loading={loadingNissan}
                    />
                ),
        },
    ];

    const utility = [
        {
            id: 0,
            component: (
                <UtilityButton width="155px" onClick={() => history.push('/objectives')}>
                    {t('SEE OBJECTIVES')}
                </UtilityButton>
            ),
            render: can(12),
        },
    ];

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Dashboard')}</title>
            </Helmet>
            <div className={styles.header}>
                <Typography variant="firstPageTitle" sx={{ padding: vw <= 640 ? '0 1rem' : 0 }}>
                    {t('Hello')}, {user.firstName + ' ' + user.name}! {t('Welcome to the platform')}!
                </Typography>
            </div>
            <div className={styles.container}>
                <div>
                    {user.roleId !== '5' && (
                        <>
                            <Box
                                sx={{
                                    marginTop: '32px',
                                    marginBottom: '16px',
                                    gap: '1rem',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="cardHeader">{t('Objectives')}</Typography>
                                <Tooltip
                                    position="right"
                                    textTip={t('* Here are displayed only the objectives on the current month!')}
                                    style={{
                                        border: '1px solid',
                                        borderColor: '#ff4b55',
                                        color: '#ff4b55',
                                        fontSize: '13px',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'inline-flex',
                                            color: '#ff4b55',
                                            marginTop: '1rem',
                                        }}
                                    >
                                        <InfoIcon
                                            sx={{
                                                fontSize: '22px',
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            </Box>

                            <TableLayout
                                tabs={tabs}
                                utility={utility}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                withBoxShadow={true}
                            />
                        </>
                    )}

                    <Box sx={{ marginTop: '32px', marginBottom: '16px', textAlign: vw <= 640 ? 'center' : 'none' }}>
                        <Typography variant="cardHeader">{t('General administration')}</Typography>
                    </Box>

                    <div className={styles.generalAdministration}>
                        {generalAdministrationData.map((data) => {
                            return (
                                data.render && (
                                    <div
                                        key={data.id}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <GeneralAdministrationButton
                                            onClick={() => history.push(`${data.path}`)}
                                            text={data.text}
                                            icon={data.icon}
                                        />
                                    </div>
                                )
                            );
                        })}
                    </div>

                    {can(18) && (
                        <Box sx={{ marginTop: '32px', marginBottom: '16px', textAlign: vw <= 640 ? 'center' : 'none' }}>
                            <Typography variant="cardHeader">{t('Nomenclatures')}</Typography>
                        </Box>
                    )}

                    <div className={styles.unitsCharacteristics}>
                        {unitFeaturesData.map((data) => {
                            return (
                                data.render && (
                                    <div
                                        key={data.id}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <GeneralAdministrationButton
                                            onClick={() => history.push(`${data.path}`)}
                                            text={data.text}
                                            icon={data.icon}
                                        />
                                    </div>
                                )
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
