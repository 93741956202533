import axios from './axios';

const Users = {
    /**
     * Fetches all Users's
     *
     * @return {array} Array of Users's
     */
    async get(allowBlocked = false, perPage = 9999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/users', {
                params: { allowBlocked, perPage, currentPage, pagesToLoad },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches one Users by it's ID
     *
     * @param {string} id ID of the Users
     * @return {object} Users with the corresponding ID
     */
    async getById(usersId) {
        try {
            const res = await axios.get('/users/' + usersId);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    async getByBrandsId(brandId, perPage = 9999, currentPage = 0, pagesToLoad = 1) {
        try {
            const res = await axios.get('/users/findByBrandId/brandId', {
                params: {
                    brandId,
                    perPage,
                    currentPage,
                    pagesToLoad,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    async getByBrandsIdNoPagination(brandId) {
        try {
            const res = await axios.get('/users/findByBrand/brandId', {
                params: {
                    brandId,
                },
            });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all Users that contain the the specified string
     *
     * @param {string} text used to find Users
     * @return {object} Array of Users's
     */
    async searchUsers(text) {
        try {
            const res = await axios.get(`/users/findByString/text?text=${text}`);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all Users witch contain the the specified string
     *
     * @param {string} text used to find Users
     * @return {object} Array of Users's
     */
    async filterUsers(data) {
        try {
            const res = await axios.get(`/users/filter/users`, { params: { ...data } });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all Users witch contain the the specified string
     *
     * @param {string} text used to find Users
     * @return {object} Array of Users's
     */
    async getReport(data) {
        const newData = { startDate: new Date(data.startDate), endDate: new Date(data.endDate) };
        try {
            const res = await axios.get(`/reports`, { params: { ...newData } });

            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Fetches all Users witch contain the the specified string
     *
     * @param {string} text used to find Users
     * @return {object} Array of Users's
     */
    async downloadReport(id) {
        try {
            const res = await axios.get(`/reports/${id}`, { responseType: 'arraybuffer' });
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Users
     *
     * @param {object} users Object with the creation data
     * @return {object} Newly created Users
     */
    async create(users) {
        try {
            const res = await axios.post('/users', users);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Add user image
     *
     * @param {file} users file with the image data
     * @param {string} userId userId to be updated
     * @return {object} Newly created file
     */
    async addPicture(userId, file) {
        try {
            const res = await axios.post(
                '/users/Profile/' + userId,
                { file },
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            );
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Update user image
     *
     * @param {file} users file with the image data
     * @param {string} userId userId to be updated
     * @return {object} Newly updated file
     */
    async updatePicture(userId, file) {
        try {
            const res = await axios.post(
                '/users/Profile/' + userId,
                { file },
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }
            );
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Ger user image
     *
     * @param {string} fileId id of the file
     * @return {object} User picture
     */
    async getPicture(fileId) {
        try {
            const { data: imgBlob } = await axios.get(`/users/profile/${fileId}`, {
                responseType: 'blob',
            });

            const imgUrl = URL.createObjectURL(imgBlob);

            return {
                ok: true,
                data: imgUrl,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Update one Users
     *
     * @param {object} users Object with the update data
     * @param {string} id ID of the Users
     * @return {object} Newly updated Users
     */
    async update(users, id) {
        try {
            const res = await axios.put('/users/' + id, users);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Deletes Users by ID
     *
     * @param {string} usersId ID of the Users to be deleted
     * @return {string} ID of the deleted Users
     */
    async delete(usersId) {
        try {
            await axios.delete('/users/' + usersId);
            return {
                ok: true,
                data: {
                    id: usersId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: usersId,
                },
            };
        }
    },

    /**
     * Block User by ID
     *
     * @param {string} usersId ID of the User to be blocked
     * @return {string} ID of the blocked User
     */
    async block(usersId) {
        try {
            await axios.delete('/users/block/' + usersId);
            return {
                ok: true,
                data: {
                    id: usersId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: usersId,
                },
            };
        }
    },

    /**
     * Unblock User by ID
     *
     * @param {string} usersId ID of the User to be unblocked
     * @return {string} ID of the unblocked User
     */
    async unblock(usersId) {
        try {
            await axios.patch('/users/unBlock/' + usersId);
            return {
                ok: true,
                data: {
                    id: usersId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: usersId,
                },
            };
        }
    },

    /**
     * Change User password
     *
     * @param {string} email email of the Users to be updated
     * @param {string} newPassword new user password
     * @param {string} oldPassword old user password
     * @return {string} new password for the specific user
     */
    async changeUserPassword(email, oldPassword, newPassword) {
        try {
            const res = await axios.put('/users/user/reset_user_password', { email, oldPassword, newPassword });
            const { data } = res;

            if (data.statusCode === 400) {
                return {
                    ok: false,
                    error: data.error,
                    data: {},
                };
            } else {
                return {
                    ok: true,
                    data: data,
                };
            }
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Change User password
     *
     * @param {string} email email of the Users to be updated
     * @param {string} newPassword new user password
     * @param {string} oldPassword old user password
     * @return {string} new password for the specific user
     */
    async hardChangeUserPassword(email, newPassword) {
        try {
            const res = await axios.put('/users/userPass/hardResetUser_password/pass', { email, newPassword });
            const { data } = res;

            if (data.statusCode === 400) {
                return {
                    ok: false,
                    error: data.error,
                    data: {},
                };
            } else {
                return {
                    ok: true,
                    data: data,
                };
            }
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Reset User password
     *
     * @param {string} email email of the Users
     * @return {email} An email with a link will be sent to the user in question with is gonna be used to reset the password
     */
    async recoverPassword(email) {
        try {
            const res = await axios.post('/users/user/recover_password/' + email);
            const { data } = res;
            return {
                ok: true,
                data: data.message,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    /**
     * Reset User password
     *
     * @param {string} token token of the Users
     * @return {email} a message with the updated user
     */
    async resetUserPassword(token, newPassword) {
        try {
            const res = await axios.put('/users/user/reset_password/' + token, { newPassword });
            const { data } = res;
            return {
                ok: true,
                data: data.message,
            };
        } catch (error) {
            return {
                ok: false,
                error,
                data: {},
            };
        }
    },

    async moveClients(usersId, users) {
        try {
            await axios.patch('/users/user/move_clients/' + usersId, { users });
            return {
                ok: true,
                data: {
                    id: usersId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: usersId,
                },
            };
        }
    },
};

export default Users;
