import axios from './axios';

const Cities = {
    /**
     * Fetches all Cities's
     *
     * @return {array} Array of Cities's
     */
    async get() {
        try {
            const res = await axios.get('/cities');
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },
    async getAllCities() {
        try {
            const res = await axios.get('/cities');
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },
    async getAllStates() {
        try {
            const res = await axios.get('/states');
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches one Cities by it's ID
     *
     * @param {string} id ID of the Cities
     * @return {object} Cities with the corresponding ID
     */
    async getById(citiesId) {
        try {
            const res = await axios.get('/cities/' + citiesId);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Cities
     *
     * @param {object} cities Object with the creation data
     * @return {object} Newly created Cities
     */
    async create(cities) {
        let reqBody = { ...cities };
        try {
            const res = await axios.post('/cities', reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Deletes Cities by ID
     *
     * @param {string} citiesId ID of the Cities to be deleted
     * @return {string} ID of the deleted Cities
     */
    async delete(citiesId) {
        try {
            await axios.delete('/cities/' + citiesId);
            return {
                ok: true,
                data: {
                    id: citiesId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: citiesId,
                },
            };
        }
    },
};

export default Cities;
