import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField } from 'lib';
import { CustomDialog } from 'lib';
import { OrdersAPI } from 'api_darex';
import { toast } from 'react-toastify';

const ChassisNumberModal = ({ open, setOpen, getOrder, order }) => {
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const INITIAL_FORM_STATE = {
        chassisNumber: order.chassisNumber ? order.chassisNumber : '',
    };

    const FORM_VALIDATION = yup.object().shape({
        chassisNumber: yup.string().trim().required(t('The chassis series is mandatory!')),
    });

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={t('Edit chassis number')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={handleSubmit}
            onClickButtonClose={() => {
                setOpen(false);
            }}
            width="580px"
            buttonFinishLoading={loading}
            buttonCloseDisabled={loading}
        >
            <Formik
                innerRef={formRef}
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    setLoading(true);
                    OrdersAPI.updateVIN(order.id, values.chassisNumber).then((res) => {
                        if (res.ok) {
                            getOrder();
                            setOpen(false);
                            setLoading(false);
                            toast.success(t('The chassis number has been updated successfully!'));
                        } else {
                            setLoading(false);
                            toast.error(t('The chassis number could not be updated successfully!'));
                        }
                    });
                }}
            >
                <Form>
                    <div className="add-user-wrapper" style={{ paddingTop: '10px' }}>
                        <TextField name="chassisNumber" label={`${t('Chassis Number')}`} size="medium" />
                    </div>
                </Form>
            </Formik>
        </CustomDialog>
    );
};

export default ChassisNumberModal;
