const ro = {
    Profile: 'Profil',
    'General settings': 'Setări generale',
    'Profile settings': 'Setări profil',
    'Manage notifications': 'Gestionează notificările',
    Administrator: 'Administrator',
    'Change the language': 'Schimbă limba',
    'Updates the language in which the application is displayed': 'Actualizează limba în care este afișată aplicația',
    'Setting permissions': 'Setarea permisiunilor',
    'Access allowed only for Admin': 'Acces permis doar pentru Admin',
    'Complete name': 'Nume complet',
    Modify: 'Modifică',
    'Login data settings': 'Setările datelor de logare',
    'Change the password': 'Schimbă parola',
    'Update your password as often as possible for better account security':
        'Actualizează-ți parola cât mai repede pentru o mai bună securizare a contului',
    'Close the account': 'Închide contul',
    'This action is irreversible': 'Această acțiune este ireversibilă',
    'Close!': 'Închide!',
    'Change last name/first name': 'Schimbă numele/prenumele',
    'You entered the wrong password!': 'Ai introdus parola greșită!',
    'The current password is mandatory!': 'Parola curentă este obligatorie!',
    'You have entered an invalid password! Password must contain at least 8 characters, one capital letter and one number!':
        'Ați introdus o parolă invalidă! Parola trebuie să conțină minim 8 caractere, cel putin o majusculă și cel putin o cifră!',
    'The new password is mandatory!': 'Noua parolă este obligatorie!',
    'The confirmation password does not match the new password!':
        'Parola de confirmare nu se potrivește cu noua parolă!',
    'The confirm password is mandatory!': 'Confirmarea parolei este obligatorie!',
    'Current password': 'Parola curentă',
    'New password': 'Parola nouă',
    'Confirm password': 'Confirmă parolă',
    'This action is irreversible!': 'Această acțiune este ireversibilă!',
    'Change your profile picture': 'Schimbă imaginea de profil',
    'Image name': 'Numele imaginii',
    'Set the language of the platform': 'Setează limba platformei',
    'Upload image': 'Încarcă imagine',
    'Change image': 'Schimbă imagine',
    'Both passwords need to be the same!': 'Ambele parole trebuie să coincidă!',
    Login: ' Autentifică-te',
    'Recover password': 'Recuperează parola',
    'Enter the email address you created your account with. You will receive a password reset link at this address.':
        'Introduceți adresa de email pe care are ați creat contul dumneavoastră. Pe această adresa veți primi un link de resetare a parolei.',
    'Send reset link': 'Trimitere link de resetare',
    'You have successfully sent the reset link!': 'Ați trimis cu succes linkul de resetare!',
    'You have successfully logged in!': 'V-ați autentificat cu succes!',
    'You must enter a valid email address!': 'Trebuie să introduceți un format de email valid!',
    'You must enter a valid password format!': 'Trebuie să introduceți un format de parolă valid!',
    'Email address is mandatory!': 'Adresa de email este obligatorie!',
    'Password is required!': 'Parola este obligatorie!',
    'Reset password': 'Resetează parola',
    'You have successfully reset your password!': 'Ați resetat parola cu succes!',
    'The new password must be different from the old password!':
        'Noua parolă trebuie să fie diferită de vechea parolă!',
    'No workpoint': 'Nu există',
    "Current password doesn't match!": 'Parola curentă nu se potrivește!',
    'End date should be greater then start date!': 'Data de încheiere ar trebui să fie mai mare decât data de început!',
    'Download report': 'Descărcați raportul',
    'Download a report of the database between two dates': 'Descărcați un raport al bazei de date între două date',
    REPORT: 'RAPORT',
    'Report downloaded successfully!': 'Raport descărcat cu succes!',
    "Report could't be downloaded!": 'Raportul nu a putut fi descărcat!',
};

export default { ro };
