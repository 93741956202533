import React, { useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styles from './CreateOffer.module.scss';

import { NavigationButton, UtilityButton } from 'lib/components/Buttons/buttons';
import { CustomDialog, Tooltip } from 'lib';
import UnitForm from 'nomenclators/Vehicles/UnitForm/UnitForm';
import PropTypes from 'prop-types';
import { useConfirm } from 'hooks/useConfirm';
import { useTranslation } from 'react-i18next';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LayersIcon from '@mui/icons-material/Layers';
import { toast } from 'react-toastify';

const AddUnits = ({ units, setUnits, client }) => {
    const formikRef = useRef();
    const [unitToEdit, setUnitToEdit] = useState({});
    const [open, setOpen] = useState(false);
    const confirm = useConfirm();
    const { t } = useTranslation();

    const addUnit = (unit) => {
        setUnits([...units, unit]);
    };

    const duplicateUnit = (baseUnit) => {
        const i = units.findIndex((u) => u.id === baseUnit.id);
        const newUnit = { ...baseUnit, id: Date.now() };
        setUnits([...units.slice(0, i), newUnit, ...units.slice(i, units.length)]);
    };

    const updateUnit = (unit) => {
        const unitIndex = units.findIndex((u) => u.id === unit.id);
        if (unitIndex < 0) return;

        const newUnits = [...units];
        newUnits[unitIndex] = { ...unit };
        setUnits(newUnits);
        toast.success(t('Unit has been successfully edited'));
    };

    const deleteUnit = (i) => {
        confirm(t('Are you sure you want to delete this unit?'), () => {
            const newUnits = [...units];
            newUnits.splice(i, 1);
            setUnits(newUnits);
            toast.success(t('Unit has been successfully deleted'));
        });
    };

    return (
        <React.Fragment>
            <div className={styles.container_3}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 5 }}>
                    <Typography variant="h6">{t('Units')}</Typography>
                    <UtilityButton
                        onClick={() => {
                            setUnitToEdit({});
                            setOpen(true);
                        }}
                    >
                        {t('Add unit')}
                    </UtilityButton>
                </Box>
                {units.length > 0 && (
                    <ul>
                        {/* Headers */}
                        <li>
                            <Typography variant="clientListMember">{t('Brand')}</Typography>
                            <Typography variant="clientListMember">{t('Model')}</Typography>
                            <Typography variant="clientListMember">{t('Color')}</Typography>
                            <Typography variant="clientListMember">{t('Engine')}</Typography>
                            <Typography variant="clientListMember">{t('Price')}</Typography>
                            <b></b>
                        </li>

                        {units.map((unit, i) => (
                            <li key={unit.id}>
                                <Typography variant="clientListMember">{unit.unitRenderData.brand}</Typography>
                                <Typography variant="clientListMember">{unit.unitRenderData.model}</Typography>
                                <Typography variant="clientListMember">{unit.unitRenderData.color}</Typography>
                                <Typography variant="clientListMember">{unit.unitRenderData.engine}</Typography>
                                <Typography variant="clientListMember">{unit.price}</Typography>

                                {/* Action Buttons */}
                                <section>
                                    <Tooltip textTip={t('Duplicate unit')} arrow style={{ borderColor: '#5B80BA' }}>
                                        <LayersIcon onClick={() => duplicateUnit(unit)} />
                                    </Tooltip>

                                    <Tooltip textTip={t('Edit unit')} arrow style={{ borderColor: '#5B80BA' }}>
                                        <EditIcon
                                            onClick={() => {
                                                setUnitToEdit(unit);
                                                setOpen(true);
                                            }}
                                        />
                                    </Tooltip>

                                    <Tooltip textTip={t('Delete unit')} arrow style={{ borderColor: '#5B80BA' }}>
                                        <DeleteIcon onClick={() => deleteUnit(i)} />
                                    </Tooltip>
                                </section>
                            </li>
                        ))}
                    </ul>
                )}

                {!Boolean(units.length) && (
                    <Box
                        sx={{
                            background: '#f4f4f4',
                            borderRadius: '10px',
                            padding: '64px 16px',
                            textAlign: 'center',
                        }}
                    >
                        <Typography>{t('No units')}</Typography>
                    </Box>
                )}
            </div>

            <CustomDialog maxWidth={1000} width={1000} open={open} setOpen={setOpen}>
                <UnitForm
                    unit={unitToEdit}
                    client={client}
                    formikRef={formikRef}
                    onSubmit={(unit) => {
                        if (Object.keys(unitToEdit).length === 0) addUnit(unit);
                        else updateUnit(unit);
                        setOpen(false);
                    }}
                />
                <div className={styles.add_unit_footer}>
                    <NavigationButton color="red" onClick={() => setOpen(false)}>
                        {t('Back')}
                    </NavigationButton>
                    <NavigationButton color="blue" onClick={() => formikRef.current.handleSubmit()}>
                        {t('Finish')}
                    </NavigationButton>
                </div>
            </CustomDialog>
        </React.Fragment>
    );
};

AddUnits.propTypes = {
    units: PropTypes.array,
    setUnits: PropTypes.func,
};

AddUnits.defaultProps = {
    units: [],
    setUnits: () => {},
};

export default AddUnits;
