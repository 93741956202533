import React, { useState } from 'react';
import { CustomDialog } from 'lib';
import styles from './SeeUnitsModal.module.scss';
import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { NavigationButton } from 'lib/components/Buttons/buttons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: '#fff',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const SeeUnitsModal = ({ open, setOpen, units }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <CustomDialog maxWidth="725px" width="725px" open={open} setOpen={setOpen} title={t('Visualize units')}>
            {units.map((unit, i) => (
                <Accordion expanded={expanded === i} onChange={handleChange(i)} key={i}>
                    <AccordionSummary>
                        <Typography variant="objectivesList">
                            {t('Unit')} #{i + 1}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={styles['accordion_wrapper']}>
                            <div className={styles['accordion-content']}>
                                {/* Brand */}
                                <div>
                                    <Typography variant="clientData">{t('Brand')}</Typography>
                                    <Typography variant="clientListMember" sx={{ whiteSpace: 'nowrap' }}>
                                        {unit.unitRenderData.brand}
                                    </Typography>
                                </div>

                                {/* Model */}
                                <div>
                                    <Typography variant="clientData">{t('Model')}</Typography>
                                    <Typography variant="clientListMember" sx={{ whiteSpace: 'nowrap' }}>
                                        {unit.unitRenderData.model}
                                    </Typography>
                                </div>

                                {/* Color */}
                                <div>
                                    <Typography variant="clientData">{t('Color')}</Typography>
                                    <Typography variant="clientListMember" sx={{ whiteSpace: 'nowrap' }}>
                                        {unit.unitRenderData.color}
                                    </Typography>
                                </div>

                                {/* Engine */}
                                <div>
                                    <Typography variant="clientData">{t('Engine')}</Typography>
                                    <Typography variant="clientListMember" sx={{ whiteSpace: 'nowrap' }}>
                                        {unit.unitRenderData.engine}
                                    </Typography>
                                </div>

                                {/* Price */}
                                <div>
                                    <Typography variant="clientData">{t('Price')}</Typography>
                                    <Typography variant="clientListMember" sx={{ whiteSpace: 'nowrap' }}>
                                        {unit.price}
                                    </Typography>
                                </div>
                            </div>

                            {/* Options */}

                            {unit.options.length > 0 && (
                                <div className={styles['element']}>
                                    <Typography variant="clientData">{t('Options')}</Typography>
                                    <Typography variant="clientListMember">{unit.options}</Typography>
                                </div>
                            )}

                            {/* Accessories */}
                            {unit.accessories.length > 0 && (
                                <div className={styles['element']}>
                                    <Typography variant="clientData">{t('Accessories')}</Typography>
                                    <Typography variant="clientListMember">{unit.accessories}</Typography>
                                </div>
                            )}

                            {/* Details */}
                            {unit.details.length > 0 && (
                                <div className={styles['element']}>
                                    <Typography variant="clientData">{t('Details')}</Typography>
                                    <Typography variant="clientListMember">{unit.details}</Typography>
                                </div>
                            )}
                        </div>
                    </AccordionDetails>
                </Accordion>
            ))}
            <div className={styles.footer}>
                <NavigationButton onClick={() => setOpen(false)}>{t('Back')}</NavigationButton>
            </div>
        </CustomDialog>
    );
};

SeeUnitsModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    units: PropTypes.array,
};

SeeUnitsModal.defaultProps = {
    open: false,
    setOpen: () => {},
    units: [],
};

export default SeeUnitsModal;
