// react imports
import React, { useRef } from 'react';

import { Formik, Form } from 'formik';

import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { PasswordField } from 'lib';

import customTheme from 'lib/theme';
import { UtilityButton } from 'lib/components/Buttons/buttons.jsx';

import * as yup from 'yup';
import { passwordRegex } from 'utils/regex';

import logoRaisis from 'resources/LogoRaisisCRM.png';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { UsersAPI } from 'api_darex';
import { toast } from 'react-toastify';

const ResetPassword = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    const mobilePhone = useMediaQuery(theme.breakpoints.down('md'));
    const { token } = useParams();

    const formRef = useRef();

    const handleSubmitFormik = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const INITIAL_FORM_STATE = {
        newPassword: '',
        confirmPassword: '',
    };

    const FORM_VALIDATION = yup.object().shape({
        newPassword: yup
            .string()
            .trim()
            .matches(
                passwordRegex,
                t(
                    'You have entered an invalid password! Password must contain at least 8 characters, one capital letter and one number!'
                )
            )
            .required(t('The new password is mandatory!')),
        confirmPassword: yup
            .string()
            .trim()
            .when('newPassword', {
                is: (val) => (val && val.length > 0 ? true : false),
                then: yup.string().oneOf([yup.ref('newPassword')], t('Both passwords need to be the same!')),
            })
            .required(t('The confirm password is mandatory!')),
    });

    const handleResetPassword = ({ values }) => {
        UsersAPI.resetUserPassword(token, values.newPassword).then((res) => {
            if (res.ok) {
                toast.success(t('You have successfully reset your password!'));
                history.push('/login');
            } else {
                toast.error(t(res.error.response.data.message));
            }
        });
    };

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Reset password')}</title>
            </Helmet>
            <ThemeProvider theme={customTheme}>
                <Box
                    sx={{
                        width: '100vw',
                        minHeight: '100vh',
                        backgroundColor: '#FFFFFF',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            flex: 1,
                            gap: '7.5vw',
                            display: 'flex',
                            flexDirection: mobilePhone ? 'column' : 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: '2.5rem',
                        }}
                    >
                        <Box
                            sx={{
                                width: mobilePhone ? '90vw' : '40vw',
                                maxWidth: '500px',
                                flexShrink: 0,
                                borderRadius: '15px',
                                padding: '25px 32px',
                                boxShadow: '#eaeef4 0px 7px 29px 0px;',
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                <Formik
                                    innerRef={formRef}
                                    initialValues={{
                                        ...INITIAL_FORM_STATE,
                                    }}
                                    validationSchema={FORM_VALIDATION}
                                    onSubmit={(values) => {
                                        handleResetPassword({ values });
                                    }}
                                >
                                    <Form>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '25px',
                                            }}
                                        >
                                            <PasswordField name="newPassword" label="Parolă nouă" />
                                            <PasswordField name="confirmPassword" label="Confirmă parola" />
                                        </div>
                                    </Form>
                                </Formik>
                                <UtilityButton
                                    onClick={handleSubmitFormik}
                                    color="blue"
                                    variant="contained"
                                    style={{
                                        padding: '12px 14px',
                                    }}
                                >
                                    {t('Reset password')}
                                </UtilityButton>
                            </Box>
                        </Box>
                    </Box>

                    <img
                        src={logoRaisis}
                        alt="Raisis Software"
                        style={{ flexShrink: 0, width: '35%', maxWidth: '256px', paddingBlock: '2.5rem' }}
                    />
                </Box>
            </ThemeProvider>
        </>
    );
};

export default ResetPassword;
