import React, { useEffect, useState, useCallback } from 'react';
import { PrimaryButton } from 'lib/components/Buttons/buttons';
import Search from 'lib/components/Search/Search';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';
import AddTask from 'pages/Tasks/AddTask';
import FilterTasks from 'pages/Tasks/FilterTasks';
import { TasksAPI, ClientsAPI, UsersAPI } from 'api_darex';
import { useUser } from 'hooks/useUser';
import TasksComponent from 'lib/components/Tasks/TasksComponent';
import { PageLayout } from 'lib';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from 'lib';
import { Helmet } from 'react-helmet';
import FilterListIcon from '@mui/icons-material/FilterList';

const UserTasks = () => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [openAdd, setOpenAdd] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [doneTasks, setDoneTasks] = useState([]);
    const [toDoTasks, setToDoTasks] = useState([]);
    const [clients, setClients] = useState([]);
    const [pageNumberToDo, setPageNumberToDo] = useState(0);
    const [pageNumberDone, setPageNumberDone] = useState(0);
    const [hasMoreToDo, setHasMoreToDo] = useState(false);
    const [hasMoreDone, setHasMoreDone] = useState(false);
    const [loadingToDo, setLoadingToDo] = useState(false);
    const [loadingDone, setLoadingDone] = useState(false);
    const [userState, setUserState] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [triggerFilter, setTriggerFilter] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isFilter, setIsFilter] = useState(false);

    const { can, user } = useUser();
    const { id } = useParams();

    const [filterData, setFilterData] = useState({
        userId: Number(id),
        brandId: '',
        clientBrandsId: '',
        type: '',
        startDate: new Date(),
        endDate: new Date(),
    });

    const resetSearch = () => {
        setIsSearch(false);
        setSearch('');
    };

    const resetFilter = () => {
        setIsFilter(false);
        setFilterData({
            userId: Number(id),
            brandId: '',
            clientBrandsId: '',
            type: '',
            startDate: new Date(),
            endDate: new Date(),
        });
    };

    const getUserById = useCallback(() => {
        UsersAPI.getById(id).then((res) => {
            setUserState(res.data);
        });
    }, [id]);

    const getUserClients = useCallback(() => {
        ClientsAPI.getByUserIdBrands(id).then((res) => {
            setClients(
                res.data.content
                    .map((client) => ({
                        ...client.Clients,
                        brandId: client.brandId,
                        userId: client.userId,
                        clientsBrandId: client.id,
                        brand: client.Brand,
                        user: client.User,
                        clientUID: client.id,
                    }))
                    .filter((item) => user.UserBrand.map((uBrand) => uBrand.brandId).includes(item.brandId))
            );
        });
    }, [id]);

    const refreshToDoTasks = useCallback((props) => {
        const { actionCase, id = null, item = null } = props;

        switch (actionCase) {
            case 'add':
                setToDoTasks((prev) => [...prev, item]);
                break;
            case 'edit':
                setToDoTasks((current) =>
                    current.map((obj) => {
                        if (obj.id === item.id) {
                            return { ...obj, ...item };
                        }
                        return obj;
                    })
                );
                break;
            case 'delete':
                setToDoTasks((current) => current.filter((obj) => obj.id !== id));
                break;
            case 'confirm':
                setToDoTasks((current) => current.filter((obj) => obj.id !== item.id));
                setDoneTasks((prev) => [...prev, item]);
                break;
            default:
        }
    }, []);

    const refreshDoneTasks = useCallback((props) => {
        const { id } = props;
        setDoneTasks((current) => current.filter((obj) => obj.id !== id));
    }, []);

    const getToDoTasks = useCallback(() => {
        setLoadingToDo(true);
        if (user.roleId === '3') {
            TasksAPI.getByUserBrandsId(false, user.UserBrand[0].brandId, id, 20, pageNumberToDo, 1).then((res) => {
                setToDoTasks((prev) =>
                    [...prev, ...res.data.content].reduce((item, current) => {
                        const value = item.find((item) => item.id === current.id);
                        if (!value) {
                            return item.concat([current]);
                        } else return item;
                    }, [])
                );
                setHasMoreToDo(res.data.content.length > 0);
                setLoadingToDo(false);
            });
        } else {
            TasksAPI.getByUserId(id, false, 20, pageNumberToDo, 1).then((res) => {
                setToDoTasks((prev) =>
                    [...prev, ...res.data.content].reduce((item, current) => {
                        const value = item.find((item) => item.id === current.id);
                        if (!value) {
                            return item.concat([current]);
                        } else return item;
                    }, [])
                );
                setHasMoreToDo(res.data.content.length > 0);
                setLoadingToDo(false);
            });
        }
    }, [pageNumberToDo, id]);

    const getDoneTasks = useCallback(() => {
        setLoadingDone(true);
        if (user.roleId === '3') {
            TasksAPI.getByUserBrandsId(true, user.UserBrand[0].brandId, id, 20, pageNumberDone, 1).then((res) => {
                setDoneTasks((prev) =>
                    [...prev, ...res.data.content].reduce((item, current) => {
                        const value = item.find((item) => item.id === current.id);
                        if (!value) {
                            return item.concat([current]);
                        } else return item;
                    }, [])
                );
                setHasMoreDone(res.data.content.length > 0);
                setLoadingDone(false);
            });
        } else {
            TasksAPI.getByUserId(id, true, 20, pageNumberDone, 1).then((res) => {
                setDoneTasks((prev) =>
                    [...prev, ...res.data.content].reduce((item, current) => {
                        const value = item.find((item) => item.id === current.id);
                        if (!value) {
                            return item.concat([current]);
                        } else return item;
                    }, [])
                );
                setHasMoreDone(res.data.content.length > 0);
                setLoadingDone(false);
            });
        }
    }, [pageNumberDone, id]);

    useEffect(() => {
        getUserById();
        getUserClients();
    }, [getUserById, getUserClients]);

    useEffect(() => {
        getToDoTasks();
    }, [pageNumberToDo, getToDoTasks]);

    useEffect(() => {
        getDoneTasks();
    }, [pageNumberDone, getDoneTasks]);

    const getSearchedTasks = () => {
        if (user.roleId === '3') {
            TasksAPI.searchTasks(search).then((res) => {
                setToDoTasks(
                    res.data.filter(
                        (item) =>
                            item.status === false &&
                            item.userId === Number(id) &&
                            ((item.ClientBrands !== null && item.ClientBrands.brandId === user.UserBrand[0].brandId) ||
                                (item.ClientBrands === null &&
                                    item.user.UserBrand.some((brand) =>
                                        user.UserBrand.map((uBrand) => uBrand.brandId).includes(brand.brandId)
                                    )))
                    )
                );
                setDoneTasks(
                    res.data.filter(
                        (item) =>
                            item.status === true &&
                            item.userId === Number(id) &&
                            ((item.ClientBrands !== null && item.ClientBrands.brandId === user.UserBrand[0].brandId) ||
                                (item.ClientBrands === null &&
                                    item.user.UserBrand.some((brand) =>
                                        user.UserBrand.map((uBrand) => uBrand.brandId).includes(brand.brandId)
                                    )))
                    )
                );
                setLoadingToDo(false);
                setLoadingDone(false);
            });
        } else {
            TasksAPI.searchTasks(search).then((res) => {
                setToDoTasks(res.data.filter((item) => item.status === false && item.userId === Number(id)));
                setDoneTasks(res.data.filter((item) => item.status === true && item.userId === Number(id)));
                setLoadingToDo(false);
                setLoadingDone(false);
            });
        }
    };

    const getFilterTasks = () => {
        if (user.roleId === '3') {
            TasksAPI.filterTasks(filterData).then((res) => {
                setToDoTasks(
                    res.data.filter(
                        (item) =>
                            item.status === false &&
                            item.userId === Number(id) &&
                            ((item.ClientBrands !== null && item.ClientBrands.brandId === user.UserBrand[0].brandId) ||
                                (item.ClientBrands === null &&
                                    item.user.UserBrand.some((brand) =>
                                        user.UserBrand.map((uBrand) => uBrand.brandId).includes(brand.brandId)
                                    )))
                    )
                );
                setDoneTasks(
                    res.data.filter(
                        (item) =>
                            item.status === true &&
                            item.userId === Number(id) &&
                            ((item.ClientBrands !== null && item.ClientBrands.brandId === user.UserBrand[0].brandId) ||
                                (item.ClientBrands === null &&
                                    item.user.UserBrand.some((brand) =>
                                        user.UserBrand.map((uBrand) => uBrand.brandId).includes(brand.brandId)
                                    )))
                    )
                );
                setLoadingToDo(false);
                setLoadingDone(false);
            });
        } else {
            TasksAPI.filterTasks(filterData).then((res) => {
                setToDoTasks(res.data.filter((item) => item.status === false && item.userId === Number(id)));
                setDoneTasks(res.data.filter((item) => item.status === true && item.userId === Number(id)));
                setLoadingToDo(false);
                setLoadingDone(false);
            });
        }
    };

    useEffect(() => {
        if (!isSearch) return;

        getSearchedTasks();
    }, [triggerSearch]);

    useEffect(() => {
        if (!isFilter) return;

        getFilterTasks();
    }, [triggerFilter]);

    useEffect(() => {
        if (!triggerRefetch) return;

        if (pageNumberToDo > 0) {
            setDoneTasks([]);
            setPageNumberToDo(0);
        } else {
            getToDoTasks();
        }

        if (pageNumberDone > 0) {
            setToDoTasks([]);
            setPageNumberDone(0);
        } else {
            getDoneTasks();
        }

        setTriggerRefetch(false);
    }, [triggerRefetch]);

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('User tasks')}</title>
            </Helmet>
            <PageLayout
                title={`${t('Tasks')} - ${userState?.firstName ? userState.firstName : ''}`}
                backArrow
                actionArea={
                    <React.Fragment>
                        <Search
                            resetSearch={resetSearch}
                            search={search}
                            setSearch={setSearch}
                            isSearch={isSearch}
                            setIsSearch={setIsSearch}
                            nameToFind={t('Search task')}
                            triggerRefetch={triggerRefetch}
                            setTriggerRefetch={setTriggerRefetch}
                            triggerSearch={triggerSearch}
                            setTriggerSearch={setTriggerSearch}
                            setterFunction={activeTab === 0 ? setToDoTasks : activeTab === 1 ? setDoneTasks : () => {}}
                            setLoading={activeTab === 0 ? setLoadingToDo : activeTab === 1 ? setLoadingDone : () => {}}
                        />
                        <PrimaryButton startIcon={<FilterListIcon />} color="light" onClick={() => setOpenFilter(true)}>
                            {t('FILTER TASKS')}
                        </PrimaryButton>

                        {can(8) && (
                            <PrimaryButton startIcon={<AddIcon />} color="primary" onClick={() => setOpenAdd(true)}>
                                {t('ADD TASK')}
                            </PrimaryButton>
                        )}
                    </React.Fragment>
                }
            >
                {!toDoTasks || !doneTasks ? (
                    <LoadingSpinner margin="0 auto" loading={!toDoTasks || !doneTasks} />
                ) : (
                    <TasksComponent
                        toDoTasks={toDoTasks}
                        setToDoTasks={setToDoTasks}
                        doneTasks={doneTasks}
                        setDoneTasks={setDoneTasks}
                        getToDoTasks={refreshToDoTasks}
                        getDoneTasks={refreshDoneTasks}
                        loadingToDo={loadingToDo}
                        loadingDone={loadingDone}
                        setPageNumberDone={setPageNumberDone}
                        pageNumberDone={pageNumberDone}
                        setPageNumberToDo={setPageNumberToDo}
                        pageNumberToDo={pageNumberToDo}
                        hasMoreDone={hasMoreDone}
                        hasMoreToDo={hasMoreToDo}
                        clients={clients}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        isSearch={isSearch}
                        isFilter={isFilter}
                    />
                )}
            </PageLayout>
            <AddTask
                open={openAdd}
                setOpen={setOpenAdd}
                getToDoTasks={refreshToDoTasks}
                clients={clients}
                userId={Number(id)}
            />
            <FilterTasks
                open={openFilter}
                setOpen={setOpenFilter}
                clients={clients}
                setClients={setClients}
                filterData={filterData}
                setFilterData={setFilterData}
                triggerFilter={triggerFilter}
                setTriggerFilter={setTriggerFilter}
                triggerRefetch={triggerRefetch}
                setTriggerRefetch={setTriggerRefetch}
                setIsFilter={setIsFilter}
                setLoading={activeTab === 0 ? setLoadingToDo : activeTab === 1 ? setLoadingDone : () => {}}
                setData={activeTab === 0 ? setToDoTasks : activeTab === 1 ? setDoneTasks : () => {}}
                resetSearch={resetSearch}
                resetFilter={resetFilter}
                isFilter={isFilter}
                userId={Number(id)}
                userState={userState}
            />
        </>
    );
};

export default UserTasks;
