import { useState, useEffect } from 'react';
import { useUser } from 'hooks/useUser';
import useWindowDimensions from '../hooks/useWindowDimensions';

import { Drawer } from '@mui/material';
import { Appbar, Sidebar } from '../lib';
import { useTranslation } from 'react-i18next';

//svg imports
import { ReactComponent as UsersIcon } from 'resources/svg/menu-navbar/UsersIcon.svg';
import { ReactComponent as DashboardIcon } from 'resources/svg/menu-navbar/DashboardIcon.svg';
import { ReactComponent as OffersIcon } from 'resources/svg/menu-navbar/OffersIcon.svg';
import { ReactComponent as TasksIcon } from 'resources/svg/menu-navbar/TasksIcon.svg';
import { ReactComponent as ObjectiveIcon } from 'resources/svg/menu-navbar/ObjectiveIcon.svg';
import { ReactComponent as ClientsLogo } from 'resources/svg/menu-navbar/ClientsLogo.svg';
import { ReactComponent as VehiclesIcon } from 'resources/svg/menu-navbar/VehiclesIcon.svg';
import { ReactComponent as WorkPointsIcon } from 'resources/svg/menu-navbar/WorkPointsIcon.svg';
import { ReactComponent as SourceIcon } from 'resources/svg/menu-navbar/SourceIcon.svg';
import { ReactComponent as ProfileIcon } from 'resources/svg/menu-navbar/ProfileIcon.svg';
import { ReactComponent as LogoutIcon } from 'resources/svg/menu-navbar/LogoutIcon.svg';
import { ReactComponent as RomanaIcon } from 'resources/svg/menu-navbar/RomanaIcon.svg';
import { ReactComponent as EnglishIcon } from 'resources/svg/menu-navbar/EnglishIcon.svg';

function Layout() {
    const { vw } = useWindowDimensions();
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(i18n.language);
    const { user, can } = useUser();

    const menuHandler = () => {
        setOpen(!open);
    };

    const handleLangChange = (prop) => {
        const lang = prop;
        localStorage.setItem('preferredLang', lang);
        setSelectedLang(lang);
        i18n.changeLanguage(lang);
    };

    useEffect(() => {
        setSelectedLang(i18n.language);
    }, [i18n.language]);

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    width: '100%',
                    height: '6rem',
                    top: 0,
                    left: 0,
                    zIndex: 100,
                }}
            >
                <Appbar onMenuClick={menuHandler} />

                <Drawer
                    sx={{ '& .MuiPaper-root': { height: 'auto' } }}
                    open={open}
                    onClose={menuHandler}
                    variant={vw >= 1080 ? 'permanent' : 'temporary'}
                >
                    <div
                        style={{
                            position: 'fixed',
                            width: '15rem',
                            height: vw >= 1080 ? 'calc(100vh - 6rem)' : '100vh',
                            top: vw >= 1080 ? '6rem' : 0,
                            left: 0,
                            backgroundColor: '#FFFFFF',
                            zIndex: 50,
                        }}
                    >
                        <Sidebar
                            navMain={[
                                {
                                    render: true,
                                    label: t('Menu'),
                                    navItems: [
                                        {
                                            content: t('Dashboard'),
                                            icon: <DashboardIcon />,
                                            render: true,
                                            path: '/',
                                            nested: false,
                                        },
                                        {
                                            content: user.roleId === '3' ? t('Councilors') : t('Users'),
                                            icon: <UsersIcon />,
                                            render: can(3),
                                            path: '/users',
                                            nested: false,
                                        },
                                        {
                                            content: t('Offers'),
                                            icon: <OffersIcon />,
                                            render: can(6),
                                            path: '/offers',
                                            nested: false,
                                        },
                                        {
                                            content: t('Tasks'),
                                            icon: <TasksIcon />,
                                            render: can(9),
                                            path: '/tasks',
                                            nested: false,
                                        },
                                        {
                                            content: t('Objectives'),
                                            icon: <ObjectiveIcon />,
                                            render: can(12),
                                            path: '/objectives',
                                            nested: false,
                                        },
                                        {
                                            content: t('Clients'),
                                            icon: <ClientsLogo />,
                                            render: can(15),
                                            path: '/clients',
                                            nested: false,
                                        },
                                    ],
                                },

                                {
                                    render: can(18),
                                    label: t('Nomenclatures'),
                                    navItems: [
                                        {
                                            content: t('Vehicles'),
                                            icon: <VehiclesIcon />,
                                            render: can(18),
                                            path: '/vehicles',
                                            nested: false,
                                        },
                                        {
                                            content: t('Work points'),
                                            icon: <WorkPointsIcon />,
                                            render: can(18),
                                            path: '/work-points',
                                            nested: false,
                                        },
                                        {
                                            content: t('Client source'),
                                            icon: <SourceIcon />,
                                            render: can(18),
                                            path: '/client-source',
                                            nested: false,
                                        },
                                    ],
                                },
                                {
                                    render: true,
                                    label: t('Account'),
                                    navItems: [
                                        {
                                            content: t('Profile'),
                                            icon: <ProfileIcon />,
                                            render: true,
                                            path: '/profile',
                                            nested: false,
                                        },
                                        {
                                            content: t('Logout'),
                                            icon: <LogoutIcon />,
                                            render: true,
                                            nested: false,
                                            popover: true,
                                        },
                                        {
                                            withNoIconColor: true,
                                            content: selectedLang === 'ro' ? 'Română' : 'English',
                                            icon: selectedLang === 'ro' ? <RomanaIcon /> : <EnglishIcon />,
                                            render: true,
                                            nested: true,
                                            nestedItems: [
                                                {
                                                    content: 'Română',
                                                    render: true,
                                                    icon: <RomanaIcon />,
                                                    onClick: () => {
                                                        handleLangChange('ro');
                                                    },
                                                },
                                                {
                                                    content: 'English',
                                                    render: true,
                                                    icon: <EnglishIcon />,
                                                    onClick: () => {
                                                        handleLangChange('en');
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ]}
                        />
                    </div>
                </Drawer>
            </div>
        </>
    );
}

export default Layout;
