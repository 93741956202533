import axios from './axios';

const Clientsource = {
    /**
     * Fetches all Clientsource's
     *
     * @return {array} Array of Clientsource's
     */
    async get() {
        try {
            const res = await axios.get('/clientSources');
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: [],
            };
        }
    },

    /**
     * Fetches one Clientsource by it's ID
     *
     * @param {string} id ID of the Clientsource
     * @return {object} Clientsource with the corresponding ID
     */
    async getById(clientsourceId) {
        try {
            const res = await axios.get('/clientSources/' + clientsourceId);
            return { ok: true, data: res.data };
        } catch (error) {
            return {
                ok: false,
                data: {},
            };
        }
    },

    /**
     * Creates one Clientsource
     *
     * @param {object} clientsource Object with the creation data
     * @return {object} Newly created Clientsource
     */
    async create(clientsource) {
        let reqBody = { ...clientsource };
        try {
            const res = await axios.post('/clientSources', reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },

    /**
     * Deletes Clientsource by ID
     *
     * @param {string} clientsourceId ID of the Clientsource to be deleted
     * @return {string} ID of the deleted Clientsource
     */
    async delete(clientsourceId) {
        try {
            await axios.delete('/clientSources/' + clientsourceId);
            return {
                ok: true,
                data: {
                    id: clientsourceId,
                },
            };
        } catch (error) {
            return {
                ok: false,
                data: {
                    id: clientsourceId,
                },
            };
        }
    },

    /**
     * Update Clientsource by ID
     *
     * @param {object} clientsource Object with the creation data
     * @param {string} clientsourceId ID of the Clientsource to be updated
     * @return {string} ID of the updated Clientsource
     */
    async update(clientsourceId, clientsource) {
        let reqBody = { ...clientsource };
        try {
            const res = await axios.patch('/clientSources/' + clientsourceId, reqBody);
            return {
                ok: true,
                data: res.data,
            };
        } catch (error) {
            return {
                ok: false,
                data: error,
            };
        }
    },
};

export default Clientsource;
