import React from 'react';
import { Box, Typography, Grid, MenuItem } from '@mui/material';
import useWindowDimensions from 'hooks/useWindowDimensions';

import { TextField, Select } from 'lib';

import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';

const Step1ModelForm = ({
    newModel,
    setNewModel,
    brand,
    setActiveStep,
    formRefModel,
    intialStateModel,
    validationStateModel,
}) => {
    const { vw } = useWindowDimensions();
    const { t } = useTranslation();
    return (
        <Box sx={{ padding: '0 0rem 0 0.5rem' }}>
            <Box sx={{ marginTop: '32px', marginBottom: '22px' }}>
                <Typography variant="addingVehicleModel"> {t('Model name')} </Typography>
            </Box>
            <Box>
                <Formik
                    innerRef={formRefModel}
                    initialValues={{
                        ...intialStateModel,
                    }}
                    validationSchema={validationStateModel}
                    onSubmit={(values) => {
                        setNewModel({
                            ...newModel,
                            modelName: values.modelName,
                            modelType: values.modelType,
                        });
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }}
                >
                    <Form>
                        <Grid
                            container
                            justifyContent={'space-between'}
                            spacing={2}
                            direction={vw <= 670 ? 'column' : 'row'}
                        >
                            <Grid item xs={6}>
                                <TextField name="modelName" label={t('Model name')} />
                            </Grid>
                            <Grid item xs={6}>
                                <Select name="modelType" label={t('Model type')}>
                                    {[
                                        { id: 1, name: 'personal', realName: t('Personal Vehicle') },
                                        { id: 2, name: 'utility', realName: t('Utility Vehicle') },
                                    ].map((option, pos) => (
                                        <MenuItem key={pos} value={option.name}>
                                            {option.realName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Box>
        </Box>
    );
};

export default Step1ModelForm;
