import React, { useEffect, useState, useCallback } from 'react';
import { TasksAPI } from 'api_darex';
import TasksComponent from 'lib/components/Tasks/TasksComponent';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from 'lib';

const ClientTasks = ({ client }) => {
    const { id } = useParams();
    const [doneTasks, setDoneTasks] = useState([]);
    const [toDoTasks, setToDoTasks] = useState([]);
    const [pageNumberToDo, setPageNumberToDo] = useState(0);
    const [pageNumberDone, setPageNumberDone] = useState(0);
    const [hasMoreToDo, setHasMoreToDo] = useState(false);
    const [hasMoreDone, setHasMoreDone] = useState(false);
    const [loadingToDo, setLoadingToDo] = useState(false);
    const [loadingDone, setLoadingDone] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [triggerFilter, setTriggerFilter] = useState(false);
    const [triggerRefetch, setTriggerRefetch] = useState(false);

    const [filterData, setFilterData] = useState({
        userId: '',
        brandId: Number(client.brandId),
        clientBrandsId: id,
        type: '',
        startDate: new Date(),
        endDate: new Date(),
    });

    const resetFilter = () => {
        setIsFilter(false);
        setFilterData({
            userId: '',
            brandId: Number(client.brandId),
            clientBrandsId: id,
            type: '',
            startDate: new Date(),
            endDate: new Date(),
        });
    };

    const refreshToDoTasks = useCallback((props) => {
        const { actionCase, id = null, item = null } = props;

        switch (actionCase) {
            case 'add':
                setToDoTasks((prev) => [...prev, item]);
                break;
            case 'edit':
                setToDoTasks((current) =>
                    current.map((obj) => {
                        if (obj.id === item.id) {
                            return { ...obj, ...item };
                        }
                        return obj;
                    })
                );
                break;
            case 'delete':
                setToDoTasks((current) => current.filter((obj) => obj.id !== id));
                break;
            case 'confirm':
                setToDoTasks((current) => current.filter((obj) => obj.id !== item.id));
                setDoneTasks((prev) => [...prev, item]);
                break;
            default:
        }
    }, []);

    const refreshDoneTasks = useCallback((props) => {
        const { id } = props;
        setDoneTasks((current) => current.filter((obj) => obj.id !== id));
    }, []);

    const getToDoTasks = useCallback(() => {
        setLoadingToDo(true);
        TasksAPI.getByClientIdBrands(id, false, 20, pageNumberToDo, 1).then((res) => {
            setToDoTasks((prev) =>
                [...prev, ...res.data.content].reduce((item, current) => {
                    const value = item.find((item) => item.id === current.id);
                    if (!value) {
                        return item.concat([current]);
                    } else return item;
                }, [])
            );
            setHasMoreToDo(res.data.content.length > 0);
            setLoadingToDo(false);
        });
    }, [pageNumberToDo, id]);

    const getDoneTasks = useCallback(() => {
        setLoadingDone(true);
        TasksAPI.getByClientIdBrands(id, true, 20, pageNumberDone, 1).then((res) => {
            setDoneTasks((prev) =>
                [...prev, ...res.data.content].reduce((item, current) => {
                    const value = item.find((item) => item.id === current.id);
                    if (!value) {
                        return item.concat([current]);
                    } else return item;
                }, [])
            );
            setHasMoreDone(res.data.content.length > 0);
            setLoadingDone(false);
        });
    }, [pageNumberDone, id]);

    useEffect(() => {
        getToDoTasks();
    }, [pageNumberToDo, getToDoTasks]);

    useEffect(() => {
        getDoneTasks();
    }, [pageNumberDone, getDoneTasks]);

    const getFilterTasks = () => {
        TasksAPI.filterTasks(filterData).then((res) => {
            setToDoTasks(res.data.filter((item) => item.status === false && item.clientBrandsId === id));
            setDoneTasks(res.data.filter((item) => item.status === true && item.clientBrandsId === id));
            setLoadingToDo(false);
            setLoadingDone(false);
        });
    };

    useEffect(() => {
        if (!isFilter) return;

        getFilterTasks();
    }, [triggerFilter]);

    useEffect(() => {
        if (!triggerRefetch) return;

        if (pageNumberToDo > 0) {
            setDoneTasks([]);
            setPageNumberToDo(0);
        } else {
            getToDoTasks();
        }

        if (pageNumberDone > 0) {
            setToDoTasks([]);
            setPageNumberDone(0);
        } else {
            getDoneTasks();
        }

        setTriggerRefetch(false);
    }, [triggerRefetch]);

    return !toDoTasks || !doneTasks ? (
        <LoadingSpinner margin="0 auto" loading={!toDoTasks || !doneTasks} />
    ) : (
        <TasksComponent
            toDoTasks={toDoTasks}
            setToDoTasks={setToDoTasks}
            doneTasks={doneTasks}
            setDoneTasks={setDoneTasks}
            getToDoTasks={refreshToDoTasks}
            getDoneTasks={refreshDoneTasks}
            loadingToDo={loadingToDo}
            loadingDone={loadingDone}
            setPageNumberDone={setPageNumberDone}
            pageNumberDone={pageNumberDone}
            setPageNumberToDo={setPageNumberToDo}
            pageNumberToDo={pageNumberToDo}
            hasMoreDone={hasMoreDone}
            hasMoreToDo={hasMoreToDo}
            withBoxShadow={false}
            withMarginTop={true}
            withUtility={true}
            clients={[]}
            clientId={id}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isFilter={isFilter}
            setIsFilter={setIsFilter}
            filterData={filterData}
            setFilterData={setFilterData}
            triggerFilter={triggerFilter}
            setTriggerFilter={setTriggerFilter}
            triggerRefetch={triggerRefetch}
            setTriggerRefetch={setTriggerRefetch}
            resetFilter={resetFilter}
            setLoadingDone={setLoadingDone}
            setLoadingToDo={setLoadingToDo}
        />
    );
};

export default ClientTasks;
