import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { Formik } from 'formik';

import { OffersAPI } from 'api_darex';
import { CustomDialog, DatePicker } from 'lib';

const CommonModal = ({ open, setOpen, offerData, useCase, getOffer }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [loading, setLoading] = useState(false);

    const INITIAL_FORM_STATE = {
        date:
            useCase === 'testDriveDate'
                ? offerData.driveTestDate
                : useCase === 'reContactDate'
                ? offerData.reContactDate
                : useCase === 'RCIfileDate'
                ? offerData.RCIfileDate
                : new Date(),
    };

    const FROM_VALIDATION = yup.object().shape({
        date: yup.date().typeError(t('Choose a valid date type!')).required(t('The date is mandatory!')),
    });

    const onSubmitFunc = (values) => {
        setLoading(true);
        if (useCase === 'testDriveDate') {
            OffersAPI.updateDriveTest(offerData.id, values.date).then((res) => {
                if (res.ok) {
                    getOffer();
                    setOpen(false);
                    setLoading(false);
                    toast.success(t('The drive test date has been updated successfully!'));
                } else {
                    setLoading(false);
                    toast.error(t('Something went wrong! The drive test date could not be updated!'));
                }
            });
        } else if (useCase === 'reContactDate') {
            OffersAPI.updateReContact(offerData.id, values.date).then((res) => {
                if (res.ok) {
                    getOffer();
                    setOpen(false);
                    setLoading(false);
                    toast.success(t('The recontact date has been updated successfully!'));
                } else {
                    setLoading(false);
                    toast.error(t('Something went wrong! The drive test date could not be updated!'));
                }
            });
        } else if (useCase === 'RCIfileDate') {
            OffersAPI.updateRCIfileDate(offerData.id, values.date).then((res) => {
                if (res.ok) {
                    getOffer();
                    setOpen(false);
                    setLoading(false);
                    toast.success(t('The RCI file date has been updated successfully!'));
                } else {
                    setLoading(false);
                    toast.error(t('Something went wrong! The RCI file date could not be updated!'));
                }
            });
        }
    };

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={
                useCase === 'testDriveDate'
                    ? t('Edit drive test date')
                    : useCase === 'reContactDate'
                    ? t('Edit recontact date')
                    : useCase === 'RCIfileDate'
                    ? t('Edit RCI file date')
                    : ''
            }
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={handleSubmit}
            width="100%"
            maxWidth="450px"
            onClickButtonClose={() => {
                setOpen(false);
            }}
            buttonFinishLoading={loading}
            buttonCloseDisabled={loading}
        >
            <Formik
                innerRef={formRef}
                initialValues={{ ...INITIAL_FORM_STATE }}
                validationSchema={FROM_VALIDATION}
                onSubmit={(values) => onSubmitFunc(values)}
            >
                <DatePicker name="date" label={t('Date')} size="medium" inputFormat="DD/MM/YYYY" />
            </Formik>
        </CustomDialog>
    );
};

export default CommonModal;
