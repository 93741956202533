import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'lib';
import { Helmet } from 'react-helmet';

import AddClientMain from './AddClientMain';

const AddClient = () => {
    const { t } = useTranslation();

    return (
        <PageLayout title={t('Clients')} subTitle={t('Manage your clients')}>
            <Helmet>
                <title>Darex CRM :: {t('Add client')}</title>
            </Helmet>
            <AddClientMain />
        </PageLayout>
    );
};

export default AddClient;
