import React, { useState, useEffect } from 'react';
import Search from 'lib/components/Search/Search';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'lib/components/Buttons/buttons';
import styles from './CreateOffer.module.scss';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import Clients from 'api_darex/Clients';
import SuggestionList from 'lib/components/SuggestionList/SuggestionList';
import { useUser } from 'hooks/useUser';
import { CustomDialog } from 'lib';

import AddClientMain from 'pages/Clients/AddClientMain';
import CloseIcon from '@mui/icons-material/Close';

const ChooseClient = ({ search, setSearch, setClient, areaOfUse }) => {
    const { t } = useTranslation();
    const [clients, setClients] = useState([]);
    const [open, setOpen] = useState(false);
    const [clientDataForCreation, setClientDataForCreation] = useState({});

    const { user } = useUser();

    const getCLients = () => {
        Clients.getClientsByBrands().then((res) => {
            setClients(
                res.data.content
                    .map((client) => ({
                        ...client.Clients,
                        brandId: client.brandId,
                        userId: client.userId,
                        clientsBrandId: client.id,
                        brand: client.Brand,
                        user: client.User,
                        clientUID: client.id,
                        status: client.status,
                    }))
                    .filter((item) => item.hasGDPR === true)
            );
        });
    };

    useEffect(() => {
        getCLients();
    }, []);

    const clientToString = (client) => {
        if (client.type === 'Juridic') return `${client.firmName} ${'  '} CUI - ${client.CUI}`;
        if (client.type === 'Individual') return `${client.firstName} ${client.lastName}`;
    };

    /**
     *
     * @param {object} client - object with information of client
     * @returns an object with a boolean value indicating if client can be treated by the user,
     * and one value to check if the client will be created and assigned to the user
     */
    const logicForSelectingAClientOnOffer = (client) => {
        let isLegitClient = true;
        let creation = true;
        let awake = false;
        let changeAdviser = false;

        user.UserBrand.forEach((myBrand) => {
            if (client.brandId !== myBrand.brandId) {
                if (client.userId !== user.id) {
                    const hasClientsOnOtherBrands = clients.filter((item) => item.id === client.id);
                    if (hasClientsOnOtherBrands.length > 1) {
                        const checkForBrandPresence = hasClientsOnOtherBrands.findIndex(
                            (item) => item.brandId === myBrand.brandId
                        );

                        if (checkForBrandPresence >= 0) {
                            isLegitClient = false;
                        }
                    }
                }
            }

            if (client.brandId === myBrand.brandId) {
                if (client.userId !== user.id) {
                    isLegitClient = false;
                }
            }

            if (client.status === 'Sleep') {
                isLegitClient = true;

                if (client.brandId === myBrand.brandId) {
                    if (client.userId === user.id) {
                        awake = true;
                    } else changeAdviser = true;
                }
            }

            if (client.brandId === myBrand.brandId) {
                if (client.userId === user.id) {
                    creation = false;
                }
            }
        });

        return { isLegitClient, creation, awake, changeAdviser };
    };

    const logicForSelectingAClientOnCreationOfClient = (client) => {
        let isLegitClient = false;
        let creation = false;
        let changeAdviser = false;
        let awake = false;

        user.UserBrand.forEach((myBrand) => {
            if (client.brandId !== myBrand.brandId) {
                isLegitClient = true;
                creation = true;

                const hasClientsOnOtherBrands = clients.filter((item) => item.id === client.id);
                if (hasClientsOnOtherBrands.length > 1) {
                    const checkForBrandPresence = hasClientsOnOtherBrands.filter((item) =>
                        user.UserBrand.map((uItem) => uItem.brandId).some((uBrand) => uBrand.includes(item.brandId))
                    );

                    if (user.UserBrand.length === 3 && checkForBrandPresence.length === 3) {
                        isLegitClient = false;
                        creation = false;
                    } else if (user.UserBrand.length === 1 && checkForBrandPresence.length > 0) {
                        isLegitClient = false;
                        creation = false;
                    }
                }
            }

            if (client.status === 'Sleep') {
                isLegitClient = true;

                if (client.brandId === myBrand.brandId) {
                    if (client.userId === user.id) {
                        awake = true;
                    } else {
                        changeAdviser = true;
                    }
                }
            }
        });

        return { isLegitClient, creation, awake, changeAdviser };
    };

    useEffect(() => {
        if (open === false) {
            if (areaOfUse === 'OFFER') {
                setClientDataForCreation({});
                getCLients();
            }
        }
    }, [open, areaOfUse]);

    return (
        <React.Fragment>
            <div className={styles.container_1}>
                <div className={styles.search_container}>
                    <SuggestionList
                        trigger={
                            <Search
                                search={search}
                                setSearch={setSearch}
                                nameToFind={t('Client name')}
                                style={{ borderRadius: '5px' }}
                            />
                        }
                        list={clients.filter((client) => {
                            const s = search.toLowerCase().trim();
                            const c = clientToString(client).toLocaleLowerCase().trim();
                            if (c.includes(s)) return true;
                            return false;
                        })}
                        renderItem={(client) => clientToString(client)}
                        onSelectItem={(client) => {
                            setClient(client);
                            setSearch(clientToString(client));
                        }}
                        isLegitClient={
                            areaOfUse === 'OFFER'
                                ? logicForSelectingAClientOnOffer
                                : logicForSelectingAClientOnCreationOfClient
                        }
                        myUserInfo={user}
                        setClientDataForCreation={setClientDataForCreation}
                        setToggleAddNewClientModal={setOpen}
                        getCLients={getCLients}
                        areaOfUse={areaOfUse}
                    />
                </div>

                {areaOfUse === 'OFFER' && (
                    <PrimaryButton startIcon={<AddIcon />} onClick={() => setOpen(true)}>
                        {t('Add client')}
                    </PrimaryButton>
                )}
            </div>

            <CustomDialog open={open} setOpen={setOpen} maxWidth="1100px">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }} onClick={() => setOpen(false)}>
                    <CloseIcon sx={{ color: 'rgba(0,0,0,0.62)', fontSize: '2rem', cursor: 'pointer' }} />
                </div>
                <AddClientMain
                    submitCallback={(data) =>
                        Clients.getClientsByBrands().then((res) => {
                            const clients = res.data.content.map((client) => ({
                                ...client.Clients,
                                brandId: client.brandId,
                                userId: client.userId,
                                clientsBrandId: client.id,
                                brand: client.Brand,
                                user: client.User,
                                clientUID: client.id,
                                status: client.status,
                            }));
                            const addedClient = clients.filter((c) => c.clientUID === data.id)[0];

                            setClients(clients);
                            setSearch(clientToString(addedClient));
                            setClient(addedClient);
                            setOpen(false);
                        })
                    }
                    clientDataForCreation={clientDataForCreation}
                    createFromOffer={true}
                    setModalOpen={setOpen}
                />
            </CustomDialog>
        </React.Fragment>
    );
};

ChooseClient.propTypes = {
    setClient: PropTypes.func.isRequired,
};

export default ChooseClient;
