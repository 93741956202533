import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PrimaryButton, UtilityButton } from 'lib/components/Buttons/buttons';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import Search from 'lib/components/Search/Search';
import VisualizeVehicleComponent from './VisualizeVehicleComponent';

import { CustomTable } from 'lib';
import { VehiclesAPI } from 'api_darex';
import { PageLayout } from 'lib';
import CustomTableLayout from 'lib/components/Tables/TabsLayout';
import { useUser } from 'hooks/useUser';
import { Helmet } from 'react-helmet';
import { useLayoutEffect } from 'react';
import { useRef } from 'react';

const Vehicles = (props) => {
    const { t } = useTranslation();
    const { can } = useUser();
    const history = useHistory();
    // const confirm = useConfirm();

    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [daciaVehicles, setDaciaVehicles] = useState([]);
    const [nissanVehicles, setNissanVehicles] = useState([]);
    const [renaultVehicles, setRenaultVehicles] = useState([]);
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const [loadingDacia, setLoadingDacia] = useState(true);
    const [loadingNissan, setLoadingNissan] = useState(true);
    const [loadingRenault, setLoadingRenault] = useState(true);

    const data = props.location.state || {};
    const [activeTab, setActiveTab] = useState(data.savedTab || 0);

    const [autoTab, setAutoTab] = useState(data.brandId - 1);
    const [stateForAddingModal, setStateForAddingModal] = useState('');

    const [triggerSearch, setTriggerSearch] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const firstUpdate = useRef(true);

    useEffect(() => {
        if (!can(18)) {
            history.goBack();
        }
    }, []);

    const labels = [
        { id: 'vehicles', label: <Typography variant="nomenclatorName"> {t('Vehicles')}</Typography>, minWidth: 10 },
    ];

    const cellModifier = (row, column, value) => {
        if (column.id === 'vehicles') {
            return (
                <VisualizeVehicleComponent
                    row={row}
                    triggerRefetch={triggerRefetch}
                    setTriggerRefetch={setTriggerRefetch}
                />
            );
        }
    };
    const cellModifier2 = (row, column, value) => {
        if (column.id === 'vehicles') {
            return (
                <VisualizeVehicleComponent
                    row={row}
                    triggerRefetch={triggerRefetch}
                    setTriggerRefetch={setTriggerRefetch}
                />
            );
        }
    };
    const cellModifier3 = (row, column, value) => {
        if (column.id === 'vehicles') {
            return (
                <VisualizeVehicleComponent
                    row={row}
                    triggerRefetch={triggerRefetch}
                    setTriggerRefetch={setTriggerRefetch}
                />
            );
        }
    };

    const tabs = [
        {
            label: 'DACIA',
            id: 0,
            render: true,
            hasUtility: true,
            component: (
                <CustomTable
                    labels={labels}
                    loading={loadingDacia}
                    setLoading={setLoadingDacia}
                    cellModifier={cellModifier}
                    tableData={isSearch ? daciaVehicles.filter((item) => item.brandId === '1') : daciaVehicles}
                    withBoxShadow={false}
                    withRowColors={false}
                    hover={false}
                    withPagination={true}
                    getterFunction={VehiclesAPI.getAllVehicles}
                    setterFunction={setDaciaVehicles}
                    additionalId={'1'}
                    triggerRefetch={triggerRefetch}
                    withNoCellPadding={true}
                    labelsPadding="1rem 0 0 2.2rem"
                    triggerSearch={triggerSearch}
                    searchFunction={VehiclesAPI.searchVehicle}
                    search={search}
                    isSearch={isSearch}
                />
            ),
        },
        {
            label: 'RENAULT',
            id: 1,
            render: true,
            hasUtility: true,
            component: (
                <CustomTable
                    labels={labels}
                    loading={loadingRenault}
                    setLoading={setLoadingRenault}
                    cellModifier={cellModifier2}
                    tableData={isSearch ? renaultVehicles.filter((item) => item.brandId === '2') : renaultVehicles}
                    withBoxShadow={false}
                    withRowColors={false}
                    withPagination={true}
                    getterFunction={VehiclesAPI.getAllVehicles}
                    setterFunction={setRenaultVehicles}
                    additionalId={'2'}
                    triggerRefetch={triggerRefetch}
                    withNoCellPadding={true}
                    labelsPadding="1rem 0 0 2.2rem"
                    triggerSearch={triggerSearch}
                    searchFunction={VehiclesAPI.searchVehicle}
                    search={search}
                    isSearch={isSearch}
                />
            ),
        },
        {
            label: 'NISSAN',
            id: 2,
            render: true,
            hasUtility: true,
            component: (
                <CustomTable
                    labels={labels}
                    loading={loadingNissan}
                    setLoading={setLoadingNissan}
                    cellModifier={cellModifier3}
                    tableData={isSearch ? nissanVehicles.filter((item) => item.brandId === '3') : nissanVehicles}
                    withBoxShadow={false}
                    withRowColors={false}
                    withPagination={true}
                    getterFunction={VehiclesAPI.getAllVehicles}
                    setterFunction={setNissanVehicles}
                    additionalId={'3'}
                    triggerRefetch={triggerRefetch}
                    withNoCellPadding={true}
                    labelsPadding="1rem 0 0 2.2rem"
                    triggerSearch={triggerSearch}
                    searchFunction={VehiclesAPI.searchVehicle}
                    search={search}
                    isSearch={isSearch}
                />
            ),
        },
    ];

    const utility = [
        {
            id: 0,
            component: (
                <UtilityButton
                    startIcon={<AddIcon />}
                    onClick={() =>
                        history.push({
                            pathname: '/vehicles/create',
                            state: {
                                savedTab: activeTab,
                            },
                        })
                    }
                >
                    {activeTab === 0
                        ? t('ADD A NEW DACIA VEHICLE')
                        : activeTab === 1
                        ? t('ADD A NEW RENAULT VEHICLE')
                        : t('ADD A NEW NISSAN VEHICLE')}
                </UtilityButton>
            ),
            render: can(17),
        },
    ];

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Vehicles')}</title>
            </Helmet>
            <PageLayout
                title={t('Vehicles')}
                subTitle={t('Manage your vehicles')}
                actionArea={
                    <Search
                        nameToFind={t('Search vehicle')}
                        search={search}
                        setSearch={setSearch}
                        isSearch={isSearch}
                        setIsSearch={setIsSearch}
                        triggerRefetch={triggerRefetch}
                        setTriggerRefetch={setTriggerRefetch}
                        triggerSearch={triggerSearch}
                        setTriggerSearch={setTriggerSearch}
                        setterFunction={
                            activeTab === 0
                                ? setDaciaVehicles
                                : activeTab === 1
                                ? setRenaultVehicles
                                : activeTab === 2
                                ? setNissanVehicles
                                : () => {}
                        }
                        setLoading={
                            activeTab === 0
                                ? setLoadingDacia
                                : activeTab === 1
                                ? setLoadingRenault
                                : activeTab === 2
                                ? setLoadingNissan
                                : () => {}
                        }
                    />
                }
            >
                <CustomTableLayout
                    tabs={tabs}
                    utility={utility}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setStateForAddingModal={setStateForAddingModal}
                    withBoxShadow={true}
                />
            </PageLayout>
        </>
    );
};

export default Vehicles;
