import { MenuItem } from '@mui/material';
import { UsersAPI } from 'api_darex';
import { Form, Formik } from 'formik';
import { CustomDialog, Select } from 'lib';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const mappedBrands = {
    dacia: 1,
    renault: 1,
    nissan: 1,
};

const MoveClients = ({ open, setOpen, clients, callback }) => {
    const { t } = useTranslation();

    const { id } = useParams();
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState({
        dacia: [],
        renault: [],
        nissan: [],
    });
    const {
        1: daciaClients = [],
        2: renaultClients = [],
        3: nissanClients = [],
    } = useMemo(() => Object.groupBy(clients, ({ brandId }) => brandId), [clients]);

    const getUsers = useCallback(() => {
        UsersAPI.get().then((res) => {
            const filteredUsers = res.data.content.filter((u) => u.roleId === '4' && u.id !== Number(id));
            setUsers({
                dacia: filteredUsers.filter((u) => u.UserBrand.map((b) => b.brandId).includes('1')),
                renault: filteredUsers.filter((u) => u.UserBrand.map((b) => b.brandId).includes('2')),
                nissan: filteredUsers.filter((u) => u.UserBrand.map((b) => b.brandId).includes('3')),
            });
        });
    }, [id]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const INITIAL_FORM_STATE = {
        dacia: '',
        renault: '',
        nissan: '',
    };

    const FORM_VALIDATION = yup.object().shape({
        dacia: yup.number().test('validDacia', t('Choose the counselor'), (value) => {
            if (!daciaClients.length || !users.dacia.length) return true;
            return !!value;
        }),
        renault: yup.number().test('validRenault', t('Choose the counselor'), (value) => {
            if (!renaultClients.length || !users.renault.length) return true;
            return !!value;
        }),
        nissan: yup.number().test('validNissan', t('Choose the counselor'), (value) => {
            if (!nissanClients.length || !users.nissan.length) return true;
            return !!value;
        }),
    });

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const handleSubmitCounselor = async (values) => {
        try {
            setLoading(true);

            const users = Object.entries(values).reduce(
                (acc, [key, value]) => (value ? [...acc, { brandId: mappedBrands[key], userId: value }] : acc),
                []
            );

            const res = await UsersAPI.moveClients(id, users);

            if (res.ok) {
                toast.success(t('Clients moved successfully'));
                await callback();
            } else {
                throw new Error(t('An error ocurred. Please try again'));
            }
        } catch (err) {
            console.error(err);
            toast.error(t('An error ocurred. Please try again'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <CustomDialog
            setOpen={setOpen}
            title={t('Move clients')}
            buttonClose={t('BACK')}
            open={open}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={handleSubmit}
            width="100%"
            buttonFinishLoading={loading}
            buttonCloseDisabled={loading}
        >
            <Formik
                innerRef={formRef}
                initialValues={INITIAL_FORM_STATE}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => handleSubmitCounselor(values)}
            >
                <Form>
                    <div className="move-clients-wrapper">
                        {daciaClients.length > 0 && users.dacia.length > 0 && (
                            <Select name="dacia" label={t('New counselor for Dacia clients')}>
                                {users.dacia.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.name} {user.firstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        {renaultClients.length > 0 && users.renault.length > 0 && (
                            <Select name="renault" label={t('New counselor for Renault clients')}>
                                {users.renault.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.name} {user.firstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        {nissanClients.length > 0 && users.nissan.length > 0 && (
                            <Select name="nissan" label={t('New counselor for Nissan clients')}>
                                {users.nissan.map((user) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        {user.name} {user.firstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </div>
                </Form>
            </Formik>
        </CustomDialog>
    );
};

export default MoveClients;
