import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import styles from '../Offers/Offers.module.scss';

import { Typography, Chip } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ActionButton } from 'lib/components/Buttons/buttons';
import { useUser } from 'hooks/useUser';
import { OffersAPI } from 'api_darex';
import { CustomTable, Tooltip } from 'lib';
import RefuseOffer from '../Offer/modals/RefuseOffer';
import ConfirmModal from 'lib/components/Modals/ConfirmModal';
import ReOfferOffer from 'pages/Offer/modals/ReOfferOffer';
import { OfferOptions } from 'lib/components/Dropdowns/dropdowns';

const ClientOffers = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { can } = useUser();
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [rowId, setRowId] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const [openRefuseModal, setOpenRefuseModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openReOffer, setOpenReOffer] = useState(false);
    const { user } = useUser();
    const { id } = useParams();

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const idOpen = open ? 'simple-popover' : undefined;

    const labels = [
        {
            id: 'id',
            label: 'ID',
            minWidth: 10,
        },
        {
            id: 'ClientBrands',
            label: 'Client',
            minWidth: 10,
        },
        {
            id: 'brand',
            label: t('Brand'),
            minWidth: 10,
        },
        {
            id: 'OfferModel',
            label: t('Models'),
            minWidth: 10,
        },
        {
            id: 'status',
            label: 'Status',
            minWidth: 10,
        },

        {
            id: 'createdAt',
            label: t('Creation date'),
            minWidth: 10,
        },
        {
            id: 'view',
            minWidth: 10,
        },
    ];

    const offerOptions = (row) => {
        const defaultOpt = [
            {
                render: can(6),
                component: (
                    <p
                        className={`${styles.popoverOption} ${styles.yellow}`}
                        onClick={() => history.push(`/offers/${row.id}`)}
                    >
                        {t('View more details')}
                    </p>
                ),
            },
            {
                render: row.status === 'Open' && user.roleId === '4' && can(5),
                component: (
                    <p
                        className={`${styles.popoverOption} ${styles.green}`}
                        onClick={() => {
                            setRowId(row.id);
                            setOpenConfirm(true);
                        }}
                    >
                        {t('Accept')}
                    </p>
                ),
            },
            {
                render: row.status === 'Open' && user.roleId === '4' && can(5),
                component: (
                    <p
                        className={`${styles.popoverOption} ${styles.red}`}
                        onClick={() => {
                            setRowId(row.id);
                            setOpenRefuseModal(true);
                        }}
                    >
                        {t('Refuse')}
                    </p>
                ),
            },
            {
                render: row.status === 'Open' && user.roleId === '4' && can(5),
                component: (
                    <p
                        className={`${styles.popoverOption} ${styles.blue}`}
                        onClick={() => {
                            setRowId(row.id);
                            setOpenReOffer(true);
                        }}
                    >
                        {t('Rebid')}
                    </p>
                ),
            },
        ];

        const filterOptions = defaultOpt.filter((fOpt) => fOpt.render === true);

        return filterOptions;
    };

    const cellModifier = (row, column, value) => {
        if (column.id === 'id') {
            return <Typography variant="tableContent">{row.autoincrement}</Typography>;
        }
        if (column.id === 'ClientBrands') {
            if (row.ClientBrands?.Clients.type === 'Juridic') {
                return <Typography variant="tableContent">{row.ClientBrands?.Clients.firmName}</Typography>;
            } else if (row.ClientBrands?.Clients.type === 'Individual') {
                return (
                    <Typography variant="tableContent">
                        {row.ClientBrands?.Clients.lastName} {row.ClientBrands.Clients.firstName}
                    </Typography>
                );
            }
        }
        if (column.id === 'OfferModel') {
            const uniqueValues = row.OfferModel.reduce((item, current) => {
                const value = item.find((item) => item.model.name === current.model.name);
                if (!value) {
                    return item.concat([current]);
                } else return item;
            }, []).map((item) => item.model.name);

            return (
                <Typography variant="tableContent">
                    {uniqueValues.map((item, index) => {
                        if (index === uniqueValues.length - 1) {
                            return (
                                <Typography variant="tableContent" key={index}>
                                    {item}
                                </Typography>
                            );
                        } else {
                            return <Typography variant="tableContent" key={index}>{`${item}, `}</Typography>;
                        }
                    })}
                </Typography>
            );
        }
        if (column.id === 'brand') {
            return (
                <Typography variant="tableContent">
                    {row.OfferModel[0]?.brandId === '1'
                        ? 'Dacia'
                        : row.OfferModel[0]?.brandId === '2'
                        ? t('Renault')
                        : row.OfferModel[0]?.brandId === '3'
                        ? t('Nissan')
                        : ''}
                </Typography>
            );
        }
        if (column.id === 'status') {
            return (
                <Chip
                    sx={{
                        backgroundColor:
                            value === 'Open'
                                ? '#EFAC00'
                                : value === 'Refused'
                                ? '#FF4B55'
                                : value === 'Accepted'
                                ? '#009C10'
                                : '#5B80BA',
                    }}
                    label={
                        <Typography variant="tableContent" color="white">
                            {value === 'Open'
                                ? t('Open')
                                : value === 'Refused'
                                ? t('Refused')
                                : value === 'Accepted'
                                ? t('Accepted')
                                : t('Reoffered')}
                        </Typography>
                    }
                />
            );
        }
        if (column.id === 'createdAt') {
            return <div style={{ display: 'flex' }}>{dayjs(value).format('DD MMM YYYY')}</div>;
        }
        if (column.id === 'view') {
            return (
                can(6) && (
                    <>
                        <Tooltip position="left" textTip={<text>{t('View options')}</text>} followCursor>
                            <div
                                className="details-arrow-style"
                                onClick={(e) => {
                                    setRowData(row);
                                    handleClick(e);
                                }}
                            >
                                <ChevronRightIcon />
                            </div>
                        </Tooltip>
                    </>
                )
            );
        }
        return <Typography variant="tableContent">{value}</Typography>;
    };

    const handleAccept = () => {
        OffersAPI.acceptOffer(rowId).then((res) => {
            if (res.ok) {
                setTriggerRefetch(!triggerRefetch);
                handleClose();
                setOpenConfirm(false);
                toast.success(t('Offer was marked as accepted!'));
            } else {
                handleClose();
                setOpenConfirm(false);
                toast.error(t('Something went wrong! Offer could not be marked as accepted!'));
            }
        });
    };

    return (
        <>
            <CustomTable
                labels={labels}
                loading={loading}
                setLoading={setLoading}
                tableData={offers}
                withBoxShadow={false}
                setterFunction={setOffers}
                cellModifier={cellModifier}
                getterFunction={OffersAPI.getByClientIdBrands}
                withPagination={true}
                additionalId={id}
                triggerRefetch={triggerRefetch}
            />
            <RefuseOffer
                open={openRefuseModal}
                setOpen={setOpenRefuseModal}
                rowId={rowId}
                triggerRefetch={triggerRefetch}
                setTriggerRefetch={setTriggerRefetch}
                handleClose={handleClose}
            />
            <ReOfferOffer open={openReOffer} setOpen={setOpenReOffer} rowId={rowId} />
            <ConfirmModal
                open={openConfirm}
                setOpen={setOpenConfirm}
                text={t('Are you sure you want to accept this offer?')}
                onClickButtonYes={() => handleAccept()}
            />
            {rowData && (
                <OfferOptions
                    options={offerOptions(rowData)}
                    id={idOpen}
                    open={open}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                />
            )}
        </>
    );
};

export default ClientOffers;
