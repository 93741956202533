import React, { useRef } from 'react';
import styles from './Tasks.module.scss';
import { CustomDialog } from 'lib';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';

import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { TextField, Select, DatePicker } from 'lib';
import { TasksAPI } from 'api_darex';
import { useUser } from 'hooks/useUser';
import DeleteIcon from '@mui/icons-material/Delete';

const AddTask = ({ open, setOpen, rowState, getToDoTasks, clients = [], userId, clientId, users, getClients }) => {
    const formRef = useRef();
    const { t } = useTranslation();
    const { user } = useUser();

    const brands = [
        { id: 1, name: 'Dacia' },
        { id: 2, name: 'Renault' },
        { id: 3, name: 'Nissan' },
    ];

    const INITIAL_FORM_STATE = {
        title: rowState?.title ? rowState.title : '',
        details: rowState?.details ? rowState.details : '',
        deadline: rowState?.deadline ? rowState.deadline : new Date(),
        clientBrandsId: clientId ? clientId : rowState?.clientId ? rowState.clientId : '',
        userId: rowState?.userId ? rowState.userId : userId ? userId : '',
        type: 'others',
        status: false,
    };

    const FORM_VALIDATION = yup.object().shape({
        title: yup
            .string()
            .typeError(t('This title is not valid!'))
            .trim()
            .min(3, t('The title must contain at least 3 characters!'))
            .required(t('The title is mandatory!')),
        details: yup.string().typeError(t('This details is not valid!')).required(t('The details are mandatory!')),
        deadline: yup.date().typeError(t('Choose a valid date type!')).required(t('The date is mandatory!')),
        userId: yup.string().typeError(t('This counselor is not valid!')).required(t('The counselor is mandatory!')),
        clientBrandsId: yup.string().typeError(t('This client is not valid!')),
    });

    const createTask = (values) => {
        if (values.clientBrandsId === '') {
            delete values.clientBrandsId;
        }

        TasksAPI.create(values).then((res) => {
            if (res.ok === true) {
                getToDoTasks({ actionCase: 'add', item: res.data });
                setOpen(false);
                toast.success(t('The task has been added successfully!'));
            } else {
                toast.error(t('Something went wrong!'));
            }
        });
    };

    const updateTask = (values) => {
        if (values.clientBrandsId === '') {
            delete values.clientBrandsId;
        }

        TasksAPI.update(rowState.id, values).then((res) => {
            if (res.ok === true) {
                getToDoTasks({ actionCase: 'edit', item: res.data });
                setOpen(false);
                toast.success(t('The task has been updated successfully!'));
            } else {
                toast.error(t('Something went wrong!'));
            }
        });
    };

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={rowState ? t('Edit task') : t('Add task')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={handleSubmit}
            width="580px"
        >
            <Formik
                innerRef={formRef}
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    rowState ? updateTask(values) : createTask(values);
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <div className={styles.addTaskWrapper}>
                                <TextField name="title" label={`${t('Title')}`} size="medium" />
                                <DatePicker
                                    name="deadline"
                                    label={t('Deadline')}
                                    size="medium"
                                    inputFormat="DD/MM/YYYY"
                                />
                                <TextField name="details" label={`${t('Details')}`} size="medium" />
                                {users && user.roleId !== '4' && (
                                    <Select name="userId" label={`${t('Counselor')}`} size="medium">
                                        {users.map((user) => (
                                            <MenuItem
                                                key={user.id}
                                                value={user.id}
                                                sx
                                                onClick={() => {
                                                    getClients(user.id);
                                                }}
                                            >
                                                {`${user.name} ${user.firstName}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                                {!clientId && !rowState && (
                                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                                        <Select name="clientBrandsId" label={`${t('Client (optional)')}`} size="medium">
                                            {clients.map((client) => (
                                                <MenuItem key={client.clientsBrandId} value={client.clientsBrandId} sx>
                                                    {`${
                                                        client.type === 'Individual'
                                                            ? client.lastName + ' ' + client.firstName
                                                            : client.type === 'Juridic'
                                                            ? client.firmName
                                                            : ''
                                                    } - ${
                                                        brands.find((brand) => String(brand.id) === client.brandId)?.[
                                                            'name'
                                                        ]
                                                    }`}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        <DeleteIcon
                                            fontSize="large"
                                            sx={{
                                                cursor: props.values.clientBrandsId !== '' && 'pointer',
                                                color:
                                                    props.values.clientBrandsId !== ''
                                                        ? '#FF4B55'
                                                        : 'rgba(0, 0, 0, 0.3)',
                                                '&:hover': {
                                                    color: props.values.clientBrandsId !== '' && '#a50009',
                                                },
                                            }}
                                            onClick={
                                                props.values.clientBrandsId !== ''
                                                    ? () => {
                                                          props.setFieldValue('clientBrandsId', '');
                                                      }
                                                    : undefined
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </CustomDialog>
    );
};

export default AddTask;
