import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useUser } from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import ToDoTasksComponent from './ToDoTasksCompoenent';
import DoneTasksComponent from './DoneTasksComponent';
import TabsLayout from '../Tables/TabsLayout';
import AddTask from 'pages/Tasks/AddTask';
import FilterTasks from 'pages/Tasks/FilterTasks';
import { UtilityButton } from '../Buttons/buttons';

/**
 * Tasks component
 * @param {array} clients - represents the clients that will be rendered in add or edit modal
 * @param {function} getAllTasks - function used to refresh the tasks when one of them is added, deleted, edited or marked as done
 * @param {boolean} loading - used to conditionally render the tasks
 * @param {array} toDoTasks - tasks to be render in toDoTasks component
 * @param {array} doneTasks - tasks to be render in doneTasks component
 * @returns a component
 */
const TasksComponent = ({
    toDoTasks,
    doneTasks,
    getToDoTasks,
    getDoneTasks,
    loadingDone,
    loadingToDo,
    clients = [],
    withBoxShadow = true,
    withMarginTop = false,
    withUtility = false,
    clientId = null,
    setPageNumberDone,
    setPageNumberToDo,
    hasMoreDone,
    hasMoreToDo,
    activeTab,
    setActiveTab,
    isSearch = false,
    pageNumberDone,
    pageNumberToDo,
    setToDoTasks,
    setDoneTasks,
    isFilter,
    setIsFilter,
    filterData,
    setFilterData,
    setTriggerFilter,
    triggerRefetch,
    setTriggerRefetch,
    triggerFilter,
    resetFilter,
    setLoadingDone,
    setLoadingToDo,
}) => {
    const { t } = useTranslation();
    const [openAdd, setOpenAdd] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const { user, can } = useUser();

    useEffect(() => {
        if (activeTab === 0 && pageNumberDone > 0 && !isSearch && !isFilter) {
            setDoneTasks([]);
            setPageNumberDone(0);
        }
        if (activeTab === 1 && pageNumberToDo > 0 && !isSearch && !isFilter) {
            setToDoTasks([]);
            setPageNumberToDo(0);
        }
    }, [activeTab]);

    const tabs = [
        {
            label: t('Tasks to do'),
            id: 0,
            render: true,
            hasUtility: true,
            component: (
                <ToDoTasksComponent
                    tasks={toDoTasks}
                    getToDoTasks={getToDoTasks}
                    getDoneTasks={getDoneTasks}
                    clients={clients}
                    setPageNumberToDo={setPageNumberToDo}
                    loading={loadingToDo}
                    hasMore={hasMoreToDo}
                    activeTab={activeTab}
                    isSearch={isSearch}
                    isFilter={isFilter}
                />
            ),
        },
        {
            label: t('Tasks performed'),
            id: 1,
            render: true,
            hasUtility: true,
            component: (
                <DoneTasksComponent
                    tasks={doneTasks}
                    getDoneTasks={getDoneTasks}
                    getToDoTasks={getToDoTasks}
                    clients={clients}
                    setPageNumberDone={setPageNumberDone}
                    loading={loadingDone}
                    hasMore={hasMoreDone}
                    activeTab={activeTab}
                    isSearch={isSearch}
                    isFilter={isFilter}
                />
            ),
        },
    ];

    const utility = [
        {
            id: 0,
            component: (
                <UtilityButton startIcon={<FilterListIcon />} onClick={() => setOpenFilter(true)}>
                    {t('FILTER TASKS')}
                </UtilityButton>
            ),
            render: true,
        },
        {
            id: 1,
            component: (
                <UtilityButton startIcon={<AddIcon />} onClick={() => setOpenAdd(true)}>
                    {t('ADD TASK')}
                </UtilityButton>
            ),
            render: can(8),
        },
    ];

    return (
        <>
            <TabsLayout
                tabs={tabs}
                withBoxShadow={withBoxShadow}
                withMarginTop={withMarginTop}
                utility={withUtility && utility}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
            <AddTask
                open={openAdd}
                setOpen={setOpenAdd}
                getToDoTasks={getToDoTasks}
                userId={user.id}
                clientId={clientId}
            />
            <FilterTasks
                open={openFilter}
                setOpen={setOpenFilter}
                isFilter={isFilter}
                setIsFilter={setIsFilter}
                filterData={filterData}
                setFilterData={setFilterData}
                triggerFilter={triggerFilter}
                setTriggerFilter={setTriggerFilter}
                triggerRefetch={triggerRefetch}
                setTriggerRefetch={setTriggerRefetch}
                resetFilter={resetFilter}
                clientId={clientId}
                setLoading={activeTab === 0 ? setLoadingToDo : activeTab === 1 ? setLoadingDone : () => {}}
                setData={activeTab === 0 ? setToDoTasks : activeTab === 1 ? setDoneTasks : () => {}}
            />
        </>
    );
};

export default TasksComponent;
