import React from 'react';
import { Dashboard, TestPageOC, User, Users, TestForm, AddClient, TestLayout } from 'pages';
import Vehicles from 'nomenclators/Vehicles/Vehicles';
import { Switch, Route } from 'react-router-dom';
import AddModel from 'nomenclators/Vehicles/AddModel.js';

import WorkPoint from 'pages/WorkPoint/WorkPoint';
import ClientSource from 'pages/ClientSource/ClientSource';
import Profile from 'pages/Profile/Profile';
import Offers from 'pages/Offers/Offers';
import Offer from 'pages/Offer/Offer';
import OrderDetails from 'pages/Orders/OrderDetails';
import Tasks from 'pages/Tasks/Tasks';
import Objectives from 'pages/Objectives/Objectives';
import CreateOffer from 'pages/CreateOffer/CreateOffer';
import UserOffers from 'pages/Users/UserOffers';
import UserTasks from 'pages/Users/UserTasks';
import UserClients from 'pages/Users/UserClients';
import UserObjectives from 'pages/Users/UserObjectives';
import ComponentsOverview from 'pages/TestPageOC/ComponentsOverview';
import Versions from 'pages/Versions/Versions';
import Clients from 'pages/Clients/Clients';
import Client from 'pages/Clients/Client';

export function RouteWithSubRoutes(route) {
    return (
        <Route
            path={route.path}
            exact={route.exact || false}
            render={(props) => <route.component {...props} routes={route.routes || []} />}
        />
    );
}

const routes = [
    //Dashboard
    {
        path: '/',
        exact: true,
        component: Dashboard,
    },

    /**
     * Routes for USERS
     */
    {
        path: '/users',
        exact: true,
        component: Users,
    },
    {
        path: '/users/:id',
        exact: true,
        component: User,
    },
    {
        path: '/users/offers/:id',
        exact: true,
        component: UserOffers,
    },
    {
        path: '/users/tasks/:id',
        exact: true,
        component: UserTasks,
    },
    {
        path: '/users/clients/:id',
        exact: true,
        component: UserClients,
    },
    {
        path: '/users/objectives/:id',
        exact: true,
        component: UserObjectives,
    },

    /**
     * Routes for OFFERS
     */
    {
        path: '/offers',
        exact: true,
        component: Offers,
    },
    {
        path: '/offers/create',
        exact: true,
        component: CreateOffer,
    },
    {
        path: '/offers/:id',
        exact: true,
        component: Offer,
    },
    {
        path: '/offers/orders/:id',
        exact: true,
        component: OrderDetails,
    },

    /**
     * Routes for CLIENTS
     */
    {
        path: '/clients',
        exact: true,
        component: Clients,
    },
    {
        path: '/clients/create',
        exact: true,
        component: AddClient,
    },
    {
        path: '/clients/:id',
        exact: true,
        component: Client,
    },
    {
        path: '/clients/update/:id',
        exact: true,
        component: AddClient,
    },

    /**
     * Routes for Tasks
     */
    {
        path: '/tasks',
        exact: true,
        component: Tasks,
    },
    {
        path: '/objectives',
        exact: true,
        component: Objectives,
    },

    /**
     * Routes for NOMENCLATURES
     */
    {
        path: '/work-points',
        exact: true,
        component: WorkPoint,
    },
    {
        path: '/client-source',
        exact: true,
        component: ClientSource,
    },
    {
        path: '/vehicles',
        exact: true,
        component: Vehicles,
    },
    {
        path: '/vehicles/create',
        exact: true,
        component: AddModel,
    },
    {
        path: '/vehicles/update/:id',
        exact: true,
        component: AddModel,
    },

    /**
     * Route for PROFILE
     */
    {
        path: '/profile',
        exact: true,
        component: Profile,
    },

    /**
     * Versions of the CRM
     */
    {
        path: '/versions',
        exact: true,
        component: Versions,
    },

    /**
     * Routes for TESTING
     */
    //Test pages Onofrei Constantin
    {
        path: '/testoc',
        exact: true,
        component: TestPageOC,
    },
    {
        path: '/components-overview',
        exact: true,
        component: ComponentsOverview,
    },
    //Test pages Codrin
    {
        path: '/form-test',
        exact: true,
        component: TestForm,
    },
    {
        path: '/test-layout',
        exact: true,
        component: TestLayout,
    },

    /**
     * Nested route - is just an example
     */
    {
        path: '/nested',
        component: ({ routes }) => (
            <React.Fragment>
                <p>Nested route example</p>
                <Switch>
                    {routes.map((route, i) => (
                        <RouteWithSubRoutes key={i} {...route} />
                    ))}
                </Switch>
            </React.Fragment>
        ),
        routes: [
            {
                path: '/nested/page-1',
                component: () => <React.Fragment>nest here!</React.Fragment>,
            },
        ],
    },
];

export default routes;
