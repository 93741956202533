import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDialog } from 'lib';
import { Button, TextField, Avatar } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { uploadSingleFile } from 'utils/functions';
import { toast } from 'react-toastify';
import { FileUploadWrapper } from 'lib';
import { UsersAPI } from 'api_darex';
import DefaultUserPicture from 'resources/img/user/DefaultUserPicture.png';

const ChangeProfilePicture = ({ open, setOpen, userData, getUserById }) => {
    const { t } = useTranslation();
    const [fileBlob, setFileBlob] = useState(null);
    const [name, setName] = useState('');
    const [profilePic, setProfilePic] = useState('');

    useEffect(() => {
        (async () => {
            const imgId = userData?.ProfilePicture[userData.ProfilePicture.length - 1]?.file.fileName;
            if (imgId) {
                const { data: imgUrl } = await UsersAPI.getPicture(imgId);
                setProfilePic(imgUrl);
            }
        })();
    }, [userData]);

    const handleFileUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    toast.error(t(message));
                    return;
                }
                setFileBlob(blob);
                setName(blob.name);
            },
            'image'
        );
    };

    const onSubmitFunc = () => {
        UsersAPI.updatePicture(userData.id, fileBlob).then((res) => {
            if (res.ok === true) {
                getUserById();
                setOpen(false);
                setName('');
                setFileBlob(null);
                toast.success(t('User has been updated successfully!'));
            } else {
                toast.error(t('Something went wrong! User could not be updated!'));
            }
        });
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={t('Change your profile picture')}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={onSubmitFunc}
            buttonFinishDisabled={!fileBlob ? true : false}
            onClickButtonClose={() => {
                setName('');
                setFileBlob(null);
                setOpen(false);
            }}
            width="580px"
        >
            <div className="change-container">
                <div className="change-left">
                    <TextField
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        disabled
                        sx={{
                            fieldset: { borderRadius: '10px' },
                            width: '283px',
                            '& .MuiOutlinedInput-root': {
                                '&.Mui-focused fieldset': {
                                    borderColor: '#5b80ba',
                                },
                            },
                            '& label.Mui-focused': {
                                color: '#5b80ba',
                            },
                        }}
                        placeholder={t('Image name')}
                    />
                    <FileUploadWrapper onUpload={handleFileUpload}>
                        <Button
                            variant="contained"
                            component="label"
                            endIcon={<AttachFileIcon />}
                            sx={{
                                width: '283px',
                                height: '52px',
                                padding: '16px 62px',
                                backgroundColor: '#5B80BA',
                                borderRadius: '10px',
                            }}
                        >
                            {t('Upload image')}
                        </Button>
                    </FileUploadWrapper>
                </div>
                <div className="change-right">
                    <Avatar
                        src={
                            profilePic && !fileBlob
                                ? profilePic
                                : fileBlob
                                ? URL.createObjectURL(fileBlob)
                                : DefaultUserPicture
                        }
                        alt={userData.firstName}
                        sx={{
                            width: '150px',
                            height: '150px',
                            svg: { width: '150px', height: '150px' },
                            margin: '0 auto',
                        }}
                    ></Avatar>
                </div>
            </div>
        </CustomDialog>
    );
};

export default ChangeProfilePicture;
