import React, { useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { MenuItem } from '@mui/material';

// Trebuie importata din lib componenta modificata de TextField
import { TextField, Select, MultiSelect, DatePicker, PermissionsWrapper, FileUploadWrapper, LoadingSpinner } from 'lib';
import { emailRegex, phoneRegex } from 'utils/regex';
import { uploadSingleFile } from 'utils/functions';
import { toast } from 'react-toastify';

// Import perntru permisiuni
import styles from 'styles/inputStyles.module.scss';
import radioStyles from 'styles/radioGroup.module.scss';
import { UtilityButton } from 'lib/components/Buttons/buttons';
import { useTranslation } from 'react-i18next';

const TestForm = () => {
    const { t } = useTranslation();
    const formRef = useRef();

    const persons = [
        { id: 1, name: 'Ana' },
        { id: 2, name: 'Iulian' },
        { id: 3, name: 'Florinel' },
        { id: 4, name: 'Giani' },
        { id: 5, name: 'Evelin' },
    ];

    const INITIAL_FORM_STATE = {
        firstName: '',
        email: '',
        phone: '',
        country: '',
        city: [],
        birthday: new Date(),
        cars: [],
        rca: '',
    };

    const FORM_VALIDATION = yup.object().shape({
        // Cheile trebuie sa aiba aceasi denumire ca in INITIAL_FORM_STATE
        firstName: yup
            .string() // Tipul valorii
            .typeError('Acesta nu este un nume valabil!') // Eroare ce este aruncata cand valoarea introdus nu este de tipul selectat
            .trim() // Trim - ignora spatiile goale si nu le considera caractere, ne ajuta ca un user sa nu treaca de un camp apasand de mai multe ori pe tasta space
            .min(3, 'Numele trebuie sa contina cel putin 3 caractere!') // Minimul de caractere pe care trebuie sa le aiba valoarea introdusa
            .required('Numele este obligatoriu!'), // Acesta face va valoarea sa fie obligatorie
        email: yup
            .string()
            .trim()
            .matches(emailRegex, 'Trebuie sa introduceti un mail valid')
            .required('Mailul este obligatoriu'),
        phone: yup
            .string()
            .trim()
            .matches(phoneRegex, 'Trebuie sa introduceti un numar de telefon valid')
            .required('Numarul de telefon este obligatoriu'),
        country: yup.string().required(),
        city: yup.array().of(yup.string()).min(2, 'Cel putin un oras!').required('Este obligatoriu'),
        birthday: yup.date().typeError('Alegeti un tip de data valid').required('Data este obligatorie'),
        cars: yup
            .array()
            .of(yup.string().typeError('prostie'))
            .min(2, 'Cel putin doua masini!')
            .required('Data este obligatorie'),
        rca: yup.string().required('Plata rca este obligatorie!'),
    });

    /**
     *
     *
     * Componenta permisiuni
     *
     *
     */

    const handleSubmitFormik = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const [fileBlob, setFileBlob] = useState();

    const handleFileUpload = async (e) => {
        await uploadSingleFile(
            e,
            ({ message, blob }) => {
                if (message) {
                    toast.error(t(message));
                    return;
                }
                setFileBlob(blob);
            },
            'image'
        );
    };

    return (
        <div>
            <Formik
                innerRef={formRef}
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    alert('aici');
                }}
            >
                {(props) => (
                    <Form>
                        {/* Propul name trebuie sa aiba aceasi denumire cu cheia pentru acest field din INITIAL_FORM_STATE */}
                        <TextField name="firstName" label="First name" />
                        <TextField name="email" label="Email" />
                        <TextField name="phone" label="Phone number" />
                        <Select name="country" label="Country">
                            {[
                                { id: 1, name: 'Ana' },
                                { id: 2, name: 'Iulian' },
                                { id: 3, name: 'Florinel' },
                                { id: 4, name: 'Giani' },
                                { id: 5, name: 'Evelin' },
                            ].map((option, pos) => (
                                <MenuItem key={pos} value={option.name} sx>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <div style={{ margin: '2rem' }}></div>
                        <MultiSelect name="city" label="City">
                            {persons.map((option, pos) => (
                                <MenuItem key={pos} value={option.id} sx>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </MultiSelect>
                        <div style={{ margin: '2rem' }}></div>
                        <DatePicker name="birthday" label="Data nastere" />
                        <div style={{ margin: '2rem' }}></div>
                        <div style={{ margin: '2rem' }}></div>
                        <div
                            role="group"
                            aria-labelledby="checkbox-group"
                            className={styles.checkboxGroupWrapper}
                            onBlur={props.handleBlur}
                        >
                            <div className={styles.checkboxGroup}>
                                <label>
                                    <Field type="checkbox" name="cars" value="dacia" />
                                    DACIA
                                </label>
                                <label>
                                    <Field type="checkbox" name="cars" value="nissan" />
                                    RENAULT
                                </label>
                                <label>
                                    <Field type="checkbox" name="cars" value="renault" />
                                    Nissan
                                </label>
                            </div>
                            {props.errors.cars && props.touched.cars ? (
                                <div className={styles.checkboxGroupError}>{props.errors.cars}</div>
                            ) : (
                                ''
                            )}
                        </div>

                        <div style={{ margin: '2rem' }}></div>

                        {/* Radio buttons */}
                        <div role="group" className={radioStyles.radioGroupWrapper}>
                            <label>
                                <Field type="radio" name="rca" value="1" />
                                <div className={radioStyles.labelName}>Plata avans</div>
                            </label>
                            <label>
                                <Field type="radio" name="rca" value="2" />
                                <div className={radioStyles.labelName}>Plata intreaga</div>
                            </label>

                            <label>
                                <Field type="radio" name="rca" value="3" />
                                <div className={radioStyles.labelName}>Plata dosar finantare</div>
                            </label>
                        </div>

                        {/* <CheckBox name="cars" label="TEst" /> */}
                    </Form>
                )}
            </Formik>
            <div style={{ margin: '5rem' }}></div>
            {/* 
            
            Componenta permisiuni
            
            */}
            <button
                onClick={() => {
                    handleSubmitFormik();
                }}
            >
                Test
            </button>
            <PermissionsWrapper />
            <div style={{ margin: '10rem' }}></div>
            <FileUploadWrapper onUpload={handleFileUpload}>
                <UtilityButton>Incarca fisier</UtilityButton>
            </FileUploadWrapper>
            <div style={{ margin: '10rem' }}></div>
            <div style={{ marginLeft: '10rem' }}>
                <LoadingSpinner loading={true} />
            </div>
            <div style={{ margin: '20rem' }}></div>
        </div>
    );
};

export default TestForm;
