import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { Formik } from 'formik';

import { OrdersAPI } from 'api_darex';
import { CustomDialog, DatePicker } from 'lib';

const DatesModal = ({ open, setOpen, order, useCase, getOrder }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const [loading, setLoading] = useState(false);

    let INITIAL_FORM_STATE;
    let onSubmitFunc;
    let title;

    switch (useCase) {
        case 'advancePaymentDoc':
            INITIAL_FORM_STATE = {
                value: order.dateAdvancePaymentDoc ? order.dateAdvancePaymentDoc : new Date(),
            };

            title = t('Edit date for advanced payment document');
            onSubmitFunc = (values) => {
                setLoading(true);
                OrdersAPI.update(order.id, { dateAdvancePaymentDoc: values.value }).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(
                            t('The date of the document for advanced payment has been updated successfully!')
                        );
                    } else {
                        setLoading(false);
                        toast.error(
                            t('The date of the document for advanced payment could not be updated successfully!')
                        );
                    }
                });
            };
            break;
        case 'billingAdvancePayment':
            INITIAL_FORM_STATE = {
                value: order.dateBillingAdvancePayment ? order.dateBillingAdvancePayment : new Date(),
            };

            title = t('Edit the date for payment in advance');
            onSubmitFunc = (values) => {
                setLoading(true);
                OrdersAPI.updateLaunch(order.id, values.value).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(t('The date for payment in advance has been updated successfully!'));
                    } else {
                        if (res.error.response.data.message === 'You have no objectives set for this period') {
                            setLoading(false);
                            toast.error(
                                t(
                                    'The counselor on this order has no objectives set in the month of the selected date!'
                                )
                            );
                        } else {
                            setLoading(false);
                            toast.error(t('The date for payment in advance could not be updated successfully!'));
                        }
                    }
                });
            };
            break;
        case 'estimationProduction':
            INITIAL_FORM_STATE = {
                value: order.dateEstimationProduction ? order.dateEstimationProduction : new Date(),
            };
            title = t('Edit the estimated production date');
            onSubmitFunc = (values) => {
                setLoading(true);
                OrdersAPI.update(order.id, { dateEstimationProduction: values.value }).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(t('The estimated production date has been updated successfully!'));
                    } else {
                        setLoading(false);
                        toast.error(t('The estimated production date could not be updated successfully!'));
                    }
                });
            };
            break;
        case 'exitProduction':
            INITIAL_FORM_STATE = {
                value: order.dateExitProduction ? order.dateExitProduction : new Date(),
            };
            title = t('Edit the ex-factory date');
            onSubmitFunc = (values) => {
                setLoading(true);
                OrdersAPI.updateOutOfProduction(order.id, values.value).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(t('The ex-factory date has been updated successfully!'));
                    } else {
                        setLoading(false);
                        toast.error(t('The ex-factory date could not be updated successfully!'));
                    }
                });
            };
            break;
        case 'arrivalPark':
            INITIAL_FORM_STATE = {
                value: order.dateOfArrivalPark ? order.dateOfArrivalPark : new Date(),
            };
            title = t('Edit the Darex park entry date');
            onSubmitFunc = (values) => {
                setLoading(true);
                OrdersAPI.update(order.id, { dateOfArrivalPark: values.value }).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(t('The Darex park entry date has been updated successfully!'));
                    } else {
                        setLoading(false);
                        toast.error(t('The Darex park entry date could not be updated successfully!'));
                    }
                });
            };
            break;
        case 'fixedDelivery':
            INITIAL_FORM_STATE = {
                value: order.dateFixedDelivery ? order.dateFixedDelivery : new Date(),
            };
            title = t('Edit the date set for delivery');
            onSubmitFunc = (values) => {
                setLoading(true);
                OrdersAPI.update(order.id, { dateFixedDelivery: values.value }).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(t('The date set for delivery has been updated successfully!'));
                    } else {
                        setLoading(false);
                        toast.error(t('The date set for delivery could not be updated successfully!'));
                    }
                });
            };
            break;
        case 'transferRequest':
            INITIAL_FORM_STATE = {
                value: order.dateTransferRequest ? order.dateTransferRequest : new Date(),
            };
            title = t('Edit transfer request date');
            onSubmitFunc = (values) => {
                setLoading(true);
                OrdersAPI.update(order.id, { dateTransferRequest: values.value }).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(t('The transfer request date has been updated successfully!'));
                    } else {
                        setLoading(false);
                        toast.error(t('The transfer request date could not be updated successfully!'));
                    }
                });
            };
            break;
        case 'stornoBill':
            INITIAL_FORM_STATE = {
                value: order.dateStornoBill ? order.dateStornoBill : new Date(),
            };
            title = t('Edit cancelling billing date');
            onSubmitFunc = (values) => {
                setLoading(true);
                OrdersAPI.update(order.id, { dateStornoBill: values.value }).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(t('The cancelling billing date has been updated successfully!'));
                    } else {
                        setLoading(false);
                        toast.error(t('The cancelling billing date could not be updated successfully!'));
                    }
                });
            };
            break;
        case 'rebilling':
            INITIAL_FORM_STATE = {
                value: order.dateRebilling ? order.dateRebilling : new Date(),
            };
            title = t('Edit rebilling date');
            onSubmitFunc = (values) => {
                setLoading(true);
                OrdersAPI.update(order.id, { dateRebilling: values.value }).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(t('The rebilling date has been updated successfully!'));
                    } else {
                        setLoading(false);
                        toast.error(t('The rebilling date could not be updated successfully!'));
                    }
                });
            };
            break;
        case 'finalBill':
            INITIAL_FORM_STATE = {
                value: order.dateFinalBill ? order.dateFinalBill : new Date(),
            };
            title = t('Edit final invoice date');
            onSubmitFunc = (values) => {
                setLoading(true);
                OrdersAPI.update(order.id, { dateFinalBill: values.value }).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(t('The final invoice date has been updated successfully!'));
                    } else {
                        if (res.error.response.data.message === 'You have no objectives set for this period') {
                            setLoading(false);
                            toast.error(
                                t(
                                    'The counselor on this order has no objectives set in the month of the selected date!'
                                )
                            );
                        } else {
                            setLoading(false);
                            toast.error(t('The date for payment in advance could not be updated successfully!'));
                        }
                    }
                });
            };
            break;
        case 'delivery':
            INITIAL_FORM_STATE = {
                value: order.deliveryDate ? order.deliveryDate : new Date(),
            };
            title = t('Edit the delivery date');
            onSubmitFunc = (values) => {
                setLoading(true);
                OrdersAPI.update(order.id, { deliveryDate: values.value }).then((res) => {
                    if (res.ok) {
                        getOrder();
                        setOpen(false);
                        setLoading(false);
                        toast.success(t('The delivery date has been updated successfully!'));
                    } else {
                        if (res.error.response.data.message === 'You have no objectives set for this period') {
                            setLoading(false);
                            toast.error(
                                t(
                                    'The counselor on this order has no objectives set in the month of the selected date!'
                                )
                            );
                        } else {
                            setLoading(false);
                            toast.error(t('The date for payment in advance could not be updated successfully!'));
                        }
                    }
                });
            };
            break;
        default:
    }

    const FROM_VALIDATION = yup.object().shape({
        value: yup.date().typeError(t('Choose a valid date type!')).required(t('he date is mandatory!')),
    });

    const handleSubmit = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    return (
        <CustomDialog
            open={open}
            setOpen={setOpen}
            title={title}
            buttonClose={t('BACK')}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={handleSubmit}
            width="100%"
            maxWidth="450px"
            onClickButtonClose={() => {
                setOpen(false);
            }}
            buttonFinishLoading={loading}
            buttonCloseDisabled={loading}
        >
            <Formik
                innerRef={formRef}
                initialValues={{ ...INITIAL_FORM_STATE }}
                validationSchema={FROM_VALIDATION}
                onSubmit={(values) => onSubmitFunc(values)}
            >
                <DatePicker name="value" label={t('Date')} size="medium" inputFormat="DD/MM/YYYY" />
            </Formik>
        </CustomDialog>
    );
};

export default DatesModal;
