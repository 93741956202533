import React from 'react';
import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, RemoveButton, EditButton } from 'lib/components/Buttons/buttons';
import AddIcon from '@mui/icons-material/Add';
import { CustomTable } from 'lib';
import dayjs from 'dayjs';
import AddClientSource from './AddClientSource';
import ConfirmModal from 'lib/components/Modals/ConfirmModal';
import { ClientSourceAPI } from 'api_darex';
import { toast } from 'react-toastify';
import { PageLayout } from 'lib';
import { useUser } from 'hooks/useUser';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

const ClientSource = () => {
    const [sources, setSources] = useState([]);
    const { t } = useTranslation();
    const { can } = useUser();

    const [rowState, setRowState] = useState(false);
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const getAllClientSources = () => {
        ClientSourceAPI.get().then((res) => {
            setSources(res.data);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (!can(18)) {
            history.goBack();
        }

        getAllClientSources();
    }, []);

    const labels = [
        {
            id: 'name',
            label: t('Source name'),
            minWidth: 10,
        },

        {
            id: 'createdAt',
            label: t('Creation date'),
            minWidth: 10,
        },
        {
            id: 'actions',
            minWidth: 10,
        },
    ];

    const cellModifier = (row, column, value) => {
        if (column.id === 'createdAt') {
            return <div style={{ display: 'flex' }}>{dayjs(value).format('DD MMM YYYY')}</div>;
        }
        if (column.id === 'actions') {
            return (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {can(17) && (
                        <EditButton
                            color="#1860D2"
                            onClick={() => {
                                setRowState(row);
                                setOpen(true);
                            }}
                        />
                    )}
                    {can(16) && (
                        <RemoveButton
                            onClick={() => {
                                setRowState(row);
                                setOpenConfirm(true);
                            }}
                        />
                    )}
                </div>
            );
        }
        return <Typography variant="tableContent">{value}</Typography>;
    };

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Client sources')}</title>
            </Helmet>
            <PageLayout
                title={t('Client sources')}
                subTitle={t('Client sources administration')}
                actionArea={
                    can(17) && (
                        <PrimaryButton
                            startIcon={<AddIcon />}
                            color="primary"
                            onClick={() => {
                                setRowState(null);
                                setOpen(true);
                            }}
                        >
                            {t('Add client source')}
                        </PrimaryButton>
                    )
                }
            >
                <CustomTable
                    title={t('Client sources')}
                    labels={labels}
                    loading={loading}
                    tableData={sources}
                    cellModifier={cellModifier}
                    withPagination={false}
                />
            </PageLayout>

            <AddClientSource
                open={open}
                setOpen={setOpen}
                rowState={rowState}
                getAllClientSources={getAllClientSources}
            />
            <ConfirmModal
                open={openConfirm}
                setOpen={setOpenConfirm}
                onClickButtonYes={() =>
                    ClientSourceAPI.delete(rowState.id).then((res) => {
                        if (res.ok === true) {
                            getAllClientSources();
                            setOpenConfirm(false);
                            toast.success(t('This client source has been successfully deleted!'));
                        } else {
                            toast.error(t('Something went wrong! This client resource could not be deleted!'));
                        }
                    })
                }
                text={t('Are you sure you want to delete this client source?')}
            />
        </>
    );
};

export default ClientSource;
