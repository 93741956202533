import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, MenuItem, Box } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { TextField, Select, DatePicker } from 'lib';
import { ObjectivesAPI } from 'api_darex';
import { toast } from 'react-toastify';

const RenaultObjectives = ({ formRefDacia, setOpen, users, rowData, setTriggerRefetch, triggerRefetch }) => {
    const { t } = useTranslation();

    const INITIAL_FORM_STATE = {
        vpDeliveries: rowData?.tDeliveriesVP > 0 ? rowData?.tDeliveriesVP : rowData?.tDeliveriesVP === 0 ? 0 : '',
        vuDeliveries: rowData?.tDeliveriesVU > 0 ? rowData?.tDeliveriesVU : rowData?.tDeliveriesVU === 0 ? 0 : '',
        // totalDeliveries: rowData?.totalDeliveries ? rowData.totalDeliveries : '',
        pfOrders: rowData?.tOrdersPF > 0 ? rowData?.tOrdersPF : rowData?.tOrdersPF === 0 ? 0 : '',
        pjOrders: rowData?.tOrdersPJ > 0 ? rowData?.tOrdersPJ : rowData?.tOrdersPJ === 0 ? 0 : '',
        startDate: rowData?.startDate ? rowData.startDate : new Date(),
        endDate: rowData?.endDate ? rowData.endDate : new Date(),
        counselor: rowData?.user.id ? rowData.user.id : '',
    };

    const FORM_VALIDATION = yup.object().shape({
        // Cheile trebuie sa aiba aceasi denumire ca in INITIAL_FORM_STATE
        counselor: yup.string().required(t('The name of the counselor is mandatory!')),
        vpDeliveries: yup
            .number()
            .moreThan(-1, t('This value must be positive!'))
            .typeError(t('This value should be a number!'))
            .required(t('The number of the VP Deliveries is mandatory!')),
        vuDeliveries: yup
            .number() // Tipul valorii
            .moreThan(-1, t('This value must be positive!'))
            .typeError(t('This value should be a number!'))
            .required(t('The number of the VU Deliveries is mandatory!')),
        // totalDeliveries: yup
        //     .string() // Tipul valorii
        //     .typeError(t('This name is not a valid one!'))
        //     .trim()
        //     .min(1, t('The name has to contain at least one character'))
        //     .required(t('The number of the total deliveries is mandatory!')),
        pfOrders: yup
            .number() // Tipul valorii
            .moreThan(-1, t('This value must be positive!'))
            .typeError(t('This value should be a number!'))
            .required(t('The number of the PF Orders is mandatory!')),
        pjOrders: yup
            .number() // Tipul valorii
            .moreThan(-1, t('This value must be positive!'))
            .typeError(t('This value should be a number!'))
            .required(t('The number of the PJ Orders is mandatory!')),
        startDate: yup.date().typeError(t('Choose a valid date type!')).required(t('The date is mandatory!')),
        endDate: yup.date().typeError(t('Choose a valid date type!')).required(t('The date is mandatory!')),
    });

    const renaultObjectives = [
        { id: 1, label: t('VP Deliveries'), name: 'vpDeliveries' },
        { id: 2, label: t('VU Deliveries'), name: 'vuDeliveries' },
        // { id: 3, label: t('Total Deliveries'), name: 'totalDeliveries' },
        { id: 4, label: t('PF Orders'), name: 'pfOrders' },
        { id: 5, label: t('PJ Orders'), name: 'pjOrders' },
    ];

    const filteredUsers = users.filter(
        (user) => user.roleId === '4' && user.UserBrand.findIndex((us) => us.brandId === '2') >= 0
    );

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                borderRadius: '1.5rem',
                height: '100%',
            }}
        >
            <Formik
                innerRef={formRefDacia}
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    rowData
                        ? ObjectivesAPI.update(rowData.id, {
                              brandId: '2',
                              userId: values.counselor,
                              tDeliveriesVP: Number(values.vpDeliveries),
                              tDeliveriesVU: Number(values.vuDeliveries),
                              //   tTotalDeliveries: Number(values.totalDeliveries),
                              tOrdersPF: Number(values.pfOrders),
                              tOrdersPJ: Number(values.pjOrders),
                              startDate: values.startDate,
                              //   endDate: values.endDate,
                          }).then((res) => {
                              if (res.ok === true) {
                                  setOpen(false);
                                  setTriggerRefetch(!triggerRefetch);
                                  toast.success(t('The objective was succesfully updated'));
                              } else {
                                  if (
                                      res.error.response.data.message ===
                                      'Already exist an objective for this user in this month'
                                  ) {
                                      toast.error(
                                          t(
                                              'The objective could not be added! The counselor already has an objective for this month!'
                                          )
                                      );
                                  } else {
                                      toast.error(t('An error ocurred. Please try again'));
                                  }
                              }
                          })
                        : ObjectivesAPI.create({
                              brandId: '2',
                              userId: values.counselor,
                              tDeliveriesVP: Number(values.vpDeliveries),
                              tDeliveriesVU: Number(values.vuDeliveries),
                              //   tTotalDeliveries: Number(values.totalDeliveries),
                              tOrdersPF: Number(values.pfOrders),
                              tOrdersPJ: Number(values.pjOrders),
                              startDate: values.startDate,
                              //   endDate: values.endDate,
                          }).then((res) => {
                              if (res.ok === true) {
                                  setOpen(false);
                                  setTriggerRefetch(!triggerRefetch);
                                  toast.success(t('The objective was succesfully created'));
                              } else {
                                  if (
                                      res.error.response.data.message ===
                                      'Already exist an objective for this user in this month'
                                  ) {
                                      toast.error(
                                          t(
                                              'The objective could not be added! The counselor already has an objective for this month!'
                                          )
                                      );
                                  } else {
                                      toast.error(t('An error ocurred. Please try again'));
                                  }
                              }
                          });
                }}
            >
                <Form>
                    <Grid container justifyContent={'space-between'} spacing={2} sx={{ marginBottom: '14px' }}>
                        <Grid item xs={6}>
                            <Select name="counselor" label={t('Counselor')}>
                                {filteredUsers.map((user, pos) => (
                                    <MenuItem key={pos} value={user.id}>
                                        {user.name} {user.firstName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <DatePicker label={t('Month')} name="startDate" views={['year', 'month']} />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <DatePicker label={t('End Date')} name="endDate" views={['year', 'month']} />
                        </Grid> */}
                    </Grid>
                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
                            <Typography variant="objectivesName"> {t('Objective Name')} </Typography>
                        </div>

                        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                            <Typography variant="objectivesName">{t('Objectives')}</Typography>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
                        <div
                            style={{
                                display: 'flex',
                                width: '70%',
                                flexDirection: 'column',
                                flexShrink: 0,
                                gap: '20px',
                            }}
                        >
                            {renaultObjectives.map((obj) => (
                                <div key={obj.id} style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                                    <Typography variant="objectivesList">{obj.label}</Typography>
                                </div>
                            ))}
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                width: '30%',
                                flexShrink: 0,
                                gap: '20px',
                                flexDirection: 'column',
                            }}
                        >
                            {renaultObjectives.map((obj) => (
                                <div
                                    key={obj.id}
                                    style={{
                                        flex: 1,
                                        display: 'flex',
                                        flexDirection: 'flex-end',
                                        alignItems: 'center',
                                    }}
                                >
                                    <TextField size="small" name={obj.name} label={obj.label} />
                                </div>
                            ))}
                        </div>
                    </div>
                </Form>
            </Formik>
        </Box>
    );
};

export default RenaultObjectives;
