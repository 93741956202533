import React, { useState } from 'react';
import styles from './TaskComponent.module.scss';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CarIcon } from 'resources/svg/tasks/CarIcon.svg';
import { ReactComponent as GDPRIcon } from 'resources/svg/tasks/GDPRIcon.svg';
import { ReactComponent as OtherTask } from 'resources/svg/tasks/OtherTask.svg';
import { ReactComponent as PhoneIcon } from 'resources/svg/tasks/PhoneIcon.svg';
import { Typography } from '@mui/material';
import { RemoveButton, EditButton, ConfirmButton } from '../Buttons/buttons';
import ConfirmModal from '../Modals/ConfirmModal';
import AddTask from 'pages/Tasks/AddTask';
import { TasksAPI } from 'api_darex';
import { toast } from 'react-toastify';
import { useUser } from 'hooks/useUser';

/**
 * Task component
 * @param {object} item - object that represents data of a task
 * @param {function} getAllTasks - function used to refresh the tasks when one of them is added, deleted, edited or marked as done
 * @param {array} clients - represents the clients that will be rendered in add or edit modal
 * @returns a component
 */
const TaskComponent = ({ item, getToDoTasks, getDoneTasks, clients, innerRef, activeTab }) => {
    const [openDelete, setOpenDelete] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const { t } = useTranslation();
    const { type, deadline, status, title, details } = item;
    const { user, can } = useUser();

    const deleteTask = () => {
        TasksAPI.delete(item.id).then((res) => {
            if (res.ok === true) {
                if (activeTab === 0) {
                    getToDoTasks({ actionCase: 'delete', id: item.id });
                } else if (activeTab === 1) {
                    getDoneTasks({ id: item.id });
                }
                setOpenDelete(false);
                toast.success(t('The task was successfully deleted!'));
            } else {
                toast.error(t('Something went wrong! This task could not be deleted!'));
            }
        });
    };

    const confirmTask = () => {
        TasksAPI.updateStatus(item.id, true).then((res) => {
            if (res.ok === true) {
                getToDoTasks({ actionCase: 'confirm', item: res.data });
                setOpenConfirm(false);
                toast.success(t('The task was successfully marked as completed!'));
            } else {
                toast.error(t('Something went wrong! This task could not be marked as completed!'));
            }
        });
    };

    let icon;
    let bgColor;
    let color;
    let borderColor;
    let svgClass;
    let dotClass;

    switch (type) {
        case 'DriveTest':
            icon = <CarIcon />;
            break;
        case 'reContact':
            icon = <PhoneIcon />;
            break;
        case 'GDPR':
            icon = <GDPRIcon />;
            break;
        case 'others':
            icon = <OtherTask />;
            break;
        default:
            icon = <PhoneIcon />;
    }

    const currentDate = new Date(Date.now());
    currentDate.setHours(0, 0, 0, 0);

    const dataDate = new Date(deadline);
    dataDate.setHours(0, 0, 0, 0);

    if (dataDate.getTime() < currentDate.getTime()) {
        color = '#FF4B55';
        bgColor = '#FFFDFD';
        svgClass = 'svgRed';
        dotClass = 'dotRed';
        borderColor = '#FF4B55';
    } else {
        color = '#5B80BA';
        bgColor = '#FFFFFF';
        svgClass = 'svgBlue';
        dotClass = 'dotBlue';
        borderColor = '';
    }

    if (status === true) {
        color = '#009c10';
        bgColor = '#F8FFF9';
        svgClass = 'svgGreen';
        dotClass = 'dotGreen';
        borderColor = '#009c10';
    }

    return (
        <>
            <div className={styles.taskWrapper} ref={innerRef ? innerRef : null}>
                <span className={`${styles.bigDot} ${styles[dotClass]}`}></span>
                <div className={styles.taskContainer} style={{ borderColor: borderColor, backgroundColor: bgColor }}>
                    <div className={styles.data}>
                        <div className={`${styles.dataTop} ${styles[svgClass]}`}>
                            {icon}
                            <Typography variant="taskNameText" color={color}>
                                {title}
                            </Typography>
                        </div>
                        {type === 'others' && (
                            <div className={styles.dataCenter}>
                                {/* <Typography variant="taskPersonText">{title}</Typography> */}
                                <Typography variant="taskPersonText">{details}</Typography>
                            </div>
                        )}
                        <div className={styles.dataBottom}>
                            <Typography variant="taskDateText">{dayjs(deadline).format('DD MMM YYYY')}</Typography>
                            {item?.user && (
                                <>
                                    <span className={styles.dot} />
                                    <Typography variant="taskPersonText">{t('Counselor')}</Typography>
                                    <Typography variant="taskDateText">{`${item.user.name} ${item.user.firstName}`}</Typography>
                                </>
                            )}
                            {item?.ClientBrands && (
                                <>
                                    <span className={styles.dot} />
                                    <Typography variant="taskPersonText">Client:</Typography>
                                    <Typography variant="taskDateText">
                                        {`${
                                            item.ClientBrands.Clients.type === 'Individual'
                                                ? item.ClientBrands.Clients.lastName +
                                                  ' ' +
                                                  item.ClientBrands.Clients.firstName
                                                : item.ClientBrands.Clients.type === 'Juridic'
                                                ? item.ClientBrands.Clients.firmName
                                                : ''
                                        }`}
                                    </Typography>
                                </>
                            )}
                            {item?.ClientBrands?.Clients && (
                                <>
                                    <span className={styles.dot} />
                                    <Typography variant="taskPersonText">{t('Brand')}:</Typography>

                                    <Typography variant="taskDateText">
                                        {item.ClientBrands.brandId === '1'
                                            ? 'Dacia'
                                            : item.ClientBrands.brandId === '2'
                                            ? 'Renault'
                                            : 'Nissan'}
                                    </Typography>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={styles.actionButtons}>
                        {can(8) && status === false && <ConfirmButton onClick={() => setOpenConfirm(true)} />}
                        {can(8) && status === false && type === 'others' && (
                            <EditButton
                                onClick={() => {
                                    setOpenEdit(true);
                                }}
                                color="#1860D2"
                            />
                        )}
                        {can(7) && (user.roleId !== '4' || (user.roleId === '4' && type === 'others')) && (
                            <RemoveButton onClick={() => setOpenDelete(true)} />
                        )}
                    </div>
                </div>
            </div>
            <ConfirmModal
                open={openDelete}
                setOpen={setOpenDelete}
                onClickButtonYes={() => deleteTask()}
                text={t('Are you sure you want to delete this task?')}
            />
            <ConfirmModal
                open={openConfirm}
                setOpen={setOpenConfirm}
                onClickButtonYes={() => confirmTask()}
                text={t('Are you sure you want to mark this task as finished?')}
            />
            <AddTask
                open={openEdit}
                setOpen={setOpenEdit}
                rowState={item}
                getToDoTasks={getToDoTasks}
                user={user}
                clients={clients}
            />
        </>
    );
};

export default TaskComponent;
