import React from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';

function Content({ children }) {
    const { vw } = useWindowDimensions();
    return (
        <div
            style={{
                padding: vw >= 1080 ? '6rem 0 0 15rem' : '6rem 0 0 0',
                background: '#ffffff',
                width: '100vw',
                height: '100vh',
                overflow: 'hidden',
            }}
        >
            <div
                style={{
                    position: 'relative',
                    borderTopLeftRadius: vw >= 1080 ? '1rem' : '0',
                    background: '#fafbfc',
                    width: '100%',
                    height: 'calc(100vh - 6rem)',
                }}
            >
                {/* Inner shadow */}
                <div
                    style={{
                        position: 'fixed',
                        borderTopLeftRadius: 'inherit',
                        width: '100%',
                        height: 'inherit',
                        boxShadow: 'inset 5px 5px 15px rgba(35, 78, 140, 0.1)',
                        pointerEvents: 'none',
                        zIndex: 50,
                    }}
                />

                {/* Inner content */}
                <div
                    style={{
                        height: 'inherit',
                        width: '100%',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Content;
