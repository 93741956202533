const ro = {
    'Manage your vehicles': 'Administrare Vehicule',
    'FILTER MODELS': 'FILTRARE MODELE',
    'ADD MODEL': 'ADAUGĂ MODEL',
    'Search model': 'Caută model',
    'Create model': 'Creare model',
    'Model name': 'Denumire model',
    'Model type': 'Tip model',
    Engine: 'Motorizare',
    Finish: 'Finalizare',
    Next: 'CONTINUĂ',
    Colors: 'Culori',
    Color: 'Culoare',
    Black: 'Negru',
    White: 'Alb',
    Yellow: 'Galben',
    Gray: 'Gri',
    'Add color': 'Adaugă culoare',
    'Please pick 1': 'Alegeți cel puțin una',
    Engines: 'Motorizări',
    'Add new engine type': 'Adaugă motorizare',
    Done: 'Finalizat',
    Cancel: 'Înapoi',
    'Engine name': 'Denumire',
    'Engine type': 'Tip motorizare',
    Reset: 'Resetează',
    'This name is not a valid one!': 'Acest nume nu este valid',
    'The name has to contain at least three characters': 'Numele trebuie sa contina cel putin 3 caractere!',
    'The name of the model is mandatory!': 'Numele modelului este obligatoriu!',
    'The type of the model is mandatory!': 'Tipul modelului este obligatoriu!',
    'Personal Vehicle': 'Vehicul personal',
    'Utility Vehicle': 'Vehicul utilitar',
    'Color is mandatory': 'Culoarea este obligatorie!',
    'Engine type is mandatory': 'Tipul motorizarii este obligatoriu!',
    'Engine name is mandatory': 'Numele motorizării este obligatorie!',
    'Choose at least one color': 'Alegeți cel putin o culoare',
    'Choose at least one engine': 'Alegeți cel puțin o motorizare',
    'Engine is mandatory': 'Motorizarea este obligatorie',
    Thermic: 'Termic',
    Hybrid: 'Hibrid',
    thermic: 'termic',
    electrical: 'electric',
    'Representative Details': 'Detalii reprezentant',
    'Utility vehicle': 'Vehicul Utilitar',
    'Personal vehicle': 'Vehicul Personal',
    'The model was succesfully created': 'Modelul a fost creat cu succes',
    'Edit vehicle': 'Editează vehicul',
    'Delete vehicle': 'Șterge vehicul',
    'Are you sure you want to mark delete this vehicle? This cannot be undone!':
        'Sunteți sigur că doriți să ștergeți acest vehicul? Acest lucru este ireversibil!',
    'An error ocurred. Please try again': 'A intervenit o eroare. Încercați din nou!',
    'The vehicle was successfully deleted': 'Vehiculul a fost șters cu succes',
    'Create new model': 'Crează model nou',
    'Visualize models': 'Vizualizare modele',
    'This color already exist': 'Această culoare există deja',
    'The engine with this name and type already exist': 'Motorizarea și tipul ales există deja',
    'The model with this name already exist': 'Modelul cu acest nume există deja',
    'The model was succesfully updated': 'Modelul a fost editat cu succes',
    'Edit model': 'Editare model',
    'Search vehicle': 'Caută vehicul',
    'ADD A NEW DACIA VEHICLE': 'ADĂUGAȚI UN NOU VEHICUL DACIA',
    'ADD A NEW RENAULT VEHICLE': 'ADĂUGAȚI UN NOU VEHICUL RENAULT',
    'ADD A NEW NISSAN VEHICLE': 'ADĂUGAȚI UN NOU VEHICUL NISSAN',
};
export default { ro };
