import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Accordion, AccordionSummary, AccordionDetails, Typography, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { UtilityButton } from 'lib/components/Buttons/buttons';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';

import ConfirmModal from 'lib/components/Modals/ConfirmModal';
import { VehiclesAPI } from 'api_darex';
import { toast } from 'react-toastify';
import { useUser } from 'hooks/useUser';

const VisualizeVehicleComponent = ({ vehicleData, row, triggerRefetch, setTriggerRefetch }) => {
    const { t } = useTranslation();
    const { vw } = useWindowDimensions();
    const [openConfirm, setOpenConfirm] = useState(false);
    const history = useHistory();
    const { can } = useUser();

    return (
        <div style={{ margin: '0 2rem' }}>
            <Accordion
                elevation={0}
                sx={{
                    backgroundColor: '#FFFFFF',
                    outline: 'none',
                    borderBottom: '1px solid #D9D9D9',
                    padding: '0.5rem',

                    '&.MuiAccordion-root': {
                        borderBottomLeftRadius: '0px',
                        borderBottomRightRadius: '0px',
                    },
                }}
            >
                <AccordionSummary
                    sx={{
                        padding: '0px',
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="vehicleNomenclatorText"> {row.name} </Typography>
                    <Typography variant="vehicleNomenclatorFaded">
                        <li style={{ marginLeft: '24px' }}>
                            {row.type === 'utility' ? t('Utility vehicle') : t('Personal vehicle')}
                        </li>
                    </Typography>
                </AccordionSummary>

                <Divider sx={{ width: '100px' }} />

                <AccordionDetails sx={{ padding: '8px 0px 16px' }}>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            marginBottom: '8px',
                            marginTop: '16px',
                            color: 'rgba(91, 128, 186, 1)',
                        }}
                    >
                        {t('Colors')}
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: vw < 600 ? 'column' : 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        {row.ModelColor?.map((color, index) => (
                            <div
                                key={color.id}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Typography key={color.id} variant="vehicleNomenclatorFaded">
                                    {color.color.name + ' '}
                                </Typography>
                                {index < row.ModelColor.length - 1 && (
                                    <div
                                        style={{
                                            width: '5px',
                                            height: '5px',
                                            // borderRadius: '15px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                            marginLeft: '24px',
                                            marginRight: '24px',
                                        }}
                                    ></div>
                                )}
                            </div>
                        ))}
                    </div>
                </AccordionDetails>

                <Divider sx={{ width: '100px' }} />

                <AccordionDetails sx={{ padding: '8px 0px 16px' }}>
                    <Typography
                        sx={{
                            fontWeight: 600,
                            fontSize: '16px',
                            marginBottom: '8px',
                            marginTop: '16px',
                            color: 'rgba(91, 128, 186, 1)',
                        }}
                    >
                        {t('Engines')}
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: vw < 600 ? 'column' : 'row',
                            marginBottom: '24px',
                            flexWrap: 'wrap',
                        }}
                    >
                        {row.ModelEngine?.map((engine, index) => (
                            <div
                                key={engine.id}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Typography key={engine.id + '('} variant="userHeader">
                                    {engine.engine.name}
                                </Typography>
                                <span
                                    style={{
                                        color: 'rgba(0, 0, 0, 0.3)',
                                        marginLeft: '3px',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                    }}
                                >
                                    ( {t(engine.engine.type)} )
                                </span>

                                {index < row.ModelEngine.length - 1 && (
                                    <div
                                        style={{
                                            width: '5px',
                                            height: '5px',
                                            // borderRadius: '15px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                            marginLeft: '24px',
                                            marginRight: '24px',
                                        }}
                                    ></div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            gap: '1rem',
                            flexDirection: vw < 600 ? 'column' : 'row',
                        }}
                    >
                        <div>
                            {can(17) && (
                                <UtilityButton
                                    onClick={() =>
                                        history.push({
                                            pathname: `/vehicles/update/${row.id}`,
                                            state: {
                                                id: row.id,
                                                brandId: row.brandId,
                                                modelName: row.name,
                                                modelType: row.type,
                                                colors: row.ModelColor.map((clr) => clr.colorId),
                                                engines: row.ModelEngine.map((eng) => eng.engineId),
                                            },
                                        })
                                    }
                                    startIcon={<CreateIcon />}
                                >
                                    {t('Edit vehicle')}
                                </UtilityButton>
                            )}
                        </div>
                        {can(16) && (
                            <div>
                                <UtilityButton
                                    onClick={() => setOpenConfirm(true)}
                                    startIcon={<DeleteIcon />}
                                    color="red"
                                >
                                    {t('Delete vehicle')}{' '}
                                </UtilityButton>
                            </div>
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
            <ConfirmModal
                open={openConfirm}
                setOpen={setOpenConfirm}
                onClickButtonYes={() =>
                    VehiclesAPI.delete(row.id).then((res) => {
                        if (res.ok === true) {
                            toast.success(t('The vehicle was successfully deleted'));
                            setOpenConfirm(false);
                            setTriggerRefetch(!triggerRefetch);
                        } else {
                            toast.error(t('An error ocurred. Please try again'));
                            setOpenConfirm(false);
                        }
                    })
                }
                text={t('Are you sure you want to mark delete this vehicle? This cannot be undone!')}
            />
        </div>
    );
};

export default VisualizeVehicleComponent;
