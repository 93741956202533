import styles from './Details.module.scss';
import React, { useState } from 'react';
import { Comments } from 'lib';
import { Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ModifySmallButton } from 'lib/components/Buttons/buttons';
import dayjs from 'dayjs';
import { PrimaryButton, UtilityButton } from 'lib/components/Buttons/buttons';
import AddIcon from '@mui/icons-material/Add';
import ViewOffer from './modals/ViewOffer';
import UploadDocument from 'pages/Clients/modals/UploadDocument';
import { OffersAPI } from 'api_darex';
import { LoadingSpinner } from 'lib';
import CommonModal from './modals/CommonModal';
import DocumentRow from 'lib/components/DocumentRowNew/DocumentRow';
import { useUser } from 'hooks/useUser';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const DetailsComponent = ({ offer, getOffer, loading, documents, getDocuments }) => {
    const { t } = useTranslation();
    const [viewOrder, setViewOrder] = useState(false);
    const [openAddDocument, setOpenAddDocument] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [useCase, setUseCase] = useState(null);
    const history = useHistory();

    const { can } = useUser();

    const onSubmitFunction = ({ values, file }) => {
        OffersAPI.addDocument(offer.id, values.documentName, file).then((res) => {
            if (res.ok) {
                toast.success(t('Document was uploaded successfully!'));
                getDocuments();
            } else {
                toast.error(t('Document could not be uploaded successfully!'));
            }
        });
    };

    const onDeleteFunction = ({ doc }) => {
        OffersAPI.removeDocument(doc.id).then((res) => {
            if (res.ok) {
                toast.success(t('Document was deleted successfully!'));
                getDocuments();
            } else {
                toast.error(t('Document could not be deleted successfully!'));
            }
        });
    };

    const handleDownloadDocument = (path) => {
        OffersAPI.getDocumentByPath(path).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', path);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        });
    };

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Offer')}</title>
            </Helmet>
            {loading ? (
                <LoadingSpinner loading={loading} margin="0 auto" />
            ) : (
                <div className={styles.detailsWrapper}>
                    <div className={styles.dataWrapper}>
                        <div className={styles.dataLeft}>
                            <div className={styles.dataRow}>
                                <div className={styles.dataCell}>
                                    <Typography variant="offerDataLabel">{t('OFFER ID')}:</Typography>
                                </div>
                                <div className={styles.dataCellBig}>
                                    <Typography variant="offerDataText">{offer.autoincrement}</Typography>
                                </div>
                            </div>
                            <div className={styles.dataRow}>
                                <div className={styles.dataCell}>
                                    <Typography variant="offerDataLabel">Client:</Typography>
                                </div>
                                <div className={`${styles.dataCellBig}`}>
                                    <Typography
                                        variant="offerDataText"
                                        sx={{
                                            textDecoration: 'underline',
                                            '&:hover': {
                                                color: '#5B80BA',
                                                cursor: 'pointer',
                                            },
                                        }}
                                        onClick={() => {
                                            history.push(`/clients/${offer.ClientBrands.id}`);
                                        }}
                                    >
                                        {`${
                                            offer.ClientBrands.Clients.type === 'Individual'
                                                ? offer.ClientBrands.Clients.lastName +
                                                  ' ' +
                                                  offer.ClientBrands.Clients.firstName
                                                : offer.ClientBrands.Clients.type === 'Juridic'
                                                ? offer.ClientBrands.Clients.firmName
                                                : ''
                                        }`}
                                    </Typography>
                                </div>
                            </div>
                            <div className={styles.dataRow}>
                                <div className={styles.dataCell}>
                                    <Typography variant="offerDataLabel">{t('Client type')}:</Typography>
                                </div>
                                <div className={styles.dataCellBig}>
                                    <Typography variant="offerDataText">
                                        {offer.ClientBrands.Clients?.type === 'Individual'
                                            ? 'Individual'
                                            : offer.ClientBrands.Clients?.type === 'Juridic'
                                            ? t('Legal')
                                            : ''}
                                    </Typography>
                                </div>
                            </div>
                            <div className={styles.dataRow}>
                                <div className={styles.dataCell}>
                                    <Typography variant="offerDataLabel">{t('Brand')}:</Typography>
                                </div>
                                <div className={styles.dataCellBig}>
                                    <Typography variant="offerDataText">
                                        {offer.OfferModel[0]?.brandId === '1'
                                            ? 'Dacia'
                                            : offer.OfferModel[0]?.brandId === '2'
                                            ? t('Renault')
                                            : offer.OfferModel[0]?.brandId === '3'
                                            ? t('Nissan')
                                            : ''}
                                    </Typography>
                                </div>
                            </div>
                            <div className={styles.dataRow}>
                                <div className={styles.dataCell}>
                                    <Typography variant="offerDataLabel">Status:</Typography>
                                </div>
                                <div className={styles.dataCellBig}>
                                    <Typography variant="offerDataText">
                                        {offer.status === 'Open'
                                            ? t('Open')
                                            : offer.status === 'Refused'
                                            ? t('Refused')
                                            : offer.status === 'Accepted'
                                            ? t('Accepted')
                                            : offer.status === 'ReOffered'
                                            ? t('Reoffered')
                                            : ''}
                                    </Typography>
                                </div>
                            </div>
                            <div className={styles.dataRow}>
                                <div className={styles.dataCell}>
                                    <Typography variant="offerDataLabel">{t('Creation date')}:</Typography>
                                </div>
                                <div className={styles.dataCellBig}>
                                    <Typography variant="offerDataText">
                                        {dayjs(offer.createdAt).format('DD.MM.YYYY')}
                                    </Typography>
                                </div>
                            </div>
                            <div className={styles.dataRow}>
                                <div className={styles.dataCell}>
                                    <Typography variant="offerDataLabel">{t('Counselor')}:</Typography>
                                </div>
                                <div className={styles.dataCellBig}>
                                    <Typography variant="offerDataText">
                                        {offer.user.name} {offer.user.firstName}
                                    </Typography>
                                </div>
                            </div>

                            <div className={styles.dataRow}>
                                <div className={styles.dataCell}>
                                    <Typography variant="offerDataLabel">{t('Price')}:</Typography>
                                </div>
                                <div className={styles.dataCellBig}>
                                    <Typography variant="offerDataText">
                                        {offer.OfferModel.reduce((preVal, curVal) => {
                                            if (curVal.isDeleted === false) return preVal + curVal.price;
                                            else return preVal + 0;
                                        }, 0)}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div className={styles.dataRight}>
                            {offer.driveTest !== 'NU' && (
                                <div className={styles.dataRow}>
                                    <div className={styles.dataCell}>
                                        <Typography variant="offerDataLabel">{t('Test Drive')}:</Typography>
                                    </div>
                                    <div className={styles.dataCellBig}>
                                        {offer.driveTestDate ? (
                                            <Typography variant="offerDataText">{`${t('scheduled')}: ${dayjs(
                                                offer.driveTestDate
                                            ).format('DD.MM.YYYY')}`}</Typography>
                                        ) : (
                                            <Typography variant="offerDataText">{`${t(
                                                'The test drive is not scheduled'
                                            )}`}</Typography>
                                        )}

                                        {can(5) && offer.status !== 'Refused' && offer.status !== 'ReOffered' && (
                                            <ModifySmallButton
                                                onClick={() => {
                                                    setOpenEdit(true);
                                                    setUseCase('testDriveDate');
                                                }}
                                            >
                                                {t('Modify')}
                                            </ModifySmallButton>
                                        )}
                                    </div>
                                </div>
                            )}
                            {offer.comments && (
                                <div className={styles.dataRow}>
                                    <div className={styles.dataCell}>
                                        <Typography variant="offerDataLabel">{t('Other comments')}:</Typography>
                                    </div>
                                    <div className={styles.dataCellBig}>
                                        <Typography variant="offerDataText">{offer.comments}</Typography>
                                    </div>
                                </div>
                            )}
                            <div className={styles.dataRow}>
                                <div className={styles.dataCell}>
                                    <Typography variant="offerDataLabel">{t('Recontract date')}:</Typography>
                                </div>
                                <div className={styles.dataCellBig}>
                                    <Typography variant="offerDataText">
                                        {dayjs(offer.reContactDate).format('DD.MM.YYYY')}
                                    </Typography>
                                    {can(5) && offer.status !== 'Refused' && offer.status !== 'ReOffered' && (
                                        <ModifySmallButton
                                            onClick={() => {
                                                setOpenEdit(true);
                                                setUseCase('reContactDate');
                                            }}
                                        >
                                            {t('Modify')}
                                        </ModifySmallButton>
                                    )}
                                </div>
                            </div>
                            {offer.status === 'Refused' && (
                                <div className={styles.dataRow}>
                                    <div className={styles.dataCell}>
                                        <Typography variant="offerDataLabel" color="#FF4B55">
                                            {t('The reason for the refusal')}:
                                        </Typography>
                                    </div>
                                    <div className={styles.dataCellBig}>
                                        <Typography variant="offerDataText">{offer.refuseReason}</Typography>
                                    </div>
                                </div>
                            )}
                            {offer.status === 'ReOffered' && (
                                <div className={styles.dataRow}>
                                    <div className={styles.dataCell}>
                                        <Typography variant="offerDataLabel" color="#5B80BA">
                                            {t('The reason for reoffering')}:
                                        </Typography>
                                    </div>
                                    <div className={styles.dataCellBig}>
                                        <Typography variant="offerDataText">{offer.reOfferReason}</Typography>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {offer.OfferModel.find((item) => item.paymentType === 'RCI') && (
                        <>
                            <Divider />
                            <div className={styles.dateWrapper}>
                                <div className={styles.dateFirst}>
                                    <Typography variant="offerDateText">
                                        {t('RCI document submission date:')}
                                    </Typography>
                                </div>
                                <div className={styles.dateFirst}>
                                    <Typography variant="offerOptionalText">({t('optional')})</Typography>
                                </div>
                                <div className={styles.dateSecond}>
                                    <Typography variant="offerDataText">
                                        {offer.RCIfileDate
                                            ? dayjs(offer.RCIfileDate).format('DD MMM YYYY')
                                            : t('No document has been uploaded')}
                                    </Typography>
                                </div>
                                <div
                                    className={styles.dateFirst}
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                >
                                    {can(5) && offer.status !== 'Refused' && offer.status !== 'ReOffered' && (
                                        <ModifySmallButton
                                            onClick={() => {
                                                setOpenEdit(true);
                                                setUseCase('RCIfileDate');
                                            }}
                                        >
                                            {t('Modify')}
                                        </ModifySmallButton>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    <Divider />
                    <div className={styles.visualizeOrderWrapper}>
                        {can(6) && (
                            <PrimaryButton width="230px" onClick={() => setViewOrder(true)}>
                                {t('VIEW UNITS ON OFFER')}
                            </PrimaryButton>
                        )}
                    </div>
                    {offer.status !== 'Refused' && (
                        <div className={styles.documentsWrapper}>
                            <Typography variant="h6">{t('Documents')}</Typography>
                            <div className={styles.documentsContainer}>
                                {documents?.map((doc, index) => {
                                    return (
                                        <DocumentRow
                                            key={index}
                                            doc={doc}
                                            permission={4}
                                            onDeleteFunc={onDeleteFunction}
                                            onDownloadFunction={handleDownloadDocument}
                                        />
                                    );
                                })}
                            </div>
                            {can(5) && (
                                <div className={styles.addWrapper}>
                                    <UtilityButton startIcon={<AddIcon />} onClick={() => setOpenAddDocument(true)}>
                                        {t('ADD DOCUMENT')}
                                    </UtilityButton>
                                </div>
                            )}
                        </div>
                    )}
                    <div className={styles.commentsWrapper}>
                        <Comments id={offer.id} />
                    </div>
                </div>
            )}

            <ViewOffer open={viewOrder} setOpen={setViewOrder} offerData={offer} getOffer={getOffer} />
            <UploadDocument
                open={openAddDocument}
                setOpen={setOpenAddDocument}
                onSubmitFunc={onSubmitFunction}
                type="file"
            />
            <CommonModal
                open={openEdit}
                setOpen={setOpenEdit}
                offerData={offer}
                useCase={useCase}
                getOffer={getOffer}
            />
        </>
    );
};

export default DetailsComponent;
