import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import StepperComponent from './StepperComponent';
import { PageLayout } from 'lib';
import CustomTableLayout from 'lib/components/Tables/TabsLayout';

const AddModel = (props) => {
    const { t } = useTranslation();
    const { id } = useParams();

    const data = props.location.state || {};

    const [activeTab, setActiveTab] = useState(() => {
        if (data.savedTab) return data.savedTab;
        if (data.brandId) return Number(data.brandId) - 1;
        return 0;
    });

    const tabs = [
        {
            label: 'DACIA',
            id: 0,
            render: true,
            disabled: data.brandId !== '1' ? true : false,
            hasUtility: false,
            component: <StepperComponent brand={'1'} updateData={data} />,
        },
        {
            label: 'Renault',
            id: 1,
            render: true,
            disabled: data.brandId !== '2' ? true : false,
            hasUtility: false,
            component: <StepperComponent brand={'2'} updateData={data} />,
        },
        {
            label: 'Nissan',
            id: 2,
            render: true,
            disabled: data.brandId !== '3' ? true : false,
            hasUtility: false,
            component: <StepperComponent brand={'3'} updateData={data} />,
        },
    ];

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {id ? t('Edit vehicles') : t('Add vehicles')}</title>
            </Helmet>
            <PageLayout
                title={id ? t('Edit model') : t('Create model')}
                subTitle={t('Manage your vehicles')}
                backArrow={true}
            >
                <CustomTableLayout tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} withBoxShadow={true} />
            </PageLayout>
        </>
    );
};

export default AddModel;
