import React, { useCallback, useEffect, useState } from 'react';
import { PrimaryButton } from 'lib/components/Buttons/buttons';
import Search from 'lib/components/Search/Search';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import AddTask from './AddTask';
import FilterTasks from './FilterTasks';
import { TasksAPI, ClientsAPI, UsersAPI } from 'api_darex';
import TasksComponent from 'lib/components/Tasks/TasksComponent';
import { Helmet } from 'react-helmet';
import FilterListIcon from '@mui/icons-material/FilterList';

import { LoadingSpinner, PageLayout } from 'lib';
import { useUser } from 'hooks/useUser';

const Tasks = () => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [openAdd, setOpenAdd] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [doneTasks, setDoneTasks] = useState([]);
    const [toDoTasks, setToDoTasks] = useState([]);
    const [pageNumberToDo, setPageNumberToDo] = useState(0);
    const [pageNumberDone, setPageNumberDone] = useState(0);
    const [hasMoreToDo, setHasMoreToDo] = useState(false);
    const [hasMoreDone, setHasMoreDone] = useState(false);
    const [clients, setClients] = useState([]);
    const [users, setUsers] = useState([]);
    const [loadingToDo, setLoadingToDo] = useState(false);
    const [loadingDone, setLoadingDone] = useState(false);
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [triggerFilter, setTriggerFilter] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const history = useHistory();
    const { can, user } = useUser();

    useEffect(() => {
        if (!can(9)) {
            history.goBack();
        }
    }, []);

    const [filterData, setFilterData] = useState({
        userId: '',
        brandId: user.roleId === '3' ? user.UserBrand[0].brandId : '',
        clientBrandsId: '',
        type: '',
        startDate: new Date(),
        endDate: new Date(),
    });

    const resetSearch = () => {
        setIsSearch(false);
        setSearch('');
    };

    const resetFilter = () => {
        setIsFilter(false);
        setFilterData({
            userId: '',
            brandId: user.roleId === '3' ? user.UserBrand[0].brandId : '',
            clientBrandsId: '',
            type: '',
            startDate: new Date(),
            endDate: new Date(),
        });
    };

    const onChangeOpenAdd = useCallback((prop) => {
        setOpenAdd(prop);
    }, []);

    const getAllUsers = useCallback(() => {
        if (user.roleId === '1' || user.roleId === '2') {
            UsersAPI.getByBrandsIdNoPagination([1, 2, 3]).then((res) => {
                setUsers(res.data.content);
            });
        } else if (user.roleId === '3') {
            UsersAPI.getByBrandsIdNoPagination(user.UserBrand[0].brandId).then((res) => {
                setUsers(res.data.content);
            });
        }
    }, []);

    const getClients = useCallback((id) => {
        ClientsAPI.getByUserIdBrands(id).then((res) => {
            setClients(
                res.data.content
                    .map((client) => ({
                        ...client.Clients,
                        brandId: client.brandId,
                        userId: client.userId,
                        clientsBrandId: client.id,
                        brand: client.Brand,
                        user: client.User,
                        clientUID: client.id,
                    }))
                    .filter((item) => user.UserBrand.map((uBrand) => uBrand.brandId).includes(item.brandId))
            );
        });
    }, []);

    useEffect(() => {
        if (user.roleId === '4') {
            getClients(user.id);
        }
    }, [getClients]);

    const refreshToDoTasks = useCallback((props) => {
        const { actionCase, id = null, item = null } = props;

        switch (actionCase) {
            case 'add':
                setToDoTasks((prev) => [...prev, item]);
                break;
            case 'edit':
                setToDoTasks((current) =>
                    current.map((obj) => {
                        if (obj.id === item.id) {
                            return { ...obj, ...item };
                        }
                        return obj;
                    })
                );
                break;
            case 'delete':
                setToDoTasks((current) => current.filter((obj) => obj.id !== id));
                break;
            case 'confirm':
                setToDoTasks((current) => current.filter((obj) => obj.id !== item.id));
                setDoneTasks((prev) => [...prev, item]);
                break;
            default:
        }
    }, []);

    const refreshDoneTasks = useCallback((props) => {
        const { id } = props;
        setDoneTasks((current) => current.filter((obj) => obj.id !== id));
    }, []);

    const getToDoTasks = useCallback(() => {
        setLoadingToDo(true);

        if (user.roleId === '1' || user.roleId === '2' || user.roleId > '5') {
            TasksAPI.getByStatus(false, 20, pageNumberToDo, 1).then((res) => {
                setToDoTasks((prev) =>
                    [...prev, ...res.data.content].reduce((item, current) => {
                        const value = item.find((item) => item.id === current.id);
                        if (!value) {
                            return item.concat([current]);
                        } else return item;
                    }, [])
                );
                setHasMoreToDo(res.data.content.length > 0);
                setLoadingToDo(false);
            });
        } else if (user.roleId === '3') {
            TasksAPI.getByBrandId(user.UserBrand[0].brandId, false, 20, pageNumberToDo, 1).then((res) => {
                setToDoTasks((prev) =>
                    [...prev, ...res.data.content].reduce((item, current) => {
                        const value = item.find((item) => item.id === current.id);
                        if (!value) {
                            return item.concat([current]);
                        } else return item;
                    }, [])
                );
                setHasMoreToDo(res.data.content.length > 0);
                setLoadingToDo(false);
            });
        } else if (user.roleId === '4') {
            TasksAPI.getByUserId(user.id, false, 20, pageNumberToDo, 1).then((res) => {
                setToDoTasks((prev) =>
                    [...prev, ...res.data.content].reduce((item, current) => {
                        const value = item.find((item) => item.id === current.id);
                        if (!value) {
                            return item.concat([current]);
                        } else return item;
                    }, [])
                );
                setHasMoreToDo(res.data.content.length > 0);
                setLoadingToDo(false);
            });
        }
    }, [pageNumberToDo]);

    const getDoneTasks = useCallback(() => {
        setLoadingDone(true);

        if (user.roleId === '1' || user.roleId === '2' || user.roleId > '5') {
            TasksAPI.getByStatus(true, 20, pageNumberDone, 1).then((res) => {
                setDoneTasks((prev) =>
                    [...prev, ...res.data.content].reduce((item, current) => {
                        const value = item.find((item) => item.id === current.id);
                        if (!value) {
                            return item.concat([current]);
                        } else return item;
                    }, [])
                );
                setHasMoreDone(res.data.content.length > 0);
                setLoadingDone(false);
            });
        } else if (user.roleId === '3') {
            TasksAPI.getByBrandId(user.id, true, 20, pageNumberDone, 1).then((res) => {
                setDoneTasks((prev) =>
                    [...prev, ...res.data.content].reduce((item, current) => {
                        const value = item.find((item) => item.id === current.id);

                        if (!value) {
                            return item.concat([current]);
                        } else return item;
                    }, [])
                );
                setHasMoreDone(res.data.content.length > 0);
                setLoadingDone(false);
            });
        } else if (user.roleId === '4') {
            TasksAPI.getByUserId(user.id, true, 20, pageNumberDone, 1).then((res) => {
                setDoneTasks((prev) =>
                    [...prev, ...res.data.content].reduce((item, current) => {
                        const value = item.find((item) => item.id === current.id);

                        if (!value) {
                            return item.concat([current]);
                        } else return item;
                    }, [])
                );
                setHasMoreDone(res.data.content.length > 0);
                setLoadingDone(false);
            });
        }
    }, [pageNumberDone]);

    useEffect(() => {
        getAllUsers();
    }, [getAllUsers]);

    useEffect(() => {
        getToDoTasks();
    }, [pageNumberToDo, getToDoTasks]);

    useEffect(() => {
        getDoneTasks();
    }, [pageNumberDone, getDoneTasks]);

    const getSearchedTasks = () => {
        TasksAPI.searchTasks(search).then((res) => {
            if (user.roleId === '1' || user.roleId === '2') {
                setToDoTasks(res.data.filter((item) => item.status === false));
                setDoneTasks(res.data.filter((item) => item.status === true));
            } else if (user.roleId === '3') {
                setToDoTasks(
                    res.data.filter(
                        (item) =>
                            item.status === false &&
                            ((item.ClientBrands !== null && item.ClientBrands.brandId === user.UserBrand[0].brandId) ||
                                (item.ClientBrands === null &&
                                    item.user.UserBrand.some((brand) =>
                                        user.UserBrand.map((uBrand) => uBrand.brandId).includes(brand.brandId)
                                    )))
                    )
                );
                setDoneTasks(
                    res.data.filter(
                        (item) =>
                            item.status === true &&
                            ((item.ClientBrands !== null && item.ClientBrands.brandId === user.UserBrand[0].brandId) ||
                                (item.ClientBrands === null &&
                                    item.user.UserBrand.some((brand) =>
                                        user.UserBrand.map((uBrand) => uBrand.brandId).includes(brand.brandId)
                                    )))
                    )
                );
            } else if (user.roleId === '4') {
                setToDoTasks(res.data.filter((item) => item.status === false && item.userId === user.id));
                setDoneTasks(res.data.filter((item) => item.status === true && item.userId === user.id));
            }
            setLoadingToDo(false);
            setLoadingDone(false);
        });
    };

    const getFilterTasks = () => {
        TasksAPI.filterTasks(filterData).then((res) => {
            if (user.roleId === '1' || user.roleId === '2') {
                setToDoTasks(res.data.filter((item) => item.status === false));
                setDoneTasks(res.data.filter((item) => item.status === true));
            } else if (user.roleId === '3') {
                setToDoTasks(
                    res.data.filter(
                        (item) =>
                            item.status === false &&
                            ((item.ClientBrands !== null && item.ClientBrands.brandId === user.UserBrand[0].brandId) ||
                                (item.ClientBrands === null &&
                                    item.user.UserBrand.some((brand) =>
                                        user.UserBrand.map((uBrand) => uBrand.brandId).includes(brand.brandId)
                                    )))
                    )
                );
                setDoneTasks(
                    res.data.filter(
                        (item) =>
                            item.status === true &&
                            ((item.ClientBrands !== null && item.ClientBrands.brandId === user.UserBrand[0].brandId) ||
                                (item.ClientBrands === null &&
                                    item.user.UserBrand.some((brand) =>
                                        user.UserBrand.map((uBrand) => uBrand.brandId).includes(brand.brandId)
                                    )))
                    )
                );
            } else if (user.roleId === '4') {
                setToDoTasks(res.data.filter((item) => item.status === false && item.userId === user.id));
                setDoneTasks(res.data.filter((item) => item.status === true && item.userId === user.id));
            }
            setLoadingToDo(false);
            setLoadingDone(false);
        });
    };

    useEffect(() => {
        if (!isSearch) return;

        getSearchedTasks();
    }, [triggerSearch]);

    useEffect(() => {
        if (!isFilter) return;

        getFilterTasks();
    }, [triggerFilter]);

    useEffect(() => {
        if (!triggerRefetch) return;

        if (pageNumberToDo > 0) {
            setDoneTasks([]);
            setPageNumberToDo(0);
        } else {
            getToDoTasks();
        }

        if (pageNumberDone > 0) {
            setToDoTasks([]);
            setPageNumberDone(0);
        } else {
            getDoneTasks();
        }

        setTriggerRefetch(false);
    }, [triggerRefetch]);

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Tasks')}</title>
            </Helmet>
            <PageLayout
                title={t('Tasks')}
                subTitle={t('Manage Tasks')}
                actionArea={
                    <React.Fragment>
                        <Search
                            resetSearch={resetSearch}
                            search={search}
                            setSearch={setSearch}
                            isSearch={isSearch}
                            setIsSearch={setIsSearch}
                            nameToFind={t('Search task')}
                            triggerRefetch={triggerRefetch}
                            setTriggerRefetch={setTriggerRefetch}
                            triggerSearch={triggerSearch}
                            setTriggerSearch={setTriggerSearch}
                            setterFunction={activeTab === 0 ? setToDoTasks : activeTab === 1 ? setDoneTasks : () => {}}
                            setLoading={activeTab === 0 ? setLoadingToDo : activeTab === 1 ? setLoadingDone : () => {}}
                        />
                        <PrimaryButton startIcon={<FilterListIcon />} color="light" onClick={() => setOpenFilter(true)}>
                            {t('FILTER TASKS')}
                        </PrimaryButton>
                        {can(8) && (
                            <PrimaryButton
                                startIcon={<AddIcon />}
                                color="primary"
                                onClick={() => onChangeOpenAdd(true)}
                            >
                                {t('ADD TASK')}
                            </PrimaryButton>
                        )}
                    </React.Fragment>
                }
            >
                {!toDoTasks || !doneTasks ? (
                    <LoadingSpinner margin="0 auto" loading={!toDoTasks || !doneTasks} />
                ) : (
                    <TasksComponent
                        toDoTasks={toDoTasks}
                        doneTasks={doneTasks}
                        getToDoTasks={refreshToDoTasks}
                        getDoneTasks={refreshDoneTasks}
                        loadingToDo={loadingToDo}
                        loadingDone={loadingDone}
                        clients={clients}
                        setPageNumberDone={setPageNumberDone}
                        pageNumberDone={pageNumberDone}
                        setPageNumberToDo={setPageNumberToDo}
                        pageNumberToDo={pageNumberToDo}
                        hasMoreDone={hasMoreDone}
                        hasMoreToDo={hasMoreToDo}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        isSearch={isSearch}
                        isFilter={isFilter}
                        setToDoTasks={setToDoTasks}
                        setDoneTasks={setDoneTasks}
                    />
                )}
            </PageLayout>
            <AddTask
                open={openAdd}
                setOpen={onChangeOpenAdd}
                getToDoTasks={refreshToDoTasks}
                clients={clients}
                users={users}
                userId={user.roleId === '4' && Number(user.id)}
                getClients={getClients}
            />
            <FilterTasks
                open={openFilter}
                setOpen={setOpenFilter}
                clients={clients}
                setClients={setClients}
                filterData={filterData}
                setFilterData={setFilterData}
                triggerFilter={triggerFilter}
                setTriggerFilter={setTriggerFilter}
                triggerRefetch={triggerRefetch}
                setTriggerRefetch={setTriggerRefetch}
                setIsFilter={setIsFilter}
                setLoading={activeTab === 0 ? setLoadingToDo : activeTab === 1 ? setLoadingDone : () => {}}
                setData={activeTab === 0 ? setToDoTasks : activeTab === 1 ? setDoneTasks : () => {}}
                resetSearch={resetSearch}
                resetFilter={resetFilter}
                isFilter={isFilter}
                users={users}
                getClients={getClients}
            />
        </>
    );
};

export default Tasks;
