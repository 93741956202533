// react imports
import React, { useState, useRef, useEffect } from 'react';
import { useUser } from 'hooks/useUser';

import { Formik, Form } from 'formik';

import { ThemeProvider } from '@mui/material/styles';
import { Typography, Box, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { TextField, PasswordField } from 'lib';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import customTheme from 'lib/theme';
import { UtilityButton, NavigationButton } from 'lib/components/Buttons/buttons.jsx';

import { toast } from 'react-toastify';
import * as yup from 'yup';
import { emailRegex } from 'utils/regex';
import axios from 'api_darex/axios';

import loginImage from 'resources/LoginFormImage.png';
import logoRaisis from 'resources/LogoRaisisCRM.png';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { UsersAPI } from 'api_darex';

function Login() {
    const { t } = useTranslation();

    const theme = useTheme();
    const mobilePhone = useMediaQuery(theme.breakpoints.down('md'));

    const formRef = useRef();
    const recoverFormRef = useRef();

    const [recoverPassword, setRecoverPassword] = useState(false);

    const { setAccessToken } = useUser();

    /**
     * Login form
     */
    const INITIAL_FORM_STATE = {
        email: '',
        password: '',
    };

    const FORM_VALIDATION = yup.object().shape({
        email: yup
            .string()
            .trim()
            .matches(emailRegex, t('You must enter a valid email address!'))
            .required(t('Email address is mandatory!')),
        password: yup
            .string()
            .trim()
            .typeError(t('You must enter a valid password format!'))
            .required(t('Password is required!')),
    });

    const handleSubmitFormik = () => {
        if (formRef.current) {
            formRef.current.handleSubmit();
        }
    };

    const handleLogin = async (values) => {
        try {
            const response = await axios.post('/users/login', {
                email: values.email,
                password: values.password,
            });
            const { token } = response.data;
            toast.success(t('You have successfully logged in!'));
            setAccessToken(token);
        } catch (error) {
            console.error(error);
            toast.error(t(error.response.data.errors.message));
        }
    };

    /**
     * Recover password form
     */
    const INITIAL_FORM_STATE_RECOVER = {
        email: '',
    };

    const FORM_VALIDATION_RECOVER = yup.object().shape({
        email: yup
            .string()
            .trim()
            .matches(emailRegex, t('You must enter a valid email address!'))
            .required(t('Email address is mandatory!')),
    });

    const handleSubmitRecoverForm = () => {
        if (recoverFormRef.current) {
            recoverFormRef.current.handleSubmit();
        }
    };

    const handleRecoverPassword = async (values) => {
        UsersAPI.recoverPassword(values.email).then((res) => {
            if (res.ok) {
                setRecoverPassword(false);
                toast.success(t('You have successfully sent the reset link!'));
            } else {
                toast.error(t(res.error.response.data.message));
            }
        });
    };

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();

                if (recoverPassword === true) {
                    handleSubmitRecoverForm();
                } else {
                    handleSubmitFormik();
                }
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [recoverPassword]);

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {t('Login')}</title>
            </Helmet>
            <ThemeProvider theme={customTheme}>
                <Box
                    sx={{
                        width: '100vw',
                        minHeight: '100vh',
                        backgroundColor: '#FFFFFF',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            position: 'relative',
                            flex: 1,
                            gap: '7.5vw',
                            display: 'flex',
                            flexDirection: mobilePhone ? 'column' : 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: '2.5rem',
                        }}
                    >
                        <img
                            style={{
                                width: '40%',
                                maxWidth: '400px',
                            }}
                            src={loginImage}
                            alt="Darex Auto"
                        />
                        <Box
                            sx={{
                                width: mobilePhone ? '90vw' : '40vw',
                                maxWidth: '500px',
                                flexShrink: 0,
                                borderRadius: '15px',
                                padding: '25px 32px',
                                boxShadow: '#eaeef4 0px 7px 29px 0px;',
                            }}
                        >
                            {recoverPassword === false ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                                    <Formik
                                        innerRef={formRef}
                                        initialValues={{
                                            ...INITIAL_FORM_STATE,
                                        }}
                                        validationSchema={FORM_VALIDATION}
                                        onSubmit={(values) => {
                                            handleLogin(values);
                                        }}
                                    >
                                        <Form>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '25px',
                                                }}
                                            >
                                                <TextField name="email" label="Email" />
                                                <PasswordField name="password" label="Parola" />
                                            </div>
                                        </Form>
                                    </Formik>

                                    <UtilityButton
                                        onClick={handleSubmitFormik}
                                        color="blue"
                                        variant="contained"
                                        style={{
                                            padding: '12px 14px',
                                        }}
                                    >
                                        {t('Login')}
                                    </UtilityButton>
                                    <Divider />
                                    <UtilityButton
                                        onClick={() => setRecoverPassword(true)}
                                        color="blue"
                                        style={{
                                            padding: '12px 14px;',
                                        }}
                                    >
                                        {t('Recover password')}
                                    </UtilityButton>
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                                    <div>
                                        <NavigationButton
                                            onClick={() => setRecoverPassword(false)}
                                            startIcon={<NavigateBeforeIcon />}
                                        >
                                            <Typography sx={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.62)' }}>
                                                <div onClick={() => setRecoverPassword(false)}>{t('Back')}</div>
                                            </Typography>
                                        </NavigationButton>
                                    </div>
                                    <Typography variant="loginForm">
                                        {t(
                                            'Enter the email address you created your account with. You will receive a password reset link at this address.'
                                        )}
                                    </Typography>

                                    <Formik
                                        innerRef={recoverFormRef}
                                        initialValues={{
                                            ...INITIAL_FORM_STATE_RECOVER,
                                        }}
                                        validationSchema={FORM_VALIDATION_RECOVER}
                                        onSubmit={(values) => {
                                            handleRecoverPassword(values);
                                        }}
                                    >
                                        <Form>
                                            <TextField name="email" label="Email" />
                                        </Form>
                                    </Formik>

                                    <UtilityButton
                                        onClick={handleSubmitRecoverForm}
                                        color="blue"
                                        variant="contained"
                                        style={{
                                            padding: '12px 14px',
                                        }}
                                    >
                                        {t('Send reset link')}
                                    </UtilityButton>
                                </Box>
                            )}
                        </Box>
                    </Box>

                    <img
                        src={logoRaisis}
                        alt="logo raisis"
                        style={{ flexShrink: 0, width: '35%', maxWidth: '256px', paddingBlock: '2.5rem' }}
                    ></img>
                </Box>
            </ThemeProvider>
        </>
    );
}

export default Login;
