import React, { useState } from 'react';
import { MenuItem, Typography } from '@mui/material';
import { TextField } from 'lib';
import styles from './UnitForm.module.scss';
import { Select } from 'lib';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { VehiclesAPI } from 'api_darex';
import radioStyles from 'styles/radioGroup.module.scss';
import { useEffect } from 'react';
import { useUser } from 'hooks/useUser';

const UnitForm = ({ formikRef, onSubmit, unit, client }) => {
    const { t } = useTranslation();
    const [modelOptions, setModelOptions] = useState([]);
    const [colorOptions, setColorOptions] = useState([]);
    const [engineOptions, setEngineOptions] = useState([]);

    const { user } = useUser();

    const brands = ['DACIA', 'RENAULT', 'NISSAN'];

    const brandOfTheClient = () => {
        let brandArray = [];
        switch (client.brandId) {
            case '1':
                brandArray = ['Dacia'];
                break;

            case '2':
                brandArray = ['Renault'];
                break;
            case '3':
                brandArray = ['Nissan'];
                break;

            default:
                break;
        }

        return brandArray;
    };

    // const brands = formikRef.current.values.brandId;

    const createMode = Object.keys(unit).length === 0;

    let initialValues = {
        brandId: '',
        modelId: '',
        modelColorId: '',
        modelEngineId: '',
        price: '',
        purchaseCategory: 'REMAT', // Additional key added by devs to track if it is REMAT
        purchaseType: 'DOSAR_DEPUS',
        details: '',
        options: '',
        accessories: '',
        paymentType: 'CASH',
    };

    const validationSchema = yup.object().shape({
        brandId: yup.string().typeError(t('Choose a brand!')).trim().required(t('Choose a brand')),
        modelId: yup.string().typeError(t('Choose a brand!')).trim().required(t('Choose a model')),
        modelColorId: yup.string().typeError(t('Choose a color')).trim().required(t('Choose a color')),
        modelEngineId: yup.string().typeError(t('Choose an engine model')).trim().required(t('Choose an engine model')),
        price: yup.string().typeError(t('Choose a price')).trim().required(t('Choose a price')),
        details: yup.string().trim(),
        options: yup.string().trim(),
        accessories: yup.string().trim(),
    });

    if (!createMode) {
        initialValues = { ...unit };
        if (unit.purchaseType !== 'NON_REMAT') {
            initialValues.purchaseCategory = 'REMAT';
        } else initialValues.purchaseCategory = 'NON_REMAT';
    }

    const getData = (brandId, modelId) => {
        VehiclesAPI.getAllVehicles(brandId).then((res) => {
            setModelOptions(res.data.content);

            if (modelId !== null) {
                VehiclesAPI.getModelColors(modelId).then((res) => setColorOptions(res.data));
                VehiclesAPI.getModelEngines(modelId).then((res) => setEngineOptions(res.data));
            }
        });
    };

    const handleDropdownChange = (brandId, modelId = null) => {
        getData(brandId, modelId);

        formikRef.current.values.modelId = '';
        formikRef.current.values.modelColorId = '';
        formikRef.current.values.modelEngineId = '';
    };

    useEffect(() => {
        if (createMode) return;

        const { brandId, modelId } = unit;
        getData(brandId, modelId);
    }, [unit, createMode]);

    return (
        <div className={styles.container}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    const finalObj = { ...values };

                    /*
                        Generate id for unit if it doesn't already have one
                        This applies to the newly created unit
                    */
                    if (!('id' in finalObj)) finalObj.id = Date.now();

                    /*
                        Establish purchase type & category
                        Category is a key only on front end, it does not go to the server
                        Used to separate available options Remat / Non-remat
                        Remat has 3 sub options that we only display when remat is chosen
                    */
                    if (values.purchaseCategory === 'NON_REMAT') finalObj.purchaseType = values.purchaseCategory;
                    delete finalObj.purchaseCategory;

                    if (!client.CUI && !values.purchaseCategory === 'NON_REMAT') finalObj.purchaseType = 'REMAT';

                    /*
                        Additional key with model & color & engine name
                        Otherwise we would have to fetch them again in the parent component
                    */
                    finalObj.unitRenderData = {
                        brand: brands[Number(finalObj.brandId) - 1],
                        model: modelOptions.filter((i) => i.id === finalObj.modelId)[0].name,
                        color: colorOptions.filter((i) => i.id === finalObj.modelColorId)[0].color.name,
                        engine: engineOptions.filter((i) => i.id === finalObj.modelEngineId)[0].engine.name,
                    };

                    onSubmit(finalObj);
                }}
                innerRef={formikRef}
            >
                {(props) => (
                    <Form>
                        <Typography variant="h6" sx={{ mb: '12px' }}>
                            {t('Select unit options')}
                        </Typography>

                        <div className={styles.column_container}>
                            {/* First column */}
                            <div className={styles.column}>
                                {/* Brand select */}
                                <Select sx name="brandId" label={t('Brand')}>
                                    {brandOfTheClient().map((brand, i) => (
                                        <MenuItem
                                            key={i}
                                            value={brand === 'Dacia' ? '1' : brand === 'Renault' ? '2' : '3'}
                                            onClick={() =>
                                                handleDropdownChange(
                                                    brand === 'Dacia' ? '1' : brand === 'Renault' ? '2' : '3'
                                                )
                                            }
                                        >
                                            {brand}
                                        </MenuItem>
                                    ))}
                                </Select>

                                {/* Model select */}
                                <Select sx name="modelId" label={t('Model')}>
                                    {modelOptions.map((i) => (
                                        <MenuItem
                                            key={i.id}
                                            value={i.id}
                                            onClick={() => handleDropdownChange(props.values.brandId, i.id)}
                                        >
                                            {i.name}
                                        </MenuItem>
                                    ))}
                                </Select>

                                {/* Color select */}
                                <Select sx name="modelColorId" label={t('Color')}>
                                    {colorOptions.map((i) => (
                                        <MenuItem key={i.id} value={i.id}>
                                            {i.color.name}
                                        </MenuItem>
                                    ))}
                                </Select>

                                {/* Engine select */}
                                <Select sx name="modelEngineId" label={t('Engine')}>
                                    {engineOptions.map((i) => (
                                        <MenuItem key={i.id} value={i.id}>
                                            {i.engine.name}
                                        </MenuItem>
                                    ))}
                                </Select>

                                <TextField name="price" label={t('Price')} type={'number'} />

                                <div>
                                    <Typography sx={{ mb: 2 }}>{t('Purchase type')}</Typography>
                                    <div role="group" className={radioStyles.radioGroupWrapper}>
                                        <label>
                                            <Field type="radio" name="purchaseCategory" value="REMAT" />
                                            <div className={radioStyles.labelName}>Remat</div>
                                        </label>
                                        <label>
                                            <Field type="radio" name="purchaseCategory" value="NON_REMAT" />
                                            <div className={radioStyles.labelName}>Non-remat</div>
                                        </label>
                                    </div>

                                    <div role="group" className={radioStyles.radioGroupWrapper}>
                                        {props.values.purchaseCategory === 'REMAT' && client.CUI && (
                                            <React.Fragment>
                                                <label>
                                                    <Field type="radio" name="purchaseType" value="DOSAR_DEPUS" />
                                                    <div className={radioStyles.labelName}>{t('File submitted')}</div>
                                                </label>
                                                <label>
                                                    <Field type="radio" name="purchaseType" value="DOSAR_NEDEPUS" />
                                                    <div className={radioStyles.labelName}>
                                                        {t('File not submitted')}
                                                    </div>
                                                </label>

                                                <label>
                                                    <Field type="radio" name="purchaseType" value="DOSAR_AFM" />
                                                    <div className={radioStyles.labelName}>
                                                        {t('AFM validated file')}
                                                    </div>
                                                </label>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {/* Second column */}
                            <div className={styles.column}>
                                <div>
                                    <Typography variant="h6">{t('Additional information')}</Typography>
                                    <TextField name="details" multiline rows={2} />
                                </div>

                                <div>
                                    <Typography variant="h6">{t('Options')}</Typography>
                                    <TextField name="options" multiline rows={2} />
                                </div>

                                <div>
                                    <Typography variant="h6">{t('Accessories')}</Typography>
                                    <TextField name="accessories" multiline rows={2} />
                                </div>

                                <div>
                                    <Typography sx={{ mb: 2 }}>{t('Payment type')}</Typography>
                                    <div role="group" className={radioStyles.radioGroupWrapper}>
                                        <label>
                                            <Field type="radio" name="paymentType" value="CASH" />
                                            <div className={radioStyles.labelName}>{t('Cash')}</div>
                                        </label>
                                        <label>
                                            <Field type="radio" name="paymentType" value="RCI" />
                                            <div className={radioStyles.labelName}>{t('RCI')}</div>
                                        </label>

                                        <label>
                                            <Field type="radio" name="paymentType" value="OTHER" />
                                            <div className={radioStyles.labelName}>{t('Other funder')}</div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

UnitForm.propTypes = {
    onSubmit: PropTypes.func,
    unit: PropTypes.object,

    // Required
    formikRef: PropTypes.object.isRequired,
};

UnitForm.defaultProps = {
    unit: {},
    onSubmit: (values) => {},
};

export default UnitForm;
