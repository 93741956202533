import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomDialog } from 'lib';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { Select } from 'lib';
import { ClientsAPI } from 'api_darex';
import { MenuItem } from '@mui/material';
import { toast } from 'react-toastify';

const EditClientCounselor = ({ open, setOpen, client, addCallback }) => {
    const { t } = useTranslation();

    const formikRef = useRef();

    const initialValues = {
        status: client.status,
    };

    const validationSchema = yup.object().shape({
        status: yup.string().required(t('Choose the status')),
    });

    const handleSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit();
        }
    };

    const statuses = [
        { id: 1, name: t('Active'), value: 'New', disabled: false },
        { id: 2, name: t('Sleep'), value: 'Sleep', disabled: true },
    ];

    const handleSubmitStatus = (id, status) => {
        id = id.toString();
        status = status.toString();
        ClientsAPI.updateStatus(id, status).then((res) => {
            if (res.ok === true) {
                addCallback();
                setOpen(false);
                toast.success(t('Status changed succesfully'));
            } else {
                toast.error(t('An error ocurred. Please try again'));
            }
        });
    };

    return (
        <CustomDialog
            setOpen={setOpen}
            title={t('Change status')}
            buttonClose={t('BACK')}
            open={open}
            buttonFinish={t('COMPLETE')}
            onClickButtonFinish={handleSubmit}
        >
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmitStatus(client.id, values.status)}
            >
                <Form>
                    <Select name="status">
                        {statuses.map((status) => (
                            <MenuItem key={status.id} value={status.value} disabled={status.disabled}>
                                {status.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Form>
            </Formik>
        </CustomDialog>
    );
};

export default EditClientCounselor;
