import React from 'react';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { styled } from '@mui/material/styles';

import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import { UtilityButton } from 'lib/components/Buttons/buttons';
import PropTypes from 'prop-types';
// mui styled components

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const VisualizeRepresentative = ({ representativeInfo, onDelete, onClickEdit }) => {
    const [expanded, setExpanded] = React.useState();
    const { vw } = useWindowDimensions();
    const { t } = useTranslation();

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const noPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <div onClick={noPropagation}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={0}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Grid container justifyContent={'flex-start'} spacing={2} direction={vw < 670 ? 'column' : 'row'}>
                        <Grid item xs={4}>
                            <Typography variant="smallCardHeader">
                                {representativeInfo?.firstName + ' ' + representativeInfo?.lastName}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography variant="smallCardHeader">
                                <li> {representativeInfo?.phone} </li>
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography variant="smallCardHeader">
                                <li>{representativeInfo?.email}</li>
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{ paddingLeft: '20px' }}>
                        <Grid
                            container
                            justifyContent={'flex-start'}
                            spacing={2}
                            direction={vw < 670 ? 'column' : 'row'}
                        >
                            <Grid item xs={12}>
                                <div style={{ marginBottom: '8px' }}>
                                    <Typography variant="clientData"> {t('Representative Details')} </Typography>
                                </div>
                                <div>
                                    <Typography variant={'smallCardHeader'}>
                                        {representativeInfo?.details || ' - '}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{ marginBottom: '8px' }}>
                                    <Typography variant="clientData"> {t('Representative Role')} </Typography>
                                </div>
                                <div>
                                    <Typography variant={'smallCardHeader'}>
                                        {representativeInfo?.representativeRole || ' - '}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>

                        {representativeInfo.clientsId === undefined && (
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '1rem',
                                    flexDirection: vw < 600 ? 'column' : 'row',
                                    marginTop: '16px',
                                }}
                            >
                                <div>
                                    <UtilityButton onClick={onClickEdit} startIcon={<CreateIcon />}>
                                        {t('Edit representative')}
                                    </UtilityButton>
                                </div>
                                <div>
                                    <UtilityButton onClick={onDelete} startIcon={<DeleteIcon />} color="red">
                                        {t('Delete representative')}
                                    </UtilityButton>
                                </div>
                            </div>
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

VisualizeRepresentative.propTypes = {
    representativeInfo: PropTypes.object,
    onDelete: PropTypes.func,
    onClickEdit: PropTypes.func,
};

VisualizeRepresentative.defaultProps = {
    representativeInfo: null,
    onDelete: () => {},
    onClickEdit: () => {},
};

export default VisualizeRepresentative;
